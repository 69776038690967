import React from "react";
import { CircularProgress } from "@material-ui/core";
import { InView } from "react-intersection-observer";

const LOADING_SPINNER_HEIGHT = 18;

interface Props {
  onLoadMore: () => void;
}

export const LoadMoreOnScroll = ({ onLoadMore }: Props): JSX.Element => {
  const handleChange = (isInViewport: boolean): void => {
    if (isInViewport) {
      onLoadMore();
    }
  };

  return (
    <InView as="div" onChange={handleChange}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: LOADING_SPINNER_HEIGHT,
        }}
      >
        <CircularProgress
          color="primary"
          style={{
            width: LOADING_SPINNER_HEIGHT,
            height: LOADING_SPINNER_HEIGHT,
          }}
        />
      </div>
    </InView>
  );
};
