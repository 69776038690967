import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  ExclamationSolid,
  ExclamationCircleSolid,
  Typography,
  Chip,
  useNumberFormatter,
  useTranslation,
  useSession,
} from "@lumar/shared";
import { RuleAndThreshold } from "./utils/types";
import {
  ReportTemplateUnit,
  Severity,
  ThresholdPredicate,
} from "../../graphql";
import { clsx } from "clsx";
import {
  ReportChangeTitle,
  IfImprovesTitle,
  IfWorsensTitle,
  ReportTitle,
  RuleTitle,
  ReportSegmentTitle,
  SeverityTitle,
  ThresholdTitle,
} from "./table/RulesAndThresholdsTableTitles";
import { BaseReport } from "../../_common/utils/constants";

export function RulesAndThresholdsViewTable<T extends BaseReport>({
  testList,
  className,
  title,
  getTrendRenderer,
  typeCell = <ReportTitle />,
  segmentCell = <ReportSegmentTitle />,
  changeCell = <ReportChangeTitle />,
}: {
  testList: RuleAndThreshold<T>[];
  className?: string;
  title?: string;
  getTrendRenderer: (test: RuleAndThreshold<T>) => React.ReactNode;
  typeCell?: React.ReactNode;
  segmentCell?: React.ReactNode;
  changeCell?: React.ReactNode;
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { t } = useTranslation(["alerts", "units", "common", "thresholds"]);
  const formatNumber = useNumberFormatter();

  return (
    <div className={clsx(classes.element, className)}>
      {Boolean(title) && (
        <Typography variant="subtitle2SemiBold" className={classes.title}>
          {title}
        </Typography>
      )}
      <div className={classes.wrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                className={clsx(classes.headerCell, classes.severityCell)}
              >
                <SeverityTitle />
              </TableCell>
              <TableCell className={classes.headerCell}>{typeCell}</TableCell>
              {segmentationAvailable && (
                <TableCell className={classes.headerCell}>
                  {segmentCell ?? <ReportSegmentTitle />}
                </TableCell>
              )}
              <TableCell className={clsx(classes.headerCell, classes.ruleCell)}>
                <RuleTitle />
              </TableCell>
              <TableCell className={classes.headerCell}>
                <ThresholdTitle />
              </TableCell>
              <TableCell
                className={clsx(classes.headerCell, classes.issuesCell)}
              >
                <IfImprovesTitle />
              </TableCell>
              <TableCell
                className={clsx(classes.headerCell, classes.issuesCell)}
              >
                <IfWorsensTitle />
              </TableCell>
              <TableCell className={classes.changeHeader}>
                {changeCell ?? <ReportChangeTitle />}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testList.map((test, index) => {
              return (
                <TableRow
                  key={test.id}
                  className={classes.tableRow}
                  data-testid={`rule-and-threshold-row-${index}`}
                  data-testid-2={`rule-and-threshold-row-${test.report?.name.replace(
                    " ",
                    "-",
                  )}${
                    test.segment
                      ? `-${test.segment?.name.replace(" ", "-")}`
                      : ""
                  }`}
                >
                  <TableCell className={classes.centeredRowCell}>
                    <Tooltip
                      title={t(`common:${test.severity}`) as string}
                      arrow={false}
                    >
                      {test.severity === Severity.Warning ? (
                        <ExclamationSolid
                          className={classes.severityIcon}
                          htmlColor={theme.palette.yellow[400]}
                        />
                      ) : (
                        <ExclamationCircleSolid
                          className={classes.severityIcon}
                          htmlColor={theme.palette.red[400]}
                        />
                      )}
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.rowCell}>
                    <Typography
                      variant="body2"
                      className={classes.limitedCellSize}
                    >
                      {test.report.name}
                    </Typography>
                  </TableCell>
                  {segmentationAvailable && (
                    <TableCell className={classes.rowCell}>
                      <Typography
                        variant="body2"
                        className={classes.limitedCellSize}
                      >
                        {test.segment?.name ?? t("common:noSegments")}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell className={classes.centeredRowCell}>
                    <Tooltip
                      title={
                        (test.threshold ===
                        ThresholdPredicate.GreaterThanOrEqual
                          ? t("thresholds:greater")
                          : t("thresholds:less")) as string
                      }
                      arrow={false}
                    >
                      <Chip
                        color="primary"
                        size="small"
                        rounded={true}
                        label={
                          <div
                            style={{
                              padding: theme.spacing(0, 0.5),
                              fontSize: theme.typography.pxToRem(15),
                            }}
                          >
                            {test.threshold ===
                            ThresholdPredicate.GreaterThanOrEqual
                              ? "≥"
                              : "<"}
                          </div>
                        }
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.rowCell}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="body2">
                        {formatNumber(test.urls)}&nbsp;
                      </Typography>
                      <Typography variant="body2" className={classes.urls}>
                        {t(
                          `units:${test.report.unit ?? ReportTemplateUnit.UrLs}`,
                          {
                            count: test.urls,
                          },
                        )}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    className={clsx(classes.rowCell, classes.issuesCell)}
                  >
                    <Typography variant="body2">
                      {t(
                        `alerts:rulesAndThresholds:${test.thresholdAcceptanceWhenBetter}`,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={clsx(classes.rowCell, classes.issuesCell)}
                  >
                    <Typography variant="body2">
                      {t(
                        `alerts:rulesAndThresholds:${test.thresholdAcceptanceWhenWorse}`,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.rowCell}>
                    {getTrendRenderer(test)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  headerCell: {
    boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.grey[800],
  },
  table: {
    color: theme.palette.grey[700],
  },
  tableRow: {
    boxShadow: `0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset`,
    height: 56,
  },
  centeredRowCell: {
    textAlign: "center",
    boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
  },
  rowCell: {
    boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
    padding: theme.spacing(1.5, 3),
    color: theme.palette.grey[700],
    maxWidth: 200,
  },
  severityIcon: {
    fontSize: theme.typography.pxToRem(20),
  },
  changeHeader: {
    boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
    width: 205,
    maxWidth: 205,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  severityCell: {
    width: 60,
    maxWidth: 93,
  },
  ruleCell: {
    width: 50,
    maxWidth: 83,
  },
  urls: {
    color: theme.palette.grey[400],
  },
  issuesCell: {
    background: "#EBEFF3",
    width: 175,
    minWidth: 175,
    maxWidth: 175,
  },
  element: {
    display: "flex",
    flexFlow: "column",
  },
  wrapper: {
    overflow: "auto",
    borderRadius: theme.spacing(1, 1, 0, 0),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderCollapse: "unset",
    background: theme.palette.background.paper,
  },

  limitedCellSize: {
    textOverflow: "ellipsis",
    overflowX: "hidden",
    maxWidth: 200,
    minWidth: 100,
  },
  title: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(18.15),
    marginBottom: theme.spacing(0.5),
  },
}));
