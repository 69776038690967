import { ApolloError, NetworkStatus, useSession } from "@lumar/shared";
import React from "react";
import { useParams } from "react-router-dom";
import {
  GetViewsForCollectionQuery,
  useGetViewsForCollectionQuery,
} from "../../../../graphql";

interface ViewListLazyQueryReturnType {
  data: GetViewsForCollectionQuery | undefined;
  loading: boolean;
  hasFetchedAllData: boolean;
  error: ApolloError | undefined;
  handleLoadMore: () => void;
}

export function useGetViewsForBoardLazyQuery(
  skip: boolean,
  onCompleted?: (data: GetViewsForCollectionQuery) => void,
): ViewListLazyQueryReturnType {
  const { collectionId } = useParams<{ collectionId: string }>();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { data, error, loading, networkStatus, fetchMore } =
    useGetViewsForCollectionQuery({
      variables: {
        collectionId,
        hasSegmentation: segmentationAvailable,
      },
      skip: skip || !Boolean(collectionId),
      fetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
      onCompleted: onCompleted,
    });

  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const pageInfo = data?.getCustomDashboardCollection?.customViews?.pageInfo;
  const hasFetchedAllData =
    !loading && !isFetchingMore && !Boolean(pageInfo?.hasNextPage);

  const handleLoadMore = React.useCallback(() => {
    const pageInfo = data?.getCustomDashboardCollection?.customViews?.pageInfo;

    if (pageInfo?.hasNextPage && pageInfo.endCursor && !isFetchingMore) {
      fetchMore({
        variables: {
          collectionId,
          cursor: pageInfo.endCursor,
        },
      });
    }
  }, [fetchMore, data, isFetchingMore, collectionId]);

  return {
    data,
    error,
    loading: loading || isFetchingMore,
    hasFetchedAllData,
    handleLoadMore,
  };
}
