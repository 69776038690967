import {
  AuthPage,
  AuthProvider,
  GQLClientProvider,
  SessionProvider,
  SnackbarProvider,
  ChurnZeroProvider,
  useDocumentTitle,
  useTranslation,
} from "@lumar/shared";
import { Translation } from "react-i18next";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CreateNewAlert } from "./alerts/create/CreateNewAlert";
import { ManageAlerts } from "./alerts/manage/ManageAlerts";
import { Board } from "./board/Board";
import { BoardSettings } from "./board/settings/board/BoardSettings";
import { MarketingPage } from "./marketing-page/MarketingPage";
import { NotFound } from "./not-found/NotFound";
import { NotificationsPage } from "./notifications/NotificationsPage";
import { RedirectToAccountOverview } from "./redirect-to-account-overview/RedirectToAccountOverview";
import { UrlHistoryProvider } from "./UrlHistoryProvider";
import { Wrapper } from "./wrapper/Wrapper";
import { PendoData } from "./_common/pendo/PendoData";
import { MONITOR_ROUTES } from "./_common/routing/monitorRoutes";

export const App = (): JSX.Element => {
  const { t } = useTranslation("navigation");

  useDocumentTitle(t("title"));

  return (
    <BrowserRouter>
      <UrlHistoryProvider>
        <Translation ns="errors">
          {(t) => (
            <AuthProvider app="monitor">
              <GQLClientProvider
                name="monitor"
                version={process.env.REACT_APP_BUILD_HASH}
              >
                <SessionProvider
                  onSessionError={{
                    message: t("sessionError"),
                    actions: [
                      {
                        name: t("reload"),
                        onClick: () => location.reload(),
                      },
                    ],
                  }}
                >
                  <ChurnZeroProvider>
                    <>
                      <PendoData />
                      <SnackbarProvider>
                        <Switch>
                          <Route exact path={MONITOR_ROUTES.Index}>
                            <RedirectToAccountOverview />
                          </Route>
                          <Route exact path={MONITOR_ROUTES.MarketingPage}>
                            <MarketingPage />
                          </Route>
                          <Route exact path={MONITOR_ROUTES.Login}>
                            <AuthPage
                              onSession={(session, history) => {
                                if (session) {
                                  history.replace(MONITOR_ROUTES.Index);
                                }
                              }}
                            />
                          </Route>
                          <Route exact path={MONITOR_ROUTES.Dashboard}>
                            <Wrapper>
                              <Board />
                            </Wrapper>
                          </Route>
                          <Route exact path={MONITOR_ROUTES.CreateAlert}>
                            <Wrapper hideSidebar whiteBackground>
                              <CreateNewAlert />
                            </Wrapper>
                          </Route>
                          <Route exact path={MONITOR_ROUTES.Alerts}>
                            <Wrapper hideSidebar whiteBackground>
                              <ManageAlerts />
                            </Wrapper>
                          </Route>
                          <Route exact path={MONITOR_ROUTES.Notifications}>
                            <Wrapper hideSidebar>
                              <NotificationsPage />
                            </Wrapper>
                          </Route>
                          <Route exact path={MONITOR_ROUTES.BoardSettings}>
                            <Wrapper hideSidebar whiteBackground>
                              <BoardSettings />
                            </Wrapper>
                          </Route>
                          <Route component={() => <NotFound />} />
                        </Switch>
                      </SnackbarProvider>
                    </>
                  </ChurnZeroProvider>
                </SessionProvider>
              </GQLClientProvider>
            </AuthProvider>
          )}
        </Translation>
      </UrlHistoryProvider>
    </BrowserRouter>
  );
};
