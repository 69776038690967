/*
Because javascript floating point math is horrible, 0.57*100 = 56.9999999999 or 0.28*100 = 27.9999999999
we have to correct somehow this because we use Math.floor everywhere for transforming API health score to UI heath score,
so for 0.57 API health score we will get 56 on the UI
It is possible to correct this in many ways, Math.floor(Number((x*100).toFixed(2))),Math.floor(Number((x*100).toPrecision(5))),etc
But these functions are all using casting because .toFixed or .toPrecision are returning strings. 
Math.floor(x*100+0.00001) feels hacky but the other methods are confusing to and slow.
the 0.00001 part is just for adding a small amount of value over the multiplied value and like this we will not alter the value it self, 
but it will correct the error, and because we get 4 digit precision from API it will correct only the .999999999 value 
*/

export function healthScoreRound(value: number): number {
  return Math.floor(value + 0.00001);
}
