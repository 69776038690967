import {
  createStyles,
  makeStyles,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { InformationCircleOutlined } from "@lumar/shared";

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: theme.spacing(1.625, 2, 1.625, 2),
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 600,
      backgroundColor: theme.palette.background.paper,
      borderTopRightRadius: theme.shape.borderRadius,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomWidth: 0,
      filter:
        "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))",
      boxShadow: "none",
      height: 62,
    },
    iconRoot: {
      margin: theme.spacing(0.5, 0.375, 0.375, 0.875),
      color: theme.palette.grey["400"],
      fontSize: theme.typography.pxToRem(18),
      verticalAlign: "middle",
    },
  }),
);

interface MonitorGridHeaderProps {
  title: string;
  titleTooltipText: string;
  children?: React.ReactNode;
  loading: boolean;
}

export function MonitorGridHeader({
  title,
  titleTooltipText,
  children,
  loading,
}: MonitorGridHeaderProps): JSX.Element {
  const classes = useStyles();

  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={classes.header}
    >
      {title}

      {loading ? (
        <CircularProgress style={{ height: 10, width: 10, marginLeft: 8 }} />
      ) : null}

      <Tooltip
        title={titleTooltipText}
        arrow={false}
        data-pendo="monitor-dashboard-table-title-tooltip"
      >
        <InformationCircleOutlined
          classes={{
            root: classes.iconRoot,
          }}
        />
      </Tooltip>
      {children}
    </div>
  );
}
