import { useParams } from "react-router-dom";
import {
  GetAlertWithTestsQuery,
  useGetReportNotificationsLazyQuery,
} from "../../../graphql";
import { RulesAndThresholdsFormState } from "../../_common/utils/types";
import { useSaveEmailAlertsMutation } from "../../_common/utils/useSaveEmailAlertsMutation";
import { useSaveMsTeamsWebhooksMutations } from "../../_common/utils/useSaveMsTeamsWebhooksMutation";
import { useSaveSlackWebhooksMutation } from "../../_common/utils/useSaveSlackWebhooksMutation";
import { useSaveReportRulesAndThresholdsMutation } from "./useSaveReportRulesAndThresholdsMutation";
import { useSaveHealthScoreRulesAndThresholdsMutation } from "./useSaveHealthScoreRulesAndThresholdsMutation";

interface UpdateAlertInput {
  initialAlertSettings: RulesAndThresholdsFormState;
  newAlertSettings: RulesAndThresholdsFormState;
  alertData: GetAlertWithTestsQuery | undefined;
  alertId: string;
}

type UpdateAlertFunction = (args: UpdateAlertInput) => Promise<void>;

export function useUpdateAlertMutation(): [
  UpdateAlertFunction,
  { loading: boolean },
] {
  const { accountId } = useParams<{ accountId: string }>();

  const [saveReportRulesAndThreshold, { loading: isSavingReportRules }] =
    useSaveReportRulesAndThresholdsMutation();
  const [
    saveHealthScoreRulesAndThreshold,
    { loading: isSavingHealthScoreRules },
  ] = useSaveHealthScoreRulesAndThresholdsMutation();

  const [saveEmailAlerts, { loading: isSavingEmailAlerts }] =
    useSaveEmailAlertsMutation();

  const [saveSlackWebhooks, { loading: isSavingSlackWebhooks }] =
    useSaveSlackWebhooksMutation();

  const [saveMsTeamsWebhooks, { loading: isSavingMsTeamsWebhooks }] =
    useSaveMsTeamsWebhooksMutations();

  const [getNotifications] = useGetReportNotificationsLazyQuery({
    fetchPolicy: "network-only",
  });

  const isLoading =
    isSavingReportRules ||
    isSavingHealthScoreRules ||
    isSavingEmailAlerts ||
    isSavingSlackWebhooks ||
    isSavingMsTeamsWebhooks;

  async function updateAlert({
    initialAlertSettings,
    newAlertSettings,
    alertData,
    alertId,
  }: UpdateAlertInput): Promise<void> {
    await Promise.all([
      saveReportRulesAndThreshold(
        initialAlertSettings.reportRulesAndThresholds,
        newAlertSettings.reportRulesAndThresholds,
        alertId,
      ),
      saveHealthScoreRulesAndThreshold(
        initialAlertSettings.categoryRulesAndThresholds,
        newAlertSettings.categoryRulesAndThresholds,
        alertId,
      ),
      saveEmailAlerts(
        alertData?.alert?.emailAlerts?.nodes ?? [],
        newAlertSettings.emailAlerts ?? [],
        alertId,
      ),
      saveSlackWebhooks(
        alertData?.alert?.slackWebhooks?.nodes ?? [],
        newAlertSettings.slackWebhooks ?? [],
        alertId,
      ),
      saveMsTeamsWebhooks(
        alertData?.alert?.webhooks?.nodes ?? [],
        newAlertSettings.microsoftTeamsWebhooks ?? [],
        alertId,
      ),
    ]);

    // Note: we can't use `refetchQueries: ["GetNotifications"]` because we don't know for certain which mutations will actually be called.
    // Therefore, we have to manually refetch the notifications to ensure that the data doesn't become stale.
    getNotifications({ variables: { accountId } });
  }
  return [updateAlert, { loading: isLoading }];
}
