import {
  TagInput,
  ToggleIconButton,
  TrashSolid,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { createStyles, makeStyles, Tooltip, useTheme } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { HideFromInsufficientRole } from "../../../../_common/components/HideFromInsufficientRole";
import { RulesAndThresholdsFormState } from "../../utils/types";
import { SlackInstructions } from "./SlackInstructions";
import { RoleCode } from "../../../../graphql";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(1),
      flex: 1,
      "& .TagInput-chip-error": {
        backgroundColor: theme.palette.red[600],
        color: theme.palette.background.paper,
        "&:focus": {
          backgroundColor: `${theme.palette.red[400]} !important`,
        },
      },
      "& .TagInput-chip-error:hover": {
        backgroundColor: theme.palette.red[400],
        color: theme.palette.background.paper,
      },
    },
    description: {
      maxWidth: 600,
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(14),
      color: theme.palette.grey[500],
    },
    textFieldContainer: {
      display: "flex",
      alignItems: "flex-start",
      maxWidth: 700,
    },
  }),
);

export function SlackNotificationPanel({
  onRemovePanel,
  disabled = false,
}: {
  onRemovePanel?: () => void;
  disabled?: boolean;
}): JSX.Element {
  const { t } = useTranslation(["slackNotification"]);
  const theme = useTheme();
  const classes = useStyles();
  const session = useSession();
  const hasSufficientRole = session.hasSufficientRole(RoleCode.Editor);

  const { values, setFieldValue, setFieldTouched, errors } =
    useFormikContext<RulesAndThresholdsFormState>();

  const error = errors?.slackWebhooks
    ? Array.isArray(errors?.slackWebhooks)
      ? errors?.slackWebhooks.find(Boolean)
      : errors?.slackWebhooks
    : undefined;

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
      }}
    >
      <Typography variant="subtitle2SemiBold">{t("title")}</Typography>
      <Typography variant="caption" className={classes.description}>
        {t("description")}
      </Typography>
      <div className={classes.textFieldContainer}>
        <Field
          className={classes.root}
          style={{ maxWidth: 650 }}
          name={`slackWebhooks`}
          component={TagInput}
          value={values.slackWebhooks}
          errors={errors?.slackWebhooks}
          error={error}
          addOnSpace
          addOnComma
          disabled={!hasSufficientRole || disabled}
          variant="outlined"
          onChange={(value: string[]) => {
            setFieldTouched(`slackWebhooks`, true);
            setFieldValue(`slackWebhooks`, value);
          }}
          data-pendo="slack-notification-channel-input"
          data-testid="slack-notification-channel-input"
          InputProps={{
            placeholder: "Enter Slack webhook URL(s)...",
            autoFocus:
              !values.slackWebhooks || values.slackWebhooks.length === 0,
          }}
        />
        {values?.slackWebhooks?.length && !disabled ? (
          <HideFromInsufficientRole>
            <Tooltip title={t("remove") as string} arrow={false}>
              <ToggleIconButton
                colorVariant="red"
                size="large"
                variant="outlined"
                onClick={() => {
                  setFieldValue(`slackWebhooks`, []);
                  onRemovePanel?.();
                }}
                data-pendo="slack-notification-channel-delete-alert"
                data-testid="slack-notification-channel-delete-alert"
              >
                <TrashSolid />
              </ToggleIconButton>
            </Tooltip>
          </HideFromInsufficientRole>
        ) : undefined}
      </div>
      <div
        style={{
          marginTop: theme.spacing(2.75),
          marginBottom: theme.spacing(2.125),
          borderTop: `1px solid ${theme.palette.grey[200]}`,
          width: "100%",
        }}
      />
      <div style={{ maxWidth: 700 }}>
        <SlackInstructions />
      </div>
    </div>
  );
}
