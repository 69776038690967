import { MutableRefObject, useState } from "react";
import {
  Popover,
  Tooltip,
  makeStyles,
  IconButton,
  useTheme,
  Badge,
} from "@material-ui/core";
import {
  CogSolid,
  ToggleIconButton,
  Typography,
  PlusSolid,
  NotificationBellV2,
  useTranslation,
  insertIf,
  useSession,
  SelectableButtonGroup,
} from "@lumar/shared";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";
import { Link, useParams } from "react-router-dom";
import { useGetNotificationsCountQuery } from "../../graphql";
import { ReportNotificationsTab } from "../ReportNotificationsTab";
import { HealthScoreNotificationsTab } from "../HealthScoreNotificationsTab";
import { useHealthScoreEnabled } from "../../_common/hooks/useHealthScoreEnabled";

export function NotificationsMenu(props: {
  anchorRef: MutableRefObject<HTMLDivElement | null>;
}): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const { hasFeatureFlagEnabled } = useSession();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const classes = useStyles({ isPopupOpen });
  const { t } = useTranslation(["alerts", "notifications"]);

  const theme = useTheme();

  const { alertsPage, createAlertPage, notificationsPage } = useMonitorRoutes();

  const { data: totalCount } = useGetNotificationsCountQuery({
    variables: { accountId },
    fetchPolicy: "cache-first",
  });

  const [currentTab, setCurrentTab] = useState<string>("report");

  const hasHealthScores =
    useHealthScoreEnabled() && hasFeatureFlagEnabled("health-score-alerts");

  const tabs = [
    {
      label: t("notifications:reportNotifications"),
      value: "report",
      tab: (
        <ReportNotificationsTab
          variant="simple"
          actions={
            <Link to={notificationsPage.getUrl()} className={classes.link}>
              {t("allNotifications")} -&gt;
            </Link>
          }
          classes={{
            loading: classes.loading,
            list: classes.list,
            emptyState: classes.emptyState,
          }}
        />
      ),
    },
    ...insertIf(hasHealthScores, {
      label: t("notifications:healthscoreNotifications"),
      value: "healthscore",
      tab: (
        <HealthScoreNotificationsTab
          variant="simple"
          actions={
            <Link to={notificationsPage.getUrl()} className={classes.link}>
              {t("allNotifications")} -&gt;
            </Link>
          }
          classes={{
            loading: classes.loading,
            list: classes.list,
            emptyState: classes.emptyState,
          }}
        />
      ),
    }),
  ];
  const handleClick = (): void => {
    setIsPopupOpen(true);
  };

  const handleClose = (): void => {
    setIsPopupOpen(false);
  };
  return (
    <>
      <Badge
        badgeContent={
          (totalCount?.getAccount?.reportNotifications.totalCount ?? 0) +
          (totalCount?.getAccount?.healthScoreNotifications.totalCount ?? 0)
        }
        color="primary"
        max={9}
        classes={{ badge: classes.badge }}
      >
        <Tooltip title={t("notifications") as string} arrow={false}>
          <ToggleIconButton
            variant="outlined"
            size="large"
            color="secondary"
            className={classes.iconButton}
            onClick={handleClick}
            data-pendo="view-notifications-button"
            data-testid="notifications-menu-button"
          >
            <NotificationBellV2 />
          </ToggleIconButton>
        </Tooltip>
      </Badge>
      <Popover
        id="notifications-popover"
        open={isPopupOpen}
        anchorEl={props.anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.popoverPaper,
        }}
      >
        <div className={classes.popoverContainer}>
          <div
            style={{ position: "sticky", top: 0, zIndex: theme.zIndex.tooltip }}
          >
            <div className={classes.popoverHeader}>
              <Typography variant="h6SemiBold">{t("notifications")}</Typography>
              <Tooltip title={t("manage") as string} arrow={false}>
                <ToggleIconButton
                  style={{ marginLeft: "auto" }}
                  data-pendo="view-notifications-manange-alerts-button"
                  data-testid="manage-alerts-button"
                  variant="outlined"
                  colorVariant="primary"
                  onClick={() => alertsPage.visit()}
                >
                  <CogSolid />
                </ToggleIconButton>
              </Tooltip>
              <HideFromInsufficientRole>
                <Tooltip title={t("createNewAlert") as string} arrow={false}>
                  <IconButton
                    className={classes.plusButton}
                    size="medium"
                    data-pendo="view-notifications-create-alert-button"
                    data-testid="create-new-alert-icon-button"
                    onClick={() => createAlertPage.visit()}
                  >
                    <PlusSolid />
                  </IconButton>
                </Tooltip>
              </HideFromInsufficientRole>
            </div>
            <Typography
              variant="h1"
              style={{
                fontWeight: 600,
                background: theme.palette.grey[100],
              }}
            >
              {tabs.length === 1 ? undefined : (
                <SelectableButtonGroup
                  value={currentTab}
                  onValueChange={(value) => {
                    setCurrentTab(value);
                  }}
                  options={tabs}
                  className={classes.tabs}
                />
              )}
            </Typography>
            {tabs.find((e) => e.value === currentTab)?.tab ?? (
              <ReportNotificationsTab />
            )}
          </div>
        </div>
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  popoverContainer: {
    width: "min(1200px, 95vw)",
  },
  popoverHeader: {
    display: "flex",
    alignItems: "center",
    height: 65,
    padding: theme.spacing(0, 3),
    background: theme.palette.grey[100],
  },
  popoverPaper: {
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow:
      "-20px 0px 25px -5px rgba(0, 0, 0, 0.1), 10px 20px 10px -5px rgba(0, 0, 0, 0.06)",
    borderRadius: 8,
    marginTop: 6,
    maxHeight: "80vh",
  },
  iconButton: ({ isPopupOpen }: { isPopupOpen: boolean }) => ({
    padding: 0,
    color: theme.palette.grey[700],
    borderRadius: 61,
    borderWidth: 1.5,
    backgroundColor: isPopupOpen ? theme.palette.grey[200] : "white",
    borderColor: theme.palette.grey[300],
    boxShadow: "0px 1px 2px rgb(0 0 0 / 5%)",
    "&:hover": {
      background: theme.palette.grey[200],
      color: theme.palette.grey[700],
      borderColor: theme.palette.grey[300],
    },
  }),
  plusButton: {
    padding: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    height: 31,
    width: 31,
    "&:hover": {
      backgroundColor: theme.palette.ultraviolet[700],
    },
    color: "white",
    "& svg": {
      fontSize: 19,
    },
    marginLeft: theme.spacing(1),
  },

  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginRight: theme.spacing(3),
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.ultraviolet[700],
    },
  },
  badge: {
    background: theme.palette.red[600],
    border: `2px solid ${theme.palette.background.paper}`,
    top: 5,
    right: 8,
  },
  loading: {
    minHeight: 300,
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  list: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  emptyState: { filter: "none" },
  tabs: {
    marginLeft: theme.spacing(1),
  },
}));
