import React, { useMemo, useState } from "react";
import {
  Backdrop,
  Divider,
  FormControlLabel,
  makeStyles,
  Popover,
} from "@material-ui/core";

import {
  Button,
  SwitchWithIcon,
  ToggleIconButton,
  Typography,
  XSolid,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { isFilterEmpty, SettingsFilters } from "../components/SettingsFilters";
import { useDashboardViews } from "../../components/DashboardViewsProvider";
import { View } from "../types";
import {
  filterViewList,
  GetViewListFilter,
} from "../board/utils/filterViewList";
import { DashboardSettingsList } from "./DashboardSettingsList";
import { useGetViewsForBoardAccumulator } from "../board/utils/useGetViewsForBoardAccumulator";
import { useUpdateViewsForDashboard } from "./utils/useUpdateViewsForDashboard";
import { differenceBy } from "lodash";

import {
  DSEmptyStateManager,
  DSEmptyStateType,
} from "./utils/DSEmptyStateManager";
import { useMonitorRoutes } from "../../../_common/routing/useMonitorRoutes";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    top: "0px !important",
    left: "unset !important",
    right: 0,
    maxWidth: 701,
    minWidth: 701,
    minHeight: "100vh",
  },
  backdrop: {
    background: "rgba(75, 85, 99, 0.2)",
    backdropFilter: "blur(1.5px)",
  },
  xIcon: {
    color: theme.palette.grey[700],
    fontSize: 10,
  },
  dividers: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(0, 2, 0, 2),
  },
  titleDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(3, 1, 2, 3),
    alignItems: "center",
  },
  filtersDiv: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(1.5, 2, 1.5, 2),
  },
  list: {
    padding: theme.spacing(0, 2, 0, 2),
  },
  listTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2, 0, 1, 0),
    alignItems: "center",
  },
  switch: {
    height: 20,
    width: 40,
    "& svg": {
      fontSize: 21,
    },
  },
  switchFormControl: {
    marginRight: "auto",
    marginLeft: theme.spacing(2),
  },
}));

export interface DashboardSettingsProps {
  dashboardSettingsOpen: boolean;
  setDashboardSettingsOpen: (value: boolean) => void;
}

export function DashboardSettings(props: DashboardSettingsProps): JSX.Element {
  const { dashboardSettingsOpen, setDashboardSettingsOpen } = props;
  const classes = useStyles();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { t } = useTranslation(["dashboards", "board"]);
  const { boardSettingsPage } = useMonitorRoutes();

  const { accountId, collectionId, dashboardId } = useParams<{
    accountId: string;
    collectionId: string;
    dashboardId: string;
  }>();

  const updateDashboard = useUpdateViewsForDashboard();

  const { data: dashboardViewsData, error: dashboardViewsError } =
    useDashboardViews();
  const boardViewsData = useGetViewsForBoardAccumulator(false);

  const [searchTerms, setSearchTerms] = useState<GetViewListFilter>({});
  const [selected, setSelected] = useState<View[]>(dashboardViewsData ?? []);

  const shouldShowViewsWithSegments =
    searchTerms.segmentNameSearchTherm !== null;

  const boardViews = useMemo(
    () =>
      boardViewsData.data
        ? filterViewList(boardViewsData.data.views, {}, selected)
        : [],
    [boardViewsData.data, selected],
  );

  const filteredBoardViews = useMemo(
    () => filterViewList(boardViews, searchTerms),
    [boardViews, searchTerms],
  );

  const filteredSelected = useMemo(
    () => filterViewList(selected, searchTerms),
    [selected, searchTerms],
  );

  const selectedSegmentList = useMemo(() => {
    return boardViewsData.data?.views.reduce((acumulator, value) => {
      const found =
        Boolean(acumulator.find((e) => e === value.segmentName)) ||
        !Boolean(value.segmentName);
      return !Boolean(found)
        ? [...acumulator, value.segmentName ?? ""]
        : acumulator;
    }, [] as string[]);
  }, [boardViewsData.data?.views]);

  const numberOfSecondListRows = boardViews.length <= 0 ? 0 : boardViews.length;

  const handleSave = async (): Promise<void> => {
    await updateDashboard(dashboardId, dashboardViewsData ?? [], selected);
  };

  const isFiltered = !isFilterEmpty(searchTerms);

  const isParentEmpty = !Boolean(boardViewsData.data?.views.length);

  const isEmpty = selected.length === 0;
  const isResultEmpty =
    isFiltered &&
    filteredBoardViews.length === 0 &&
    filteredSelected.length === 0;

  const needsEmptyState =
    isEmpty || isResultEmpty || Boolean(dashboardViewsError);

  function closeSettings(): void {
    if (!isEmpty) {
      handleSave();
      setDashboardSettingsOpen(false);
      return;
    }
    if (isParentEmpty) {
      setDashboardSettingsOpen(false);
      return;
    }
  }

  return (
    <Popover
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      open={dashboardSettingsOpen}
      classes={{ paper: classes.paper }}
      onClose={() => {
        closeSettings();
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{ classes: { root: classes.backdrop } }}
      data-testid="monitor-dashboard-settings-popover"
    >
      <div className={classes.titleDiv}>
        <Typography variant="subtitle1SemiBold">
          {t("dashboards:dashboardViewsOptionTitle", {
            value1: dashboardViewsData?.length ?? 0,
            value2: boardViewsData.data?.views.length ?? 0,
          })}
        </Typography>
        <ToggleIconButton
          className={classes.xIcon}
          onClick={() => {
            closeSettings();
          }}
          disabled={isEmpty && !isParentEmpty}
          data-pendo="monitor-dashboard-settings-close"
          data-testid="monitor-dashboard-settings-close"
          aria-label={t("dashboards:closeSettings")}
        >
          <XSolid />
        </ToggleIconButton>
      </div>
      <Divider className={classes.dividers} />
      <div className={classes.filtersDiv}>
        <SettingsFilters
          disabled={false}
          segments={selectedSegmentList ?? []}
          searchTerms={searchTerms}
          onChange={(value) => {
            setSearchTerms(value);
          }}
          shouldShowSegmentsFilter={!shouldShowViewsWithSegments}
          data-pendo="monitor-dashboard-settings-filter"
          data-testid="monitor-dashboard-settings-filter"
        />
        {segmentationAvailable && (
          <FormControlLabel
            className={classes.switchFormControl}
            control={
              <SwitchWithIcon
                className={classes.switch}
                checked={shouldShowViewsWithSegments}
                onChange={() => {
                  if (!shouldShowViewsWithSegments) {
                    setSearchTerms({});
                  } else {
                    setSearchTerms({ segmentNameSearchTherm: null });
                  }
                }}
                data-pendo="show-hide-segments"
                data-testid="show-hide-segments"
              />
            }
            label="Segments"
          />
        )}
      </div>
      <Divider className={classes.dividers} />

      <div className={classes.list}>
        <div className={classes.listTitle}>
          <Typography variant="subtitle4Medium">
            {t("dashboards:shownOnDash")}
          </Typography>

          {filteredSelected.length ? (
            <div
              style={{
                width: 150,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  setSelected(differenceBy(selected, filteredSelected, "id"))
                }
                data-pendo="monitor-dashboard-settings-hide-all"
                data-testid="monitor-dashboard-settings-hide-all"
              >
                {t("dashboards:hideAll", { count: filteredSelected.length })}
              </Button>
            </div>
          ) : undefined}
        </div>
        <div>
          {!needsEmptyState ? (
            <DashboardSettingsList
              testId="dashboard-settings-selected"
              error={dashboardViewsError}
              data={filteredSelected}
              checked={true}
              onChange={(view) => {
                const index = selected.findIndex((e) => e.id === view.id);
                setSelected([
                  ...selected.slice(0, index),
                  ...selected.slice(index + 1),
                ]);
              }}
              type="deselect"
            />
          ) : (
            <DSEmptyStateManager
              isError={Boolean(dashboardViewsError)}
              isParentEmpty={isParentEmpty}
              isEmpty={isEmpty}
              isFiltered={isFiltered}
              isResultEmpty={isResultEmpty}
              onClick={(type) => {
                switch (type) {
                  case DSEmptyStateType.Error:
                    window.location.reload();
                    break;
                  case DSEmptyStateType.NoBoardViews:
                    boardSettingsPage.visit({
                      accountId,
                      collectionId,
                    });
                    break;
                  case DSEmptyStateType.NoResult:
                    setSearchTerms({});
                    break;
                }
              }}
            />
          )}
        </div>
      </div>

      {numberOfSecondListRows && filteredBoardViews.length ? (
        <>
          <Divider className={classes.dividers} />
          <div className={classes.list}>
            <div className={classes.listTitle}>
              <Typography variant="subtitle4Medium">
                {t("dashboards:hiddenFromDash")}
              </Typography>
              {filteredBoardViews.length ? (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setSelected([...filteredBoardViews, ...selected]);
                  }}
                  data-pendo="monitor-dashboard-settings-show-all"
                  data-testid="monitor-dashboard-settings-show-all"
                >
                  {t("dashboards:showAll", {
                    count: filteredBoardViews.length,
                  })}
                </Button>
              ) : undefined}
            </div>
            <div>
              <DashboardSettingsList
                testId="dashboard-settings-hidden"
                error={dashboardViewsError}
                data={filteredBoardViews}
                onChange={(view) => setSelected([view, ...selected])}
                type="select"
              />
            </div>
          </div>
        </>
      ) : undefined}
    </Popover>
  );
}
