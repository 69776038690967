import { getApiAccountId, useApolloClient } from "@lumar/shared";
import { useParams } from "react-router-dom";

export function useRefreshProjects(): () => void {
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const { cache } = useApolloClient();

  return () => {
    cache.modify({
      id: cache.identify({
        __typename: "Account",
        id: getApiAccountId(accountId),
      }),
      fields: {
        monitorProjects: (_, details) => details.DELETE,
        projects: (_, details) => details.DELETE,
      },
    });
  };
}
