import { healthScoreRound } from "../../../_common/utils/healthScoreRound";
import {
  ThresholdPredicate,
  GetHealthScoreTrendsQuery,
  useGetHealthScoreTrendsQuery,
} from "../../../graphql";

const DEFAULT_VALUE = 50;

function standardDeviation(values: number[]): number {
  const sum = values.reduce((accumulator, current) => {
    return accumulator + current;
  }, 0);
  const length = values.length;
  const mean = sum / length;

  const standardDeviation = values.reduce((accumulator, current) => {
    const val = Math.pow(current - mean, 2);
    return accumulator + val;
  }, 0);

  const value = Math.sqrt(standardDeviation / length);
  return Math.ceil(value) === 0 ? 1 : value;
}

function movingAverage(values: number[]): number {
  const sum = values.reduce((accumulator, current) => {
    return accumulator + current;
  }, 0);
  const length = values.length;

  return sum / length;
}

export function calculateInitialThresholdFromData(
  data?: GetHealthScoreTrendsQuery,
  predicate?: ThresholdPredicate,
): number {
  if (!data) {
    return DEFAULT_VALUE;
  }

  const healthScore = data?.getProject?.healthScore ?? [];

  if (healthScore.length === 0) {
    return DEFAULT_VALUE;
  }

  const trends: { createdAt: string; value: number }[] = healthScore.map(
    (tr) => ({
      createdAt: tr.createdAt ?? "",
      value: healthScoreRound((tr.healthScore ?? 0) * 100.0),
    }),
  );

  const pred = predicate ?? ThresholdPredicate.LessThan;

  if (trends.length === 1) {
    return Math.min(
      Math.max(
        1,
        (trends[0].value ?? 0) +
          (pred === ThresholdPredicate.GreaterThanOrEqual ? 1 : 0),
      ),
      100,
    );
  }

  // eslint-disable-next-line fp/no-mutating-methods
  const seriesData = (
    trends.map((tr) => [new Date(tr.createdAt).getTime(), tr.value]) ?? []
  )
    .sort((a, b) => b[0] - a[0])
    .slice(0, 10)
    .map((tr) => tr[1]);

  const average = movingAverage(seriesData);
  const percent = average * 0.5;

  const deviation = Math.min(standardDeviation(seriesData), percent);
  const value = Math.ceil(
    movingAverage(seriesData) +
      (pred === ThresholdPredicate.LessThan ? -deviation : deviation),
  );
  return Math.floor(Math.min(Math.max(value, 1), 100) + 0.00001);
}

export function useInitialHealthScoreThresholdCalculator(
  projectId?: string,
  segmentId?: string,
  categoryCode?: string,
): [
  { loading: boolean; value?: number; firstLoad: boolean },
  (
    id: string,
    segmentId: string | undefined,
    p: string,
    predicate?: ThresholdPredicate,
  ) => Promise<number>,
] {
  const {
    loading,
    data,
    refetch: fetchThrend,
    previousData,
  } = useGetHealthScoreTrendsQuery({
    variables: {
      projectId,
      segmentId,
      categoryCode: categoryCode ?? "",
    },
    context: {
      includeInBatch: true,
    },
    skip: !Boolean(projectId),
    fetchPolicy: "cache-first",
  });

  const calculateInitialThreshold = async (
    projectId: string,
    segmentId: string | undefined,
    categoryCode: string,
    predicate?: ThresholdPredicate,
  ): Promise<number> => {
    const ret = await fetchThrend({
      projectId,
      segmentId,
      categoryCode,
    });
    return calculateInitialThresholdFromData(ret?.data, predicate);
  };
  return [
    {
      loading,
      value: calculateInitialThresholdFromData(data),
      firstLoad: !Boolean(previousData),
    },
    calculateInitialThreshold,
  ];
}
