import React, { useState } from "react";
import { Button, ChevronDownSolid, useTranslation } from "@lumar/shared";
import {
  createStyles,
  useTheme,
  MenuItem,
  Menu,
  makeStyles,
  ListSubheader,
} from "@material-ui/core";
import { DashboardLabel } from "./DashboardLabel";
import { CustomDashboardType } from "../../graphql";

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      width: "100%",
      display: "flex",
      "& .MuiTypography-root": {
        flex: 1,
      },
    },
    selected: {
      textDecoration: "none",
      backgroundColor: theme.palette.grey[300],
    },
    menuTitle: {
      color: theme.palette.grey[600],
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(15),
      fontWeight: 500,
      padding: theme.spacing(1),
    },
    menuItem: {
      "&.MuiMenuItem-root": {
        height: "unset",
        minHeight: 35,
      },
      "&.Mui-focusVisible": {
        boxShadow: "none",
      },
    },
  }),
);

interface Props {
  dashboards: { id: string; name: string; type: CustomDashboardType }[];
  dashboardNum: number;
  onChange: (id: string) => void;
  onClone: (id: string, type: CustomDashboardType, name: string) => void;
  disableDelete?: boolean;
  disableCloning?: boolean;
}

export const DashboardTabList = ({
  dashboards,
  dashboardNum,
  onChange,
  onClone,
  disableDelete,
  disableCloning,
}: Props): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();

  const { t } = useTranslation(["dashboardTabs", "errors", "dashboards"]);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchor);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setMenuAnchor(null);
  };

  const handleMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement>,
    id: string,
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    setMenuAnchor(null);
    onChange(id);
  };

  return (
    <>
      <Button
        style={{
          marginBottom: theme.spacing(2),
          color: theme.palette.grey[700],
          marginRight: theme.spacing(1.9),
          marginLeft: theme.spacing(-1),
          minWidth: 90,
          height: 32,
        }}
        className={isMenuOpen ? classes.selected : undefined}
        size="medium"
        disableElevation
        data-pendo="view-invisible-sub-dashboard-list-button"
        data-testid="view-invisible-sub-dashboard-list-button"
        onClick={handleOpenMenu}
        endIcon={<ChevronDownSolid />}
      >
        {t("dashboardTabs:more", { count: dashboardNum })}
      </Button>
      <Menu
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        data-testid="dashboard-list-menu"
      >
        <ListSubheader
          className={classes.menuTitle}
          onClickCapture={(e) => e.stopPropagation()}
        >
          {t("menuTitle")}
        </ListSubheader>
        {dashboards.map(({ id, name, type }) => (
          <MenuItem
            key={id}
            data-testid="sub-dashboard-list-item"
            onClick={(e) => handleMenuItemClick(e, id)}
            data-pendo={"monitor-sub-dashboard-list-item"}
            style={{ width: 350 }}
            className={classes.menuItem}
          >
            <DashboardLabel
              disableCloning={disableCloning}
              disableDelete={disableDelete}
              className={classes.label}
              name={name}
              id={id}
              type={type}
              variant="outlined"
              colorVariant="primary"
              onCloneAction={() => onClone(id, type, name)}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
