import { EmptyState, useTranslation } from "@lumar/shared";

export function ProjectComparisonChartErrorState(props: {
  className: string;
}): JSX.Element {
  const { t } = useTranslation(["errors", "projectComparisonChart"]);

  return (
    <EmptyState
      title={t("errors:genericError")}
      description={t("projectComparisonChart:errorLoadingChartDescription")}
      actions={[
        {
          type: "button",
          title: t("errors:reload"),
          onClick: () => window.location.reload(),
        },
      ]}
      className={props.className}
    />
  );
}
