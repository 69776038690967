import {
  CheckSmallIcon,
  ComboBox,
  SearchOutlined,
  Select,
  TextField,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import {
  InputAdornment,
  makeStyles,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import { ScheduleFrequencyCode } from "../../../graphql";
import clsx from "clsx";
import { GetViewListFilter } from "../board/utils/filterViewList";
import { isEmpty } from "lodash";

const useStyles = makeStyles(() => ({
  textField: {
    height: 32,
    minWidth: 110,
    "& .MuiInput-root": {
      height: 32,
    },
  },
  flex: {
    flex: 1,
  },
  segments: {
    minWidth: 170,
  },
  frequency: {
    flex: 0,
    minWidth: 160,
    "& .MuiInputBase-root": {
      height: 32,
    },
  },
}));

export function SettingsFilters({
  segments,
  loading,
  searchTerms,
  onChange,
  disabled,
  shouldShowSegmentsFilter,
  ...props
}: {
  segments: string[];
  loading?: boolean;
  searchTerms?: GetViewListFilter;
  onChange?: (filter: GetViewListFilter) => void;
  disabled?: boolean;
  shouldShowSegmentsFilter?: boolean;
  "data-pendo"?: string;
  "data-testid"?: string;
}): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();

  const { t } = useTranslation(["board"]);

  const MonitorScheduleFrequencyCode = [
    {
      name: t("All"),
      value: undefined,
    },
    { name: t("noSchedule"), value: null },
    {
      name: t(ScheduleFrequencyCode.Hourly as string),
      value: ScheduleFrequencyCode.Hourly,
    },
    {
      name: t(ScheduleFrequencyCode.Daily as string),
      value: ScheduleFrequencyCode.Daily,
    },
    {
      name: t(ScheduleFrequencyCode.Weekly as string),
      value: ScheduleFrequencyCode.Weekly,
    },
    {
      name: t(ScheduleFrequencyCode.Fortnightly as string),
      value: ScheduleFrequencyCode.Fortnightly,
    },
    {
      name: t(ScheduleFrequencyCode.Monthly as string),
      value: ScheduleFrequencyCode.Monthly,
    },
    {
      name: t(ScheduleFrequencyCode.Quarterly as string),
      value: ScheduleFrequencyCode.Quarterly,
    },
  ];

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexFlow: "row",
        gap: theme.spacing(1.5),
        flexWrap: "wrap",
      }}
    >
      <TextField
        data-testid={
          props["data-testid"]
            ? `${props["data-testid"]}-project-name`
            : undefined
        }
        className={clsx(classes.textField, classes.flex)}
        disabled={disabled}
        value={searchTerms?.projectNameSearchTherm ?? ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined
                fontSize="small"
                htmlColor={theme.palette.grey[500]}
              />
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          onChange?.({
            ...searchTerms,
            projectNameSearchTherm: event.currentTarget.value,
          });
        }}
        placeholder={t("searchPlaceholder")}
      />
      <Select
        data-testid={
          props["data-testid"] ? `${props["data-testid"]}-schedule` : undefined
        }
        data-pendo={
          props["data-pendo"] ? `${props["data-pendo"]}-schedule` : undefined
        }
        disabled={disabled}
        value={
          MonitorScheduleFrequencyCode.find(
            (e) => e.value === searchTerms?.frequency,
          )?.name
        }
        className={clsx(classes.textField, classes.frequency)}
        placeholder={t("selectPlaceholder")}
        renderValue={() => {
          const value = MonitorScheduleFrequencyCode.find(
            (e) => e.value === searchTerms?.frequency,
          );
          return (
            <Typography
              variant="body2"
              style={{
                color:
                  value?.value === undefined
                    ? theme.palette.grey[400]
                    : "inherit",
                marginTop: 1,
              }}
            >
              {value?.value === undefined
                ? t("selectPlaceholder")
                : (value as { name: string }).name}
            </Typography>
          );
        }}
      >
        {MonitorScheduleFrequencyCode.map((e) => (
          <MenuItem
            button={true}
            key={e.name}
            value={e.name}
            style={{ display: "flex", flexFlow: "row" }}
            onClick={() => {
              onChange?.({
                ...searchTerms,
                frequency: e.value,
              });
            }}
          >
            <Typography variant="body2" style={{ marginTop: 1, flex: 1 }}>
              {e.name}
            </Typography>
            {searchTerms?.frequency === e.value ? (
              <CheckSmallIcon
                className="check"
                htmlColor={theme.palette.primary.main}
                style={{ marginLeft: 10 }}
              />
            ) : undefined}
          </MenuItem>
        ))}
      </Select>
      {segmentationAvailable && (
        <ComboBox
          data-testid={
            props["data-testid"]
              ? `${props["data-testid"]}-segment-name`
              : undefined
          }
          data-pendo={
            props["data-pendo"]
              ? `${props["data-pendo"]}-segment-name`
              : undefined
          }
          value={searchTerms?.segmentNameSearchTherm ?? null}
          className={clsx(classes.textField, classes.segments, classes.flex)}
          options={filterListByText(
            segments,
            searchTerms?.segmentNameSearchTherm ?? "",
          )}
          loading={loading}
          disabled={disabled || shouldShowSegmentsFilter}
          inputMode="search"
          inputProps={{
            placeholder: t("allSegments"),
            onChange: (event) =>
              onChange?.({
                ...searchTerms,
                segmentNameSearchTherm: event.target.value ?? "",
              }),
          }}
          onChange={(_, value) => {
            if (value || value === null)
              onChange?.({
                ...searchTerms,
                segmentNameSearchTherm: value ?? "",
              });
          }}
        />
      )}
    </div>
  );
}

function filterListByText(list: string[], text: string): string[] {
  if (text.trim().length === 0) return list;
  return list.filter((e) => e.toUpperCase().includes(text.toUpperCase()));
}

export function isFilterEmpty(filter: GetViewListFilter): boolean {
  if (isEmpty(filter)) return true;
  return (
    !Boolean(filter.frequency) &&
    filter.frequency !== null &&
    !Boolean(filter.projectNameSearchTherm?.length) &&
    !Boolean(filter.segmentNameSearchTherm?.length)
  );
}
