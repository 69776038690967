import {
  createStyles,
  makeStyles,
  Select,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import {
  CheckSolid,
  Typography,
  ViewListOutlined,
  useTranslation,
} from "@lumar/shared";
import { GridApiContext, useGridApi } from "@mui/x-data-grid-pro";
import { useContext } from "react";
import { get } from "lodash";
import { assert } from "../../../../_common/assert";

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: theme.spacing(1.625, 2, 1, 2),
      backgroundColor: theme.palette.background.paper,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomWidth: 0,
      filter:
        "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))",
      boxShadow: "none",
    },
    select: {
      marginTop: -3,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      "&:after": {
        display: "none",
      },
      "&:before": {
        display: "none",
      },
    },
    pagination: {
      flex: 1,
      marginRight: theme.spacing(30),
      marginTop: "auto",
      marginBottom: theme.spacing(1.5),
      "& > *:first-child": {
        borderTopStyle: "none",
      },
    },
  }),
);

const defaultPageOptions = [10, 20, 30, 50, 100];
export function MonitorGridFooter(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const apiRef = useContext(GridApiContext);
  assert(apiRef);
  const gridApi = useGridApi(apiRef);
  const { t } = useTranslation("table");

  const paginationProps = apiRef?.current.componentsProps?.pagination;
  const Pagination = apiRef?.current.components.Pagination;
  const pages =
    apiRef?.current.state.options.rowsPerPageOptions ?? defaultPageOptions;
  const loading = get(apiRef?.current.state.options, "loading");

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "right" }}
        className={classes.header}
      >
        <Pagination {...paginationProps} className={classes.pagination} />
        <div
          style={{
            verticalAlign: "middle",
            display: "flex",
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "auto",
          }}
        >
          <Typography
            style={{
              marginRight: theme.spacing(1),
              marginTop: theme.spacing(0.2),
              color: theme.palette.grey[500],
            }}
            variant="caption"
          >
            {t("rows")}
          </Typography>
          <ViewListOutlined htmlColor={theme.palette.ultraviolet[400]} />
          <Select
            disabled={loading}
            className={classes.select}
            value={apiRef?.current.state.pagination.pageSize}
            onChange={(event) => {
              gridApi?.setPageSize(Number(event.target.value) ?? 10);
            }}
            renderValue={(value) => (
              <div style={{ paddingLeft: theme.spacing(0.5) }}>{value}</div>
            )}
          >
            {pages.map((element) => (
              <MenuItem key={element} value={element}>
                {element}
                {element === apiRef?.current.state.pagination.pageSize ? (
                  <CheckSolid
                    style={{
                      width: 18,
                      marginLeft: theme.spacing(2),
                      fill: theme.palette.primary.main,
                      color: theme.palette.primary.main,
                    }}
                  />
                ) : undefined}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
}
