export enum NotificationStatusFilter {
  Unread = "unread",
  Adjustments = "adjustments",
  Archived = "archived",
  WorkingOnIt = "workingonit",
}

export const notificationStatusFilters: NotificationStatusFilter[] = [
  NotificationStatusFilter.Unread,
  NotificationStatusFilter.Adjustments,
  NotificationStatusFilter.Archived,
  NotificationStatusFilter.WorkingOnIt,
];
