import { DataGridReportValue } from "../types";
import { isDataGridTrendValue } from "./isDataGridTrendValue";

type WithDataGridReportValueKeys = Record<keyof DataGridReportValue, unknown>;

function hasDataGridReportValueKeys(
  value: unknown,
): value is WithDataGridReportValueKeys {
  return (
    typeof value === "object" &&
    value !== null &&
    Boolean(value.hasOwnProperty("trends")) &&
    Boolean(value.hasOwnProperty("total")) &&
    Boolean(value?.hasOwnProperty("totalSign"))
  );
}

export function isDataGridReportValue(
  value: unknown,
): value is DataGridReportValue {
  if (hasDataGridReportValueKeys(value)) {
    return (
      Array.isArray(value.trends) &&
      value.trends.every(isDataGridTrendValue) &&
      (typeof value.total === "number" || value.total === null) &&
      (typeof value.totalSign === "number" || value.totalSign === null)
    );
  }

  return false;
}
