import { TetrixV4 } from "@lumar/shared";

export function DefaultCategoryIcon(props: {
  style: React.CSSProperties;
}): JSX.Element {
  return (
    <TetrixV4
      style={{ ...props.style, transform: "rotate(45deg)" }}
      viewBox="3,3,18,18"
    />
  );
}
