import {
  ConnectionTestResultStatusFilterInput,
  Exact,
  Maybe,
  Project,
  ReportTemplateUnit,
  Segment,
  Test,
  TestResult,
} from "../graphql";
import { NotificationSelectionType } from "./utils/constants";

export interface BasicTestResult
  extends Pick<
    TestResult,
    | "severity"
    | "id"
    | "createdAt"
    | "thresholdPredicate"
    | "thresholdType"
    | "absoluteThreshold"
    | "suggestedAbsoluteThreshold"
    | "suggestedAbsoluteThresholdAcceptedAt"
    | "suggestedAbsoluteThresholdRejectedAt"
    | "automaticThresholdAcceptanceWhenTestResultIsWorse"
    | "automaticThresholdAcceptanceWhenTestResultIsBetter"
    | "status"
  > {
  segment?: Pick<Segment, "id" | "name"> | null;
}

export enum MonitorNotificationType {
  HealthScore,
  Report,
}

export type NotificationUnit = ReportTemplateUnit | "percent";
export type ReportUnit = ReportTemplateUnit | "score";
export interface MonitorNotification extends BasicTestResult {
  type: MonitorNotificationType;
  unit: NotificationUnit;
  base: {
    id?: string;
    name: string;
    code: string;
    sign?: number | null;
    unit?: ReportUnit;
  };
  stat?: {
    weight: number | null;
    crawlId?: string;
    trend: {
      crawlId?: number | null;
      value?: number | null;
      createdAt: string | null;
    }[];
    project: Pick<Project, "id" | "name" | "primaryDomain"> & {
      lastFinishedCrawl?: {
        id: string;
      } | null;
    };
    customExtractions?:
      | {
          reportTemplateCode: string;
          label: string;
        }[]
      | null;
    testResults: {
      nodes: {
        absoluteThreshold?: Maybe<number>;
        createdAt: string;
      }[];
    };
  } | null;
  test?: Maybe<
    Pick<
      Test,
      | "id"
      | "absoluteThreshold"
      | "severity"
      | "thresholdType"
      | "thresholdPredicate"
      | "automaticThresholdAcceptanceWhenTestResultIsWorse"
      | "automaticThresholdAcceptanceWhenTestResultIsBetter"
    > & {
      code: string;
    }
  >;
}

export interface NotificationSelection {
  state: NotificationSelectionType;
  selected: MonitorNotification[];
}

export type ThresholdSettings = Pick<
  Test,
  "absoluteThreshold" | "severity" | "thresholdPredicate"
>;

export type ExtendedThresholdSettings = ThresholdSettings &
  Pick<
    Test,
    | "id"
    | "automaticThresholdAcceptanceWhenTestResultIsBetter"
    | "automaticThresholdAcceptanceWhenTestResultIsWorse"
  >;

export type ReportBulkAcceptDeclineVariables = Exact<{
  accountId: string;
  projectIds?: string[];
  severity?: Maybe<string> | undefined;
  reportTemplateCodes?: Maybe<string | string[]> | undefined;
  statusFilter?: Maybe<ConnectionTestResultStatusFilterInput> | undefined;
  testResultIds?: string[];
  blackListed: boolean;
  passed: boolean;
}>;

export type HealthScoreBulkAcceptDeclineVariables = Exact<{
  accountId: string;
  projectIds?: string[];
  severity?: Maybe<string> | undefined;
  reportCategoryCodes?: Maybe<string | string[]> | undefined;
  statusFilter?: Maybe<ConnectionTestResultStatusFilterInput> | undefined;
  testResultIds?: string[];
  blackListed: boolean;
  passed: boolean;
}>;
