/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-let */
/* eslint-disable fp/no-loops */
import { Snackbar, useTranslation, useApolloClient } from "@lumar/shared";
import { useSnackbar } from "notistack";
import {
  useAddViewsToBoardMutation,
  useDeleteViewsFromBoardMutation,
} from "../../../../graphql";
import { getErrorMessage } from "../../../../_common/utils/getErrorMessage";
import { mapViewsToAction } from "./mapViewsToAction";
import { SimpleView } from "../../types";
import { ADD_VIEW_LIMIT } from "./useAddViewsToCollection";
import { assert } from "../../../../_common/assert";
import { useParams } from "react-router-dom";

export function useUpdateViewsForCollection(): (
  collectionId: string | undefined,
  initialValues: SimpleView[],
  newValues: SimpleView[],
  updateDashboards: boolean,
  noSuccessMessage: boolean,
) => Promise<boolean> {
  const { accountId } = useParams<{ accountId: string }>();
  assert(accountId);
  const [deleteViewsFromBoard] = useDeleteViewsFromBoardMutation();
  const [addViewsToBoard] = useAddViewsToBoardMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["board"]);

  const apollo = useApolloClient();
  function clearDashboardsFromCache(): void {
    apollo.cache.modify({
      fields: {
        getCustomDashboard: (_, details) => details.DELETE,
      },
    });
  }

  async function updateViewsForCollection(
    collectionId: string | undefined,
    initialValues: SimpleView[],
    newValues: SimpleView[],
    updateDashboards: boolean,
    noSuccessMessage: boolean,
  ): Promise<boolean> {
    const actions = mapViewsToAction(initialValues, newValues);
    try {
      if (actions.toDelete.length)
        await deleteViewsFromBoard({
          variables: {
            collectionId,
            viewIds: actions.toDelete,
          },
          refetchQueries: ["GetViewsForCollection"],
        });
      if (actions.toCreate.length) {
        const length = Math.ceil(actions.toCreate.length / ADD_VIEW_LIMIT);
        const counter = Array.from(Array(length).keys());
        for (const e of counter) {
          const values = actions.toCreate.slice(
            e * ADD_VIEW_LIMIT,
            (e + 1) * ADD_VIEW_LIMIT,
          );
          await addViewsToBoard({
            variables: {
              collectionId,
              addToDashboards: updateDashboards,
              views: values.map((value) => ({
                accountId,
                projectId: value.projectId,
                segmentId: value.segmentId,
              })),
            },
            refetchQueries:
              e === length - 1 ? ["GetViewsForCollection"] : undefined,
          });
        }
      }
      clearDashboardsFromCache();
      if (!noSuccessMessage)
        enqueueSnackbar(
          <Snackbar variant="success" title={t("updatedSuccessfully")} />,
        );
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("updateFailed", { message: getErrorMessage(e) })}
        />,
      );
      return false;
    }
    return true;
  }

  return updateViewsForCollection;
}
