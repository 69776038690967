import { ReactElement } from "react";
import {
  isReactElement,
  isReactElementArray,
} from "../../utils/isReactElementArray";

export function getReactElementAttrib(
  element: ReactElement,
  attrib: string,
): string | undefined {
  if (element.props[attrib]) return element.props[attrib];
  if (isReactElement(element.props.children))
    return element.props.children.props[attrib];
  if (isReactElementArray(element.props.children))
    return element.props.children[0].props[attrib];

  return undefined;
}
