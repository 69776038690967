export function isRawView(value: Record<string, unknown>): value is {
  projectId: number;
  segmentId: number;
  columns: unknown[];
} {
  return (
    "projectId" in value &&
    typeof value["projectId"] === "number" &&
    "segmentId" in value &&
    typeof value["segmentId"] === "number" &&
    "columns" in value &&
    Array.isArray(value["columns"])
  );
}
