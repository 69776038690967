import { TopNav } from "../navigation/TopNav";
import {
  Box,
  Grid,
  List,
  ListItem,
  makeStyles,
  Paper,
} from "@material-ui/core";
import {
  StatsV5,
  DoneCover,
  NotificationBellV2,
  ProfileFavorite,
  Typography,
  useTranslation,
  getRawAccountId,
  useAccountGuard,
  useSession,
  AppLayout,
} from "@lumar/shared";
import background from "./images/monitor-marketingpage-background.svg";
import monitorDashboard from "./images/MonitorDashboard.svg";
import { Hubspot } from "./Hubspot";
import { useMonitorRoutes } from "../_common/routing/useMonitorRoutes";
import { Redirect } from "react-router-dom";
import { useHasMonitorApp } from "../_common/hooks/useHasMonitorApp";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(28),
    marginBottom: theme.spacing(1.625),
    color: "black",
  },
  description: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    marginBottom: theme.spacing(2.6),
    display: "block",
    color: theme.palette.grey[700],
  },
  list: {
    padding: 0,
    marginBottom: 52,
  },
  listItem: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    "& svg": {
      fontSize: 20,
      marginRight: 4,
    },
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    color: "black",
  },
  message: {
    marginBottom: 18,
    display: "block",
  },
  imageContainer: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    borderRadius: "0px 6px 6px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 2,
  },
  image: {
    height: "auto",
    width: "80%",
    margin: theme.spacing(2, 0),
  },
}));

export function MarketingPage(): JSX.Element {
  useAccountGuard();
  const classes = useStyles();
  const { t } = useTranslation("marketingPage");
  const session = useSession();
  const hasMonitor = useHasMonitorApp();
  const { dashboardPage } = useMonitorRoutes();

  if (hasMonitor) {
    return (
      <Redirect
        to={dashboardPage.getUrl({
          accountId: getRawAccountId(session.account.id),
        })}
      />
    );
  }

  return (
    <AppLayout app="monitor">
      <TopNav />
      <Paper style={{ flex: 1, display: "flex" }}>
        <Grid container style={{ flex: 1 }}>
          <Grid item lg={5} md={5} sm={12}>
            <Box padding="46px" style={{ paddingTop: 60 }}>
              <Typography
                component="h1"
                className={classes.title}
                data-testid="automate-marketing-page-title"
              >
                {t("header")}
              </Typography>
              <Typography className={classes.description}>
                {t("content")}
              </Typography>

              <List className={classes.list}>
                <ListItem className={classes.listItem} disableGutters>
                  <StatsV5 />
                  {t("line1")}
                </ListItem>
                <ListItem className={classes.listItem} disableGutters>
                  <DoneCover />
                  {t("line2")}
                </ListItem>
                <ListItem className={classes.listItem} disableGutters>
                  <NotificationBellV2 />
                  {t("line3")}
                </ListItem>
                <ListItem className={classes.listItem} disableGutters>
                  <ProfileFavorite />
                  {t("line4")}
                </ListItem>
              </List>
              <Hubspot />
            </Box>
          </Grid>
          <Grid item lg={7} md={7} sm={12}>
            <Box className={classes.imageContainer}>
              <img
                src={monitorDashboard}
                alt="Graphic of dashboard page with graphs"
                className={classes.image}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </AppLayout>
  );
}
