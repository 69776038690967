import { EmptyState, getApiAccountId, useTranslation } from "@lumar/shared";
import { Paper, createStyles, makeStyles } from "@material-ui/core";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { useGetDashboardCollectionsQuery } from "../graphql";
import { assert } from "../_common/assert";
import { useGenericParams } from "../_common/routing/useGenericParams";
import { useMonitorRoutes } from "../_common/routing/useMonitorRoutes";
import { BoardHeader } from "./BoardHeader";
import { BoardPage } from "./BoardPage";
import errorLottie from "../_animations/error-animation.json";

const useStyles = makeStyles(() =>
  createStyles({
    emptystate: {
      height: "calc(100vh - 130px)",
    },
  }),
);

export function Board(): JSX.Element {
  const { accountId, collectionId } = useGenericParams();
  assert(accountId);
  const { boardSettingsPage } = useMonitorRoutes();
  const classes = useStyles();
  const { t } = useTranslation(["dashboards"]);

  const { loading: dashboardsQueryRunning, error: dashboardsQueryError } =
    useGetDashboardCollectionsQuery({
      variables: {
        accountId: getApiAccountId(accountId),
      },
      onCompleted: async (response) => {
        const isResponseEmpty = !Boolean(
          response?.getAccount?.customDashboardCollections?.edges?.length,
        );
        if (isResponseEmpty)
          boardSettingsPage.visit({ accountId }, { replace: true });
      },
      fetchPolicy: "cache-first",
    });

  const somethingWrong = Boolean(dashboardsQueryError);

  return (
    <>
      {somethingWrong ? (
        <Paper>
          <EmptyState
            className={classes.emptystate}
            width="100%"
            height={500}
            title={t("dashboardLoadingError")}
            description={t("dashboardLoadingErrorDescription")}
            icon={
              <Lottie
                loop
                animationData={errorLottie}
                play
                style={{ width: "80px", margin: "auto" }}
              />
            }
            actions={[
              {
                type: "button",
                title: t("errorButton"),
                onClick: () => {
                  window.location.reload();
                },
              },
            ]}
          />
        </Paper>
      ) : dashboardsQueryRunning ? undefined : (
        <>
          <BoardHeader />
          <BoardPage key={collectionId} />
        </>
      )}
    </>
  );
}
