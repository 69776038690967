import { SelectableButtonGroup, useTranslation } from "@lumar/shared";

export type SuggestedThresholdFilter = "all" | "requiresApproval";

interface Props {
  value: SuggestedThresholdFilter;
  onChange: (value: SuggestedThresholdFilter) => void;
  totalCount: number;
}

export const SuggestedThresholdTabs = ({
  value,
  onChange,
  totalCount,
}: Props): JSX.Element => {
  const { t } = useTranslation("notifications");
  return (
    <SelectableButtonGroup
      size="medium"
      value={value}
      onValueChange={onChange}
      data-pendo="suggested-threshold-tabs"
      aria-label={t("showNotifications")}
      options={[
        {
          label: "All",
          value: "all",
          "data-pendo": "suggested-threshold-all-tab",
        },
        {
          label: `Requires manual approval (${totalCount})`,
          value: "requiresApproval",
          "data-pendo": "suggested-threshold-requires-approval-tab",
        },
      ]}
    />
  );
};
