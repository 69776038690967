import {
  InformationCircleOutlined,
  Typography,
  Trans,
  useTranslation,
  useSession,
} from "@lumar/shared";
import {
  LinearProgress,
  makeStyles,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { AbsoluteURLs } from "../../../../_common/routing/absoluteURLs";
import { RoleCode } from "../../../../graphql";

const useStyles = makeStyles((theme) => ({
  viewsCount: {
    width: 126,
    marginRight: theme.spacing(1.75),
    height: 8,
    borderRadius: 72,
    background: theme.palette.grey[200],
  },
  clearBackground: {
    background: "transparent",
  },
  green: {
    background: theme.palette.green[400],
  },
  yellow: {
    background: theme.palette.yellow[500],
  },
  red: {
    background: theme.palette.red[500],
  },
  progress: {
    background: theme.palette.grey[400],
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: 100,
    marginRight: theme.spacing(0.625),
  },
  iconRoot: {
    margin: theme.spacing(0.5, 0.375, 0.375, 0.875),
    color: theme.palette.ultraviolet["500"],
    fontSize: theme.typography.pxToRem(15),
    verticalAlign: "middle",
  },
  tooltip: {
    pointerEvents: "inherit",
  },
}));

interface AccountViewsCountProgressBarProps {
  count: number;
  total: number;
  current: number;
}

enum ColorCodes {
  green,
  yellow,
  red,
}

export function AccountViewsCountProgressBar({
  count,
  total,
  current,
}: AccountViewsCountProgressBarProps): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation("board");
  const { accountId } = useParams<{ accountId: string }>();
  const session = useSession();

  const admin = session.hasSufficientRole(RoleCode.Admin);

  const percent = total ? (count + current) / total : 0;

  function getColor(): string {
    if (percent <= 0.59) return theme.palette.green[400];
    return percent <= 0.89 ? theme.palette.yellow[400] : theme.palette.red[400];
  }

  const currentColorCode: ColorCodes = getColorCode(percent);

  if (total <= 0) return <></>;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Tooltip
        title={
          <div>
            <div
              style={{ display: "flex", marginBottom: theme.spacing(1.125) }}
            >
              <div
                className={classes.circle}
                style={{ background: getColor() }}
              />
              <Typography
                variant="overline"
                style={{ marginRight: theme.spacing(1) }}
              >
                {t("thisBoard")}
              </Typography>
              <Typography variant="overline" style={{ marginLeft: "auto" }}>
                {current}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <div className={clsx(classes.circle, classes.progress)} />
              <Typography
                variant="overline"
                style={{ marginRight: theme.spacing(1) }}
              >
                {t("otherBoards")}
              </Typography>
              <Typography variant="overline" style={{ marginLeft: "auto" }}>
                {count}
              </Typography>
            </div>
          </div>
        }
        arrow={false}
      >
        <LinearProgress
          className={classes.viewsCount}
          variant="buffer"
          classes={{
            dashed: classes.clearBackground,
            bar2Buffer: clsx(classes.green, {
              [classes.yellow]: currentColorCode === ColorCodes.yellow,
              [classes.red]: currentColorCode === ColorCodes.red,
            }),
            bar1Buffer: classes.progress,
          }}
          value={Math.min(total ? (count / total) * 100 : 0, 100)}
          valueBuffer={Math.min(
            total ? ((count + current) / total) * 100 : 0,
            100,
          )}
        />
      </Tooltip>
      <Typography
        variant="subtitle3"
        style={{ minWidth: 150, textAlign: "right" }}
      >
        {t("board:viewsCount", {
          count: count + current,
          max: total,
        })}
      </Typography>
      <Tooltip
        leaveTouchDelay={1500}
        leaveDelay={1000}
        classes={{ popper: classes.tooltip }}
        title={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="subtitle3SemiBold"
              style={{ marginBottom: theme.spacing(1.125) }}
            >
              {t("board:viewCountTooltipTitle")}
            </Typography>
            <Typography variant="caption">
              <Trans
                ns="board"
                i18nKey={
                  admin ? "viewCountTooltipAdmin" : "viewCountTooltipOthers"
                }
                components={{
                  tlink: (
                    <a
                      href={AbsoluteURLs.EXTERNAL__AccountsSubscription.getUrl(
                        accountId,
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              />
            </Typography>
          </div>
        }
        arrow={false}
      >
        <InformationCircleOutlined
          classes={{
            root: classes.iconRoot,
          }}
        />
      </Tooltip>
    </div>
  );
}

const GREEN_ZONE_VALUE = 0.59;
const YELLOW_ZONE_VALUE = 0.89;

function getColorCode(percent: number): ColorCodes {
  if (percent <= GREEN_ZONE_VALUE) return ColorCodes.green;
  return percent <= YELLOW_ZONE_VALUE ? ColorCodes.yellow : ColorCodes.red;
}
