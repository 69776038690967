import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
  SecondaryTabs,
  SecondaryTab,
  XSolid,
  ClockSolid,
  Typography,
  useTranslation,
  useSession,
  ApolloError,
} from "@lumar/shared";
import { CheckCircle } from "@material-ui/icons";
import { TestResultStatus } from "../../graphql";
import { RoleCode } from "../../graphql";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    background: theme.palette.grey[50],
    boxShadow: "inset 0px -1px 0px #E5E7EB",
    borderRadius: "100px",
    width: "max-content",
    marginLeft: theme.spacing(2),
  },
  button: {
    borderRadius: "100px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "& .workicon": {
      color: theme.palette.grey[400],
    },
    "& .readicon": {
      color: theme.palette.grey[400],
    },

    "&:hover": {
      background: theme.palette.grey[200],
      "& .workicon": {
        color: theme.palette.orange[500],
      },
      "& .x-icon": {
        color: theme.palette.grey[200],
      },
      "& .readicon": {
        color: theme.palette.green[500],
      },
    },
    "& .button-text": {
      color: theme.palette.grey[500],
    },
    "&.Mui-selected": {
      "&.button-work": {
        background: `${theme.palette.orange[600]}!important`,
        "& .button-text": {
          color: "white",
        },
        "& .workicon": {
          color: "white",
        },
        "& .x-icon": {
          color: theme.palette.orange[400],
        },
      },
      "&.button-read": {
        background: `${theme.palette.green[600]}!important`,
        "& .button-text": {
          color: "white",
        },
        "& .x-icon": {
          color: theme.palette.green[400],
        },
        "& .readicon": {
          color: "white",
        },
      },
      "&:hover": {
        "&.button-work": {
          background: `${theme.palette.orange[500]}!important`,
          "& .x-icon": {
            color: "white",
          },
        },
        "&.button-read": {
          background: `${theme.palette.green[500]}!important`,
          "& .x-icon": {
            color: "white",
          },
        },
      },
    },
  },
  hiddenButton: {
    display: "none",
  },
  splitter: {
    height: 20,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    marginTop: 5,
    marginBottom: 5,
  },
  buttonInside: {
    display: "flex",
    alignItems: "center",
    height: 14,
  },
  x: {
    width: 14,
    height: 14,
    marginLeft: theme.spacing(1.625),
    color: theme.palette.grey[50],
  },
  icon: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  initialStatus: TestResultStatus | null | undefined;
  disabled?: boolean;
  onChange: (status: TestResultStatus) => Promise<ApolloError | undefined>;
}

export function StatusChips({
  initialStatus,
  disabled,
  onChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("notifications");

  const session = useSession();
  const hasEditRights = session.hasSufficientRole(RoleCode.Editor);

  const [status, setStatus] = useState<TestResultStatus>(
    initialStatus ?? TestResultStatus.Unresolved,
  );

  async function handleStatusChange(
    newStatus: TestResultStatus,
  ): Promise<void> {
    const statusToSet =
      status === newStatus ? TestResultStatus.Unresolved : newStatus;
    setStatus(statusToSet);
    const error = await onChange(statusToSet);
    if (error) {
      setStatus(initialStatus ?? TestResultStatus.Unresolved);
    }
  }

  useEffect(() => {
    setStatus(initialStatus ?? TestResultStatus.Unresolved);
  }, [initialStatus]);

  return (
    <SecondaryTabs
      className={classes.buttonGroup}
      value={status}
      disabled={!hasEditRights || disabled}
      data-value={status}
      data-testid="status-chips"
    >
      <SecondaryTab
        disabled={!hasEditRights}
        className={clsx(classes.button, "button-work")}
        label={
          <div className={classes.buttonInside}>
            <ClockSolid className={clsx(classes.icon, "workicon")} />
            <Typography className="button-text" variant="button">
              {t("notifications:working")}
            </Typography>

            <XSolid className={clsx(classes.x, "x-icon")} />
          </div>
        }
        value={TestResultStatus.WorkingOnIt}
        data-testid={`${TestResultStatus.WorkingOnIt.toLowerCase()}-status-chip`}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleStatusChange(TestResultStatus.WorkingOnIt);
        }}
      />
      <div className={classes.splitter} />
      <SecondaryTab
        disabled={!hasEditRights}
        className={clsx(classes.button, "button-read")}
        label={
          <div className={classes.buttonInside}>
            <CheckCircle className={clsx(classes.icon, "readicon")} />
            <Typography className="button-text" variant="button">
              {t("notifications:read")}
            </Typography>

            <XSolid className={clsx(classes.x, "x-icon")} />
          </div>
        }
        value={TestResultStatus.Read}
        data-testid={`archived-status-chip`}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleStatusChange(TestResultStatus.Read);
        }}
      />
      <SecondaryTab
        className={classes.hiddenButton}
        value={TestResultStatus.Unresolved}
      />
    </SecondaryTabs>
  );
}
