import React from "react";
import { SimpleView } from "../../types";
import { mapViewsToAction } from "../../board/utils/mapViewsToAction";
import { useSnackbar } from "notistack";
import { Snackbar, useTranslation } from "@lumar/shared";
import { getErrorMessage } from "../../../../_common/utils/getErrorMessage";
import {
  useAddViewsToDashboardMutation,
  useDeleteCustomViewsFromDashboardMutation,
} from "../../../../graphql";

const VIEW_LIMIT = 100;

export function useUpdateViewsForDashboard(): (
  dashboardId: string,
  initialValues: SimpleView[],
  newValues: SimpleView[],
) => Promise<boolean> {
  const [addViewsToDashboard] = useAddViewsToDashboardMutation();
  const [deleteCustomViewsFromDashboard] =
    useDeleteCustomViewsFromDashboardMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["dashboards"]);

  async function updateViewsForDashboard(
    dashboardId: string,
    initialValues: SimpleView[],
    newValues: SimpleView[],
  ): Promise<boolean> {
    const actions = mapViewsToAction(initialValues, newValues);
    if (!actions.toDelete.length && !actions.toCreate.length) {
      return true;
    }
    try {
      const hasCreate = Boolean(actions.toCreate.length);
      if (actions.toDelete.length) {
        const length = Math.ceil(actions.toDelete.length / VIEW_LIMIT);
        const counter = Array.from(Array(length).keys());
        // eslint-disable-next-line fp/no-loops
        for (const e of counter) {
          const slice = actions.toDelete.slice(
            e * VIEW_LIMIT,
            (e + 1) * VIEW_LIMIT,
          );
          await deleteCustomViewsFromDashboard({
            variables: {
              customDashboardId: dashboardId,
              customViewIds: slice,
            },
            refetchQueries: hasCreate
              ? undefined
              : e === length - 1
                ? [
                    "GetDashboardViews",
                    "TableReportStatsDataRows",
                    "TableHealthScoreStatsDataRows",
                  ]
                : undefined,
          });
        }
      }
      if (hasCreate) {
        const length = Math.ceil(actions.toCreate.length / VIEW_LIMIT);
        const counter = Array.from(Array(length).keys());
        // eslint-disable-next-line fp/no-loops
        for (const e of counter) {
          const slice = actions.toCreate.slice(
            e * VIEW_LIMIT,
            (e + 1) * VIEW_LIMIT,
          );
          await addViewsToDashboard({
            variables: {
              dashboardId,
              viewIds: slice.map((e) => e.id),
            },
            refetchQueries:
              e === length - 1
                ? [
                    "GetDashboardViews",
                    "TableReportStatsDataRows",
                    "TableHealthScoreStatsDataRows",
                  ]
                : undefined,
          });
        }
      }
      enqueueSnackbar(
        <Snackbar variant="success" title={t("updatedSuccessfully")} />,
      );
    } catch (error) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("updateFailed", { message: getErrorMessage(error) })}
        />,
      );
      return false;
    }
    return true;
  }

  return updateViewsForDashboard;
}
