import React, { useState } from "react";
import { createStyles, makeStyles, Tooltip, useTheme } from "@material-ui/core";
import {
  useTranslation,
  useNumberFormatter,
  MonospaceNumber,
  PercentageChangeChip,
  ExclamationSignIcon,
  GoodSignIcon,
  NeutralSignIcon,
  Typography,
} from "@lumar/shared";

import { DataGridReportValue, DataGridTrendValue } from "../../types";
import noTrend from "../../../../../_common/images/no-trend-available.svg";
import { AnalyticsHubReportLink } from "../../AnalyticsHubReportLink";
import { isEqual } from "lodash";
import { InView } from "react-intersection-observer";
import { SparklinesMiniChart } from "../../../../../_common/components/SparklinesMiniChart/SparklinesMiniChart";
import { ReportOption } from "../../../../../_common/utils/constants";
import { RowData } from "../../helpers/mapAccountOverviewDataToGridRows";

const CHART_HEIGHT = 40;

const useStyles = makeStyles((theme) =>
  createStyles({
    cellAnchor: {
      textDecoration: "none",
      width: "100%",
      height: "100%",
    },
    percentageChipLabel: {
      color: theme.palette.grey[600],
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
    },
    tooltip: {
      marginTop: -16,
    },
    horMargin: {
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    title: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      alignSelf: "center",
      marginTop: theme.spacing(1),
    },
  }),
);

function getChange(trend?: DataGridTrendValue[]): number {
  if (trend && trend.length > 1) {
    return trend[0].count - trend[1].count;
  }
  return 0;
}

interface AccountOverviewTableCellProps {
  accountId: string;
  row: RowData;
  crawlId: string;
  reportTemplate?: ReportOption;
  columnId: string;
  report?: DataGridReportValue;
  width?: number | null;
}

/**
 * NOTE: we are trying to avoid using Material-UI's `useStyle` and `useTheme` hooks as much as possible in this component,
 * as well as avoid using MUI's `Typography` and `Box` component. This is because we are experiencing slow rendering of the table,
 * and this is made worse by CSS-in-JS (which is used in Material-UI). Another way we could potentially improve performance even more is to move
 * all the styles to a separate CSS file.
 */

export const AccountOverviewTableCell = React.memo(
  function AccountOverviewTableCell({
    accountId,
    row,
    crawlId,
    reportTemplate,
    report,
    width,
  }: AccountOverviewTableCellProps): JSX.Element {
    const { t, i18n } = useTranslation(["accountDashboard", "units", "common"]);
    const formatNumber = useNumberFormatter();
    const classes = useStyles();
    const theme = useTheme();
    const absoluteChange = getChange(report?.trends);
    const sign = Math.sign(absoluteChange) * (report?.totalSign ?? 0);
    const totalUrls = report?.total ?? 0;
    const chartData: [number, number][] =
      report?.trends?.map((trend) => [trend.date.getTime(), trend.count]) ?? [];
    const hasUrlCountChanged = absoluteChange !== 0;
    const hasNoChangeAndNoUrls = !hasUrlCountChanged && totalUrls === 0;
    const hasTrendData = Boolean(chartData && chartData?.length > 1);

    const [inViewStatus, setInViewStatus] = useState<{
      rendered: boolean;
      visible: boolean;
    }>({ rendered: false, visible: false });
    const onViewChange = (inView: boolean): void => {
      if (inView === true) {
        setInViewStatus({ rendered: true, visible: true });
      } else {
        setInViewStatus({ rendered: inViewStatus.rendered, visible: inView });
      }
    };
    const isNotSegmentCompatible =
      row.segmentId && !reportTemplate?.useableWithSegments;
    const isNotCompatible =
      isNotSegmentCompatible ||
      !reportTemplate?.supportedModules?.includes(row.moduleCode);

    if (isNotCompatible) {
      return (
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            lineHeight: 1.43,
            backgroundColor: theme.palette.grey[100],
            alignContent: "center",
            flexWrap: "wrap",
            borderRadius: 8,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Typography variant="subtitle4Bold" className={classes.title}>
            {t("noDataAvailable")}
          </Typography>
          <Typography
            variant="subtitle4"
            className={classes.horMargin}
            style={{ fontSize: "0.66rem" }}
          >
            {isNotSegmentCompatible
              ? t("incompatibleReportWithSegments")
              : t("incompatibleReport", { type: row.moduleCode })}
          </Typography>
        </div>
      );
    }

    const doesReportHaveNoTrend = !report?.trends?.length;

    if (!Boolean(report) || doesReportHaveNoTrend) return <></>;

    return (
      <AnalyticsHubReportLink
        accountId={accountId}
        projectId={row.projectId}
        segmentId={row.segmentId}
        crawlId={crawlId ?? ""}
        reportTemplateCode={reportTemplate?.code ?? ""}
        className={classes.cellAnchor}
        data-testid={`account-overview-grid-${reportTemplate?.code}-column-cell`}
        data-pendo="monitor-dashboard-report-link"
      >
        {/* FIXME: Should not have to cast, should not be string | undefined. Investigate i18n types */}
        <Tooltip
          title={t("common:goToReport") as string}
          arrow={false}
          classes={{ popper: classes.tooltip }}
          style={{ height: "100%" }}
        >
          <InView onChange={onViewChange}>
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                height: "100%",
                lineHeight: 1.43,
                marginRight: 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    minHeight: CHART_HEIGHT,
                    width: "100%",
                    lineHeight: 1.43,
                    padding: "8px 0px",
                    flex: 1,
                    alignItems: "center",
                    display: "flex",
                    visibility: inViewStatus.visible ? "visible" : "hidden",
                  }}
                >
                  {inViewStatus.rendered &&
                    (hasTrendData ? (
                      <SparklinesMiniChart
                        height={CHART_HEIGHT}
                        width={width}
                        allowChartUpdate={true}
                        data={[
                          {
                            values: chartData,
                            type: "area",
                            color: theme.palette.grey[600],
                            gradient: [
                              [0, theme.palette.grey[100]],
                              [1, theme.palette.grey[100]],
                            ],
                          },
                        ]}
                      />
                    ) : (
                      <img
                        src={noTrend}
                        style={{
                          width: width ?? 102,
                          height: CHART_HEIGHT,
                        }}
                        alt="No trend data"
                      />
                    ))}
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexFlow: "column",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  <h6
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "#1F2937",
                      textAlign: "right",
                      margin: 0,
                      marginTop: 8,
                    }}
                  >
                    {formatNumber(totalUrls)}
                  </h6>
                </div>
                {!hasNoChangeAndNoUrls && hasTrendData ? (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "start",
                      marginRight: "-7px",
                      marginLeft: "auto",
                    }}
                  >
                    {hasUrlCountChanged && inViewStatus.rendered ? (
                      <>
                        <PercentageChangeChip
                          total={totalUrls}
                          sign={report?.totalSign ?? 0}
                          change={absoluteChange}
                          disableBackground={true}
                          lang={i18n.language}
                          compact={true}
                          variant="triangle"
                          classes={{
                            label: classes.percentageChipLabel,
                          }}
                          label={
                            <span
                              style={{
                                fontSize: 14,
                                lineHeight: "17px",
                                fontWeight: 500,
                                color: "#4B5563",
                                marginTop: -1,
                                display: "block",
                              }}
                            >
                              <MonospaceNumber>
                                {absoluteChange > 0 && "+"}
                                {t("units:compactNumber", {
                                  count: absoluteChange,
                                })}
                              </MonospaceNumber>
                            </span>
                          }
                        />
                        <SentimentIcon sign={sign} />
                      </>
                    ) : (
                      <span
                        style={{
                          marginTop: -1,
                          color: "#4B5563",
                          paddingRight: 7,
                        }}
                      >
                        0
                      </span>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      flex: 1,
                    }}
                  />
                )}
              </div>
            </div>
          </InView>
        </Tooltip>
      </AnalyticsHubReportLink>
    );
  },
  (prev, next) =>
    prev.accountId === next.accountId &&
    prev.crawlId === next.crawlId &&
    prev.reportTemplate?.code === next.reportTemplate?.code &&
    prev.columnId === next.columnId &&
    prev.width === next.width &&
    isEqual(prev.row, next.row) &&
    isEqual(prev.report, next.report),
);

const SentimentIcon = ({ sign }: { sign: number }): JSX.Element => {
  const styles = {
    marginTop: -2,
    color: "#6B7280",
  };

  if (sign > 0) {
    return <GoodSignIcon style={styles} />;
  }

  if (sign < 0) {
    return <ExclamationSignIcon style={styles} />;
  }

  return <NeutralSignIcon style={styles} />;
};
