import { ReactNode } from "react";
import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles({
  wrapper: {
    width: "100%",
    flexGrow: 1,
  },
});

export function ProjectComparisonChartInnerWrapper({
  className,
  children,
}: {
  className: string;
  children?: ReactNode;
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div
      className={className}
      style={{
        display: "flex",
        paddingLeft: theme.spacing(2.75),
        paddingRight: theme.spacing(2.75),
      }}
    >
      <div className={classes.wrapper}>{children}</div>
    </div>
  );
}
