import {
  useCreateSlackWebhookMutation,
  useDeleteSlackWebhookMutation,
} from "../../../graphql";
import { SlackWebhook } from "./types";
import {
  Action,
  mapAlertNotificationToAction,
} from "./mapAlertNotificationToAction";

type MutationFunction = (
  initialValues: SlackWebhook[],
  newValues: string[],
  projectId: string,
) => Promise<unknown>;

export function useSaveSlackWebhooksMutation(): [
  MutationFunction,
  { loading: boolean },
] {
  const [createSlackWebhook, { loading: createLoading }] =
    useCreateSlackWebhookMutation();
  const [deleteSlackWebhook, { loading: deleteLoading }] =
    useDeleteSlackWebhookMutation();

  const isLoading = createLoading || deleteLoading;

  function saveSlackWebhooks(
    initialValues: SlackWebhook[],
    newValues: string[],
    projectId: string,
  ): Promise<unknown> {
    const slackWebhooks = mapAlertNotificationToAction<SlackWebhook>(
      initialValues,
      newValues,
      "url",
    );

    const promiseArray: Promise<unknown>[] = slackWebhooks.map(
      ({ action, url, id }) => {
        if (action === Action.Create) {
          return createSlackWebhook({
            variables: {
              projectId,
              url,
            },
            context: {
              includeInBatch: true,
            },
          });
        }
        return deleteSlackWebhook({
          variables: {
            id,
          },
          context: {
            includeInBatch: true,
          },
        });
      },
    );

    return Promise.all(promiseArray);
  }

  return [saveSlackWebhooks, { loading: isLoading }];
}
