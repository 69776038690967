import {
  ProjectComparisonChartQuery,
  ReportTrendItem,
  ReportTypeCode,
} from "../../../../../graphql";
import { isTrends } from "../../../../../_common/utils/isTrends";
import { AbsoluteURLs } from "../../../../../_common/routing/absoluteURLs";
import { SeriesLineOptions } from "highcharts";
import { View } from "../../../../settings/types";
import { isRawView } from "../../../common/project-comparison-chart/helpers/isRawView";
import { getRawProjectId, getRawSegmentId } from "@lumar/shared";

export function mapProjectComparisonChartDataToSeries({
  data,
  accountId,
  reportTemplateCode,
  views,
  noSegmentText,
}: {
  data?: ProjectComparisonChartQuery;
  accountId: string;
  reportTemplateCode: string;
  views: View[];
  noSegmentText: string;
}): SeriesLineOptions[] | undefined {
  if (!data) {
    return undefined;
  }
  const tmp = data.getCustomChart?.trends.nodes
    .map((rawView, index) => {
      if (!isRawView(rawView)) return undefined;
      const { projectId, segmentId, columns } = rawView;
      const fullView = (views ?? []).find(
        (e) =>
          getRawProjectId(e.projectId) === projectId.toString() &&
          getRawSegmentId(e.segmentId ?? "0") === segmentId.toString(),
      );
      const reportTrend: ReportTrendItem[] = isTrends(columns)
        ? columns[0]?.points.filter((e) => Boolean(e.createdAt))
        : [];

      const result = {
        name: fullView?.projectName,
        type: "line",
        id: `${projectId}-${segmentId ?? "0"}`,
        colorIndex: index,
        segment: fullView?.segmentName ?? noSegmentText,
        // NOTE: We spread the array into a new array before we mutate with a reverse so it's O.K - Saul.
        // eslint-disable-next-line fp/no-mutating-methods
        data: [
          // eslint-disable-next-line fp/no-mutating-methods
          ...reportTrend
            .filter((e) => e.createdAt)
            .map((trend) => {
              const { crawlId, basic, createdAt } = trend;
              const createdAtTimeInMilliseconds = new Date(
                createdAt ?? "",
              ).getTime();
              const crawlFrequency = fullView?.scheduleFrequency?.name ?? "";

              return {
                y: basic,
                x: createdAtTimeInMilliseconds,
                href: AbsoluteURLs.EXTERNAL__AnalyzeReport.getUrl({
                  accountId,
                  projectId: `${projectId}`,
                  crawlId: String(crawlId),
                  reportTemplateCode,
                  reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
                  segmentId: segmentId ? segmentId?.toString() : undefined,
                }),
                crawlFrequency,
              };
            })
            .sort((a, b) => a.x - b.x),
        ],
      };
      return result as SeriesLineOptions;
    })
    .filter((x): x is SeriesLineOptions => x !== undefined)
    .map((x) => {
      return x;
    });

  return tmp;
}
