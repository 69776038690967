import { Typography, useTranslation } from "@lumar/shared";
import { createStyles, makeStyles, useTheme } from "@material-ui/core";
import { useFormikContext } from "formik";
import { HideFromInsufficientRole } from "../../../_common/components/HideFromInsufficientRole";
import {
  CreateNewNotificationMenu,
  NotificationChannelType,
} from "./components/CreateNewNotificationMenu";
import { EmailNotificationPanel } from "./components/EmailNotificationPanel";
import { RulesAndThresholdsFormState } from "../utils/types";
import { useNotificationChannels } from "./useNotificationChannels";
import { MicrosoftTeamsNotificationPanel } from "./components/MicrosoftTeamsNotificationPanel";
import { NotificationChannelTabs } from "./components/NotificationChannelTabs";
import { SlackNotificationPanel } from "./components/SlackNotificationPanel";

const useStyles = makeStyles((theme) =>
  createStyles({
    description: {
      maxWidth: "600px",
      lineHeight: "14.3px",
      color: theme.palette.grey[500],
      fontSize: theme.typography.pxToRem(13),
      display: "block",
      marginTop: 6,
    },
    divider: {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      margin: theme.spacing(3, -3, 0, -2),
    },
    container: {
      display: "flex",
      flexFlow: "column",
      marginTop: theme.spacing(3),
    },
  }),
);

export function NotificationChanelSelector({
  isEditMode,
  className,
}: {
  isEditMode?: boolean;
  className?: string;
}): JSX.Element {
  const { setFieldTouched } = useFormikContext<RulesAndThresholdsFormState>();

  const {
    notificationChannels: { selectedChannel, activeChannels },
    addChannel,
    removeChannel,
    selectChannel,
  } = useNotificationChannels();

  const theme = useTheme();
  const { t } = useTranslation(["alerts"]);
  const classes = useStyles();

  const showNotificationsMenu = activeChannels.length < 3;

  function handleRemoveEmail(): void {
    removeChannel(NotificationChannelType.Email);
    setFieldTouched(`emailAlerts`, true);
  }

  function handleRemoveSlack(): void {
    removeChannel(NotificationChannelType.Slack);
    setFieldTouched(`slackWebhooks`, true);
  }

  function handleRemoveMsTeams(): void {
    removeChannel(NotificationChannelType.MsTeams);
    setFieldTouched(`microsoftTeamsWebhooks`, true);
  }

  return (
    <div
      style={{
        width: "100%",
        background: theme.palette.background.paper,
        paddingLeft: theme.spacing(2),
      }}
      className={className}
    >
      <div className={classes.divider} />
      <div className={classes.container}>
        <Typography variant="subtitle1SemiBold">{t("choose")}</Typography>
        <Typography variant="caption" className={classes.description}>
          {t("descriptionContent")}
        </Typography>
        {activeChannels.length > 1 ? (
          <div style={{ display: "flex", marginTop: theme.spacing(3.25) }}>
            <NotificationChannelTabs
              value={selectedChannel}
              tabsToShow={activeChannels}
              onChange={selectChannel}
            />
            <div style={{ marginLeft: theme.spacing(5) }}>
              {selectedChannel === NotificationChannelType.Email ? (
                <EmailNotificationPanel
                  onRemovePanel={handleRemoveEmail}
                  disabled={!isEditMode}
                />
              ) : selectedChannel === NotificationChannelType.Slack ? (
                <SlackNotificationPanel
                  onRemovePanel={handleRemoveSlack}
                  disabled={!isEditMode}
                />
              ) : (
                <MicrosoftTeamsNotificationPanel
                  onRemovePanel={handleRemoveMsTeams}
                  disabled={!isEditMode}
                />
              )}
            </div>
          </div>
        ) : activeChannels.length === 1 ? (
          <div style={{ marginTop: theme.spacing(3.25) }}>
            {activeChannels[0] === NotificationChannelType.Email ? (
              <EmailNotificationPanel
                onRemovePanel={handleRemoveEmail}
                disabled={!isEditMode}
              />
            ) : activeChannels[0] === NotificationChannelType.Slack ? (
              <SlackNotificationPanel
                onRemovePanel={handleRemoveSlack}
                disabled={!isEditMode}
              />
            ) : (
              <MicrosoftTeamsNotificationPanel
                onRemovePanel={handleRemoveMsTeams}
                disabled={!isEditMode}
              />
            )}
          </div>
        ) : null}
        <HideFromInsufficientRole>
          {showNotificationsMenu ? (
            <CreateNewNotificationMenu
              existingNotificationChannels={activeChannels}
              onMenuItemClick={addChannel}
              disabled={!isEditMode}
            />
          ) : null}
        </HideFromInsufficientRole>
      </div>
    </div>
  );
}
