import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  MailOutlined,
  Button,
  SlackSolid,
  PlusSolid,
  MsTeamsSolid,
  useTranslation,
} from "@lumar/shared";
import { Tooltip, makeStyles, useTheme } from "@material-ui/core";
import { useFormikContext } from "formik";
import { RulesAndThresholdsFormState } from "../../utils/types";

const useStyles = makeStyles((theme) => ({
  button: ({
    isMenuOpen,
    disabled,
  }: {
    isMenuOpen: boolean;
    disabled: boolean;
  }) => ({
    backgroundColor: isMenuOpen
      ? theme.palette.ultraviolet[100]
      : theme.palette.ultraviolet[100],
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiButton-label": {
      height: 36,
      color: !disabled
        ? theme.palette.ultraviolet[700]
        : theme.palette.ultraviolet[300],
    },
    "& .MuiButton-endIcon": {
      borderLeft: "1px solid white",
      height: "100%",
      display: "flex",
      alignItems: "center",
      marginLeft: 10,
      paddingLeft: 7,
    },
    "& .MuiSvgIcon-root": {
      marginTop: 2,
    },
    "&:hover": {
      backgroundColor: theme.palette.ultraviolet[200],
    },
  }),
  icon: {
    fontSize: theme.typography.pxToRem(20),
    marginRight: 12,
  },
}));

export enum NotificationChannelType {
  Email,
  Slack,
  MsTeams,
}

const fieldNames = {
  [NotificationChannelType.Email]: `emailAlerts`,
  [NotificationChannelType.Slack]: `slackWebhooks`,
  [NotificationChannelType.MsTeams]: `microsoftTeamsWebhooks`,
};

interface Props {
  onMenuItemClick: (event: NotificationChannelType) => void;
  existingNotificationChannels?: NotificationChannelType[];
  disabled?: boolean;
}

export function CreateNewNotificationMenu({
  onMenuItemClick,
  existingNotificationChannels,
  disabled = false,
}: Props): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(["alerts"]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const [selected, setSelected] = useState<
    NotificationChannelType | undefined
  >();

  const { setFieldValue, setFieldTouched } =
    useFormikContext<RulesAndThresholdsFormState>();

  const isMenuOpen = Boolean(anchorEl);

  const classes = useStyles({ isMenuOpen, disabled });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (channelType: NotificationChannelType): void => {
    handleClose();
    setSelected(channelType);
  };

  const handleExited = (): void => {
    if (selected !== undefined) {
      const fieldName = fieldNames[selected];
      setFieldValue(fieldName, undefined);
      setFieldTouched(fieldName, true);
      onMenuItemClick(selected);
      setSelected(undefined);
    }
  };

  return (
    <div style={{ marginTop: theme.spacing(4) }}>
      <Tooltip title={disabled ? (t("disabledAddNotification") as string) : ""}>
        <span>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            ref={buttonRef}
            variant="contained"
            color="primary"
            size="large"
            startIcon={
              <PlusSolid
                style={{
                  color: disabled
                    ? theme.palette.ultraviolet[300]
                    : theme.palette.primary.main,
                }}
              />
            }
            className={classes.button}
            data-pendo="new-notification-channel-menu-button"
            data-testid="add-notification-channel"
            disabled={disabled}
          >
            {t("newChannel")}
          </Button>
        </span>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        disableRestoreFocus
        TransitionProps={{
          onExited: handleExited,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          data-pendo="notification-channel-create-email-button"
          data-testid="add-email-notification-channel"
          disabled={Boolean(
            existingNotificationChannels?.includes(
              NotificationChannelType.Email,
            ),
          )}
          onClick={() => {
            handleMenuItemClick(NotificationChannelType.Email);
          }}
          style={{ minWidth: 200 }}
        >
          <MailOutlined
            className={classes.icon}
            style={{ color: theme.palette.ultraviolet[400] }}
          />
          {t("email")}
        </MenuItem>

        <MenuItem
          disabled={Boolean(
            existingNotificationChannels?.includes(
              NotificationChannelType.Slack,
            ),
          )}
          onClick={() => {
            handleMenuItemClick(NotificationChannelType.Slack);
          }}
          data-pendo="notification-channel-create-slack-button"
          data-testid="add-slack-notification-channel"
        >
          <SlackSolid
            className={classes.icon}
            style={{ color: theme.palette.ultraviolet[400] }}
          />
          {t("slack")}
        </MenuItem>
        <MenuItem
          disabled={Boolean(
            existingNotificationChannels?.includes(
              NotificationChannelType.MsTeams,
            ),
          )}
          onClick={() => {
            handleMenuItemClick(NotificationChannelType.MsTeams);
          }}
          data-pendo="notification-channel-create-msteams-button"
        >
          <MsTeamsSolid
            className={classes.icon}
            style={{ color: theme.palette.ultraviolet[400] }}
          />
          {t("msteams")}
        </MenuItem>
      </Menu>
    </div>
  );
}
