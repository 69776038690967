import { useParams } from "react-router-dom";
import {
  useDeleteReportRulesAndThresholdsMutation,
  useDeleteHealthScoreRulesAndThresholdsMutation,
  GetReportNotificationsDocument,
  useDeleteSlackWebhookMutation,
  useDeleteEmailAlertMutation,
  GetAlertWithTestsQuery,
  GetProjectListForDialogDocument,
  useDeleteMsTeamsWebhookMutation,
} from "../../../graphql";

export function useDeleteAlertMutation(): [
  (alert: GetAlertWithTestsQuery["alert"]) => Promise<void>,
] {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const [deleteReportRulesAndThresholds] =
    useDeleteReportRulesAndThresholdsMutation();
  const [deleteHealthScoreRulesAndThresholds] =
    useDeleteHealthScoreRulesAndThresholdsMutation();

  const [deleteSlackWebhook] = useDeleteSlackWebhookMutation();
  const [deleteEmailAlert] = useDeleteEmailAlertMutation();
  const [deleteMSAlert] = useDeleteMsTeamsWebhookMutation();

  const refetch = [
    {
      query: GetReportNotificationsDocument,
      variables: {
        accountId,
      },
    },
    {
      query: GetProjectListForDialogDocument,
      variables: {
        accountId,
      },
    },
    "GetTestsTotalCount",
  ];

  async function deleteAlert(
    alert: GetAlertWithTestsQuery["alert"],
  ): Promise<void> {
    const reportTestIds = alert?.tests.nodes.map((n) => n.id) ?? [];
    const healthScoreTestIds =
      alert?.healthScoreTests.nodes.map((n) => n.id) ?? [];
    const emailIds = alert?.emailAlerts?.nodes.map((n) => n.id) ?? [];
    const slackIds = alert?.slackWebhooks?.nodes.map((n) => n.id) ?? [];
    const msIds = alert?.webhooks?.nodes.map((n) => n.id) ?? [];

    const promiseEmailArray = emailIds.map((id) =>
      deleteEmailAlert({
        variables: {
          id,
        },
        context: {
          includeInBatch: true,
        },
      }),
    );
    const promiseSlackArray = slackIds.map((id) =>
      deleteSlackWebhook({
        variables: {
          id,
        },
        context: {
          includeInBatch: true,
        },
      }),
    );
    const promiseMSArray = msIds.map((id) =>
      deleteMSAlert({
        variables: {
          id,
        },
        context: {
          includeInBatch: true,
        },
      }),
    );
    await Promise.all([
      deleteReportRulesAndThresholds({
        variables: { testIds: reportTestIds },
        context: {
          includeInBatch: true,
        },
        refetchQueries: healthScoreTestIds.length ? undefined : refetch,
      }),
      promiseEmailArray,
      promiseSlackArray,
      promiseMSArray,
    ]);
    await Promise.all([
      deleteHealthScoreRulesAndThresholds({
        variables: { testIds: healthScoreTestIds },
        context: {
          includeInBatch: true,
        },
        refetchQueries: refetch,
      }),
      promiseEmailArray,
      promiseSlackArray,
      promiseMSArray,
    ]);
  }

  return [deleteAlert];
}
