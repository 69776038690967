import {
  Typography,
  Chip,
  useNumberFormatter,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { ReportTemplateUnit } from "../../graphql";
import { NotificationUnit } from "../types";

const FONT_SIZE = 16;

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[600],
  },
  text: {
    fontSize: FONT_SIZE,
  },
}));

interface NotificationTitleProps {
  reportName: string;
  previousUrlCount: number;
  currentUrlCount: number;
  isFailed: boolean;
  isHovering: boolean;
  isAdjustment: boolean;
  unit: NotificationUnit;
  noUnitOnChange?: boolean;
}

export const NotificationTitle = ({
  reportName,
  previousUrlCount,
  currentUrlCount,
  isFailed,
  isHovering,
  isAdjustment,
  unit,
  noUnitOnChange,
}: NotificationTitleProps): JSX.Element => {
  const formatNumber = useNumberFormatter();
  const classes = useStyles();
  const { t } = useTranslation(["notifications", "units"]);

  if (isAdjustment) {
    return (
      <Typography variant="subtitle1SemiBold" className={classes.text}>
        <span
          data-testid="notification-list-item-report-name"
          style={{
            textDecoration: isHovering ? "underline" : "none",
          }}
        >
          {`${reportName} `}
        </span>
        <Chip
          label={t("notifications:nottriggered")}
          style={{ fontSize: FONT_SIZE }}
          color="lightgrey"
          className={classes.chip}
        />{" "}
        {t("notifications:adjustmentLabel1")}{" "}
        <Chip
          label={formatNumber(currentUrlCount)}
          color="primary"
          style={{ fontSize: FONT_SIZE }}
        />{" "}
        {t(`units:${unit}`, { count: currentUrlCount })}
      </Typography>
    );
  }

  const absoluteUrlChange = currentUrlCount - previousUrlCount;
  const percentageUrlChange = absoluteUrlChange / previousUrlCount;

  const changePhrase =
    absoluteUrlChange > 0
      ? t("notifications:up")
      : absoluteUrlChange === 0
        ? t("notifications:unchanged")
        : t("notifications:down");

  const chipLabel = noUnitOnChange
    ? `${formatNumber(absoluteUrlChange)}`
    : `${formatNumber(absoluteUrlChange)} ${t(`units:${unit}`, {
        count: absoluteUrlChange,
      })} (${formatNumber(percentageUrlChange, { style: "percent" })})`;

  const comparisonPhrase = absoluteUrlChange
    ? `${t("notifications:from")} ${formatNumber(previousUrlCount)} ${t(
        "notifications:to",
      )}`
    : t("notifications:with");

  return (
    <Typography variant="subtitle1SemiBold" className={classes.text}>
      <span
        data-testid="notification-list-item-report-name"
        style={{
          textDecoration: isHovering ? "underline" : "none",
        }}
      >
        {reportName}
      </span>
      {` ${t("notifications:is")} ${changePhrase} `}
      {absoluteUrlChange === 0 ? undefined : (
        <Chip
          label={chipLabel}
          style={{ fontSize: FONT_SIZE }}
          color={
            absoluteUrlChange === 0 ? "lightgrey" : isFailed ? "red" : "yellow"
          }
        />
      )}
      {` ${comparisonPhrase} `}
      <Chip
        label={formatNumber(currentUrlCount)}
        color="primary"
        style={{ fontSize: FONT_SIZE }}
      />{" "}
      {t(`units:${unit ?? ReportTemplateUnit.UrLs}`, {
        count: currentUrlCount,
      })}
    </Typography>
  );
};
