import { useTranslation } from "@lumar/shared";
import { ModuleCode } from "../../../../graphql";
import { isMatch } from "lodash";

export interface Template {
  id: string;
  isHealthScore: boolean;
  moduleCode: ModuleCode;
  code: string;
  name: string;
  description?: string;
  subCodes?: string[];
  visible: boolean;
  graphCode: string;
  default?: boolean;
}

export function useTemplates(): {
  templates: Template[];
  searchFunction: (element: Partial<Template>) => Template[];
} {
  const { t } = useTranslation(["dashboardTemplates"]);

  const templates = [
    {
      id: "link-health",
      code: "linkHealth",
      name: t("lHName"),
      description: t("lHDescription"),
      isHealthScore: false,
      visible: true,
      moduleCode: ModuleCode.Seo,
      subCodes: [
        "4xx_errors",
        "5xx_errors",
        "broken_js",
        "broken_css",
        "broken_sitemap_links",
        "backlink_pages_with_errors",
        "all_broken_redirects",
      ],
      graphCode: "4xx_errors",
    },
    {
      id: "indexability",
      code: "indexability",
      name: t("indexabilityName"),
      description: t("indexabilityDescription"),
      isHealthScore: false,
      moduleCode: ModuleCode.Seo,
      visible: true,
      subCodes: [
        "indexable_pages",
        "non_indexable_pages",
        "canonicalized_pages",
        "noindex_pages",
        "4xx_errors",
        "all_redirects",
      ],
      graphCode: "indexable_pages",
    },
    {
      id: "redirects",
      code: "redirects",
      name: t("redirectsName"),
      description: t("redirectsDescription"),
      isHealthScore: false,
      visible: true,
      moduleCode: ModuleCode.Seo,
      subCodes: [
        "all_redirects",
        "301_redirects",
        "non_301_redirects",
        "all_broken_redirects",
        "redirection_loop",
        "redirect_chains",
      ],
      graphCode: "all_redirects",
    },
    {
      id: "canonicals",
      code: "canonicals",
      name: t("canonicalsName"),
      description: t("canonicalsDescription"),
      isHealthScore: false,
      moduleCode: ModuleCode.Seo,
      visible: true,
      subCodes: [
        "pages_without_canonical_tag",
        "canonical_to_non_200",
        "canonicalized_noindexed_pages",
        "conflicting_canonical_tags",
        "canonical_link_mismatch",
        "unlinked_canonical_pages",
      ],
      graphCode: "pages_without_canonical_tag",
    },
    {
      id: "sitemap-hygiene",
      code: "sitemapHygiene",
      visible: true,
      name: t("sitemapHName"),
      description: t("sitemapHDescription"),
      isHealthScore: false,
      moduleCode: ModuleCode.Seo,
      subCodes: [
        "all_crawled_urls_in_xml_sitemaps",
        "missing_in_sitemaps",
        "broken_sitemap_links",
        "sitemaps_non_indexable_links",
        "sitemaps_disallowed_malformed_links",
        "sitemaps_broken_disallowed",
      ],
      graphCode: "all_crawled_urls_in_xml_sitemaps",
    },
    {
      id: "content",
      code: "content",
      name: t("contentName"),
      visible: true,
      description: t("contentDescription"),
      isHealthScore: false,
      moduleCode: ModuleCode.Seo,
      subCodes: [
        "missing_h1_tags",
        "duplicate_pages",
        "empty_pages",
        "missing_titles",
        "duplicate_titles_2",
        "missing_descriptions",
      ],
      graphCode: "missing_h1_tags",
    },
    {
      id: "performance",
      code: "performance",
      visible: true,
      name: t("performanceName"),
      description: t("performanceDescription"),
      isHealthScore: false,
      moduleCode: ModuleCode.Seo,
      subCodes: [
        "poor_cls",
        "slow_server_response",
        "slow_fcp",
        "slow_lcp",
        "slow_dcl",
        "slow_tti",
      ],
      graphCode: "poor_cls",
    },
    {
      id: "levela",
      code: "levela",
      name: t("levela"),
      description: t("levelaDescription"),
      isHealthScore: false,
      moduleCode: ModuleCode.Accessibility,
      subCodes: [
        "aria_a",
        "color_a",
        "forms_a",
        "keyboard_a",
        "language_a",
        "name_role_value_a",
        "parsing_a",
        "structure_a",
        "tables_a",
        "text_alternatives_a",
        "time_and_media_a",
      ],
      visible: true,
      graphCode: "wcag_a_issues",
    },
    {
      id: "levelaa",
      code: "levelaa",
      name: t("levelaa"),
      description: t("levelaaDescription"),
      isHealthScore: false,
      moduleCode: ModuleCode.Accessibility,
      subCodes: [
        "color_contrast_issues",
        "valid_lang_issues",
        "autocomplete_valid_issues",
        "avoid_inline_spacing_issues",
        "target_size_issues",
        "meta_viewport_issues",
      ],
      visible: true,
      graphCode: "wcag_aa_issues",
    },
    {
      id: "levelaaa",
      code: "levelaaa",
      name: t("levelaaa"),
      description: t("levelaaaDescription"),
      isHealthScore: false,
      moduleCode: ModuleCode.Accessibility,
      subCodes: [
        "color_contrast_enhanced_issues",
        "identical_links_same_purpose_issues",
      ],
      visible: true,
      graphCode: "wcag_aaa_issues",
    },
    {
      id: "best-practice",
      code: "bestPractice",
      name: t("bestPractice"),
      description: t("bestPracticeDescription"),
      isHealthScore: false,
      moduleCode: ModuleCode.Accessibility,
      subCodes: [
        "aria_bp",
        "keyboard_bp",
        "name_role_value_bp",
        "text_alternatives_bp",
        "semantics_bp",
        "sensory_and_visual_bp",
        "tables_bp",
      ],
      visible: true,
      graphCode: "best_practices_issues",
    },

    {
      id: "seo-health-score",
      code: "seoHealthScore",
      visible: true,
      name: t("healthScoreName"),
      description: t("healthScoreDesc"),
      isHealthScore: true,
      moduleCode: ModuleCode.Seo,
      subCodes: [
        "top",
        "availability",
        "indexability",
        "uniqueness",
        "discoverability",
        "rankability",
        "experience",
      ],
      graphCode: "top",
      default: true,
    },
    {
      id: "seo-indexability-score",
      code: "seoIndexability",
      visible: true,
      name: t("indexabilityScoreName"),
      description: t("indexabilityScoreDesc"),
      isHealthScore: true,
      moduleCode: ModuleCode.Seo,
      subCodes: ["non_indexable", "canonicalization", "mobile_indexability"],
      graphCode: "indexability",
    },
    {
      id: "seo-discoverability-score",
      code: "seoDiscoverability",
      visible: true,
      name: t("discoverabilityScoreName"),
      description: t("discoverabilityScoreDesc"),
      isHealthScore: true,
      moduleCode: ModuleCode.Seo,
      subCodes: [
        "crawl_budget",
        "internal_links",
        "redirections",
        "sitemaps",
        "internationalization",
      ],
      graphCode: "discoverability",
    },
    {
      id: "seo-rankability-score",
      code: "seoRankability",
      visible: true,
      name: t("rankabilityScoreName"),
      description: t("rankabilityScoreDesc"),
      isHealthScore: true,
      moduleCode: ModuleCode.Seo,
      subCodes: [
        "search_console",
        "authority",
        "page_content",
        "structured_data",
        "social_tags",
      ],
      graphCode: "rankability",
    },
    {
      id: "seo-experience-score",
      code: "seoExperience",
      visible: true,
      name: t("experienceScoreName"),
      description: t("experienceScoreDesc"),
      isHealthScore: true,
      moduleCode: ModuleCode.Seo,
      subCodes: [
        "page_speed",
        "engagement",
        "user_experience",
        "security",
        "external_links",
      ],
      graphCode: "experience",
    },

    {
      id: "a11y-health-score",
      code: "a11yHealthScore",
      visible: true,
      name: t("a11yHealthScoreName"),
      description: t("a11yHealthScoreDesc"),
      isHealthScore: true,
      moduleCode: ModuleCode.Accessibility,
      subCodes: [
        "accessibility",
        "accessibility_a",
        "accessibility_aa",
        "accessibility_aaa",
        "accessibility_bp",
      ],
      graphCode: "accessibility",
      default: true,
    },

    {
      id: "a11y-level-a-score",
      code: "levelAScore",
      visible: true,
      name: t("a11yLevelAScoreName"),
      description: t("a11yLevelAScoreDesc"),
      isHealthScore: true,
      moduleCode: ModuleCode.Accessibility,
      subCodes: [
        "accessibility_aria_a",
        "accessibility_color_a",
        "accessibility_forms_a",
        "accessibility_keyboard_a",
        "accessibility_language_a",
        "accessibility_name_role_value_a",
        "accessibility_parsing_a",
        "accessibility_structure_a",
        "accessibility_tables_a",
        "accessibility_text_alternatives_a",
        "accessibility_time_and_media_a",
      ],
      graphCode: "accessibility_a",
    },
    {
      id: "a11y-level-aa-score",
      code: "levelAAScore",
      visible: true,
      name: t("a11yLevelAAScoreName"),
      description: t("a11yLevelAAScoreDesc"),
      isHealthScore: true,
      moduleCode: ModuleCode.Accessibility,
      subCodes: [
        "accessibility_color_aa",
        "accessibility_language_aa",
        "accessibility_forms_aa",
        "accessibility_structure_aa",
        "accessibility_sensory_and_visual_aa",
      ],
      graphCode: "accessibility_aa",
    },
    {
      id: "a11y-level-aaa-score",
      code: "levelAAAScore",
      visible: true,
      name: t("a11yLevelAAAScoreName"),
      description: t("a11yLevelAAAScoreDesc"),
      isHealthScore: true,
      moduleCode: ModuleCode.Accessibility,
      subCodes: ["accessibility_color_aaa", "accessibility_semantics_aaa"],
      graphCode: "accessibility_aaa",
    },
    {
      id: "a11y-bp-score",
      code: "bestPScore",
      visible: true,
      name: t("a11yBestPScoreName"),
      description: t("a11yBestPScoreDesc"),
      isHealthScore: true,
      moduleCode: ModuleCode.Accessibility,
      subCodes: [
        "accessibility_aria_bp",
        "accessibility_keyboard_bp",
        "accessibility_name_role_value_bp",
        "accessibility_text_alternatives_bp",
        "accessibility_semantics_bp",
        "accessibility_forms_bp",
        "accessibility_sensory_and_visual_bp",
        "accessibility_tables_bp",
      ],
      graphCode: "accessibility_bp",
    },

    {
      id: "default-seo-dashboard",
      code: "seoDefaultDashboard",
      visible: false,
      name: t("default_dashboard_name"),
      description: "",
      isHealthScore: false,
      moduleCode: ModuleCode.Seo,
      subCodes: [
        "indexable_pages",
        "unique_pages",
        "duplicate_pages",
        "pages_without_canonical_tag",
        "all_redirects",
        "all_broken_links",
      ],
      graphCode: "indexable_pages",
      default: true,
    },
    {
      id: "default-a11y-dashboard",
      code: "a11yDefaultDashboard",
      visible: false,
      name: t("default_dashboard_name"),
      description: "",
      isHealthScore: false,
      moduleCode: ModuleCode.Accessibility,
      subCodes: [
        "critical_issues",
        "serious_issues",
        "moderate_issues",
        "minor_issues",
      ],
      graphCode: "accessibility_issues",
      default: true,
    },
    {
      id: "default-sitespeed-dashboard",
      code: "sitespeedDefaultDashboard",
      visible: false,
      name: t("default_dashboard_name"),
      description: "",
      isHealthScore: false,
      moduleCode: ModuleCode.SiteSpeed,
      subCodes: [
        "first_contentful_paint_slow",
        "largest_contentful_paint_slow",
        "total_blocking_time_slow",
        "cumulative_layout_shift_slow",
        "speed_index_slow",
      ],
      graphCode: "speed_index_slow",
      default: true,
    },
    {
      id: "slow_sitespeed_pages",
      code: "slowSiteSpeedPages",
      visible: true,
      name: t("slowSiteSpeedPages"),
      description: t("slowSiteSpeedPagesDesc"),
      isHealthScore: false,
      moduleCode: ModuleCode.SiteSpeed,
      subCodes: [
        "first_contentful_paint_slow",
        "largest_contentful_paint_slow",
        "total_blocking_time_slow",
        "cumulative_layout_shift_slow",
        "speed_index_slow",
      ],
      graphCode: "speed_index_slow",
      default: false,
    },
    {
      id: "moderate_sitespeed_pages",
      code: "moderateSiteSpeedPages",
      visible: true,
      name: t("moderateSiteSpeedPages"),
      description: t("moderateSiteSpeedPagesDesc"),
      isHealthScore: false,
      moduleCode: ModuleCode.SiteSpeed,
      subCodes: [
        "first_contentful_paint_moderate",
        "largest_contentful_paint_moderate",
        "total_blocking_time_moderate",
        "cumulative_layout_shift_moderate",
        "speed_index_moderate",
      ],
      graphCode: "speed_index_moderate",
      default: false,
    },
    {
      id: "fast_sitespeed_pages",
      code: "fastSiteSpeedPages",
      visible: true,
      name: t("fastSiteSpeedPages"),
      description: t("fastSiteSpeedPagesDesc"),
      isHealthScore: false,
      moduleCode: ModuleCode.SiteSpeed,
      subCodes: [
        "first_contentful_paint_fast",
        "largest_contentful_paint_fast",
        "total_blocking_time_fast",
        "cumulative_layout_shift_fast",
        "speed_index_fast",
      ],
      graphCode: "speed_index_fast",
      default: false,
    },
    {
      id: "sitespeed_scores",
      code: "siteSpeedScores",
      visible: true,
      name: t("siteSpeedScores"),
      description: t("siteSpeedScoresDesc"),
      isHealthScore: true,
      moduleCode: ModuleCode.SiteSpeed,
      subCodes: [
        "site_speed",
        "first_contentful_paint",
        "largest_contentful_paint",
        "total_blocking_time",
        "cumulative_layout_shift",
        "speed_index",
      ],
      graphCode: "site_speed",
      default: true,
    },
  ];

  function search(element: Partial<Template>): Template[] {
    return templates.filter((e) => isMatch(e, element));
  }

  return { templates, searchFunction: search };
}
