import { ReactElement } from "react";

type WithReactElementKeys = Record<keyof ReactElement, unknown>;

function hasReactElementKeys(value: unknown): value is WithReactElementKeys {
  return (
    typeof value === "object" &&
    value !== null &&
    Boolean(value.hasOwnProperty("props")) &&
    Boolean(value.hasOwnProperty("type"))
  );
}

export function isReactElementArray(
  value: unknown,
): value is Array<ReactElement> {
  return (
    Array.isArray(value) &&
    value.every((element) => hasReactElementKeys(element))
  );
}

export function isReactElement(value: unknown): value is ReactElement {
  return (
    typeof value === "object" && value !== null && hasReactElementKeys(value)
  );
}
