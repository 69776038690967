import React from "react";
import { useLocation } from "react-router-dom";

export function useURLSearchParams(): URLSearchParams {
  const location = useLocation();
  return React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
}
