import { Checkbox, useTranslation } from "@lumar/shared";
import { Divider, ListItem, makeStyles, useTheme } from "@material-ui/core";
import { useInView } from "react-intersection-observer";
import { RenderView } from "../components/RenderView";
import { GetViewListFilter } from "../board/utils/filterViewList";
import { View } from "../types";

const LIST_ITEM_HEIGHT = 51;

interface Props {
  view: View;
  searchTerms?: GetViewListFilter;
  onChange?: (view: View) => void;
  checked?: boolean;
  testId: string;
  type: "select" | "deselect";
  index: number;
}

export function DashboardSettingsListItem({
  view,
  searchTerms,
  onChange,
  checked,
  testId,
  index,
  type,
}: Props): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation("common");
  const { ref, inView } = useInView();

  return (
    <ListItem
      alignItems="flex-start"
      data-testid={`${testId}-list-item-${index}`}
      disableGutters
      style={{ paddingRight: theme.spacing(1.5) }}
      className={classes.listItem}
      ref={ref}
    >
      {inView ? (
        <>
          <Divider className={classes.dividers} />
          <div className={classes.item}>
            <Checkbox
              onChange={() => onChange?.(view)}
              className={classes.checkbox}
              checked={checked}
              data-pendo="dashboard-settings-list-item-checkbox"
              data-testid={`${testId}-list-item-checkbox`}
              inputProps={{
                "aria-label":
                  type === "select" ? t("selectView") : t("deselectView"),
              }}
            />
            <RenderView
              view={view}
              searchTerms={searchTerms}
              classes={{ root: classes.flex }}
            />
          </div>
          <Divider className={classes.dividers} />
        </>
      ) : undefined}
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: LIST_ITEM_HEIGHT,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  dividers: {
    backgroundColor: theme.palette.grey[200],
  },
  item: {
    flex: 1,
    display: "flex",
    flexFlow: "row",
    width: "100%",
    alignItems: "center",
  },
  flex: {
    flex: 1,
  },
  checkbox: {
    alignSelf: "center",
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    width: 24,
    height: 24,
    "& .MuiIconButton-label span": {
      "&:before": {
        width: 24,
        height: 24,
      },
      width: 24,
      height: 24,
    },
  },
}));
