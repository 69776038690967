import { getRawAccountId, getRawProjectId, getRawCrawlId } from "@lumar/shared";
import { ReactNode } from "react";
import { AbsoluteURLs } from "../../../_common/routing/absoluteURLs";

export function AnalyticsHubHealthScoreLink(props: {
  accountId: string;
  projectId: string;
  crawlId: string;
  reportCategoryCode: string;
  className?: string;
  children: ReactNode;
  "data-testid"?: string;
  "data-pendo": string;
  segmentId?: string;
}): JSX.Element {
  const {
    reportCategoryCode,
    children,
    "data-testid": dataTestId,
    "data-pendo": dataPendo,
    segmentId,
  } = props;

  const allIdsOk =
    Boolean(props.accountId.length) &&
    Boolean(props.projectId.length) &&
    Boolean(props.crawlId.length);
  if (!allIdsOk) return <>{children}</>;

  const accountId = getRawAccountId(props.accountId);
  const projectId = getRawProjectId(props.projectId);
  const crawlId = getRawCrawlId(props.crawlId);

  return (
    <a
      className={props.className}
      href={AbsoluteURLs.EXTERNAL__AnalyzeHealthScore.getUrl({
        accountId,
        projectId,
        crawlId,
        reportCategoryCode: reportCategoryCode,
        segmentId,
      })}
      data-testid={dataTestId}
      data-pendo={dataPendo}
    >
      {children}
    </a>
  );
}
