import { useParams } from "react-router-dom";
import {
  ModuleCode,
  useFilteredProjectListWithSelectionQuery,
} from "../../../graphql";
import { ProjectOption } from "../../utils/constants";
import { differenceBy } from "lodash";
import { getApiProjectId } from "@lumar/shared";
import { isEmpty } from "lodash";

export function useLoadProjectList(
  selected: ProjectOption[],
  filter: string | undefined | null,
): { data: ProjectOption[]; loading: boolean } {
  const { accountId } = useParams<{ accountId: string }>();

  const { data, loading } = useFilteredProjectListWithSelectionQuery({
    variables: {
      accountId: accountId,
      filterTerm: filter || null,
      ids: selected.map((e) => getApiProjectId(e.id)),
      includeSelected: Boolean(selected.length),
    },
  });

  function sortOptionsBySelectedStatus(
    a: ProjectOption,
    b: ProjectOption,
  ): number {
    const isASelected = Boolean(
      selected.find((o) => getApiProjectId(o.id) === getApiProjectId(a.id)),
    );
    const isBSelected = Boolean(
      selected.find((o) => getApiProjectId(o.id) === getApiProjectId(b.id)),
    );
    if (isASelected === isBSelected) return 0;
    return isASelected ? -1 : 1;
  }

  const selectedData = (data?.nodes ?? [])
    .filter(
      (
        e,
      ): e is {
        id: string;
        name: string;
        primaryDomain: string;
        moduleCode: ModuleCode;
      } => !isEmpty(e),
    )
    .map((e) => {
      return { ...e, id: getApiProjectId(e.id) };
    });

  const diff = differenceBy(
    selectedData,
    data?.getAccount?.projects?.edges.map((e) => e.node) ?? [],
    "id",
  );

  // eslint-disable-next-line fp/no-mutating-methods
  const currentList = [
    ...(data?.getAccount?.projects.edges.map((e) => e.node) ?? []),
    ...((data?.getAccount?.projects?.totalCount ?? 0) >= 100 ? diff : []),
  ].sort(sortOptionsBySelectedStatus);

  return {
    data: loading ? [] : currentList,
    loading,
  };
}
