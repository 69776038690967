import {
  Typography,
  Button,
  useApolloClient,
  getApiAccountId,
  useTranslation,
} from "@lumar/shared";
import { Box, makeStyles, useTheme } from "@material-ui/core";
import { ProjectsCombobox } from "../../_common/components/ProjectsCombobox";
import { useParams } from "react-router-dom";
import { Severity } from "../../graphql";
import { ReportsCombobox } from "../../_common/components/reports/ReportsCombobox";
import { SeverityFilter } from "../filters/SeverityFilter";
import {
  ProjectOption,
  ReportCategoryOption,
  ReportOption,
} from "../../_common/utils/constants";
import { ReportCategoriesCombobox } from "../../_common/components/reports/ReportCategoriesCombobox";
import { useReportTemplateAccumulator } from "../../_common/hooks/useReportTemplateAccumulator";
import { useReportCategoriesTree } from "../../_common/hooks/useReportCategoriesTree";

const MAX_NUMBER_OF_PROJECTS = 10;
const MAX_NUMBER_OF_REPORTS = 10;

const useStyles = makeStyles((theme) => ({
  category: {},
  container: {
    gap: theme.spacing(1),
    whiteSpace: "nowrap",
    flex: 1,
  },
  button: {
    background: "transparent",
    border: 0,
    minWidth: 112,
    boxShadow: "none",
    color: theme.palette.primary.main,
    "&:hover": {
      background: "transparent!important",
    },
  },
}));

export interface NotificationsFilterProp {
  disabled?: boolean;
  projects: ProjectOption[];
  reports?: ReportOption[];
  categories?: ReportCategoryOption[];
  severity: Severity | null;
  onProjectsChange: (values: ProjectOption[]) => void;
  onReportsChange?: (values: ReportOption[]) => void;
  onCategoryChange?: (values: ReportOption[]) => void;
  onSeverityChange: (values: Severity | null) => void;
  showSeverityFilter: boolean;
  showCategoriesFilter?: boolean;
  hasFiltersApplied: boolean;
  onFilterChange: () => void;
  onClearFilters: () => void;
}

export function NotificationsFilters({
  disabled,
  projects,
  reports = [],
  categories = [],
  severity,
  onProjectsChange,
  onReportsChange,
  onCategoryChange,
  onSeverityChange,
  showSeverityFilter,
  showCategoriesFilter = false,
  hasFiltersApplied,
  onFilterChange,
  onClearFilters,
}: NotificationsFilterProp): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const theme = useTheme();
  const { t } = useTranslation(["notifications"]);

  const classes = useStyles();

  const apollo = useApolloClient();

  const { reports: reportTemplates, loading: loadingReports } =
    useReportTemplateAccumulator({
      options: showCategoriesFilter ? undefined : [],
    });

  const [{ loading: loadingCategories }, { getCategoryList }] =
    useReportCategoriesTree(reportTemplates, true);

  const options = getCategoryList(2).map((e) => ({
    id: e.code,
    code: e.code,
    name: e.name,
    description: e.description,
    level: e.level,
  }));

  // Because apollo is managing the monitorNotifications cache for pagination, on filter/tab change we have to force clear the cache
  // This is neccessary only when status change happened on notifications (so maybe optimization is possible)
  function clearCache(): void {
    apollo.cache.modify({
      id: apollo.cache.identify({
        __typename: "Account",
        id: getApiAccountId(accountId),
      }),
      fields: {
        monitorNotifications: (_, details) => details.DELETE,
        monitorHealthScoreNotifications: (_, details) => details.DELETE,
      },
    });
  }

  const handleFilterChange = (): void => {
    clearCache();
    onFilterChange();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      className={classes.container}
    >
      {hasFiltersApplied ? (
        <Button
          variant="text"
          onClick={() => {
            handleFilterChange();
            onClearFilters();
          }}
          className={classes.button}
          data-pendo={`notifications-page-filter-reset-button`}
          data-testid="clear-notification-filters"
        >
          {t("notifications:clearfilters")}
        </Button>
      ) : (
        <Typography style={{ marginRight: theme.spacing(1) }}>
          {t("filterby")}
        </Typography>
      )}

      <ProjectsCombobox
        value={projects}
        onChange={(p) => {
          handleFilterChange();
          onProjectsChange(p);
        }}
        disabled={disabled}
        maxSelection={MAX_NUMBER_OF_PROJECTS}
        noSelectionText={t("notifications:allprojects")}
        data-pendo={`notifications-page-projects-filter-select`}
        maxWidth={225}
      />
      {!showCategoriesFilter ? (
        <ReportsCombobox
          onChange={(r) => {
            handleFilterChange();
            onReportsChange?.(r as ReportOption[]);
          }}
          data-pendo={`notifications-page-reports-filter-select`}
          data-testid="notifications-page-reports-combobox"
          value={reports}
          disabled={disabled}
          multiple
          maxSelection={MAX_NUMBER_OF_REPORTS}
          maxWidth={225}
          projectId={projects.length === 1 ? projects[0].id : undefined}
        />
      ) : (
        <ReportCategoriesCombobox
          value={categories}
          disabled={disabled}
          className={classes.category}
          multiple
          maxSelection={MAX_NUMBER_OF_REPORTS}
          maxWidth={240}
          options={options}
          onChange={(r) => {
            handleFilterChange();
            onCategoryChange?.(r as ReportCategoryOption[]);
          }}
          loading={loadingReports || loadingCategories}
        />
      )}
      {showSeverityFilter ? (
        <SeverityFilter
          value={severity}
          onChange={(s) => {
            handleFilterChange();
            onSeverityChange(s);
          }}
          disabled={disabled}
        />
      ) : null}
    </Box>
  );
}
