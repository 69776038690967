import { FormikErrors } from "formik";
import { RuleAndThreshold } from "./types";
import { ReportOption } from "../../../_common/utils/constants";

export function getValidationError(
  errors:
    | string
    | string[]
    | FormikErrors<RuleAndThreshold<ReportOption>>[]
    | undefined,
  index: number,
): FormikErrors<RuleAndThreshold<ReportOption>> | string | undefined {
  if (!errors) return undefined;

  if (typeof errors === "string") return errors;

  const reportError = errors[index];

  return reportError;
}
