import { useTranslation } from "@lumar/shared";

import {
  CustomDashboardType,
  ModuleCode,
  useCloneDashboardMutation,
} from "../../../graphql";
import { useCreateDashboardFromTemplate } from "../../components/create-from-template/utils/useCreateDashboardFromTemplate";
import { useGetAccountModuleCodes } from "../../../_common/hooks/useGetAccountModuleCodes";
import { useTemplates } from "../../components/create-from-template/utils/templates";

export const DEFAULT_REPORT_TEMPLATE_CODES = [
  "indexable_pages",
  "unique_pages",
  "duplicate_pages",
  "pages_without_canonical_tag",
  "all_redirects",
  "all_broken_links",
];

export const DEFAULT_REPORT_TEMPLATE_CODE = "indexable_pages";
export const DEFAULT_REPORT_TEMPLATE_CATEGORY_CODE = "top";

export function useCreateDefaultOrCloneDashboard({
  collectionId,
  refetchQueries,
}: {
  accountId: string;
  collectionId: string;
  refetchQueries?: string[];
}): ({
  name,
  dashboardId,
  type,
  views,
}: {
  name?: string;
  dashboardId?: string;
  type?: CustomDashboardType;
  views: string[];
}) => Promise<string> {
  const { t } = useTranslation(["dashboards"]);

  const moduleCodes = useGetAccountModuleCodes();
  const { searchFunction: serchForTemplate } = useTemplates();

  const createDashboardFromTemplate =
    useCreateDashboardFromTemplate(refetchQueries);

  const [cloneDashboard] = useCloneDashboardMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const moduleCode = moduleCodes[0] ?? ModuleCode.Seo;

  async function createDefaultOrCloneDashboard({
    name,
    dashboardId,
    type,
    views,
  }: {
    name?: string;
    dashboardId?: string;
    type?: CustomDashboardType;
    views: string[];
  }): Promise<string> {
    if (Boolean(dashboardId)) {
      const { data: dashboardData, errors: dashboardErrors } =
        await cloneDashboard({
          variables: {
            collectionId,
            dashboardId,
            name: name ?? "Sub-dashboard",
          },
        });
      if (dashboardErrors?.length) return Promise.reject(dashboardErrors);
      return dashboardData?.copyCustomDashboard.customDashboard.id;
    }

    const realType = type ?? CustomDashboardType.Monitor;
    const code = serchForTemplate({
      moduleCode: moduleCode,
      default: true,
      isHealthScore: !(realType === CustomDashboardType.Monitor),
    })?.[0]?.code;

    if (code) {
      const viewsData = views.slice(0, 5);
      const ret = await createDashboardFromTemplate(
        collectionId,
        code,
        name ?? t("dashboards:default_dashboard_name"),
        viewsData,
      );
      if (ret?.error) return Promise.reject(ret?.error);
      return ret?.createdDashboardIds[0];
    }
    return Promise.reject({
      message: "No template found for the activated addon!",
    });
  }

  return createDefaultOrCloneDashboard;
}
