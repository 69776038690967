import React from "react";
import { ChooseRulesAndThresholdsView } from "./components/ChooseRulesAndThresholdsView";
import { ChooseProjectView } from "./components/ChooseProjectView";
import { useTranslation } from "@lumar/shared";
import { AlertOption } from "../../_common/utils/constants";
import { RedirectIfInsufficientRole } from "../../_common/components/RedirectIfInsufficientRole";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";
import { useParams } from "react-router-dom";

export function CreateNewAlert(): JSX.Element {
  const { t } = useTranslation("alerts");
  const [selectedOption, setSelectedOption] =
    React.useState<AlertOption | null>(null);
  const { dashboardPage } = useMonitorRoutes();
  const { alertsPage } = useMonitorRoutes();
  const { accountId } = useParams<{ accountId: string }>();

  function handleAutocompleteChange(newValue: AlertOption | null): void {
    if (newValue?.testsTotalCount || newValue?.healthScoreTestsTotalCount)
      alertsPage.visit({ accountId, alertId: newValue.id });
    setSelectedOption(newValue);
  }

  return (
    <RedirectIfInsufficientRole to={dashboardPage.getUrl()}>
      <div
        style={{
          backgroundColor: "white",
          minHeight: "100vh",
        }}
      >
        {!selectedOption ? (
          <ChooseProjectView
            selectedProject={selectedOption}
            onProjectChange={handleAutocompleteChange}
          />
        ) : (
          <ChooseRulesAndThresholdsView
            selectedProject={selectedOption}
            title={t("alerts:createNewAlert")}
            enableReinitialize={false}
          />
        )}
      </div>
    </RedirectIfInsufficientRole>
  );
}
