import { Button, useTheme } from "@material-ui/core";
import {
  MonitorSortItem,
  TableColumnSortOrder,
} from "../../helpers/MonitorSortItem";
import clsx from "clsx";
import { ProjectNameColumnHeaderText } from "./ProjectNameColumnHeaderText";
import { ProjectNameColumnMenu } from "./ProjectNameColumnMenu";
import { ProjectNameColumnCell } from "./ProjectNameColumnCell";
import { InView } from "react-intersection-observer";
import { useState } from "react";
import { ArrowDown, ArrowUp, useTranslation } from "@lumar/shared";
import { OrderDirection } from "../../../../graphql";
import { useSortOptions } from "./utils/useSortOptions";

export interface GridOverlayColumnProps {
  onColumnSort: (sortType: MonitorSortItem) => void;
  sorting: MonitorSortItem;
  items: {
    name: string;
    segmentName?: string;
    primaryDomain: string;
    projectId: string;
    segmentId?: string;
  }[];
  width: number;
  loading?: boolean;
  className?: string;
  components?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    LoadingOverlay?: React.JSXElementConstructor<any>;
  };
  componentsProps?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadingOverlay?: any;
  };
  headerHeight: number;
  rowHeight: number;
  disableMenu?: boolean;
  resizeHeight?: number;
}

export function GridOverlayColumn({
  items,
  onColumnSort,
  width,
  sorting,
  loading,
  className,
  components,
  componentsProps,
  headerHeight,
  rowHeight,
  disableMenu,
  resizeHeight,
}: GridOverlayColumnProps): JSX.Element {
  const theme = useTheme();
  const LoadingOverlay = components?.LoadingOverlay;
  const [overflow, setOverflow] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const { t } = useTranslation(["table"]);

  if (loading && overflow) setOverflow(false);

  const sortOptions = useSortOptions();

  const isSortingAsc =
    sorting.type === TableColumnSortOrder.byOldestCrawl ||
    sorting.type === TableColumnSortOrder.byProjectNameAToZ ||
    sorting.type === TableColumnSortOrder.bySegmentNameAToZ;
  const isSortingDesc =
    sorting.type === TableColumnSortOrder.byMostRecentCrawl ||
    sorting.type === TableColumnSortOrder.byProjectNameZToA ||
    sorting.type === TableColumnSortOrder.bySegmentNameZToA;
  const isSorting = (isSortingAsc || isSortingDesc) && !disableMenu;

  const sortingType = sortOptions.find((e) => e.type === sorting.type);

  return (
    <div
      className={clsx("MuiDataGrid-root", className)}
      style={{
        width: width,
        position: "absolute",
        left: 0,
        zIndex: 2,
        borderRadius: 0,
        minHeight: 0,
        height: resizeHeight ? resizeHeight : "unset",
        overflow: overflow ? "visible" : "hidden",
        border: 0,
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          border: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <div
          className="MuiDataGrid-columnHeaderWrapper"
          style={{
            position: "sticky",
            top: 0,
            pointerEvents: "all",
          }}
        >
          <div
            className="MuiDataGrid-columnHeader"
            style={{
              height: headerHeight,
              backgroundColor: "white",
              display: "flex",
              padding: theme.spacing(0, 1, 0, 0.2),
              width: "100%",
              flex: 1,
              border: 0,
              borderBottom: "1px solid #f0f0f0",
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div className="MuiDataGrid-columnHeaderTitleContainer">
              <ProjectNameColumnHeaderText data-testid={`column-header-0`} />
              {!disableMenu && hover ? (
                <ProjectNameColumnMenu
                  onMenuItemClick={onColumnSort}
                  selected={sorting.type}
                  onClose={() => setHover(false)}
                />
              ) : undefined}
            </div>
            {isSorting && (
              <Button
                data-testid="title-column-sort-button"
                data-pendo="title-column-sort-button"
                variant="text"
                style={{
                  maxWidth: 10,
                  minWidth: 10,
                  boxShadow: "none",
                }}
                aria-label={t("nameHeaderOrderButton", {
                  value: sortingType?.title,
                })}
                onClick={() => {
                  onColumnSort?.({
                    ...sorting,
                    sort:
                      sorting.sort === OrderDirection.Asc
                        ? OrderDirection.Desc
                        : OrderDirection.Asc,
                  });
                }}
              >
                {isSortingAsc && (
                  <ArrowUp
                    style={{
                      fontSize: theme.typography.pxToRem(14),
                    }}
                  />
                )}
                {isSortingDesc && (
                  <ArrowDown
                    style={{
                      fontSize: theme.typography.pxToRem(14),
                    }}
                  />
                )}
              </Button>
            )}
          </div>
        </div>
        {loading && LoadingOverlay ? (
          <LoadingOverlay {...componentsProps?.loadingOverlay} />
        ) : (
          <>
            {items.map(
              ({ name, projectId, primaryDomain, segmentName, segmentId }) => {
                return (
                  <div
                    className="MuiDataGrid-cell MuiDataGrid-cell--withRenderer MuiDataGrid-cell--textLeft"
                    key={`${projectId}-cell-${segmentId}`}
                    style={{
                      height: rowHeight,
                      backgroundColor: "white",
                      pointerEvents: "all",
                    }}
                  >
                    <ProjectNameColumnCell
                      url={primaryDomain}
                      projectName={name}
                      segmentName={segmentName}
                    />
                  </div>
                );
              },
            )}
            <InView
              onChange={(inView) => {
                if (!inView) setOverflow(true);
              }}
            >
              <div />
            </InView>
          </>
        )}
      </div>
    </div>
  );
}
