import { useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { NotificationsList } from "./list/NotificationsList";
import { ErrorBoundary } from "@rollbar/react";
import { SimpleFallbackError } from "../_common/components/SimpleFallbackError";
import {
  TestAutoThresholdAcceptance,
  useHasAlertsQuery,
  useHealthScoreNotificationTotalCountsQuery,
  useHealthScoreNotificationsRequiresManualApprovalCountQuery,
} from "../graphql";
import {
  NotificationStatusFilter,
  notificationStatusFilters,
} from "./filters/constants";
import { StatusTabs } from "./filters/StatusTabs";
import { useNotificationFilters } from "./utils/useNotificationFilters";
import { useParams } from "react-router-dom";
import {
  emptyNotificationSelection,
  NotificationSelectionType,
} from "./utils/constants";
import { MonitorNotification, NotificationSelection } from "./types";
import { NotificationsFilters } from "./components/NotificationFilters";
import { intersectionWith } from "lodash";
import {
  SuggestedThresholdFilter,
  SuggestedThresholdTabs,
} from "./filters/SuggestedThresholdTabs";
import { StickyContainer } from "./StickyContainer";
import { useMostRecentCrawlIds } from "./utils/useMostRecentCrawlIds";
import { getFilterFromNotificationStatusFilter } from "./utils/getFilterFromNotificationStatusFilter";
import { getHealthScoreNotificationList } from "./utils/utils";
import { useHealthScoreNotificationsQuery } from "./utils/useHealthScoreNotificationsQuery";
import { EditHealthScoreRuleAndThresholdDialog } from "./_common/EditHealthScoreRuleAndThresholdDialog";
import { useHandleHealthScoreNotificationChanges } from "./utils/useHandleHealthScoreNotificationChanges";
import { HealthScoreNotificationStatusUpdate } from "./components/HealthScoreNotificationStatusUpdate";
import { useClearHealthScoreNotificationCache } from "./utils/useClearNotificationCache";
import { getRawCrawlId, getRawProjectId, useTranslation } from "@lumar/shared";
import clsx from "clsx";
import { AbsoluteURLs } from "../_common/routing/absoluteURLs";

const useStyles = makeStyles((theme) => ({
  multiselect: {
    margin: theme.spacing(1.75, 0, 1, 1),
    height: 30,
  },
  multiselectContainer: {
    backgroundColor: "#F0F3F7",
  },
  filtersContainer: {
    borderBottom: `solid 1px ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.grey[100],
    paddingTop: theme.spacing(1),
    gap: theme.spacing(2),
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    whiteSpace: "nowrap",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
}));

function hasManualAcceptThresholdInSelection(
  selection: NotificationSelection,
): boolean {
  return selection.selected.some((e) => {
    const isFromMostRecentCrawl =
      e.stat?.crawlId === e.stat?.project.lastFinishedCrawl?.id;
    const isNotAltered =
      e.test?.code === e.base.code &&
      e.test?.thresholdType === e.thresholdType &&
      e.test?.thresholdPredicate === e.thresholdPredicate &&
      e.test?.absoluteThreshold === e.absoluteThreshold;

    return (
      e.automaticThresholdAcceptanceWhenTestResultIsWorse ===
        TestAutoThresholdAcceptance.Suggest &&
      typeof e.suggestedAbsoluteThreshold === "number" &&
      !Boolean(e.suggestedAbsoluteThresholdAcceptedAt) &&
      !Boolean(e.suggestedAbsoluteThresholdRejectedAt) &&
      isFromMostRecentCrawl &&
      isNotAltered
    );
  });
}

interface HealthScoreNotificationsTabProps {
  actions?: React.ReactNode;
  variant?: "simple" | "full";
  classes?: {
    loading?: string;
    container?: string;
    list?: string;
    emptyState?: string;
  };
}

export function HealthScoreNotificationsTab({
  variant = "full",
  actions,
  classes: externalClasses,
}: HealthScoreNotificationsTabProps): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const classes = useStyles();
  const { t } = useTranslation("notifications");
  const ClearCache = useClearHealthScoreNotificationCache();
  const {
    severity,
    projects,
    categories,
    setSeverity,
    setProjects,
    setCategories,
    resetFilters,
  } = useNotificationFilters();

  const [suggestedThresholdFilter, setSuggestedThresholdFilter] =
    useState<SuggestedThresholdFilter>("all");

  const [notificationStatus, setNotificationStatus] = useState(
    notificationStatusFilters[0],
  );

  const [editedRuleNotification, setEditedRuleNotification] =
    useState<MonitorNotification | null>(null);

  const [notificationSelection, setNotificationSelection] =
    useState<NotificationSelection>(emptyNotificationSelection);

  const { data: crawlIds, loading: areCrawlIdsLoading } =
    useMostRecentCrawlIds();

  const { data: tabsData, loading: queryLoading } =
    useHealthScoreNotificationTotalCountsQuery({
      variables: { accountId, crawlIds: crawlIds },
    });

  const isSimple = variant === "simple";

  const {
    data,
    error,
    loading,
    reloading,
    hasFetchedAllData,
    handleLoadMore,
    queryVariables,
  } = useHealthScoreNotificationsQuery({
    severity:
      notificationStatus === NotificationStatusFilter.Adjustments
        ? undefined
        : severity,
    notificationStatus,
    suggestedThresholdFilter,
    projectIds: projects.length ? projects.map((p) => p.id) : null,
    reportCategoryCodes: categories.length
      ? categories.map((r) => r.code)
      : null,
    count: Math.ceil(notificationSelection.selected.length / 10) * 10,
    crawlIds,
    onCompleted: (data) => {
      if (data)
        setNotificationSelection({
          state: notificationSelection.state,
          selected: intersectionWith(
            notificationSelection.selected,
            data?.getAccount?.notifications?.edges.map((e) => e.node) ?? [],
            (a, b) => a.id === b.id,
          ),
        });
    },
  });

  const { data: alertCount, loading: loadingAlertCount } = useHasAlertsQuery({
    variables: { accountId: accountId },
  });

  const hasAlerts =
    !loadingAlertCount &&
    (alertCount?.getAccount?.projects?.totalCount !== 0 ||
      (alertCount?.getAccount?.reportNotification.totalCount ?? 0) +
        (alertCount?.getAccount?.healthScoreNotification.totalCount ?? 0) !==
        0);

  const hasFiltersApplied = Boolean(
    severity || projects.length || categories.length,
  );

  const isAdjustmentsTabSelected =
    notificationStatus === NotificationStatusFilter.Adjustments;

  const handleNotificationStatusChange = (
    newValue: NotificationStatusFilter,
  ): void => {
    setNotificationStatus(newValue);
    setNotificationSelection(emptyNotificationSelection);
    ClearCache();
  };

  const notifications = useMemo<MonitorNotification[] | undefined>(
    () => getHealthScoreNotificationList(data, t),
    [data, t],
  );

  const { data: manualApprovalCount } =
    useHealthScoreNotificationsRequiresManualApprovalCountQuery({
      variables: {
        accountId,
        projectIds: projects.length ? projects.map((p) => p.id) : undefined,
        reportCategoryCodes: categories.length
          ? categories.map((r) => r.code)
          : undefined,
        severity: severity ?? undefined,
        statusFilter: getFilterFromNotificationStatusFilter(notificationStatus),
        crawlIdFilter: { in: crawlIds },
      },
    });

  const {
    handleAcceptSuggestedThreshold,
    handleDeclineSuggestedThreshold,
    handleStatusUpdate,
  } = useHandleHealthScoreNotificationChanges(queryVariables);

  const showUpdateThresholdMenu =
    isAdjustmentsTabSelected ||
    (notificationSelection.state === NotificationSelectionType.SelectAll
      ? Boolean(
          manualApprovalCount?.getAccount?.healthScoreNotifications.totalCount,
        )
      : hasManualAcceptThresholdInSelection(notificationSelection));
  return (
    <>
      <StickyContainer>
        <div
          className={clsx(classes.filtersContainer, externalClasses?.container)}
        >
          <StatusTabs
            value={notificationStatus}
            onChange={handleNotificationStatusChange}
            totalCounts={{
              adjustmentNotificationsTotalCount:
                tabsData?.getAccount?.adjustmentNotifications.totalCount ?? 0,
              unreadNotificationsTotalCount:
                tabsData?.getAccount?.unreadNotifications.totalCount ?? 0,
              workingOnItNotificationsTotalCount:
                tabsData?.getAccount?.workingOnItNotifications.totalCount ?? 0,
            }}
            loading={queryLoading}
          />
          {!isSimple && (
            <NotificationsFilters
              showCategoriesFilter={true}
              hasFiltersApplied={hasFiltersApplied}
              onClearFilters={resetFilters}
              onFilterChange={() => {
                setNotificationSelection(emptyNotificationSelection);
              }}
              showSeverityFilter={!isAdjustmentsTabSelected}
              disabled={
                data?.getAccount?.notifications?.totalCount === 0 &&
                !hasFiltersApplied
              }
              projects={projects}
              onProjectsChange={(p) => {
                setProjects(p);
              }}
              categories={categories}
              onCategoryChange={(r) => {
                setCategories(r);
              }}
              severity={severity}
              onSeverityChange={(s) => {
                setSeverity(s);
              }}
            />
          )}
          {actions}
        </div>
        {!isSimple && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px 20px 8px 20px",
            }}
            className={classes.multiselectContainer}
            data-testid="bulk-update-notifications"
          >
            {(data?.getAccount?.notifications?.totalCount ?? 0) ===
            0 ? undefined : (
              <HealthScoreNotificationStatusUpdate
                setSelection={(s) => setNotificationSelection(s)}
                notifications={notifications}
                projects={projects}
                categories={categories}
                severity={severity}
                selection={notificationSelection}
                totalCount={data?.getAccount?.notifications?.totalCount ?? 0}
                loading={loading}
                hasFiltersApplied={hasFiltersApplied}
                status={notificationStatus}
                suggestedThresholdFilter={suggestedThresholdFilter}
                showUpdateThresholdMenu={showUpdateThresholdMenu}
                queryVariables={queryVariables}
              />
            )}
            {!isAdjustmentsTabSelected ? (
              <div style={{ marginLeft: "auto", paddingTop: 8 }}>
                <SuggestedThresholdTabs
                  totalCount={
                    manualApprovalCount?.getAccount?.healthScoreNotifications
                      .totalCount ?? 0
                  }
                  value={suggestedThresholdFilter}
                  onChange={(value) => {
                    setSuggestedThresholdFilter(value);
                  }}
                />
              </div>
            ) : null}
          </div>
        )}
      </StickyContainer>

      <ErrorBoundary fallbackUI={SimpleFallbackError}>
        <NotificationsList
          formatUrl={(n) => {
            const reportPageUrl = n.stat
              ? AbsoluteURLs.EXTERNAL__AnalyzeHealthScore.getUrl({
                  accountId,
                  crawlId: getRawCrawlId(String(n.stat?.crawlId)),
                  segmentId: n.segment?.id,
                  projectId: getRawProjectId(n.stat?.project.id),
                  reportCategoryCode: n.base.code,
                })
              : "/";
            return {
              url: reportPageUrl,
              tooltip: t("common:goToOverview", { category: "" }),
            };
          }}
          classes={{
            list: externalClasses?.list,
            progress: externalClasses?.loading,
            emptyState: externalClasses?.emptyState,
          }}
          readOnly={isSimple}
          noUnitOnChange={true}
          pendo={"notifications-page"}
          error={error}
          isLoading={loading || areCrawlIdsLoading}
          isReloading={reloading}
          isFilterApplied={hasFiltersApplied}
          hasAlerts={hasAlerts}
          notifications={notifications ?? []}
          hasFetchedAllData={hasFetchedAllData}
          onLoadMore={handleLoadMore}
          status={notificationStatus}
          clearFilters={resetFilters}
          handleAcceptSuggestedThreshold={handleAcceptSuggestedThreshold}
          handleDeclineSuggestedThreshold={handleDeclineSuggestedThreshold}
          values={notificationSelection}
          onGoToAllNotificationsClick={() => {
            setNotificationStatus(NotificationStatusFilter.Unread);
            setSuggestedThresholdFilter("all");
          }}
          onChange={(selection) => {
            setNotificationSelection(selection);
          }}
          onRuleEdit={(notification) => {
            setEditedRuleNotification(notification);
          }}
          showAdjustments={isAdjustmentsTabSelected}
          isRequiresManualApprovalSelected={
            suggestedThresholdFilter === "requiresApproval"
          }
          handleStatusUpdate={handleStatusUpdate}
        />
      </ErrorBoundary>
      {editedRuleNotification ? (
        <EditHealthScoreRuleAndThresholdDialog
          isOpen={Boolean(editedRuleNotification)}
          onClose={() => setEditedRuleNotification(null)}
          isAdjustment={isAdjustmentsTabSelected}
          queryVariables={queryVariables}
          notification={editedRuleNotification}
        />
      ) : null}
    </>
  );
}
