import { List } from "@material-ui/core";
import { ApolloError } from "@lumar/shared";
import { GetViewListFilter } from "../board/utils/filterViewList";
import { View } from "../types";
import { DashboardSettingsListItem } from "./DashboardSettingsListItem";

interface Props {
  data: View[];
  searchTerms?: GetViewListFilter;
  onChange?: (view: View) => void;
  error?: ApolloError;
  checked?: boolean;
  testId: string;
  type: "select" | "deselect";
}

export function DashboardSettingsList({
  data,
  searchTerms,
  onChange,
  checked,
  testId,
  type,
}: Props): JSX.Element {
  return (
    <List
      component="nav"
      style={{
        flex: 1,
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      {data.map((node, index) => {
        const key = node.id ?? `${node.projectId}_ ${node.segmentId ?? "none"}`;
        return (
          <DashboardSettingsListItem
            key={key}
            view={node}
            index={index}
            onChange={onChange}
            searchTerms={searchTerms}
            checked={checked}
            testId={testId}
            type={type}
          />
        );
      })}
    </List>
  );
}
