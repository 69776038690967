import React from "react";
import {
  Dialog,
  makeStyles,
  DialogActions,
  DialogTitle,
  Divider,
  Tooltip,
  useTheme,
  DialogContent,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { TemplatesView } from "./TemplatesView";
import { CustomDashboardType } from "../../../graphql";

import {
  Button,
  InformationCircleOutlined,
  Snackbar,
  ToggleIconButton,
  Typography,
  XSmallIcon,
  useTranslation,
} from "@lumar/shared";
import { Form, Formik } from "formik";
import { getCodeValuesInArray } from "./utils/utility";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useCreateDashboardsFromTemplate } from "./utils/useCreateDashboardsFromTemplate";
import { useMonitorRoutes } from "../../../_common/routing/useMonitorRoutes";
import { isEmpty as lodashIsEMpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: 1086,
    height: 642,
    overflowY: "hidden",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  infoCircleTitle: {
    color: theme.palette.grey[400],
    width: 18,
    height: 18,
    marginLeft: theme.spacing(1.5),
    position: "relative",
    top: 4,
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
}));
export interface TemplatesDialogProps {
  openCreationFromTemplate: boolean;
  setOpenCreationFromTemplate: (value: boolean) => void;
  onSkip?: (type: CustomDashboardType) => void;
  onClose?: () => void;
  useHealthScore?: boolean;
}

export function TemplatesDialog(props: TemplatesDialogProps): JSX.Element {
  const { accountId, collectionId } = useParams<{
    accountId: string;
    collectionId: string;
  }>();
  const { dashboardPage } = useMonitorRoutes();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(["dashboards"]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    openCreationFromTemplate,
    setOpenCreationFromTemplate,
    onSkip,
    onClose,
    useHealthScore = false,
  } = props;

  const createDashboards = useCreateDashboardsFromTemplate([
    "GetDashboardCollection",
  ]);

  async function handleSubmit(values: Record<string, boolean>): Promise<void> {
    const codeValues = getCodeValuesInArray(values);
    const ret = await createDashboards(collectionId, codeValues);
    if (ret.error) {
      const errorCode = ret.error?.graphQLErrors?.[0]?.extensions?.code;
      if (
        errorCode ===
        "CUSTOM_DASHBOARDS_COLLECTION_MAX_CUSTOM_DASHBOARDS_LIMIT_EXCEEDED"
      ) {
        setOpenCreationFromTemplate(false);
        onClose?.();
        if (ret.createdDashboardIds.length)
          dashboardPage.visit({
            accountId,
            collectionId,
            dashboardId: ret.createdDashboardIds[0],
          });
        return;
      }
    }
    if (ret.status) {
      setOpenCreationFromTemplate(false);
      onClose?.();
      enqueueSnackbar(<Snackbar variant="success" title={t("successMsg")} />);
      if (ret.createdDashboardIds.length)
        dashboardPage.visit({
          accountId,
          collectionId,
          dashboardId: ret.createdDashboardIds[0],
        });
    }
  }

  return (
    <>
      {Boolean(openCreationFromTemplate) && (
        <Dialog open classes={{ paper: classes.dialogPaper }}>
          <DialogTitle
            id="tab-reordering-dialog-title"
            className={classes.dialogTitle}
            disableTypography
          >
            <div>
              <Typography variant="subtitle1SemiBold">
                {t("chooseTemplate")}
              </Typography>
              <Tooltip title={"title"} arrow={false}>
                <InformationCircleOutlined
                  className={classes.infoCircleTitle}
                />
              </Tooltip>
            </div>
            <ToggleIconButton
              size="large"
              variant="standard"
              onClick={() => {
                onClose?.();
                setOpenCreationFromTemplate(false);
              }}
            >
              <XSmallIcon style={{ color: theme.palette.grey[700] }} />
            </ToggleIconButton>
          </DialogTitle>
          <Divider />
          <Formik
            initialValues={{}}
            enableReinitialize
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            {({ values, isSubmitting }) => {
              return (
                <>
                  <Backdrop
                    className={classes.backdrop}
                    open={isSubmitting}
                    invisible={true}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>{" "}
                  <DialogContent>
                    <TemplatesView
                      values={values}
                      useHealthScore={useHealthScore}
                    />
                  </DialogContent>
                  <Form>
                    <DialogActions
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setOpenCreationFromTemplate(false);
                          onSkip?.(CustomDashboardType.Monitor);
                        }}
                        disabled={isSubmitting}
                        style={{
                          marginLeft: theme.spacing(1.6),
                        }}
                        data-testid="monitor-template-skip"
                        data-pendo="monitor-template-skip"
                      >
                        {t("skip")}
                      </Button>
                      <div>
                        <Button
                          variant="outlined"
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            onClose?.();
                            setOpenCreationFromTemplate(false);
                          }}
                          style={{ marginRight: theme.spacing(1.5) }}
                          data-testid="monitor-template-cancel"
                          data-pendo="monitor-template-cancel"
                          disabled={isSubmitting}
                        >
                          {t("cancel")}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          data-testid="use-template-btn"
                          data-pendo="monitor-use-template-btn"
                          disabled={isSubmitting || isEmpty(values)}
                        >
                          {t("useTemplate")}
                        </Button>
                      </div>
                    </DialogActions>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Dialog>
      )}
    </>
  );
}

function isEmpty(value: Record<string, boolean>): boolean {
  if (lodashIsEMpty(value)) return true;
  return !Boolean(getCodeValuesInArray(value).length);
}
