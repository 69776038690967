import { useTranslation } from "@lumar/shared";
import { GetAlertWithTestsQuery, ReportTemplateUnit } from "../../graphql";
import { RulesAndThresholdsViewTable } from "./RulesAndThresholdsViewTable";
import { RulesAndThresholdsFormState } from "./utils/types";
import { makeStyles } from "@material-ui/core";
import { useHealthScoreEnabled } from "../../_common/hooks/useHealthScoreEnabled";
import { AnalyticsHubReportLink } from "../../board/dashboards/account-overview/AnalyticsHubReportLink";
import { ReportTrendVisualisation } from "../create/components/ReportTrendVisualisation";
import { useParams } from "react-router-dom";
import { getThresholdTrendDataFromTestList } from "./utils/getThresholdTrendDataFromTestList";
import { HealthScoreTrendVisualisation } from "../create/components/HealthScoreTrendVisualisation";
import {
  CategoryChangeTitle,
  CategorySegmentTitle,
  CategoryTitle,
} from "./table/RulesAndThresholdsTableTitles";
import { AnalyticsHubHealthScoreLink } from "../../board/dashboards/account-overview/AnalyticsHubHealthScoreLink";

export interface RulesAndThresholdsViewProps {
  alertId: string;
  values: RulesAndThresholdsFormState;
  data?: GetAlertWithTestsQuery;
}

export function RulesAndThresholdsView({
  alertId,
  values,
  data,
}: RulesAndThresholdsViewProps): JSX.Element {
  const classes = useStyles();
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation(["alerts"]);
  const hasHealthscores = useHealthScoreEnabled();

  return (
    <div>
      {Boolean(values.reportRulesAndThresholds.length) && (
        <RulesAndThresholdsViewTable
          title={t("alerts:reports")}
          className={classes.tableReports}
          testList={values.reportRulesAndThresholds ?? []}
          getTrendRenderer={(test) => {
            const thresholdTrendData = getThresholdTrendDataFromTestList(
              {
                id: test.id,
                testResults: test.testResults ?? [],
                reportTemplateCode: test.report.code,
              },
              test.report.code,
            );
            return (
              <AnalyticsHubReportLink
                accountId={accountId}
                projectId={alertId}
                segmentId={test.segment?.id}
                crawlId={data?.alert?.builds?.nodes?.[0]?.crawl?.id ?? ""}
                reportTemplateCode={test.report.code ?? ""}
                data-testid={`account-overview-grid-${test.report.code}-column-cell`}
                data-pendo="monitor-dashboard-report-link"
                className={classes.link}
              >
                <ReportTrendVisualisation
                  reportTemplateCode={test.report.code}
                  unit={test.report.unit ?? ReportTemplateUnit.UrLs}
                  projectId={alertId}
                  segmentId={test.segment?.id}
                  thresholdTrendData={thresholdTrendData}
                />
              </AnalyticsHubReportLink>
            );
          }}
        />
      )}
      {hasHealthscores &&
        Boolean(values.categoryRulesAndThresholds?.length) && (
          <RulesAndThresholdsViewTable
            title={t("alerts:healthscores")}
            typeCell={<CategoryTitle />}
            segmentCell={<CategorySegmentTitle />}
            changeCell={<CategoryChangeTitle />}
            className={classes.tableHealthscores}
            testList={values.categoryRulesAndThresholds ?? []}
            getTrendRenderer={(test) => {
              const thresholdTrendData = getThresholdTrendDataFromTestList(
                {
                  id: test.id,
                  testResults: test.testResults ?? [],
                  reportTemplateCode: test.report.code,
                },
                test.report.code,
              );
              return (
                <AnalyticsHubHealthScoreLink
                  accountId={accountId}
                  projectId={alertId}
                  segmentId={test.segment?.id}
                  crawlId={data?.alert?.builds?.nodes?.[0]?.crawl?.id ?? ""}
                  reportCategoryCode={test.report.code ?? ""}
                  data-testid={`health-score-rule-${test.report.code}-trend`}
                  data-pendo="health-score-rule-report-link"
                  className={classes.link}
                >
                  <HealthScoreTrendVisualisation
                    categoryCode={test.report.code}
                    unit={test.report.unit ?? ReportTemplateUnit.UrLs}
                    projectId={alertId}
                    segmentId={test.segment?.id}
                    thresholdTrendData={thresholdTrendData}
                  />
                </AnalyticsHubHealthScoreLink>
              );
            }}
          />
        )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  tableReports: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  tableHealthscores: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  link: {
    textDecoration: "none",
  },
}));
