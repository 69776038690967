import { useTranslation } from "@lumar/shared";
import { ReportOption } from "../../../_common/utils/constants";
import { RuleAndThreshold } from "../utils/types";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles, TableCell } from "@material-ui/core";
import { ErrorBoundary } from "@rollbar/react";
import { SimpleFallbackError } from "../../../_common/components/SimpleFallbackError";
import { AnalyticsHubReportLink } from "../../../board/dashboards/account-overview/AnalyticsHubReportLink";
import { ReportTemplateUnit } from "../../../graphql";
import { ReportTrendVisualisation } from "../../create/components/ReportTrendVisualisation";

interface ReportTrendVisualisationCellProps {
  ruleAndThreshold: RuleAndThreshold<ReportOption>;
  projectId: string;
  crawlId?: string | null;
}

export function ReportTrendVisualisationCell({
  ruleAndThreshold,
  projectId,
  crawlId,
}: ReportTrendVisualisationCellProps): JSX.Element {
  const { t } = useTranslation(["alerts"]);
  const { accountId } = useParams<{ accountId: string }>();
  const classes = useStyles();

  return (
    <TableCell className={classes.rowCell}>
      <div className={classes.reportTrendWrapper}>
        {ruleAndThreshold.report?.code ? (
          <ErrorBoundary fallbackUI={SimpleFallbackError}>
            <AnalyticsHubReportLink
              accountId={accountId}
              projectId={projectId}
              segmentId={ruleAndThreshold.segment?.id}
              crawlId={crawlId ?? ""}
              reportTemplateCode={ruleAndThreshold.report.code ?? ""}
              data-testid={`account-overview-grid-${ruleAndThreshold.report.code}-column-cell`}
              data-pendo="monitor-dashboard-report-link"
              className={classes.link}
            >
              <ReportTrendVisualisation
                reportTemplateCode={ruleAndThreshold.report.code}
                unit={ruleAndThreshold.report.unit ?? ReportTemplateUnit.UrLs}
                projectId={projectId}
                segmentId={ruleAndThreshold.segment?.id}
                yAxisLineValue={
                  ruleAndThreshold.urls ? ruleAndThreshold.urls : undefined
                }
              />
            </AnalyticsHubReportLink>
          </ErrorBoundary>
        ) : (
          <span>{t("alerts:noReportSelected")}</span>
        )}
      </div>
    </TableCell>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    reportTrendWrapper: {
      marginLeft: "auto",
    },
    rowCell: {
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
      padding: theme.spacing(1.5, 1.55),
      color: theme.palette.grey[700],
    },
    link: {
      textDecoration: "none",
    },
  }),
);
