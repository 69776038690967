import { Field, FieldArrayRenderProps } from "formik";
import { TextFieldWithUnitField } from "../TextFieldWithUnit";
import { ReportTemplateUnit } from "../../../graphql";
import { RuleAndThreshold } from "../utils/types";
import { BaseReport } from "../../../_common/utils/constants";
import { createStyles, makeStyles } from "@material-ui/core";
import { URL_THRESHOLD_WIDTH } from "../table/RulesAndThresholdsTableTitles";
import { useTranslation } from "@lumar/shared";
import { Skeleton } from "@material-ui/lab";

interface RulesAndThresholdsThresholdField<T extends BaseReport> {
  readOnly: boolean;
  name: string;
  arrayHelpers: FieldArrayRenderProps;
  ruleAndThreshold: RuleAndThreshold<T>;
  unit?: string;
  "data-testid"?: string;
  "data-pendo"?: string;
}

export function RulesAndThresholdsThresholdField<T extends BaseReport>({
  readOnly,
  name,
  unit,
  arrayHelpers,
  ruleAndThreshold,
  ...props
}: RulesAndThresholdsThresholdField<T>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["units"]);

  const { setFieldTouched } = arrayHelpers.form;

  if (ruleAndThreshold.urls === null) {
    return <Skeleton variant="rect" style={{ borderRadius: 6 }} height={28} />;
  }

  return (
    <Field
      className={classes.textField}
      component={TextFieldWithUnitField}
      disabled={readOnly}
      type="number"
      id={name}
      name={name}
      variant="outlined"
      size="small"
      {...props}
      style={{
        maxWidth: 200,
        flexShrink: 100,
        minWidth: 80,
        width: "100%",
      }}
      onFocus={() => setFieldTouched(name, true)}
      unit={t(
        `units:${unit ?? ruleAndThreshold.report?.unit ?? ReportTemplateUnit.UrLs}`,
        {
          count: ruleAndThreshold.urls,
        },
      )}
    />
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      "& input": {
        height: 17,
        paddingTop: "6px!important",
        paddingBottom: "6px!important",
      },
      "& .Mui-disabled": {
        color: theme.palette.grey[400],
      },
      minWidth: URL_THRESHOLD_WIDTH,
      padding: "0!important",
    },
  }),
);
