import { Redirect } from "react-router-dom";
import { getRawAccountId, useSession } from "@lumar/shared";

import { useMonitorRoutes } from "../_common/routing/useMonitorRoutes";
import { useHasMonitorApp } from "../_common/hooks/useHasMonitorApp";

export function RedirectToAccountOverview(): JSX.Element {
  const session = useSession();
  const { dashboardPage, marketingPage } = useMonitorRoutes();
  const hasMonitor = useHasMonitorApp();

  if (!hasMonitor) {
    return (
      <Redirect
        to={marketingPage.getUrl({
          accountId: getRawAccountId(session.account.id),
        })}
      />
    );
  }

  return (
    <Redirect
      to={dashboardPage.getUrl({
        accountId: getRawAccountId(session.account.id),
      })}
    />
  );
}
