import { Button, PlusSolid, Trans, useSession } from "@lumar/shared";
import { ConditionalTooltip } from "../../../_common/components/ConditionalTooltip";
import { HideFromInsufficientRole } from "../../../_common/components/HideFromInsufficientRole";
import { useEffect, useState } from "react";
import { RoleCode } from "../../../graphql";
import { AbsoluteURLs } from "../../../_common/routing/absoluteURLs";
import { useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core";
import { useInView } from "react-intersection-observer";

interface AddRuleButtonProps {
  onClick?: (callback: () => void) => void;
  disabled?: boolean;
  accountLimitReached?: boolean;
  "data-testid"?: string;
  "data-pendo"?: string;
  title: string;
}

export function AddRuleButton({
  onClick,
  disabled,
  accountLimitReached,
  title,
  ...props
}: AddRuleButtonProps): JSX.Element {
  const session = useSession();
  const theme = useTheme();

  const { accountId } = useParams<{ accountId: string }>();

  const [scrollToBottom, setScrollToBottom] = useState<boolean>(false);

  const admin = session.hasSufficientRole(RoleCode.Admin);

  const { ref, inView, entry } = useInView({
    delay: 0,
    rootMargin: "0px 0px -150px 0px",
    threshold: 0,
    fallbackInView: true,
  });

  useEffect(() => {
    if (scrollToBottom) {
      if (!inView) {
        entry?.target?.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
      setScrollToBottom(false);
    }
  }, [scrollToBottom, inView, entry?.target]);

  return (
    <HideFromInsufficientRole>
      <ConditionalTooltip
        interactive
        title={
          <Trans
            ns="alerts"
            i18nKey={
              admin ? "accountLimitReached" : "accountLimitReachedEditor"
            }
            components={{
              subscriptionLink: (
                <a
                  href={AbsoluteURLs.EXTERNAL__AccountsSubscription.getUrl(
                    accountId,
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        }
        show={Boolean(accountLimitReached)}
      >
        <span>
          <Button
            style={{
              marginTop: 18,
            }}
            type="button"
            variant="outlined"
            color="inherit"
            size="large"
            ref={ref}
            {...props}
            startIcon={
              <PlusSolid
                htmlColor={
                  disabled
                    ? theme.palette.grey[400]
                    : theme.palette.primary.main
                }
              />
            }
            disableElevation
            disabled={disabled}
            onClick={async () => {
              onClick?.(() => {
                setScrollToBottom(true);
              });
            }}
          >
            {title}
          </Button>
        </span>
      </ConditionalTooltip>
    </HideFromInsufficientRole>
  );
}
