/* eslint-disable fp/no-mutating-methods */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { startOfDay, endOfDay } from "date-fns";
import { FilterRuleFormValue, PredicateValue } from "@lumar/shared";

import {
  CrawlStatus,
  ConnectionPredicate,
  DateTimeRange,
} from "../../../../../graphql";

interface FormatFiltersArguments {
  filters: FilterRuleFormValue[];
  includeActiveAndScheduledFilters: false;
}

interface FormatFiltersArgumentsWithActiveAndScheduledFilters {
  filters: FilterRuleFormValue[];
  includeActiveAndScheduledFilters: true;
  metadata: {
    currentBillingPeriod: DateTimeRange;
  };
}

interface FormatFiltersEntry {
  _and?: any[];
  _or?: any[];
}

export interface FormatFiltersValue {
  _and: FormatFiltersEntry[];
}

interface Interval {
  start: string;
  end: string;
}

function getDateInterval(predicateValue: PredicateValue): Interval {
  const date = new Date(predicateValue.toString());
  const dayStart = startOfDay(date).toISOString();
  const dayEnd = endOfDay(date).toISOString();

  return {
    start: dayStart,
    end: dayEnd,
  };
}

function addDateEntriesForEqualsKey(
  filter: FilterRuleFormValue,
  andFilters: FormatFiltersEntry[],
  interval: Interval,
): void {
  andFilters.push({
    _and: [
      {
        [filter.metricCode]: {
          [ConnectionPredicate.Ge]: interval.start,
        },
      },
      {
        [filter.metricCode]: {
          [ConnectionPredicate.Le]: interval.end,
        },
      },
    ],
  });
}

function addDateEntriesForNegations(
  filter: FilterRuleFormValue,
  andFilters: FormatFiltersEntry[],
  interval: Interval,
): void {
  andFilters.push({
    _or: [
      {
        [filter.metricCode]: {
          [ConnectionPredicate.Ge]: interval.end,
        },
      },
      {
        [filter.metricCode]: {
          [ConnectionPredicate.Le]: interval.start,
        },
      },
    ],
  });
}

function addDateEntriesForOtherCases(
  filter: FilterRuleFormValue,
  andFilters: FormatFiltersEntry[],
  interval: Interval,
): void {
  if (
    filter.predicateKey === ConnectionPredicate.Le ||
    filter.predicateKey === ConnectionPredicate.Gt
  ) {
    andFilters.push({
      _and: [
        {
          [filter.metricCode]: {
            [filter.predicateKey]: interval.end,
          },
        },
      ],
    });
  } else if (
    filter.predicateKey === ConnectionPredicate.Ge ||
    filter.predicateKey === ConnectionPredicate.Lt
  ) {
    andFilters.push({
      _and: [
        {
          [filter.metricCode]: {
            [filter.predicateKey]: interval.start,
          },
        },
      ],
    });
  }
}

export const formatFilters = (
  args:
    | FormatFiltersArguments
    | FormatFiltersArgumentsWithActiveAndScheduledFilters,
): FormatFiltersValue => {
  const { filters, includeActiveAndScheduledFilters } = args;
  const andFilters: FormatFiltersEntry[] = [];

  // NOTE: In the analyze projects list, the predicate key values are "true" and "false" for boolean
  // because they are not transformed as part validating against the yup schema, but in monitor
  // "true" is transformed into true and so we check against the boolean rather than the string.
  // Beware if trying to abstract formatFilters to fit both use cases in the future, and either check
  // for both or adjust the analyze implementation to transform the strings to booleans - Saul.
  filters.forEach((filter) => {
    const includeProject =
      (filter.predicateKey === ConnectionPredicate.Eq &&
        filter.predicateValue === true) ||
      (filter.predicateKey === ConnectionPredicate.Ne &&
        filter.predicateValue === false);

    const keyIsNegation = (
      [
        ConnectionPredicate.Ne,
        ConnectionPredicate.NotContains,
        ConnectionPredicate.NotMatchesRegex,
      ] as string[]
    ).includes(filter.predicateKey);

    const dateMetric = [
      "projectCreatedAt",
      "projectLastCrawlCrawlingAt",
      "projectFinishedAt",
    ];

    if (
      includeActiveAndScheduledFilters &&
      filter.metricCode === "schedulesTotalCount"
    ) {
      if (includeProject) {
        andFilters.push({
          _and: [
            {
              schedulesTotalCount: {
                [ConnectionPredicate.Eq]: 1,
              },
            },
          ],
        });
      } else {
        andFilters.push({
          _and: [
            {
              schedulesTotalCount: {
                [ConnectionPredicate.Eq]: 0,
              },
            },
          ],
        });
      }
    } else if (filter.metricCode === "siteTest") {
      andFilters.push({
        _and: [
          {
            siteTest: {
              [ConnectionPredicate.IsNull]: includeProject ? false : true,
            },
          },
        ],
      });
    } else if (filter.metricCode === "nameOrDomain") {
      if (keyIsNegation) {
        andFilters.push({
          _and: [
            {
              projectName: {
                [filter.predicateKey]: filter.predicateValue,
              },
            },
            {
              projectPrimaryDomain: {
                [filter.predicateKey]: filter.predicateValue,
              },
            },
          ],
        });
      } else {
        andFilters.push({
          _or: [
            {
              projectName: {
                [filter.predicateKey]: filter.predicateValue,
              },
            },
            {
              projectPrimaryDomain: {
                [filter.predicateKey]: filter.predicateValue,
              },
            },
          ],
        });
      }
    } else if (filter.metricCode === "running") {
      if (includeProject) {
        andFilters.push({
          _or: [
            {
              projectLastCrawlStatus: {
                [ConnectionPredicate.Eq]: CrawlStatus.Crawling,
              },
            },
            {
              projectLastCrawlStatus: {
                [ConnectionPredicate.Eq]: CrawlStatus.Finalizing,
              },
            },
            {
              projectLastCrawlStatus: {
                [ConnectionPredicate.Eq]: CrawlStatus.Discovering,
              },
            },
            {
              projectLastCrawlStatus: {
                [ConnectionPredicate.Eq]: CrawlStatus.Queuing,
              },
            },
          ],
        });
      } else {
        andFilters.push({
          _and: [
            {
              projectLastCrawlStatus: {
                notIn: ["Crawling", "Finalizing", "Discovering", "Queued"],
              },
            },
          ],
        });
      }
    } else if (
      includeActiveAndScheduledFilters &&
      filter.metricCode === "active"
    ) {
      const { currentBillingPeriod } = args.metadata;
      if (includeProject) {
        andFilters.push({
          _and: [
            {
              projectLastCrawlCrawlingAt: {
                [ConnectionPredicate.Ge]: currentBillingPeriod.start,
              },
            },
            {
              projectLastCrawlCrawlingAt: {
                [ConnectionPredicate.Le]: currentBillingPeriod.end,
              },
            },
          ],
        });
      } else {
        andFilters.push({
          _or: [
            {
              projectLastCrawlCrawlingAt: {
                [ConnectionPredicate.Le]: currentBillingPeriod.start,
              },
            },
            {
              projectLastCrawlCrawlingAt: {
                [ConnectionPredicate.Ge]: currentBillingPeriod.end,
              },
            },
            {
              projectLastCrawlCrawlingAt: {
                isNull: true,
              },
            },
          ],
        });
      }
    } else if (dateMetric.includes(filter.metricCode)) {
      const interval = getDateInterval(filter.predicateValue);
      if (filter.predicateKey === ConnectionPredicate.Eq) {
        addDateEntriesForEqualsKey(filter, andFilters, interval);
      } else if (keyIsNegation) {
        addDateEntriesForNegations(filter, andFilters, interval);
      } else {
        addDateEntriesForOtherCases(filter, andFilters, interval);
      }
    } else {
      andFilters.push({
        _and: [
          {
            [filter.metricCode]: {
              [filter.predicateKey]: filter.predicateValue,
            },
          },
        ],
      });
    }
  });

  return {
    _and: andFilters,
  };
};
