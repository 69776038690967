import {
  Snackbar,
  getApiAccountId,
  useApolloClient,
  useTranslation,
} from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  CloneDashboardCollectionMutation,
  ModuleCode,
  useCloneDashboardCollectionMutation,
  useCreateDashboardCollectionMutation,
  useUpdateDashboardCollectionMutation,
} from "../../../../graphql";
import { useHealthScoreEnabled } from "../../../../_common/hooks/useHealthScoreEnabled";
import { useCreateDashboardsFromTemplate } from "../../../components/create-from-template/utils/useCreateDashboardsFromTemplate";
import { View } from "../../types";
import { useAddViewsToCollection } from "./useAddViewsToCollection";
import { useUpdateViewsForCollection } from "./useUpdateViewsForCollection";
import { useGetAccountModuleCodes } from "../../../../_common/hooks/useGetAccountModuleCodes";
import { useTemplates } from "../../../components/create-from-template/utils/templates";

interface CollectionProps {
  name?: string;
  originalViews?: View[];
  boardViews?: View[];
  templateCodes?: string[];
  cloning?: boolean;
}

interface CreateOrUpdateCollectionProps {
  onCompleted?: (collectionId: string | null) => void;
}

interface CreateOrUpdateCollectionRet {
  loading: boolean;
  createOrUpdateCollection: (props: CollectionProps) => Promise<void>;
}

export function useCreateOrUpdateCollection(
  mainProps: CreateOrUpdateCollectionProps,
): CreateOrUpdateCollectionRet {
  const { accountId, collectionId } = useParams<{
    accountId: string;
    collectionId: string | undefined;
  }>();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["notifications"]);
  const useHealthScore = useHealthScoreEnabled();
  const [updateIsRunning, setUpdateIsRunning] = useState<boolean>(false);

  const moduleCodes = useGetAccountModuleCodes();
  const { searchFunction: searchTemplates } = useTemplates();

  const moduleCode = moduleCodes[0] ?? ModuleCode.Seo;

  const updateViewsToBoard = useUpdateViewsForCollection();
  const addViewsToBoard = useAddViewsToCollection();
  const apollo = useApolloClient();

  function clearCache(): void {
    apollo.cache.modify({
      id: apollo.cache.identify({
        __typename: "Account",
        id: getApiAccountId(accountId),
      }),
      fields: {
        customDashboardCollections: (_, details) => details.DELETE,
      },
    });
  }

  const [updateCustomDashboardCollection, { loading: boardUpdateLoading }] =
    useUpdateDashboardCollectionMutation({});

  const createDashboardsFromTemplate = useCreateDashboardsFromTemplate();

  const [cloneCurrentDashboardCollection] =
    useCloneDashboardCollectionMutation();

  const [createCustomDashboardCollection, { loading: creationLoading }] =
    useCreateDashboardCollectionMutation({});

  async function createOrUpdateCollection(
    props: CollectionProps,
  ): Promise<void> {
    const isEditing = Boolean(collectionId);
    setUpdateIsRunning(true);

    if (isEditing || props.cloning) {
      (props.cloning
        ? cloneCurrentDashboardCollection({
            variables: {
              accountId: getApiAccountId(accountId),
              collectionId,
              name: props.name ?? "Board",
            },
          })
        : updateCustomDashboardCollection({
            variables: {
              collectionId,
              name: props.name ?? "Board",
            },
            notifyOnNetworkStatusChange: true,
          })
      ).then(
        async (data) => {
          const cloning = Boolean(props.cloning);
          if (cloning) clearCache();

          const id = isCloneDashboardCollectionData(data.data)
            ? data.data.copyCustomDashboardCollection?.customDashboardCollection
                .id
            : collectionId;
          const ret = await updateViewsToBoard(
            id,
            props.originalViews ?? [],
            props.boardViews ?? [],
            cloning,
            true,
          );
          setUpdateIsRunning(false);
          mainProps.onCompleted?.(ret && id ? id : null);
        },
        (err) => {
          const message = props.cloning
            ? "notifications:failedToDuplicateStatus"
            : "notifications:failedToUpdateStatus";
          enqueueSnackbar(
            <Snackbar
              variant="error"
              title={t(message, {
                message: err.message,
              })}
            />,
          );
          setUpdateIsRunning(false);
          mainProps.onCompleted?.(null);
        },
      );
    } else {
      createCustomDashboardCollection({
        variables: {
          accountId,
          name: props.name ?? "Board",
          types: [],
        },
        notifyOnNetworkStatusChange: true,
      }).then(
        async ({ data }) => {
          clearCache();
          const newCollectionId =
            data?.createCustomDashboardCollection.customDashboardCollection.id;
          const ret = await addViewsToBoard(
            newCollectionId,
            [],
            props.boardViews?.map((e) => ({
              id: "",
              accountId,
              projectId: e.projectId,
              segmentId: e.segmentId,
            })) ?? [],
            true,
          );

          const templateCodes = props.templateCodes?.length
            ? props.templateCodes
            : (searchTemplates({
                default: true,
                moduleCode,
                ...(useHealthScore ? {} : { isHealthScore: false }),
              })?.map((e) => e.code) ?? []);

          const creation =
            ret &&
            (
              await createDashboardsFromTemplate(
                newCollectionId ?? "",
                templateCodes,
                ret,
              )
            ).status;

          setUpdateIsRunning(false);
          mainProps.onCompleted?.(
            creation && newCollectionId ? newCollectionId : null,
          );
        },
        (err) => {
          enqueueSnackbar(
            <Snackbar
              variant="error"
              title={t("notifications:failedToUpdateStatus", {
                message: err.message,
              })}
            />,
          );
          setUpdateIsRunning(false);
          mainProps.onCompleted?.(null);
        },
      );
    }
  }

  const loading = boardUpdateLoading || creationLoading || updateIsRunning;
  return { loading, createOrUpdateCollection };
}

function isCloneDashboardCollectionData(
  data: unknown,
): data is CloneDashboardCollectionMutation {
  if (typeof data !== "object") return false;
  return data ? data.hasOwnProperty("copyCustomDashboardCollection") : false;
}
