import React, { useState } from "react";
import {
  Button,
  Typography,
  PlusSolid,
  Snackbar,
  ArrowLeftOutlined,
  EmptyState,
  useTranslation,
} from "@lumar/shared";
import { NoAlertSelectedView } from "./components/NoAlertSelectedView";
import { IconButton, makeStyles } from "@material-ui/core";
import {
  useGetAlertsFilteredQuery,
  useGetAlertWithTestsQuery,
} from "../../graphql";
import { AlertsList } from "./components/AlertsList";
import { AlertRulesAndThresholds } from "./components/AlertRulesAndThresholds";
import { useParams } from "react-router";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { Form, Formik, FormikHelpers } from "formik";
import { RulesAndThresholdsFormState } from "../_common/utils/types";
import { useSnackbar } from "notistack";
import { useValidationSchema } from "../_common/utils/useValidationSchema";
import { InView } from "react-intersection-observer";
import clsx from "clsx";
import { AlertListItem } from "./components/AlertListItem";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";
import { useInitialAlertSettings } from "./utils/useInitialAlertSettings";
import { CopyAlert } from "../copy/CopyAlert";
import { getErrorMessage } from "../../_common/utils/getErrorMessage";
import { useUpdateAlertMutation } from "./utils/useUpdateAlertMutation";
import { useHistory, useLocation } from "react-router-dom";
import { useUrlHistory } from "../../UrlHistoryProvider";
import { ProjectOption } from "../../_common/utils/constants";
import { ProjectSearchBar } from "../copy/components/ProjectSearchBar";
import { useDebounce } from "../../_common/hooks/useDebounce";
import noresult from "../../_animations/noresult-animation.json";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { useRefreshProjects } from "../copy/utils/useRefreshProjects";

const HEADER_HEIGHT = 63;
const ALERT_LIST_WIDTH = 281;
const COMPACT_ALERT_LIST_WIDTH = 180;

export function ManageAlerts(): JSX.Element {
  const classes = useStyles({ addedHeight: scroll });
  const { accountId, alertId } = useParams<{
    accountId: string;
    alertId?: string;
  }>();

  const { createAlertPage, alertsPage } = useMonitorRoutes();
  const history = useHistory();
  const location = useLocation();
  const urlHistory = useUrlHistory();

  const refreshProjects = useRefreshProjects();

  const { t } = useTranslation(["alerts"]);
  const { enqueueSnackbar } = useSnackbar();
  const [copyAlertTo, setCopyAlertTo] = useState<ProjectOption[]>([]);
  const [filterText, setFilterText] = useState<string>("");
  const [isEditMode, setEditMode] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(filterText);

  const [updateAlert, { loading: isSavingChanges }] = useUpdateAlertMutation();

  const {
    data: alertsData,
    previousData: previousAlertsData,
    loading: loadingAlerts,
    error: alertsError,
    refetch: refetchAlertList,
  } = useGetAlertsFilteredQuery({
    variables: {
      accountId,
      searchTerm: debouncedSearchTerm || null,
    },
    fetchPolicy: "cache-first",
  });

  const numberOfAlerts = alertsData?.getAccount?.allAlerts.totalCount ?? 0;
  const numberOfFoundAlerts = alertsData?.getAccount?.alerts.totalCount ?? 0;

  const {
    data,
    loading,
    error,
    refetch: refetchAlert,
  } = useGetAlertWithTestsQuery({
    variables: {
      projectId: alertId,
    },
    skip: !alertId,
  });

  const initialAlertSettings = useInitialAlertSettings(data);
  const alertHasRules =
    Boolean(data?.alert?.tests.nodes.length) ||
    Boolean(data?.alert?.healthScoreTests.nodes.length);

  function handleBackButtonClick(): void {
    setEditMode(false);
    // Note: it's ok for us to use `.reverse()` because we are creating a new array first
    // eslint-disable-next-line fp/no-mutating-methods
    const reversedUrlHistory = [...urlHistory].reverse();
    const mostRecentNonAlertsUrl = reversedUrlHistory.find(
      (url) => !url.includes("/alerts"),
    );

    if (mostRecentNonAlertsUrl) {
      history.push(mostRecentNonAlertsUrl);
    } else {
      history.goBack();
    }
  }

  const handleSubmit = async (
    values: RulesAndThresholdsFormState,
    actions: FormikHelpers<RulesAndThresholdsFormState>,
  ): Promise<void> => {
    try {
      if (alertId) {
        await updateAlert({
          initialAlertSettings,
          newAlertSettings: values,
          alertId,
          alertData: data,
        });
        await refetchAlert();
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("alerts:alertUpdatedSuccessfully")}
          />,
        );
        refetchAlertList();
      }
      setEditMode(false);
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("alerts:alertUpdateFailed", {
            message: getErrorMessage(e),
          })}
        />,
      );
    }
    actions.setSubmitting(false);
    actions.resetForm();
  };
  const schema = useValidationSchema();
  const [headerVisible, setHeaderVisible] = useState<boolean>(true);

  return copyAlertTo.length && data?.alert ? (
    <CopyAlert
      toProjects={copyAlertTo}
      fromProject={data?.alert}
      onFinished={() => {
        setCopyAlertTo([]);
        refreshProjects();
        alertsPage.visit({ alertId });
      }}
    />
  ) : (
    <Formik
      initialValues={initialAlertSettings}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, resetForm }) => {
        const handleCancel = (): void => {
          resetForm();
          setEditMode(false);
        };

        const buttons = (
          <HideFromInsufficientRole>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              disabled={isSavingChanges}
              size="large"
              type="button"
              onClick={handleCancel}
              data-pendo="cancel-edited-alert"
            >
              {t("common:cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              loading={isSubmitting}
              data-pendo="update-edited-alert"
              data-testid="save-alert-changes-button"
            >
              {t("common:saveUpdates")}
            </Button>
          </HideFromInsufficientRole>
        );
        return (
          <Form>
            <div className={classes.container}>
              <InView
                as="div"
                className={classes.header}
                onChange={(inView) => {
                  setHeaderVisible(inView);
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {!isEditMode ? (
                    <IconButton
                      className={classes.backButton}
                      onClick={handleBackButtonClick}
                      title={t("back")}
                    >
                      <ArrowLeftOutlined />
                    </IconButton>
                  ) : null}
                  <Typography
                    variant="h6SemiBold"
                    className={classes.mainTitle}
                  >
                    {isEditMode ? t("edit") : t("manage")}
                  </Typography>
                </div>
                <HideFromInsufficientRole>
                  {!isEditMode ? (
                    alertsData?.getAccount?.alerts.totalCount ? (
                      <Button
                        startIcon={<PlusSolid />}
                        variant="contained"
                        size="large"
                        color="primary"
                        type="button"
                        data-pendo="create-new-alert"
                        data-testid="create-new-alert-button"
                        onClick={() => {
                          const createAlertPageUrl = createAlertPage.getUrl();
                          history.push(createAlertPageUrl, {
                            prevPath: location.pathname,
                          });
                        }}
                      >
                        {t("new")}
                      </Button>
                    ) : undefined
                  ) : (
                    buttons
                  )}
                </HideFromInsufficientRole>
              </InView>
              <div className={classes.mainContainer}>
                <div
                  className={clsx(
                    classes.sideContentContainer,
                    classes.scrollSideContainer,
                  )}
                >
                  <div style={{ display: "flex" }}>
                    <Typography
                      component="p"
                      variant="subtitle1SemiBold"
                      className={classes.sideContentTitle}
                    >
                      {isEditMode ? t("alertEdit") : t("alerts")}
                    </Typography>
                    {!isEditMode ? (
                      <Typography
                        variant="body2"
                        className={classes.sideContentSubtitle}
                      >
                        {debouncedSearchTerm
                          ? t("alertsFoundCount", {
                              count: numberOfFoundAlerts,
                            })
                          : t("alertsCount", { count: numberOfAlerts })}
                      </Typography>
                    ) : undefined}
                  </div>
                  {isEditMode ? (
                    <>
                      <Typography
                        variant="button"
                        className={classes.sideContentEditSubtitle}
                      >
                        {t("project")}
                      </Typography>
                      <div>
                        <AlertListItem
                          name={data?.alert?.name ?? ""}
                          primaryDomain={data?.alert?.primaryDomain ?? ""}
                          selected={true}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <ProjectSearchBar
                        value={filterText}
                        onChange={(e) => {
                          setFilterText(e.target.value);
                        }}
                        onClear={() => {
                          setFilterText("");
                        }}
                        disabled={
                          !(alertsData || previousAlertsData)?.getAccount
                            ?.allAlerts.totalCount
                        }
                        className={classes.searchField}
                      />
                      {!loadingAlerts &&
                      !Boolean(alertsError) &&
                      numberOfAlerts > 0 &&
                      numberOfFoundAlerts === 0 ? (
                        <EmptyState
                          title={""}
                          description={t("notFound")}
                          icon={
                            <Lottie
                              loop
                              animationData={noresult}
                              play
                              style={{ width: "80px", margin: "auto" }}
                            />
                          }
                          actions={[
                            {
                              type: "button",
                              title: t("clearSearch"),
                              onClick: () => setFilterText(""),
                              "data-pendo": "manage-alerts-clear-search",
                            },
                          ]}
                        />
                      ) : (
                        <AlertsList
                          loading={loadingAlerts}
                          error={alertsError}
                          data={alertsData}
                          searchTerm={filterText}
                          onSelectionChanged={() => setFilterText("")}
                        />
                      )}
                    </>
                  )}
                </div>
                <div
                  className={classes.mainFlexItem}
                  style={{
                    marginLeft: ALERT_LIST_WIDTH,
                  }}
                >
                  {alertId && alertHasRules ? (
                    <AlertRulesAndThresholds
                      setEditMode={setEditMode}
                      disableCopyAlert={isEditMode}
                      isEditMode={isEditMode}
                      loading={loading}
                      error={error}
                      data={data}
                      headerVisible={headerVisible && isEditMode}
                      onDeletionSuccess={() => {
                        refreshProjects();
                      }}
                      onCopyAlert={(projects) => {
                        setCopyAlertTo(projects);
                      }}
                    />
                  ) : (
                    <NoAlertSelectedView
                      loading={loadingAlerts}
                      numberOfAlerts={numberOfAlerts}
                      numberOfProjects={
                        alertsData?.getAccount?.projects.totalCount ?? 0
                      }
                    />
                  )}
                  {debouncedSearchTerm ? (
                    <div
                      className={classes.blur}
                      onClick={() => setFilterText("")}
                    />
                  ) : undefined}
                </div>
              </div>
            </div>
            <HideFromInsufficientRole>
              {!headerVisible && isEditMode ? (
                <div
                  className={clsx(
                    classes.headerContainer,
                    classes.stickyBottom,
                  )}
                >
                  <div className={classes.buttonContainer}>{buttons}</div>
                </div>
              ) : undefined}
            </HideFromInsufficientRole>
          </Form>
        );
      }}
    </Formik>
  );
}

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    height: HEADER_HEIGHT,
  },
  container: {
    minHeight: "100vh",
    background: "white",
  },
  stickyBottom: {
    position: "sticky",
    zIndex: theme.zIndex.snackbar - 1,
    bottom: 0,
    backgroundColor: "#D1D5DB4C",
    backdropFilter: "blur(8px)",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    height: HEADER_HEIGHT,
    padding: theme.spacing(0, 3),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    height: HEADER_HEIGHT,
    alignItems: "center",
    padding: theme.spacing(0, 3),
  },
  mainTitle: { fontSize: theme.typography.pxToRem(18) },
  mainContainer: {
    display: "flex",
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
    position: "relative",
  },
  sideContentContainer: {
    flex: 1,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(3, 3, 0, 3),
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
    width: ALERT_LIST_WIDTH,
    position: "absolute",
    top: 0,
    bottom: 0,
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  sidebarCompact: {
    width: COMPACT_ALERT_LIST_WIDTH,
  },
  scrollSideContainer: {
    overflowY: "auto",
  },
  sideContentTitle: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    flex: 1,
  },
  sideContentSubtitle: {
    color: theme.palette.grey[500],
    display: "block",
    marginBottom: theme.spacing(2),
  },
  sideContentEditSubtitle: {
    color: theme.palette.grey[500],
    display: "block",
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    marginLeft: "auto",
    marginRight: 12,
  },
  mainFlexItem: {
    flex: 1,
    padding: theme.spacing(0, 0),
    overflow: "hidden",
  },
  backButton: {
    borderRadius: 6,
    marginRight: 8,
    padding: 3,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
    "& svg": {
      height: 18,
      width: 18,
      color: theme.palette.grey[700],
    },
  },
  searchField: {
    marginBottom: theme.spacing(1.5),
  },
  blur: {
    position: "absolute",
    top: 0,
    left: 0,
    width: `calc(100% - ${ALERT_LIST_WIDTH}px)`,
    height: "100%",
    backgroundColor: "#6B728030",
    backdropFilter: "blur(1px)",
    zIndex: theme.zIndex.modal,
    marginLeft: ALERT_LIST_WIDTH,
  },
}));
