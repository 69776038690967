import { healthScoreRound } from "../../../../../_common/utils/healthScoreRound";
import { TableHealthScoreStatsDataRowsQuery } from "../../../../../graphql";
import { DataGridReportValue } from "../../../account-overview/types";
import { PROJECT_NAME_COLUMN_KEY } from "../HealthScoreTable";
import { isHealthScoreTrendsTableRow } from "./isHealthScoreTrendsTableRow";

interface RowData {
  id: number;
  projectId: string;
  [PROJECT_NAME_COLUMN_KEY]: string;
  lastFinishedCrawlId: string | null;
  primaryDomain: string;
  categories: RowDataReports | null;
  segmentLastGeneratedAt?: string | null;
}
type RowDataReports = Record<string, DataGridReportValue>;

export function transformTableDataToGridRows(
  data: TableHealthScoreStatsDataRowsQuery | null,
): RowData[] {
  const rawViews = data?.getCustomTable?.table.nodes || [];
  const ret = rawViews.map((rawView, viewIndex) => {
    const projectDetails = {
      id: viewIndex,
      projectId: rawView.customDashboardView.project?.id,
      segmentId: rawView.customDashboardView.segment?.id,
      segmentLastGeneratedAt:
        rawView.customDashboardView.segment?.lastGeneratedAt,
      [PROJECT_NAME_COLUMN_KEY]: rawView.customDashboardView.projectName ?? "",
      lastFinishedCrawlId:
        rawView.customDashboardView.project?.lastFinishedCrawl?.id ?? null,
      primaryDomain: rawView.customDashboardView.projectPrimaryDomain,
      segmentName: rawView.customDashboardView.segmentName ?? undefined,
    };

    const columns = isHealthScoreTrendsTableRow(rawView.row)
      ? rawView.row.columns
      : [];
    const flattenedCategories = columns.reduce((acc, column) => {
      const { points: trendData, reportCategoryCode } = column;
      // eslint-disable-next-line fp/no-mutating-methods
      const healtscoreTrend = (
        trendData
          ?.filter((hs) => Boolean(hs.createdAt))
          .map((healthscore) => ({
            date: new Date(healthscore.createdAt ?? 0),
            count: healthScoreRound(healthscore.healthScore * 100),
          })) ?? []
      ).sort((a, b) => b.date.getTime() - a.date.getTime());
      return {
        ...acc,
        [reportCategoryCode]: {
          trends: healtscoreTrend ?? [],
          totalSign: 0,
          total: healtscoreTrend?.[0]?.count ?? 0,
        },
      };
    }, {});
    return {
      ...projectDetails,
      categories: flattenedCategories,
    };
  });
  return ret;
}
