import {
  useNumberFormatter,
  TriangleDownIcon,
  TriangleUpIcon,
  Typography,
  TypographyProps,
} from "@lumar/shared";
import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 13,
    height: 13,
  },
  text: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[500],
  },
}));

interface Props {
  newValue: number;
  previousValue: number;
  /** Whether an increase is good, bad or neutral. 1 means good, 0 means neutral and -1 means bad. */
  sign: number;
  typographyProps?: TypographyProps;
}

export function PercentageChangeWithTriangle({
  newValue,
  previousValue,
  sign,
  typographyProps,
}: Props): JSX.Element {
  const formatNumber = useNumberFormatter();
  const { palette } = useTheme();
  const classes = useStyles();

  const absoluteChange = newValue - previousValue;
  const percentage = absoluteChange ? absoluteChange / previousValue : 0;

  const changeSign = Math.sign(absoluteChange);
  // Similar to `sign`: 1 means good, 0 means neutral, -1 means bad
  const sentiment = sign * changeSign;

  const color = sentiment
    ? sentiment > 0
      ? palette.green[500]
      : palette.red[400]
    : palette.grey[400];

  const styles = {
    color,
  };

  return (
    <span className={classes.container}>
      {absoluteChange ? (
        absoluteChange > 0 ? (
          <TriangleUpIcon className={classes.icon} style={styles} />
        ) : (
          <TriangleDownIcon className={classes.icon} style={styles} />
        )
      ) : null}
      <Typography classes={{ root: classes.text }} {...typographyProps}>
        {formatNumber(Math.abs(percentage), {
          style: "percent",
          maximumFractionDigits: percentage >= 1 ? 0 : 1,
        })}
      </Typography>
    </span>
  );
}
