import {
  Dialog,
  TextField,
  DialogProps,
  Snackbar,
  getApiAccountId,
  useTranslation,
} from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useCloneDashboardCollectionMutation } from "../../graphql";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";
import {
  MAX_NAME_LENGTH,
  MIN_NAME_LENGTH,
} from "../dashboards/account-overview/name-dialog/DashboardNameDialog";

interface Props
  extends Pick<DialogProps, "anchorEl" | "onCloseViaIcon" | "open"> {
  onActionButtonClick: () => void;
  clone?: boolean;
}

export function CloneBoardDialog({
  onActionButtonClick,
  clone,
  ...rest
}: Props): JSX.Element {
  const [name, setName] = useState<string>("");
  const { t } = useTranslation(["dashboards", "boardHeader"]);
  const { enqueueSnackbar } = useSnackbar();
  const { dashboardPage } = useMonitorRoutes();
  const history = useHistory();

  const { accountId, collectionId } = useParams<{
    accountId: string;
    collectionId: string;
    dashboardId: string;
  }>();

  const [cloneCurrentDashboardCollection] = useCloneDashboardCollectionMutation(
    {
      refetchQueries: [
        "GetDashboardCollections",
        "GetDashboardCollectionsCount",
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        if (data) {
          const path = dashboardPage.getUrl({
            collectionId:
              data.copyCustomDashboardCollection.customDashboardCollection.id,
          });
          if (collectionId) history.push(path);
          else history.replace(path);

          enqueueSnackbar(
            <Snackbar
              variant="success"
              title={t("boardHeader:cloneSuccess")}
            />,
          );
        }
      },
      onError: (e) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("boardHeader:cloneError", { message: e.message })}
          />,
        );
      },
    },
  );

  function handleConfirmationClick(): void {
    onActionButtonClick();
    if (clone) {
      cloneCurrentDashboardCollection({
        variables: {
          accountId: getApiAccountId(accountId),
          collectionId,
          name,
        },
      });
    }
    setName("");
  }

  const isNameTooLong = name.length > MAX_NAME_LENGTH;

  return (
    <Dialog
      {...rest}
      anchorOrigin={{
        vertical: 45,
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      title={t("boardHeader:cloneDashboard")}
      withGreyFooter={true}
      buttons={[
        {
          variant: "outlined",
          color: "default",
          title: t("cancel"),
          onClick: () => {
            onActionButtonClick();
            setName("");
          },
          "data-pendo": "monitor-create-board-dialog-cancel",
        },
        {
          disabled: name.length < MIN_NAME_LENGTH || isNameTooLong,
          variant: "contained",
          title: t("boardHeader:cloneDashboard"),
          autoFocus: true,
          color: "primary",
          onClick: handleConfirmationClick,
          "data-pendo": "monitor-create-board-dialog-submit",
          "data-testid": "board-dialog-create-dashboard-button",
        },
      ]}
      pendoPrefix="monitor-create-dashboard"
    >
      <div style={{ width: "100%", marginBottom: 31, marginTop: 10 }}>
        <TextField
          label={t("name")}
          style={{ width: "450px" }}
          onChange={(event) => setName((event.target.value ?? "").trim())}
          onKeyUp={(e) => {
            if (
              !(name.length < MIN_NAME_LENGTH || isNameTooLong) &&
              e.key === "Enter"
            )
              handleConfirmationClick();
          }}
          autoFocus={true}
          inputProps={{
            "data-pendo": "monitor-create-board-dialog-name-input",
          }}
          error={isNameTooLong}
          data-testid="create-board-dialog-input"
          helperText={isNameTooLong ? t("nameTooLong") : undefined}
        />
      </div>
    </Dialog>
  );
}
