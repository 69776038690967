import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { useSession, useTranslation } from "@lumar/shared";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: theme.spacing(0, 1, 0, 1),
    },
    innerWrapper: {
      display: "inline-flex",
      width: "100%",
    },
    title: {
      flex: 1,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
);
interface ProjectNameColumnHeaderProps {
  empty?: boolean;
  "data-testid"?: string;
}

export function ProjectNameColumnHeaderText({
  empty,
  ...props
}: ProjectNameColumnHeaderProps): JSX.Element {
  const classes = useStyles();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { t } = useTranslation(["accountDashboard"]);
  return (
    <div className={classes.root} {...props}>
      <div className={classes.innerWrapper}>
        {empty ? undefined : (
          <>
            <Typography className={classes.title}>
              {t(
                segmentationAvailable
                  ? "projectNameColumnTitle"
                  : "projectNameColumnTitleNoSegments",
              )}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}
