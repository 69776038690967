import { Typography } from "@lumar/shared";
import { createStyles, makeStyles, useTheme } from "@material-ui/core";
import { useState } from "react";
import { CustomDashboardType } from "../../graphql";
import { DashboardLabel } from "./DashboardLabel";

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      position: "absolute",
      top: 0,
      display: "flex",
      left: 0,
      right: 0,
      justifyContent: "center",
    },
    labelMinWidth: {
      minWidth: 66,
    },
  }),
);
export const EDIT_FIELD_WIDTH = 155;

export function DashboardTabLabel({
  id,
  name,
  onEditStarted,
  onEditEnded,
  disabled,
  maxWidth,
  disableDelete,
  disableCloning,
  onClone,
  type,
}: {
  name: string;
  id: string;
  onEditStarted?: () => void;
  onEditEnded?: (name: string | null | undefined) => void;
  disabled?: boolean;
  maxWidth?: number | string;
  disableDelete?: boolean;
  disableCloning?: boolean;
  onClone?: (id: string) => void;
  type: CustomDashboardType;
}): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          position: "relative",
          minWidth: isEditing ? EDIT_FIELD_WIDTH : undefined,
          maxWidth: isEditing ? EDIT_FIELD_WIDTH : maxWidth,
        }}
        className={isEditing ? undefined : classes.labelMinWidth}
      >
        <Typography
          variant="captionMedium"
          style={{
            color: theme.palette.grey[700],
            visibility: "hidden",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            minWidth: 32,
            display: isEditing ? "none" : undefined,
            marginRight:
              type === CustomDashboardType.HealthScores
                ? 15 + theme.spacing(0.81)
                : undefined,
          }}
        >
          {name}
        </Typography>
        <DashboardLabel
          key={id}
          type={type}
          className={isEditing ? undefined : classes.label}
          name={name}
          id={id}
          disabled={disabled}
          disableDelete={disableDelete}
          disableCloning={disableCloning}
          onCloneAction={onClone}
          onEditStarted={() => {
            setIsEditing(true);
            onEditStarted?.();
          }}
          onEditEnded={(name) => {
            setIsEditing(false);
            onEditEnded?.(name);
          }}
        />
      </div>
    </>
  );
}
