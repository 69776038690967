import { ApolloError, NetworkStatus } from "@lumar/shared";
import React from "react";
import { useParams } from "react-router-dom";
import {
  GetHealthScoreNotificationsQuery,
  GetHealthScoreNotificationsQueryVariables,
  HealthScoreTestResultConnectionFilterInput,
  Severity,
  TestAutoThresholdAcceptance,
  ThresholdChangeType,
  useGetHealthScoreNotificationsQuery,
} from "../../graphql";
import { NotificationStatusFilter } from "../filters/constants";
import { SuggestedThresholdFilter } from "../filters/SuggestedThresholdTabs";
import { getFilterFromNotificationStatusFilter } from "./getFilterFromNotificationStatusFilter";

interface HealthScoreNotificationsQueryReturnType {
  data: GetHealthScoreNotificationsQuery | undefined;
  loading: boolean;
  reloading: boolean;
  hasFetchedAllData: boolean;
  error: ApolloError | undefined;
  handleLoadMore: () => void;
  queryVariables?: GetHealthScoreNotificationsQueryVariables;
}

interface HealthScoreNotificationsQueryInput {
  projectIds?: string[] | null;
  severity?: Severity | null;
  reportCategoryCodes?: string[] | null;
  count?: number;
  onCompleted?: (data: GetHealthScoreNotificationsQuery | undefined) => void;
  notificationStatus: NotificationStatusFilter;
  suggestedThresholdFilter?: SuggestedThresholdFilter;
  crawlIds: number[];
  skip?: boolean;
}

export function useHealthScoreNotificationsQuery(
  options: HealthScoreNotificationsQueryInput,
): HealthScoreNotificationsQueryReturnType {
  const { accountId } = useParams<{ accountId: string }>();

  const variables: GetHealthScoreNotificationsQueryVariables = {
    accountId,
    projectIds: options.projectIds ?? undefined,
    cursor: null,
    filter: getQueryFilter(options),
    count: options.count ? Math.max(options?.count, 10) : 10,
    upToDate:
      options.notificationStatus === NotificationStatusFilter.Adjustments ||
      options.suggestedThresholdFilter === "requiresApproval",
  };

  const {
    data,
    error,
    networkStatus,
    fetchMore,
    loading: isLoading,
  } = useGetHealthScoreNotificationsQuery({
    variables,
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    onCompleted: options.onCompleted,
    skip: options.skip,
  });
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const pageInfo = data?.getAccount?.notifications?.pageInfo;
  const loading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables;

  const hasFetchedAllData =
    !loading && !isFetchingMore && !Boolean(pageInfo?.hasNextPage);

  const handleLoadMore = React.useCallback(() => {
    const pageInfo = data?.getAccount?.notifications?.pageInfo;

    if (pageInfo?.hasNextPage && pageInfo.endCursor && !isFetchingMore) {
      fetchMore({
        variables: {
          cursor: pageInfo.endCursor,
          count: 10,
        },
      });
    }
  }, [fetchMore, data, isFetchingMore]);

  return {
    data,
    error,
    loading: loading,
    reloading: isLoading,
    hasFetchedAllData,
    handleLoadMore,
    queryVariables: variables,
  };
}

const getQueryFilter = (
  options: HealthScoreNotificationsQueryInput,
): HealthScoreTestResultConnectionFilterInput => {
  const showAdjustments =
    options.notificationStatus === NotificationStatusFilter.Adjustments;
  const showSuggestedThresholdsOnly =
    showAdjustments || options.suggestedThresholdFilter === "requiresApproval";

  return {
    passed: { eq: showAdjustments },
    severity: { eq: options.severity ?? undefined },
    reportCategoryCode: { in: options.reportCategoryCodes ?? undefined },
    status: getFilterFromNotificationStatusFilter(options.notificationStatus),
    thresholdChangeType: showSuggestedThresholdsOnly
      ? {
          eq: showAdjustments
            ? ThresholdChangeType.Better
            : ThresholdChangeType.Worse,
        }
      : undefined,
    suggestedAbsoluteThresholdAcceptedAt: showSuggestedThresholdsOnly
      ? { isNull: true }
      : undefined,
    suggestedAbsoluteThresholdRejectedAt: showSuggestedThresholdsOnly
      ? { isNull: true }
      : undefined,
    automaticThresholdAcceptanceWhenTestResultIsBetter: showAdjustments
      ? { eq: TestAutoThresholdAcceptance.Suggest }
      : undefined,
    suggestedAbsoluteThreshold: showSuggestedThresholdsOnly
      ? { isNull: false }
      : undefined,
    crawlId: showSuggestedThresholdsOnly ? { in: options.crawlIds } : undefined,
  };
};
