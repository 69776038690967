import { List, PropTypes, useTheme } from "@material-ui/core";
import { View } from "../../types";
import { ApolloError } from "@lumar/shared";
import { BoardSettingsListItem } from "./BoardSettingsListItem";
import { GetViewListFilter } from "../utils/filterViewList";

interface Props {
  data: View[];
  searchTerms?: GetViewListFilter;
  onChange?: (view: View) => void;
  button?: {
    icon?: React.ReactNode | null;
    text?: React.ReactNode;
    label?: string;
    color?: PropTypes.Color;
    variant?: "text" | "outlined" | "contained";
    className?: string;
    disabled?: boolean;
  };
  error?: ApolloError;
  "data-pendo"?: string;
  "data-testid"?: string;
  disabled?: boolean;
}

export function BoardSettingsList({
  data,
  searchTerms,
  onChange,
  button,
  disabled,
  ...props
}: Props): JSX.Element {
  const theme = useTheme();

  return (
    <List
      component="nav"
      style={{
        flex: 1,
        overflowX: "hidden",
        overflowY: "auto",
        marginTop: theme.spacing(4.5),
      }}
      data-testid={
        props["data-testid"] ? `${props["data-testid"]}-list` : undefined
      }
    >
      {data.map((node, index) => {
        const key = node.id ?? `${node.projectId}_ ${node.segmentId ?? "none"}`;
        return (
          <BoardSettingsListItem
            disabled={disabled}
            key={key}
            index={index}
            view={node}
            button={button}
            onChange={onChange}
            searchTerms={searchTerms}
            data-testid={
              props["data-testid"] ? `${props["data-testid"]}-list` : undefined
            }
            data-pendo={
              props["data-pendo"] ? `${props["data-pendo"]}-list` : undefined
            }
          />
        );
      })}
    </List>
  );
}
