import { entries } from "lodash";
import { Template } from "./templates";
import {
  CreateCustomChartForCustomDashboardInput,
  CreateCustomTableForCustomDashboardInput,
  CustomChartType,
  CustomTableType,
  OrderDirection,
  ProjectOrderField,
} from "../../../../graphql";

export function getCodeValuesInArray(
  values: Record<string, boolean>,
): string[] {
  return entries(values)
    .filter((x) => x[1] === true)
    .map((x) => x[0]);
}

export function getCharts(
  dashboardCode: string,
  templates: Template[],
  viewIds: string[],
): CreateCustomChartForCustomDashboardInput {
  const template = templates.find((t) => t.code === dashboardCode);
  return {
    customViewIds: viewIds,
    type: template?.isHealthScore
      ? CustomChartType.HealthScoreCrawlCompare
      : CustomChartType.MonitorCrawlCompare,
    metric: template?.graphCode ?? template?.subCodes?.[0] ?? "",
    metadata: { trendRange: "last_30_days" },
  };
}

export function getDashboardName(
  dashboardCode: string,
  templates: Template[],
): string {
  const template = templates.find((t) => t.code === dashboardCode);
  return template?.name ?? "";
}

export function getTables(
  dashboardCode: string,
  templates: Template[],
): CreateCustomTableForCustomDashboardInput {
  const orderBy = {
    field: ProjectOrderField.FinishedAt,
    direction: OrderDirection.Desc,
  };
  const template = templates.find((t) => t.code === dashboardCode);
  const type = template?.isHealthScore
    ? CustomTableType.HealthScores
    : CustomTableType.MonitorProjects;
  return {
    type,
    filter: {},
    columns: template?.subCodes ?? [],
    orderBy: [orderBy],
  };
}
