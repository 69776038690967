import {
  Button,
  Typography,
  InformationCircleOutlined,
  Chip,
  XSmallIcon,
  SwitchWithIcon,
  useTranslation,
  useSession,
} from "@lumar/shared";
import {
  CircularProgress,
  FormControlLabel,
  makeStyles,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { SettingsFilters } from "../../components/SettingsFilters";
import { ViewsPanelProps } from "./AllViewsPanel";
import { BoardSettingsList } from "./BoardSettingsList";
import { differenceBy } from "lodash";

export const TITLE_HEIGHT = 63;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(18),
  },
  viewsContainer: {
    paddingLeft: theme.spacing(5.375),
    paddingTop: theme.spacing(4.375),
    flex: 1,
    display: "flex",
    flexFlow: "column",
    height: "100%",
    maxWidth: "50%",
  },
  flex: {
    flex: 1,
  },
  button: {
    paddingTop: 6.5,
    paddingBottom: 6.5,
    height: 32,
  },
  listButton: {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 32,
  },
  iconRoot: {
    margin: theme.spacing(0.5, 0.375, 0.375, 0.875),
    color: theme.palette.grey["400"],
    fontSize: theme.typography.pxToRem(18),
    verticalAlign: "middle",
  },
  chip: {
    height: 18,
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[900],
    alignSelf: "center",
    borderRadius: 27,
    marginLeft: theme.spacing(1),
    "& .MuiChip-label": {
      paddingLeft: 9,
      paddingRight: 9,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(13),
    },
  },
  switch: {
    height: 20,
    width: 40,
    "& svg": {
      fontSize: 21,
    },
  },
  switchFormControl: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

export function SelectedViewsPanel({
  loading,
  filteredViewList,
  shouldShowSegments,
  segmentList,
  filters,
  viewList,
  error,
  onFilterChange,
  onViewListChange,
}: ViewsPanelProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { t } = useTranslation(["board", "common"]);

  return (
    <div className={classes.viewsContainer}>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexFlow: "row" }}>
          <Typography variant="h6SemiBold" className={classes.title}>
            {t("selectedViews")}
          </Typography>
          <Chip
            label={
              filteredViewList.length ? filteredViewList.length : t("none")
            }
            color="lightgrey"
            className={classes.chip}
          />
          <Tooltip
            title={
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  gap: 3,
                }}
              >
                <Typography variant="subtitle3SemiBold">
                  {t("selectedTooltip_title")}
                </Typography>
                <Typography variant="caption">
                  {t("selectedTooltip")}
                </Typography>
              </div>
            }
            arrow
            data-pendo="monitor-dashboard-table-title-tooltip"
          >
            <InformationCircleOutlined
              classes={{
                root: classes.iconRoot,
              }}
            />
          </Tooltip>
        </div>
        {segmentationAvailable && (
          <FormControlLabel
            className={classes.switchFormControl}
            control={
              <SwitchWithIcon
                className={classes.switch}
                checked={shouldShowSegments}
                onChange={() => {
                  if (!shouldShowSegments) {
                    onFilterChange?.({});
                  } else {
                    onFilterChange?.({
                      segmentNameSearchTherm: null,
                    });
                  }
                }}
                data-pendo="monitor-selected-show-hide-segments"
                data-testid="monitor-selected-show-hide-segments"
              />
            }
            label="Segments"
          />
        )}
      </div>
      <Typography variant="body2" style={{ color: theme.palette.grey[500] }}>
        {t("selectedDesc")}
      </Typography>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          gap: theme.spacing(1.5),
          marginTop: theme.spacing(1.5),
          flexWrap: "wrap",
        }}
      >
        <SettingsFilters
          disabled={loading}
          segments={segmentList ?? []}
          searchTerms={filters}
          onChange={(value) => {
            onFilterChange?.(value);
          }}
          shouldShowSegmentsFilter={!shouldShowSegments}
          data-pendo="monitor-board-settings-selected"
          data-testid="monitor-board-settings-selected"
        />
        <Button
          className={classes.button}
          variant="outlined"
          onClick={() => {
            onViewListChange?.(
              differenceBy(
                viewList,
                filteredViewList,
                (e) => `${e.projectId}--${e.segmentId}`,
              ),
            );
          }}
          data-pendo="monitor-board-settings-remove-all"
          data-testid="monitor-board-settings-remove-all"
        >
          {t("removeAll")}
        </Button>
      </div>
      {loading ? (
        <div style={{ flex: 1, display: "flex" }}>
          <CircularProgress
            color="primary"
            style={{
              width: 18,
              height: 18,
              margin: "auto",
            }}
          />
        </div>
      ) : (
        <BoardSettingsList
          error={error}
          button={{
            icon: null,
            text: <XSmallIcon htmlColor={theme.palette.grey[700]} />,
            variant: "outlined",
            className: classes.listButton,
            label: t("removeLabel"),
          }}
          data={filteredViewList}
          data-pendo="monitor-board-settings-selected"
          data-testid="monitor-board-settings-selected"
          onChange={(view) => {
            const index = viewList.findIndex((e) => e.id === view.id);
            onViewListChange?.([
              ...viewList.slice(0, index),
              ...viewList.slice(index + 1),
            ]);
          }}
        />
      )}
    </div>
  );
}
