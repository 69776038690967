import {
  Button,
  Typography,
  InformationCircleOutlined,
  Chip,
  SwitchWithIcon,
  Snackbar,
  Trans,
  useTranslation,
  ApolloError,
  useSession,
} from "@lumar/shared";
import {
  CircularProgress,
  FormControlLabel,
  makeStyles,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { unionBy } from "lodash";
import { GetViewListFilter } from "../utils/filterViewList";
import { View } from "../../types";
import { SettingsFilters } from "../../components/SettingsFilters";
import { BoardSettingsList } from "./BoardSettingsList";
import { useSnackbar } from "notistack";
import { AbsoluteURLs } from "../../../../_common/routing/absoluteURLs";
import { useParams } from "react-router-dom";
import { AccountViewsTooltip } from "./AccountViewsTooltip";

export const TITLE_HEIGHT = 63;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(18),
  },
  viewsContainer: {
    paddingLeft: theme.spacing(5.375),
    paddingTop: theme.spacing(4.375),
    flex: 1,
    display: "flex",
    flexFlow: "column",
    height: "100%",
    maxWidth: "50%",
  },
  flex: {
    flex: 1,
  },
  button: {
    paddingTop: 6.5,
    paddingBottom: 6.5,
    height: 32,
  },
  iconRoot: {
    margin: theme.spacing(0.5, 0.375, 0.375, 0.875),
    color: theme.palette.grey["400"],
    fontSize: theme.typography.pxToRem(18),
    verticalAlign: "middle",
  },
  chip: {
    height: 18,
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[900],
    alignSelf: "center",
    borderRadius: 27,
    marginLeft: theme.spacing(1),
    "& .MuiChip-label": {
      paddingLeft: 9,
      paddingRight: 9,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(13),
    },
  },
  switch: {
    height: 20,
    width: 40,
    "& svg": {
      fontSize: 21,
    },
  },
  switchFormControl: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

export interface ViewsPanelProps {
  loading?: boolean;
  disabled?: boolean;
  filteredViewList: View[];
  viewList: View[];
  segmentList?: string[];
  filters?: GetViewListFilter;
  error?: ApolloError;
  shouldShowSegments?: boolean;
  onFilterChange?: (f: GetViewListFilter) => void;
  onViewListChange?: (views: View[]) => void;
  limit?: number;
}

export function AllViewsPanel({
  loading,
  filteredViewList,
  viewList,
  segmentList,
  filters,
  shouldShowSegments,
  error,
  onFilterChange,
  onViewListChange,
  limit = Number.MAX_SAFE_INTEGER,
  disabled,
}: ViewsPanelProps): JSX.Element {
  const classes = useStyles();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const theme = useTheme();
  const { t } = useTranslation(["board", "common"]);
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = useParams<{ accountId: string }>();
  return (
    <div className={classes.viewsContainer}>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexFlow: "row" }}>
          <Typography variant="h6SemiBold" className={classes.title}>
            {t("available")}
          </Typography>
          <Chip
            label={filteredViewList?.length ?? 0}
            color="lightgrey"
            className={classes.chip}
          />
          <Tooltip
            title={
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  gap: 3,
                }}
              >
                <Typography variant="subtitle3SemiBold">
                  {t("availableTooltip_title")}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    gap: theme.spacing(2),
                  }}
                >
                  <Typography variant="caption">
                    {t("availableTooltip_part1")}
                  </Typography>
                  <Typography variant="caption">
                    {t("availableTooltip_part2")}
                  </Typography>
                  <Typography variant="caption">
                    {t(
                      segmentationAvailable
                        ? "availableTooltip_part3"
                        : "availableTooltip_part3_noSegment",
                    )}
                  </Typography>
                </div>
              </div>
            }
            arrow
            data-pendo="monitor-dashboard-table-title-tooltip"
          >
            <InformationCircleOutlined
              classes={{
                root: classes.iconRoot,
              }}
            />
          </Tooltip>
        </div>
        {segmentationAvailable && (
          <FormControlLabel
            className={classes.switchFormControl}
            control={
              <SwitchWithIcon
                className={classes.switch}
                checked={shouldShowSegments}
                onChange={() => {
                  if (!shouldShowSegments) {
                    onFilterChange?.({});
                  } else {
                    onFilterChange?.({
                      segmentNameSearchTherm: null,
                    });
                  }
                }}
                data-pendo="monitor-available-show-hide-segments"
                data-testid="monitor-available-show-hide-segments"
              />
            }
            label="Segments"
          />
        )}
      </div>
      <Typography variant="body2" style={{ color: theme.palette.grey[500] }}>
        {t(segmentationAvailable ? "availableDesc" : "availableDesc_noSegment")}
      </Typography>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          gap: theme.spacing(1.5),
          marginTop: theme.spacing(1.5),
          flexWrap: "wrap",
        }}
      >
        <SettingsFilters
          disabled={loading}
          segments={segmentList ?? []}
          searchTerms={filters}
          onChange={(value) => {
            onFilterChange?.(value);
          }}
          shouldShowSegmentsFilter={!shouldShowSegments}
          data-pendo="monitor-board-settings-available"
          data-testid="monitor-board-settings-available"
        />
        <AccountViewsTooltip
          arrow={false}
          leaveDelay={1000}
          show={Boolean(disabled) ? "limit" : null}
        >
          <div>
            <Button
              disabled={disabled}
              className={classes.button}
              variant="outlined"
              onClick={() => {
                const elements = unionBy(
                  viewList,
                  filteredViewList,
                  (e) => `${e.projectId}--${e.segmentId}`,
                );
                const error = limit < elements.length;
                onViewListChange?.(elements.slice(0, limit));
                if (error)
                  enqueueSnackbar(
                    <Snackbar
                      variant="error"
                      title={
                        <Trans
                          ns="board"
                          i18nKey={"viewLimitMessage"}
                          components={{
                            tlink: (
                              <a
                                href={AbsoluteURLs.EXTERNAL__AccountsSubscription.getUrl(
                                  accountId,
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                              />
                            ),
                          }}
                        />
                      }
                    />,
                  );
              }}
              data-pendo="monitor-board-settings-add-all"
              data-testid="monitor-board-settings-add-all"
            >
              {t("addAll")}
            </Button>
          </div>
        </AccountViewsTooltip>
      </div>
      {loading ? (
        <div style={{ flex: 1, display: "flex" }}>
          <CircularProgress
            color="primary"
            style={{
              width: 18,
              height: 18,
              margin: "auto",
            }}
          />
        </div>
      ) : (
        <BoardSettingsList
          disabled={disabled}
          error={error}
          data={filteredViewList}
          onChange={(view) => onViewListChange?.([...viewList, view])}
          data-pendo="monitor-board-settings-available"
          data-testid="monitor-board-settings-available"
        />
      )}
    </div>
  );
}
