import { Snackbar, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import {
  Severity,
  TestResultStatus,
  useUpdateBulkReportNotificationsStatusMutation,
  useReportNotificationsBulkAcceptSuggestedThresholdsMutation,
  useReportNotificationsBulkDeclineSuggestedThresholdsMutation,
  GetReportNotificationsQueryVariables,
} from "../../graphql";
import {
  emptyNotificationSelection,
  NotificationSelectionType,
} from "../utils/constants";
import { getFilterFromNotificationStatusFilter } from "../utils/getFilterFromNotificationStatusFilter";
import { useParams } from "react-router-dom";
import {
  MonitorNotification,
  NotificationSelection,
  ReportBulkAcceptDeclineVariables,
} from "../types";
import { NotificationStatusFilter } from "../filters/constants";
import { ProjectOption, ReportOption } from "../../_common/utils/constants";
import { SuggestedThresholdFilter } from "../filters/SuggestedThresholdTabs";
import { ThresholdStatus } from "./BulkUpdateThresholdMenu";
import { updateReportNotificationsInCache } from "../utils/updateReportNotificationsInCache";
import { useClearReportNotificationCache } from "../utils/useClearNotificationCache";
import {
  NotificationStatusUpdate,
  useFilterAndSelectionText,
} from "./NotificationStatusUpdate";

export interface ReportNotificationStatusUpdateProps {
  totalCount: number;
  notifications?: MonitorNotification[];
  loading?: boolean;
  projects: ProjectOption[];
  reports: ReportOption[];
  severity: Severity | null;
  status: NotificationStatusFilter;
  hasFiltersApplied: boolean;
  selection: NotificationSelection;
  setSelection: (selection: NotificationSelection) => void;
  suggestedThresholdFilter: SuggestedThresholdFilter;
  showUpdateThresholdMenu?: boolean;
  queryVariables?: GetReportNotificationsQueryVariables;
}

export function ReportNotificationStatusUpdate(
  props: ReportNotificationStatusUpdateProps,
): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation(["alerts", "notifications", "errors"]);
  const { enqueueSnackbar } = useSnackbar();
  const clearCache = useClearReportNotificationCache();

  const isAdjustmentsTabSelected =
    props.status === NotificationStatusFilter.Adjustments;

  const [bulkUpdateNotificationsStatus] =
    useUpdateBulkReportNotificationsStatusMutation({
      refetchQueries: "active",
      awaitRefetchQueries: true,
      onError: (error) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("notifications:failedToUpdateStatus", {
              message: error.message,
            })}
          />,
        );
      },
    });

  const [bulkAcceptSugestedThreshold] =
    useReportNotificationsBulkAcceptSuggestedThresholdsMutation({
      refetchQueries: [
        "ReportNotificationsRequiresManualApprovalCount",
        "ReportNotificationTotalCounts",
      ],
      awaitRefetchQueries: true,
      onError: (error) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("errors:thresholdUpdate", {
              message: error.message,
            })}
          />,
        );
      },
    });

  const [bulkDeclineSugestedThreshold] =
    useReportNotificationsBulkDeclineSuggestedThresholdsMutation({
      refetchQueries: [
        "ReportNotificationsRequiresManualApprovalCount",
        "ReportNotificationTotalCounts",
      ],
      awaitRefetchQueries: true,
      onError: (error) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("errors:thresholdUpdate", {
              message: error.message,
            })}
          />,
        );
      },
    });

  function getMessageByStatus(status: TestResultStatus, count: number): string {
    switch (status) {
      case TestResultStatus.Read:
        return t("notifications:movedToArchived", { count: count }) as string;
      case TestResultStatus.WorkingOnIt:
        return t("notifications:movedToWorkingOnIt", {
          count: count,
        }) as string;
      case TestResultStatus.Unresolved:
        return t("notifications:statusRemoved", { count: count }) as string;
    }
    return t("notifications:movedToUnread", { count: count }) as string;
  }

  const handleAcceptanceChange = async (
    status: ThresholdStatus,
  ): Promise<void> => {
    if (status === ThresholdStatus.None) return;

    const variables: ReportBulkAcceptDeclineVariables = {
      passed: isAdjustmentsTabSelected,
      accountId: accountId,
      severity: props.severity,
      projectIds: props.projects.length
        ? props.projects.map((p) => p.id)
        : undefined,
      reportTemplateCodes: props.reports.length
        ? props.reports.map((r) => r.code)
        : undefined,
      statusFilter: getFilterFromNotificationStatusFilter(props.status),
      testResultIds: props.selection.selected.length
        ? props.selection.selected.map((e) => e.id)
        : undefined,
      blackListed:
        props.selection.state === NotificationSelectionType.SelectAll,
    };
    const result =
      status === ThresholdStatus.Accept
        ? await bulkAcceptSugestedThreshold({
            variables,
            update: (cache, { data, errors }) => {
              if (
                !isAdjustmentsTabSelected &&
                props.queryVariables &&
                data &&
                !errors
              )
                updateReportNotificationsInCache(
                  cache,
                  props.queryVariables,
                  (cacheNotification) => {
                    const notification = props.selection.selected.find(
                      (e) => e.id === cacheNotification.id,
                    );
                    const existsInList = Boolean(notification);
                    if (
                      (props.selection.state ===
                        NotificationSelectionType.SelectAll &&
                        !existsInList) ||
                      (props.selection.state !==
                        NotificationSelectionType.SelectAll &&
                        existsInList)
                    )
                      return {
                        suggestedAbsoluteThresholdAcceptedAt:
                          new Date().toISOString(),
                        test: {
                          ...cacheNotification.test,
                          absoluteThreshold:
                            cacheNotification.suggestedAbsoluteThreshold
                              ? cacheNotification.suggestedAbsoluteThreshold
                              : notification?.test?.absoluteThreshold,
                        },
                      };
                    return {};
                  },
                );
              else if (isAdjustmentsTabSelected) clearCache();
            },
          })
        : await bulkDeclineSugestedThreshold({
            variables,
            update: (cache, { data, errors }) => {
              if (
                !isAdjustmentsTabSelected &&
                props.queryVariables &&
                data &&
                !errors
              )
                updateReportNotificationsInCache(
                  cache,
                  props.queryVariables,
                  (notification) => {
                    const existsInList = Boolean(
                      props.selection.selected.find(
                        (e) => e.id === notification.id,
                      ),
                    );
                    if (
                      (props.selection.state ===
                        NotificationSelectionType.SelectAll &&
                        !existsInList) ||
                      (props.selection.state !==
                        NotificationSelectionType.SelectAll &&
                        existsInList)
                    )
                      return {
                        suggestedAbsoluteThresholdRejectedAt:
                          new Date().toISOString(),
                      };
                    return {};
                  },
                );
              else if (isAdjustmentsTabSelected) clearCache();
            },
          });
    if (!Boolean(result.errors)) {
      props.setSelection(emptyNotificationSelection);
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t(
            status === ThresholdStatus.Accept
              ? "notifications:succesAccept"
              : "notifications:succesDecline",
          )}
        />,
      );
    }
  };

  const requiresManualApproval =
    props.suggestedThresholdFilter === "requiresApproval";

  const { count } = useFilterAndSelectionText({
    isLoading: props.loading,
    hasFiltersApplied: props.hasFiltersApplied,
    notificationSelection: props.selection,
    notifications: props.notifications,
    requiresManualApproval,
    totalCount: props.totalCount,
  });

  const handleStatusChange = async (
    status: TestResultStatus,
  ): Promise<void> => {
    const selectionCount = count;
    const result = await bulkUpdateNotificationsStatus({
      variables: {
        accountId: accountId,
        severity: props.severity,
        projectIds: props.projects.length
          ? props.projects.map((p) => p.id)
          : undefined,
        reportTemplateCodes: props.reports.length
          ? props.reports.map((r) => r.code)
          : undefined,
        statusFilter: getFilterFromNotificationStatusFilter(props.status),
        testResultIds: props.selection.selected.length
          ? props.selection.selected.map((e) => e.id)
          : undefined,
        blackListed:
          props.selection.state === NotificationSelectionType.SelectAll,
        toStatus: status,
      },
    });
    if (!Boolean(result.errors)) {
      props.setSelection(emptyNotificationSelection);
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={getMessageByStatus(status, selectionCount)}
        />,
      );
    }
  };

  return (
    <NotificationStatusUpdate
      {...props}
      handleAcceptanceChange={handleAcceptanceChange}
      handleStatusChange={handleStatusChange}
      handleRemoveAllStatuses={async () => {
        const selectionCount = count;
        const result = await bulkUpdateNotificationsStatus({
          variables: {
            accountId: accountId,
            severity: props.severity,
            projectIds: props.projects.length
              ? props.projects.map((p) => p.id)
              : undefined,
            reportTemplateCodes: props.reports.length
              ? props.reports.map((r) => r.code)
              : undefined,
            statusFilter: getFilterFromNotificationStatusFilter(props.status),
            testResultIds: props.selection.selected.length
              ? props.selection.selected.map((e) => e.id)
              : undefined,
            blackListed:
              props.selection.state === NotificationSelectionType.SelectAll,
            toStatus: TestResultStatus.Unresolved,
          },
        });
        if (!Boolean(result.errors)) {
          props.setSelection(emptyNotificationSelection);
          enqueueSnackbar(
            <Snackbar
              variant="success"
              title={getMessageByStatus(
                TestResultStatus.Unresolved,
                selectionCount,
              )}
            />,
          );
        }
      }}
    />
  );
}
