import { Typography } from "@lumar/shared";
import {
  InputAdornment,
  InputProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core";
import {
  TextField as FormikTextField,
  TextFieldProps as FormikTextFieldProps,
} from "formik-material-ui";
import { useState } from "react";

type TextFieldWithUnitProps = MuiTextFieldProps & {
  unit: string;
};
type TextFieldWithUnitFieldProps = FormikTextFieldProps & {
  unit: string;
};

function getInputProps(unit: string, showUnit: boolean): Partial<InputProps> {
  return showUnit
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <Typography
              variant="body2"
              style={{
                pointerEvents: "none",
                marginLeft: -8,
                marginRight: 4,
              }}
            >
              {unit}
            </Typography>
          </InputAdornment>
        ),
      }
    : {};
}

export function TextFieldWithUnit({
  unit,
  ...props
}: TextFieldWithUnitProps): JSX.Element {
  const [showUnit, setShowUnit] = useState<boolean>(true);
  return (
    <MuiTextField
      {...props}
      InputProps={getInputProps(unit, showUnit)}
      onFocus={(event) => {
        setShowUnit(false);
        props.onFocus?.(event);
      }}
      onBlur={(event) => {
        setShowUnit(true);
        props.onBlur?.(event);
      }}
    />
  );
}

export function TextFieldWithUnitField({
  unit,
  ...props
}: TextFieldWithUnitFieldProps): JSX.Element {
  const [showUnit, setShowUnit] = useState<boolean>(true);
  return (
    <FormikTextField
      {...props}
      InputProps={getInputProps(unit, showUnit)}
      onFocus={(event) => {
        setShowUnit(false);
        props.onFocus?.(event);
      }}
      onBlur={(event) => {
        setShowUnit(true);
        props.onBlur?.(event);
      }}
    />
  );
}
