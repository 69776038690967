import { SvgIcon, makeStyles, ListItem } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { BoardListItem } from "./BoardListItem";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useSession } from "@lumar/shared";
import { RoleCode } from "../../graphql";
import { useState } from "react";

export interface MonitorSideBarCategory {
  id: string;
  position: number;
  name: string;
  icon?: typeof SvgIcon;
  countBadgeValue?: React.ComponentType;
  href: string;
  tooltipText?: string;
  ["data-pendo"]?: string;
  "data-testid"?: string;
}

const useStyles = makeStyles((theme) => ({
  parentHighlight: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.ultraviolet[100],
  },
  boardItem: {
    padding: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "transparent",
    "&:focus": {
      boxShadow: "unset!important",
      border: 0,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export function SideBarListItem(
  props: MonitorSideBarCategory & { styleName?: string },
): JSX.Element {
  const { pathname } = useLocation();
  const classes = useStyles();
  const session = useSession();
  const hasSufficientRole = session.hasSufficientRole(RoleCode.Editor);

  const [isEditingBoardName, setIsEditingBoardName] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props.id,
    disabled: !hasSufficientRole || isEditingBoardName,
  });

  const isElementSelected = (pathname + "/").startsWith(props.href + "/");

  return (
    <ListItem
      id={props.id}
      ref={setNodeRef}
      key={props.id}
      {...attributes}
      {...listeners}
      autoFocus={isElementSelected}
      data-selected={isElementSelected ? true : undefined}
      className={clsx({
        [props.styleName ?? classes.boardItem]: true,
        [classes.parentHighlight]: isElementSelected,
      })}
      data-pendo={props["data-pendo"]}
      data-testid={props["data-testid"]}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1 : undefined,
      }}
      tabIndex={-1}
    >
      <BoardListItem
        isEditingName={isEditingBoardName}
        setEditingName={(edit) => setIsEditingBoardName(edit)}
        id={props.id}
        originalName={props.name}
        icon={props.icon}
        highlighted={isDragging ? false : isElementSelected}
        href={props.href}
        isSelected={isDragging ? false : isElementSelected}
      />
    </ListItem>
  );
}
