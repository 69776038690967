import { NotificationSelection } from "../types";

export enum NotificationSelectionType {
  SelectNone,
  SelectVisible,
  SelectAll,
}

export const emptyNotificationSelection: NotificationSelection = {
  state: NotificationSelectionType.SelectNone,
  selected: [],
};

export const allNotificationSelection: NotificationSelection = {
  state: NotificationSelectionType.SelectAll,
  selected: [],
};
