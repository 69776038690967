import { useEffect, useMemo, useState } from "react";
import { Segment, useGetProjectSegmentsQuery } from "../../graphql";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import {
  CircularProgress,
  TextField,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useDebounce } from "../hooks/useDebounce";
import { useTranslation, Check, Typography } from "@lumar/shared";
import clsx from "clsx";
import { LeftBottomPopper } from "./CustomPopper/LeftBottomPopper";

type SegmentOption = Pick<Segment, "id" | "name" | "group" | "createdAt">;

export interface SegmentComboBoxProps {
  projectId: string;
  name?: string;
  error?: boolean;
  helperText?: string;
  ["data-pendo"]?: string;
  selected?: SegmentOption;
  onChange?: (selected: SegmentOption | undefined) => void;
  size?: "small" | "medium";
  classes?: {
    input?: string;
    root?: string;
    paper?: string;
  };
  className?: string;
}

export function SegmentComboBox({
  projectId,
  name,
  error,
  helperText,
  selected,
  onChange,
  classes: outerClasses,
  ...props
}: SegmentComboBoxProps): JSX.Element {
  const { t } = useTranslation(["common"]);
  const [filterText, setFilterText] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const debouncedProjectInputValue = useDebounce(filterText);
  const classes = useStyles();
  const allURLs: SegmentOption = useMemo(
    () => ({
      id: undefined,
      name: t("noSegments"),
      group: "",
      createdAt: new Date().toISOString(),
    }),
    [t],
  );

  const {
    data,
    loading: queryLoading,
    error: queryError,
    fetchMore,
  } = useGetProjectSegmentsQuery({
    variables: {
      projectId,
      filter: debouncedProjectInputValue?.length
        ? { name: { contains: debouncedProjectInputValue } }
        : undefined,
    },
    fetchPolicy: "cache-first",
  });

  const loading =
    queryLoading || data?.getProject?.segments?.pageInfo.hasNextPage;

  useEffect(() => {
    if (
      data?.getProject?.segments?.pageInfo.hasNextPage &&
      !queryError &&
      !queryLoading
    )
      fetchMore({
        variables: {
          projectId,
          filter: debouncedProjectInputValue?.length
            ? { name: { contains: debouncedProjectInputValue } }
            : undefined,
          cursor: data?.getProject?.segments?.pageInfo.endCursor,
        },
      });
  });

  const value = selected ?? allURLs;
  const isSelectedInList = !value.id
    ? true
    : Boolean(
        (data?.getProject?.segments?.edges ?? []).find(
          ({ node: e }) => e.id === value.id,
        ),
      );

  const fullSegmentList = useMemo(
    () =>
      // eslint-disable-next-line fp/no-mutating-methods
      [
        ...[allURLs],
        ...(isSelectedInList ? [] : [value]),
        ...(data?.getProject?.segments?.edges.map(({ node }) => node) ?? []),
      ].sort(sortSegments),
    [allURLs, data?.getProject?.segments?.edges, isSelectedInList, value],
  );

  return (
    <Autocomplete
      {...props}
      data-testid="project-segment-combobox"
      options={fullSegmentList}
      inputValue={filterText}
      value={value}
      fullWidth
      size="small"
      onOpen={() => {
        setIsOpen(true);
      }}
      onChange={(_, value) => {
        if (value?.id) onChange?.(value);
        else onChange?.(undefined);
      }}
      onClose={(e, r) => {
        if (isOpen && r === "toggleInput" && e.type === "mousedown") return;
        setIsOpen(false);
      }}
      onInputChange={(event, newInputValue) => {
        if (!event) return;
        if (event.type === "change") setFilterText(newInputValue);
      }}
      onBlur={() => {
        if (filterText !== "") setFilterText("");
      }}
      renderOption={(option, state) => (
        <div
          style={{
            display: "flex",
            width: `calc(100% - ${theme.spacing(2)}px)`,
            marginLeft: theme.spacing(1),
          }}
        >
          <Typography
            style={{
              flex: 1,
              maxWidth: `100%`,
              textOverflow: "ellipsis",
              overflowX: "hidden",
            }}
          >
            {option.name}
          </Typography>
          {state.selected ? (
            <Check
              style={{ width: 15, height: 21, strokeWidth: 4 }}
              htmlColor={theme.palette.primary.main}
            />
          ) : undefined}
        </div>
      )}
      groupBy={(option) => option.group ?? "Default Group"}
      renderGroup={(group) => (
        <li key={group.key}>
          {Boolean(group.group.length) && (
            <div
              style={{
                display: "flex",
                width: "100%",
                backgroundColor: theme.palette.grey[100],
                height: 38,
                paddingLeft: theme.spacing(2),
              }}
            >
              <Typography
                variant="subtitle3SemiBold"
                style={{
                  flex: 1,
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflowX: "hidden",
                  marginTop: theme.spacing(1),
                }}
              >
                {group.group}
              </Typography>
            </div>
          )}
          <ul className={classes.list}>{group.children}</ul>
        </li>
      )}
      classes={{
        root: outerClasses?.root,
        paper: clsx(classes.paper, outerClasses?.paper),
        listbox: classes.listbox,
      }}
      PopperComponent={LeftBottomPopper}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
      renderInput={(
        params: Omit<AutocompleteRenderInputParams, "className">,
      ) => (
        <TextField
          {...params}
          variant="outlined"
          name={name}
          helperText={helperText}
          error={error}
          InputProps={{
            ...params.InputProps,
            className: clsx(outerClasses?.input, params.InputProps.className),
            endAdornment:
              loading && isOpen ? (
                <>
                  <CircularProgress size={20} style={{ marginRight: 4 }} />
                  {params.InputProps.endAdornment}
                </>
              ) : (
                params.InputProps.endAdornment
              ),
          }}
          placeholder={value.name}
        />
      )}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  listbox: {
    padding: 0,
  },
  list: {
    padding: theme.spacing(1),
  },
}));

function sortSegments(a: SegmentOption, b: SegmentOption): number {
  if (a.group !== b.group) {
    if (a.group === "") return -1;
    if (b.group === "") return 1;

    if (!a.group) return -1;
    if (!b.group) return 1;

    return a.group
      .toLocaleLowerCase()
      .localeCompare(b.group.toLocaleLowerCase());
  } else {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }
}
