import {
  EditOutlined,
  PlusOutlined,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { MenuItem, Menu, useTheme } from "@material-ui/core";
import { useState } from "react";
import { CustomDashboardType } from "../../graphql";
import { TemplatesDialog } from "./create-from-template/TemplatesDialog";
import { useHealthScoreEnabled } from "../../_common/hooks/useHealthScoreEnabled";

interface Props {
  onAction: (type: CustomDashboardType) => void;
  onClose: () => void;
  anchorEl: null | HTMLElement;
}

export const DashboardTabsCreationMenu = ({
  onAction,
  onClose,
  anchorEl,
}: Props): JSX.Element => {
  const theme = useTheme();
  const healthscore = useHealthScoreEnabled();

  const { t } = useTranslation(["dashboardTabs", "errors", "dashboards"]);
  const [openCreationFromTemplate, setOpenCreationFromTemplate] =
    useState<boolean>(false);

  return (
    <>
      {Boolean(anchorEl) && (
        <Menu
          open
          onClose={onClose}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          data-testid="dashboard-menu"
          disableRestoreFocus
          disableEnforceFocus
        >
          <MenuItem
            data-testid="create-report-dashboard"
            data-pendo={"monitor-create-report-dashboard"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onAction(CustomDashboardType.Monitor);
            }}
          >
            <PlusOutlined
              fontSize="small"
              htmlColor={theme.palette.grey[700]}
            />
            <Typography
              variant="body2"
              style={{ width: "100%", marginLeft: 15 }}
            >
              {t("dashboardTabs:newReport")}
            </Typography>
          </MenuItem>
          {healthscore ? (
            <MenuItem
              data-testid="create-health-score-dashboard"
              data-pendo={"monitor-create-health-score-dashboard"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onAction(CustomDashboardType.HealthScores);
              }}
            >
              <PlusOutlined
                fontSize="small"
                htmlColor={theme.palette.grey[700]}
              />
              <Typography
                variant="body2"
                style={{ width: "100%", marginLeft: 15 }}
              >
                {t("dashboardTabs:newHealthscore")}
              </Typography>
            </MenuItem>
          ) : undefined}

          <MenuItem
            data-testid="create-report-dashboard-template"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenCreationFromTemplate(true);
            }}
            data-pendo={"monitor-create-report-dashboard-template"}
          >
            <EditOutlined
              fontSize="small"
              htmlColor={theme.palette.grey[700]}
            />
            <Typography
              variant="body2"
              style={{ width: "100%", marginLeft: 15 }}
            >
              {t("dashboardTabs:newReportFromTemplate")}
            </Typography>
          </MenuItem>
        </Menu>
      )}
      <TemplatesDialog
        openCreationFromTemplate={openCreationFromTemplate}
        setOpenCreationFromTemplate={setOpenCreationFromTemplate}
        onSkip={onAction}
        onClose={onClose}
        useHealthScore={healthscore}
      />
    </>
  );
};
