import React, { useState } from "react";
import {
  ToggleIconButton,
  DotsHorizontalOutlined,
  TrashOutlined,
  EditOutlined,
  Snackbar,
  DestructiveMenuActionConfirmation,
  DocumentDuplicateCopyOutlined,
  useTranslation,
  useLayoutApi,
} from "@lumar/shared";
import {
  Typography,
  useTheme,
  Tooltip,
  MenuItem,
  Menu,
} from "@material-ui/core";

import { useSnackbar } from "notistack";
import { useDeleteDashboardCollectionMutation } from "../../graphql";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";
import { useParams } from "react-router-dom";

interface Props {
  className?: string;
  collectionId: string;
  showMenuButtonTooltip: boolean;
  onRenameButtonClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: (close?: boolean) => void;
  disableDelete?: boolean;
  disableCloning?: boolean;
}

export const ListItemActionMenu = ({
  className,
  collectionId,
  showMenuButtonTooltip,
  onRenameButtonClick,
  onMouseEnter,
  onMouseLeave,
  disableDelete,
  disableCloning,
}: Props): JSX.Element => {
  const layout = useLayoutApi();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["boardHeader", "errors", "dashboards"]);
  const { boardSettingsPage } = useMonitorRoutes();
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const [confirmationAnchor, setConfirmationAnchor] =
    useState<null | HTMLElement>(null);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const isMenuOpen = Boolean(menuAnchor);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    layout.blockSidebar();
    setMenuAnchor(event.currentTarget);
    setConfirmationAnchor(event.currentTarget.parentElement);
  };

  const handleCloseMenu = (
    e: React.MouseEvent<HTMLElement>,
    reason: string,
  ): void => {
    e.preventDefault();
    setMenuAnchor(null);

    if (reason === "backdropClick") {
      onMouseLeave?.(true);
      layout.unblockSidebar();
    }
  };

  const [deleteDashboard] = useDeleteDashboardCollectionMutation({
    refetchQueries: ["GetDashboardCollections", "GetDashboardCollectionsCount"],
    onCompleted: (data) => {
      if (data)
        enqueueSnackbar(
          <Snackbar variant="success" title={t("boardHeader:deleteSuccess")} />,
        );
    },
    onError: (error) => {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("errors:deleteDashboardError", { message: error.message })}
        />,
      );
    },
  });

  const handleRenameMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement>,
  ): void => {
    setMenuAnchor(null);
    onRenameButtonClick();
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDeleteMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement>,
  ): void => {
    e.preventDefault();
    setMenuAnchor(null);
    setConfirmationOpen(true);
  };

  const handleConfirmDeletionButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault();
    await deleteDashboard({
      variables: { collectionId: collectionId },
    });
    setConfirmationOpen(false);
    setConfirmationAnchor(null);
    layout.unblockSidebar();
  };

  const handleCancelDeletionButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    setConfirmationAnchor(null);
    setConfirmationOpen(false);
    layout.unblockSidebar();
  };

  return (
    <>
      <Tooltip
        placement="bottom"
        title={t("dashboards:actions") as string}
        open={showMenuButtonTooltip}
        arrow={false}
      >
        <ToggleIconButton
          style={{
            padding: theme.spacing(1.125),
            alignSelf: "center",
            display: isMenuOpen ? "inline-flex" : undefined,
          }}
          className={className}
          variant={isMenuOpen ? undefined : "outlined"}
          colorVariant="grey"
          size="small"
          onClick={handleOpenMenu}
          active={isMenuOpen}
          data-pendo={"monitor-dashboard-item-menu-toggle"}
          data-testid="dashboards-list-item-action-menu"
          onMouseEnter={onMouseEnter}
          onMouseLeave={() => onMouseLeave?.()}
        >
          <DotsHorizontalOutlined />
        </ToggleIconButton>
      </Tooltip>

      <Menu
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "center", vertical: "center" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        data-testid="dashboard-menu"
      >
        <MenuItem
          data-testid="dashboard-item-menu-rename"
          onClick={handleRenameMenuItemClick}
          data-pendo={"monitor-dashboard-item-menu-rename"}
        >
          <EditOutlined
            fontSize="small"
            htmlColor={theme.palette.ultraviolet[400]}
          />
          <Typography variant="body2" style={{ width: "100%", marginLeft: 15 }}>
            {t("boardHeader:rename_menu")}
          </Typography>
        </MenuItem>

        {disableCloning ? undefined : (
          <MenuItem
            data-testid="dashboard-item-menu-clone"
            data-pendo={"monitor-dashboard-item-menu-clone"}
            onClick={() => {
              layout.unblockSidebar();
              boardSettingsPage.visit({
                accountId,
                collectionId,
                action: "clone",
              });
            }}
          >
            <DocumentDuplicateCopyOutlined
              fontSize="small"
              htmlColor={theme.palette.ultraviolet[400]}
            />
            <Typography
              variant="body2"
              style={{ width: "100%", marginLeft: 15 }}
            >
              {t("dashboards:clone_menu")}
            </Typography>
          </MenuItem>
        )}
        {disableDelete ? undefined : (
          <MenuItem
            data-testid="dashboard-item-menu-delete"
            onClick={handleDeleteMenuItemClick}
            data-pendo={"monitor-dashboard-item-menu-delete"}
          >
            <TrashOutlined fontSize="small" color="error" />
            <Typography
              variant="body2"
              style={{ width: "100%", marginLeft: 15 }}
            >
              {t("dashboards:delete_menu")}
            </Typography>
          </MenuItem>
        )}
      </Menu>

      {confirmationAnchor && confirmationOpen ? (
        <DestructiveMenuActionConfirmation
          title={t("boardHeader:delete_confirm_title")}
          description={t("boardHeader:delete_confirm_message")}
          onConfirm={handleConfirmDeletionButtonClick}
          onCancel={handleCancelDeletionButtonClick}
          confirmText={t("dashboards:delete_menu")}
          anchorEl={confirmationAnchor}
          pendoPrefix={"monitor-dashboard-item-menu-delete"}
          style={{ width: 280 }}
        />
      ) : null}
    </>
  );
};
