import { ApolloError, useSession, useTranslation } from "@lumar/shared";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  CustomChartType,
  RoleCode,
  useHealthscoreProjectComparisonChartQuery,
} from "../../../../graphql";
import { ProjectComparisonChart } from "../../common/project-comparison-chart/ProjectComparisonChart";
import { useDashboardViews } from "../../../components/DashboardViewsProvider";
import { useCategoryIndustryBenchmarks } from "./utils/useCategoryIndustryBenchmark";
import { mapHealthscoreComparisonChartDataToSeries } from "./utils/mapHealthscoreComparisonChartDataToSeries";
import { DEFAULT_REPORT_TEMPLATE_CATEGORY_CODE } from "../../utils/useCreateDefaultOrCloneDashboard";
import { SimpleCustomChart } from "../../utils/types";

interface Props {
  chart?: SimpleCustomChart;
  isLoading: boolean;
  error?: ApolloError;
}

export function HealthScoreComparisonChart({
  chart,
  isLoading: externalLoading,
  error,
}: Props): JSX.Element | null {
  const { accountId, dashboardId } = useParams<{
    accountId: string;
    dashboardId: string;
  }>();

  const { t } = useTranslation(["errors", "common"]);

  const dashboardExists = Boolean(dashboardId);

  const session = useSession();
  const hasPermissions = session.hasSufficientRole(RoleCode.Editor);

  const dashboardViews = useDashboardViews();

  const reportTemplateCategoryCode =
    chart?.metric ?? DEFAULT_REPORT_TEMPLATE_CATEGORY_CODE;

  const {
    data: chartData,
    loading: loadingChartData,
    error: errorLoadingChartData,
    previousData: previousChartData,
  } = useHealthscoreProjectComparisonChartQuery({
    skip: externalLoading || !dashboardExists || !Boolean(chart?.id),
    variables: {
      chartId: chart?.id,
      dashboardId,
    },
  });

  const { getCategoryIndustryBenchmark } = useCategoryIndustryBenchmarks();

  const series = useMemo(
    () =>
      mapHealthscoreComparisonChartDataToSeries({
        data: loadingChartData ? undefined : chartData,
        accountId,
        reportTemplateCategoryCode,
        benchmarkFunc: getCategoryIndustryBenchmark,
        views: dashboardViews.data ?? [],
        noSegmentText: t("common:noSegments"),
      }),
    [
      loadingChartData,
      chartData,
      reportTemplateCategoryCode,
      accountId,
      dashboardViews.data,
      t,
      getCategoryIndustryBenchmark,
    ],
  );

  const isLoading =
    loadingChartData || externalLoading || dashboardViews.loading;

  const firstLoad = !Boolean(previousChartData) && isLoading;

  return (
    <ProjectComparisonChart
      chartType={CustomChartType.HealthScoreCrawlCompare}
      defaultCode={DEFAULT_REPORT_TEMPLATE_CATEGORY_CODE}
      isLoading={isLoading || loadingChartData}
      isSettingsEmpty={
        !Boolean(
          chartData?.getCustomDashboard?.customDashboardViews?.totalCount,
        )
      }
      series={series ?? []}
      chartId={chart?.id}
      code={chart?.metric}
      duration={chart?.metadata}
      error={error ?? errorLoadingChartData}
      firstLoad={firstLoad}
      views={chart?.items ?? []}
      disabled={!hasPermissions}
      refetchQueries={[
        "GetDashboardCharts",
        "HealthscoreProjectComparisonChart",
      ]}
    />
  );
}
