import { useState, KeyboardEvent, useRef, useEffect } from "react";
import { Snackbar, useTranslation } from "@lumar/shared";
import {
  MAX_NAME_LENGTH,
  MIN_NAME_LENGTH,
} from "../dashboards/account-overview/name-dialog/DashboardNameDialog";
import {
  useUpdateDashboardCollectionMutation,
  useUpdateDashboardMutation,
} from "../../graphql";
import { useSnackbar } from "notistack";
import {
  ClickAwayListener,
  TextField,
  TextFieldProps,
} from "@material-ui/core";

export interface DashboardNameEditorProps
  extends Omit<
    TextFieldProps,
    "onChange" | "error" | "onKeyDown" | "helperText" | "value" | "variant"
  > {
  value: string;
  collectionId?: string;
  dashboardId?: string;
  refetchQueries?: string[];
  onFinishedEditing?: (
    name?: string | null,
    reason?: "cancel" | "error" | "ok",
  ) => void;
  variant?: "small" | "large";
  pendoPrefix: string;
  minLength?: number;
}

export function DashboardNameEditor({
  value,
  collectionId,
  dashboardId,
  refetchQueries,
  onFinishedEditing,
  variant,
  pendoPrefix,
  minLength,
  ...props
}: DashboardNameEditorProps): JSX.Element {
  const [name, setName] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["dashboards", "boardHeader", "errors"]);

  const fullName = name ?? value ?? "";
  const validName = (name ?? value ?? "").trim();

  const isNameTooLong = (validName?.length ?? 0) > MAX_NAME_LENGTH;
  const isNameTooShort =
    (validName?.length ?? 0) < (minLength ?? MIN_NAME_LENGTH);

  const isDashboardEdit = Boolean(dashboardId) && !Boolean(collectionId);
  const isUpdateHandled = !Boolean(collectionId) && !Boolean(dashboardId);
  const ref = useRef<HTMLDivElement>(null);

  const [updateDashboardCollection] = useUpdateDashboardCollectionMutation({
    refetchQueries,
    onError: (error) => {
      setName(null);
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("errors:renameDashboardError", { message: error.message })}
        />,
      );
    },
  });

  const [updateDashboard] = useUpdateDashboardMutation({
    refetchQueries,
    onError: (error) => {
      setName(null);
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("errors:renameDashboardError", { message: error.message })}
        />,
      );
    },
  });

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void | null => {
    if (e.key === "Escape") {
      setName(null);
      onFinishedEditing?.(null, "cancel");
      return;
    }
    if (e.key === "Enter" && !isNameTooShort && !isNameTooLong) {
      if (name !== value) {
        if (!isUpdateHandled) {
          if (isDashboardEdit)
            updateDashboard({
              variables: {
                customDashboardId: dashboardId,
                name: validName ?? "",
              },
            });
          else
            updateDashboardCollection({
              variables: {
                collectionId: collectionId,
                name: validName ?? "",
              },
            });
        }
      }
      onFinishedEditing?.(validName, "ok");
      setName(null);
    }
  };

  const handleClickAway = (): void => {
    if (!isNameTooLong && !isNameTooShort) {
      if (name !== value) {
        if (!isUpdateHandled) {
          if (isDashboardEdit)
            updateDashboard({
              variables: {
                customDashboardId: dashboardId,
                name: validName ?? "",
              },
            });
          else
            updateDashboardCollection({
              variables: {
                collectionId: collectionId,
                name: validName ?? "",
              },
            });
        }
      }
      onFinishedEditing?.(validName, "ok");
    } else {
      setName(null);
      onFinishedEditing?.(null, "error");
    }
  };

  function getVariationOfMessages(name: string): string {
    return t(variant === "small" ? `${name}-small` : name);
  }

  useEffect(() => {
    if (ref.current) ref.current?.getElementsByTagName("input")[0]?.focus();
  });

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
      <TextField
        {...props}
        value={fullName}
        onChange={(e) => setName(e.target.value)}
        error={isNameTooLong || isNameTooShort}
        data-testid="edit-dashboard-name-input"
        onKeyDown={handleKeyDown}
        onBlur={handleClickAway}
        helperText={
          isNameTooLong
            ? getVariationOfMessages("nameTooLong")
            : isNameTooShort
              ? getVariationOfMessages("nameTooShort")
              : undefined
        }
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
        autoFocus
        focused
        variant="outlined"
        data-pendo={`${pendoPrefix}-input`}
        ref={ref}
      />
    </ClickAwayListener>
  );
}
