import { ApolloError, useSession } from "@lumar/shared";
import {
  FullReportCategory,
  ReportOption,
} from "../../_common/utils/constants";
import { ModuleCode } from "../../graphql";
import { ReportRulesAndThresholdsEditTable } from "./ReportRulesAndThresholdsEditTable";
import { CategoryRulesAndThresholdsEditTable } from "./CategoryRulesAndThresholdsEditTable";
import { createStyles, makeStyles } from "@material-ui/core";
import { useHealthScoreEnabled } from "../../_common/hooks/useHealthScoreEnabled";

export interface RulesAndThresholdsEditProps {
  className?: string;
  reportTemplates?: ReportOption[];
  categories?: FullReportCategory[];
  alertId: string;
  crawlId: string;
  error?: ApolloError;
  moduleCode?: ModuleCode;
}

export function RulesAndThresholdsEdit({
  className,
  reportTemplates,
  alertId,
  crawlId,
  moduleCode,
  categories,
  error,
}: RulesAndThresholdsEditProps): JSX.Element {
  const classes = useStyles();
  const { hasFeatureFlagEnabled } = useSession();

  const hasHealthscores =
    useHealthScoreEnabled() && hasFeatureFlagEnabled("health-score-alerts");

  return (
    <div className={className}>
      <ReportRulesAndThresholdsEditTable
        reports={reportTemplates ?? []}
        categories={categories ?? []}
        hasError={Boolean(error)}
        crawlId={crawlId}
        projectId={alertId}
        moduleCode={moduleCode}
      />
      {hasHealthscores && (
        <CategoryRulesAndThresholdsEditTable
          className={classes.healthscore}
          reports={reportTemplates ?? []}
          hasError={Boolean(error)}
          crawlId={crawlId}
          projectId={alertId}
          moduleCode={moduleCode}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    healthscore: {
      marginTop: theme.spacing(3.5),
    },
  }),
);
