import { useSession } from "@lumar/shared";
import React from "react";
import { RoleCode } from "../../graphql";

interface Props {
  children: React.ReactNode;
  /**
   * The minimum required role needed in order to see whatever content is passed to the `children` prop. The default is `RoleCode.Editor`
   */
  minimumRequiredRole?: RoleCode;
}

/**
 * Use this component to conditionally hide content based on the user's role.
 */
export function HideFromInsufficientRole({
  children,
  minimumRequiredRole = RoleCode.Editor,
}: Props): JSX.Element {
  const session = useSession();

  const hasSufficientRole = session.hasSufficientRole(minimumRequiredRole);

  if (hasSufficientRole) {
    return <>{children}</>;
  }

  return <></>;
}
