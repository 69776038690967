import {
  Button,
  PlusSolid,
  SwitchWithIcon,
  Chip,
  Typography,
  useTranslation,
} from "@lumar/shared";
import {
  createStyles,
  FormControlLabel,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useState } from "react";
import { HideFromInsufficientRole } from "../../../_common/components/HideFromInsufficientRole";
import { useMonitorRoutes } from "../../../_common/routing/useMonitorRoutes";
import { useDashboardViews } from "../../components/DashboardViewsProvider";
import { useGetViewsForBoardAccumulator } from "../../settings/board/utils/useGetViewsForBoardAccumulator";
import { DashboardSettings } from "../../settings/dasboard/DashboardSettings";
import { DashboardOptions } from "./DashboardOptions";

const useStyles = makeStyles((theme) =>
  createStyles({
    alertButtonIcon: {
      width: 18,
      height: 18,
      color: theme.palette.grey[700],
      marginRight: 6,
    },
    newAlertButton: {
      color: theme.palette.grey[700],
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(17),
      height: 36,
      borderRadius: 8,
      padding: "8px 8px",
      "&:hover": {
        background: theme.palette.grey[200],
        cursor: "pointer",
      },
    },
    switch: {
      height: 20,
      width: 40,
      "& svg": {
        fontSize: 21,
      },
    },
  }),
);

export const MONITOR_LOCALSTORAGE_CHART_FLAG = "monitor-show-dashboard-chart";

interface DashboardHeaderProps {
  name?: string;
  onChange: (show: boolean) => void;
  showChart: boolean;
  dashboardsNum: number;
}

export function DashboardHeader({
  name,
  showChart,
  dashboardsNum,
  onChange,
}: DashboardHeaderProps): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const { createAlertPage } = useMonitorRoutes();
  const { t } = useTranslation([
    "dashboards",
    "dashboardHeader",
    "errors",
    "board",
  ]);

  const { loading: loadingDashboardViews, data: dashboarViewsdData } =
    useDashboardViews();
  const { loading: loadingBoardViews } = useGetViewsForBoardAccumulator(false);
  const loading = Boolean(loadingDashboardViews) && Boolean(loadingBoardViews);

  const [dashboardSettingsOpen, setDashboardSettingsOpen] =
    useState<boolean>(false);

  function onSwitchChange(show: boolean): void {
    localStorage.setItem(
      MONITOR_LOCALSTORAGE_CHART_FLAG,
      show ? "true" : "false",
    );
    onChange(show);
  }

  return (
    <div
      style={{
        width: "100%",
        height: 36,
        margin: theme.spacing(1, 0, 2, 0),
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        color: theme.palette.grey[900],
      }}
    >
      <Typography variant="subtitle1SemiBold">{name ?? ""}</Typography>
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          alignItems: "center",
          flexFlow: "row",
          gap: theme.spacing(1),
        }}
      >
        <HideFromInsufficientRole>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            disabled={loading}
            className={classes.newAlertButton}
            onClick={() => {
              setDashboardSettingsOpen(true);
            }}
            data-pendo="monitor-dashboard-settings"
            data-testid="monitor-dashboard-settings"
          >
            {t("board:selectedViews")}
            <Chip
              label={dashboarViewsdData?.length ?? 0}
              color="primary"
              size="small"
              rounded={true}
              style={{ marginLeft: theme.spacing(1) }}
            />
          </Button>
        </HideFromInsufficientRole>
        <Button
          onClick={() => onSwitchChange(!showChart)}
          variant="outlined"
          color="secondary"
          size="large"
          style={{ paddingTop: 7, paddingBottom: 7 }}
          className={classes.newAlertButton}
          data-pendo="monitor-dashboard-show-hide-chart"
          data-testid="monitor-dashboard-show-hide-chart"
        >
          <FormControlLabel
            style={{
              pointerEvents: "none",
            }}
            control={
              <SwitchWithIcon
                className={classes.switch}
                checked={showChart}
                inputProps={{ tabIndex: -1 }}
              />
            }
            label={t("dashboards:graph")}
          />
        </Button>
        <HideFromInsufficientRole>
          <>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className={classes.newAlertButton}
              onClick={() => {
                createAlertPage.visit();
              }}
              data-pendo="monitor-dashboard-new-alert"
              data-testid="monitor-dashboard-new-alert"
            >
              <PlusSolid className={classes.alertButtonIcon} />
              {t("dashboardHeader:newAlert")}
            </Button>
            <DashboardOptions loading={loading} dashboardsNum={dashboardsNum} />
          </>
        </HideFromInsufficientRole>
      </div>
      {dashboardSettingsOpen ? (
        <DashboardSettings
          dashboardSettingsOpen={dashboardSettingsOpen}
          setDashboardSettingsOpen={setDashboardSettingsOpen}
        />
      ) : undefined}
    </div>
  );
}
