import { ComboBox, Dialog, Select, useTranslation } from "@lumar/shared";
import {
  ClickAwayListener,
  createStyles,
  FormControlLabel,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import {
  OrderDirection,
  TrendsComputedType,
  TrendsTableSortableField,
} from "../../../../../graphql";
import { TABLE_SORTING_DETAILS_CODE } from "./helpers";
import {
  MonitorSortItem,
  TableColumnSortOrder,
} from "../../../helpers/MonitorSortItem";

interface CurrentTableSortingItem {
  code: string;
  sortBy: {
    code: string;
    order: OrderDirection;
  };
}

export interface TableSortingItem {
  code: string;
  title: string;
  sortBy: {
    code: string;
    title: string;
    order: { type: OrderDirection; title: string }[];
  }[];
}

interface TableSortingPopupProps {
  current: MonitorSortItem;
  columns: TableSortingItem[];
  anchorEl?: null | Element | ((element: Element) => Element);
  onCancel: () => void;
  onApply: (sort: MonitorSortItem) => void;
  pendoId?: string;
  testId?: string;
}

export function TableSortingPopup({
  current,
  columns,
  anchorEl,
  onCancel,
  onApply,
  pendoId,
  testId,
}: TableSortingPopupProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["sortingPopup", "common"]);

  const currentSelection = getCurrentSelection(current);
  const [selectedColumn, setSelectedColumn] = React.useState<
    TableSortingItem | undefined
  >(columns.find((e) => e.code === currentSelection.code));
  const [selectedOrderBy, setSelectedOrderBy] = React.useState<
    | {
        code: string;
        title: string;
        order: { type: OrderDirection; title: string }[];
      }
    | undefined
  >(
    selectedColumn?.sortBy.find((e) => e.code === currentSelection.sortBy.code),
  );
  const [selectedDirection, setSelectedDirection] = React.useState<
    | {
        type: OrderDirection;
        title: string;
      }
    | undefined
  >(
    selectedOrderBy?.order.find(
      (e) => e.type === currentSelection.sortBy.order,
    ),
  );

  const opened = Boolean(anchorEl);
  const fullSelection =
    Boolean(selectedColumn) &&
    Boolean(selectedOrderBy) &&
    Boolean(selectedDirection);

  return (
    <ClickAwayListener onClickAway={onCancel}>
      <Dialog
        data-pendo={pendoId}
        data-testid={testId}
        open={opened}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 45,
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={onCancel}
        invisibleBackdrop
        buttons={[
          {
            variant: "outlined",
            color: "default",
            title: t("common:cancel"),
            onClick: () => onCancel(),
            "data-pendo": "monitor-sorting-dialog-cancel",
            "data-testid": "monitor-sorting-dialog-cancel",
          },
          {
            variant: "contained",
            title: t("common:apply"),
            disabled: !fullSelection,
            autoFocus: true,
            onClick: () => {
              const sort: MonitorSortItem = {
                field: getSortingField(
                  selectedColumn?.code ?? null,
                  selectedOrderBy?.code ?? null,
                ),
                sort: selectedDirection?.type ?? OrderDirection.Asc,
                type: TableColumnSortOrder.unused,
                trend: getSortingTrendOptions(
                  selectedColumn?.code ?? null,
                  selectedOrderBy?.code ?? null,
                ),
              };
              onApply(sort);
            },
            color: "primary",
            "data-pendo": "monitor-table-sorting-dialog-submit",
            "data-testid": "monitor-table-sorting-dialog-submit-button",
          },
        ]}
        pendoPrefix="monitor-table-sorting"
      >
        <div
          style={{
            width: "100%",
            marginBottom: 31,
            marginTop: 10,
            display: "flex",
          }}
        >
          <FormControlLabel
            label="Column"
            labelPlacement="top"
            classes={{
              label: classes.label,
              labelPlacementTop: classes.labelTop,
            }}
            control={
              <ComboBox
                id="sorted_column"
                data-pendo={pendoId ? `${pendoId}-sorted-column` : undefined}
                data-testid={testId ? `${testId}-sorted-column` : undefined}
                value={selectedColumn?.code}
                className={classes.column}
                options={columns.map((e) => e.code)}
                disableClearable
                getOptionLabel={(option) => {
                  const title =
                    columns.find((e) => e.code === option)?.title ?? "";
                  return title;
                }}
                onChange={(_, value) => {
                  const column = columns.find((e) => e.code === value);
                  setSelectedColumn(column);
                  const sameType =
                    selectedColumn?.code === column?.code ||
                    (selectedColumn?.code !== TABLE_SORTING_DETAILS_CODE &&
                      column?.code !== TABLE_SORTING_DETAILS_CODE);

                  if (!sameType) {
                    setSelectedOrderBy(
                      column?.sortBy.length ? column?.sortBy[0] : undefined,
                    );
                    setSelectedDirection(
                      column?.sortBy[0]?.order.length
                        ? column?.sortBy[0]?.order[0]
                        : undefined,
                    );
                  }
                }}
              />
            }
          />
          <Select
            data-pendo={pendoId ? `${pendoId}-sort-by` : undefined}
            data-testid={testId ? `${testId}-sort-by` : undefined}
            value={selectedOrderBy?.code ?? ""}
            className={classes.orderBy}
            displayEmpty={true}
            label="Sort by"
            renderValue={(value) => {
              const option = selectedColumn?.sortBy.find(
                (e) => e.code === value,
              );
              return option
                ? option.title
                : t("sortingPopup:orderByPlaceholder");
            }}
          >
            {selectedColumn?.sortBy.map((element) => (
              <MenuItem
                key={element.code}
                value={element.code}
                data-pendo={
                  pendoId ? `${pendoId}-sort-by-${element.code}` : undefined
                }
                data-testid={
                  testId ? `${testId}-sort-by-${element.code}` : undefined
                }
                selected={element.code === selectedOrderBy?.code}
                onClick={() => {
                  setSelectedOrderBy(element);
                  const sameType =
                    selectedColumn?.code === element?.code ||
                    (selectedColumn?.code !== TABLE_SORTING_DETAILS_CODE &&
                      element?.code !== TABLE_SORTING_DETAILS_CODE);

                  if (!sameType)
                    setSelectedDirection(
                      element.order.length ? element.order[0] : undefined,
                    );
                }}
              >
                {element.title}
              </MenuItem>
            ))}
          </Select>
          <Select
            data-pendo={pendoId ? `${pendoId}-sort-order` : undefined}
            data-testid={testId ? `${testId}-sort-order` : undefined}
            value={selectedDirection?.type ?? ""}
            className={classes.direction}
            label="Order"
            displayEmpty={true}
            renderValue={(value) => {
              const option = selectedOrderBy?.order.find(
                (e) => e.type === value,
              );
              return option
                ? option.title
                : t("sortingPopup:directionPlaceholder");
            }}
          >
            {selectedOrderBy?.order.map((element) => (
              <MenuItem
                key={element.type}
                data-pendo={
                  pendoId ? `${pendoId}-sort-order-${element.type}` : undefined
                }
                data-testid={
                  testId ? `${testId}-sort-order-${element.type}` : undefined
                }
                value={element.type}
                selected={element.type === selectedDirection?.type}
                onClick={() => setSelectedDirection(element)}
              >
                {element.title}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Dialog>
    </ClickAwayListener>
  );
}

function getCurrentSelection(
  current: MonitorSortItem,
): CurrentTableSortingItem {
  switch (current.type) {
    case TableColumnSortOrder.byMostRecentCrawl:
      return {
        code: TABLE_SORTING_DETAILS_CODE,
        sortBy: {
          code: TrendsTableSortableField.ProjectFinishedAt,
          order: OrderDirection.Desc,
        },
      };
    case TableColumnSortOrder.byOldestCrawl:
      return {
        code: TABLE_SORTING_DETAILS_CODE,
        sortBy: {
          code: TrendsTableSortableField.ProjectFinishedAt,
          order: OrderDirection.Asc,
        },
      };

    case TableColumnSortOrder.byProjectNameAToZ:
      return {
        code: TABLE_SORTING_DETAILS_CODE,
        sortBy: {
          code: TrendsTableSortableField.ProjectName,
          order: OrderDirection.Asc,
        },
      };
    case TableColumnSortOrder.byProjectNameZToA:
      return {
        code: TABLE_SORTING_DETAILS_CODE,
        sortBy: {
          code: TrendsTableSortableField.ProjectName,
          order: OrderDirection.Desc,
        },
      };
    case TableColumnSortOrder.bySegmentNameAToZ:
      return {
        code: TABLE_SORTING_DETAILS_CODE,
        sortBy: {
          code: TrendsTableSortableField.SegmentName,
          order: OrderDirection.Asc,
        },
      };
    case TableColumnSortOrder.bySegmentNameZToA:
      return {
        code: TABLE_SORTING_DETAILS_CODE,
        sortBy: {
          code: TrendsTableSortableField.SegmentName,
          order: OrderDirection.Desc,
        },
      };
    case TableColumnSortOrder.unused:
    case TableColumnSortOrder.byTrend: {
      if (!current.trend)
        return {
          code: "error",
          sortBy: { code: "error", order: OrderDirection.Desc },
        };
      return {
        code: current.trend?.code,
        sortBy: {
          code: current.trend.type,
          order: current.sort,
        },
      };
    }
  }
}

function getSortingField(
  column: string | null,
  orderBy: string | null,
): TrendsTableSortableField {
  if (
    column === TABLE_SORTING_DETAILS_CODE &&
    orderBy &&
    Object.values(TrendsTableSortableField).includes(
      orderBy as TrendsTableSortableField,
    )
  )
    return orderBy as TrendsTableSortableField;
  return TrendsTableSortableField.Trend;
}

function getSortingTrendOptions(
  column: string | null,
  orderBy: string | null,
):
  | {
      code: string;
      type: TrendsComputedType;
    }
  | undefined {
  if (!column || column === TABLE_SORTING_DETAILS_CODE) return undefined;
  return {
    code: column,
    type: orderBy as TrendsComputedType,
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    column: {
      marginRight: theme.spacing(1),
      minWidth: 250,
      maxWidth: 250,
    },
    orderBy: {
      marginRight: theme.spacing(1),
      minWidth: 185,
      maxWidth: 185,
    },
    direction: {
      minWidth: 100,
      maxWidth: 100,
    },
    label: { marginBottom: theme.spacing(1), marginLeft: 0 },
    labelTop: { alignItems: "flex-start", marginLeft: 0, marginRight: 0 },
  }),
);
