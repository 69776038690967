import {
  DotsHorizontalOutlined,
  DuplicateOutlined,
  ToggleIconButton,
  TrashOutlined,
  Typography,
  useTranslation,
} from "@lumar/shared";
import {
  ButtonGroup,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import { useState, useRef, SetStateAction, Dispatch } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    menuButtonActive: {
      marginLeft: 7,
    },
    menuButton: {
      backgroundColor: "white",
      marginLeft: 7,
    },
    menu: {
      width: 175,
      border: 0,
      paddingLeft: 8,
      paddingRight: 8,
      boxShadow:
        "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    },
    menuItem: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    copyIcon: {
      color: theme.palette.ultraviolet[400],
    },
    menuText: { width: "100%", marginLeft: 15 },
    menuSubheader: {
      color: theme.palette.grey[600],
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(15),
      padding: theme.spacing(1),
      opacity: "1!important",
    },
    boardActions: {
      borderTopRightRadius: 61,
      borderBottomRightRadius: 61,
      maxWidth: 40,
      width: 40,
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    boardActionsAction: {
      backgroundColor: theme.palette.grey[300],
    },
    boardSettings: {
      borderTopLeftRadius: 61,
      borderBottomLeftRadius: 61,
      maxWidth: 86,
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  }),
);

interface Props {
  disabled?: boolean;
  loading?: boolean;
  dashboardsNum: number;
  onOpen: (value: SetStateAction<HTMLElement | null>) => void;
  onClose: () => void;
  onClone: Dispatch<SetStateAction<HTMLElement | null>>;
  onDelete: () => void;
}

export const DashboardOptionsMenu = ({
  disabled: disabledFromOutside,
  loading: loadingFromOutside,
  dashboardsNum,
  onOpen,
  onClose,
  onClone,
  onDelete,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation(["board", "dashboards", "common"]);
  const theme = useTheme();

  const isMenuOpen = Boolean(menuAnchor);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
    onOpen(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setMenuAnchor(null);
    onClose();
  };

  const disabled = loadingFromOutside || disabledFromOutside;

  return (
    <>
      <ButtonGroup style={{ marginRight: theme.spacing(1) }}>
        <ToggleIconButton
          disabled={disabled}
          onClick={handleOpenMenu}
          colorVariant="grey"
          size="large"
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            maxWidth: 36,
            minWidth: 36,
          }}
          data-pendo={"monitor-dashboardboard-header-menu-toggle"}
          data-testid="dashboards-action-menu"
          ref={buttonRef}
          aria-label={t("dashboards:actionButton")}
        >
          <DotsHorizontalOutlined />
        </ToggleIconButton>
      </ButtonGroup>
      <Menu
        classes={{
          paper: classes.menu,
        }}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        data-testid="dashboard-menu"
      >
        <MenuItem className={classes.menuSubheader} disabled role="label">
          {t("dashboards:dashboardOptions")}
        </MenuItem>
        <MenuItem
          data-testid="dashboard-menu-clone"
          className={classes.menuItem}
          onClick={() => {
            setMenuAnchor(null);
            onClone(buttonRef.current);
          }}
          data-pendo="monitor-dashboard-header-menu-clone"
        >
          <DuplicateOutlined fontSize="small" className={classes.copyIcon} />
          <Typography variant="body2" className={classes.menuText}>
            {t("common:clone")}
          </Typography>
        </MenuItem>
        {dashboardsNum > 1 ? (
          <MenuItem
            data-testid="dashboard-menu-delete"
            className={classes.menuItem}
            onClick={() => {
              setMenuAnchor(null);
              onDelete();
            }}
            data-pendo={"monitor-dashboard-header-menu-delete"}
          >
            <TrashOutlined fontSize="small" color="error" />
            <Typography variant="body2" className={classes.menuText}>
              {t("common:delete")}
            </Typography>
          </MenuItem>
        ) : undefined}
      </Menu>
    </>
  );
};
