import {
  FaviconIcon,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { createStyles, makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";

interface DataGridCellProps {
  projectName: string;
  segmentName?: string;
  url: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      lineHeight: "normal",
      paddingLeft: theme.spacing(1.75),
    },
    iconWrapper: {
      height: 26,
      width: 26,
      flexBasis: 24,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(0.5),
    },
    textWrapper: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingLeft: theme.spacing(1.5),
      overflow: "hidden",
    },
    projectTitleText: {
      color: theme.palette.grey[800],
      marginBottom: theme.spacing(0.625),
    },
    urlText: {
      color: theme.palette.grey[600],
    },
    textOverflow: {
      width: "100%",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    segment: {
      background: theme.palette.grey[200],
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 4,
      maxWidth: "100%",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      height: 20,
      width: "fit-content",
      paddingLeft: 3,
      paddingRight: 3,
      marginBottom: theme.spacing(0.875),
    },
  }),
);

export function ProjectNameColumnCell(props: DataGridCellProps): JSX.Element {
  const { projectName, url, segmentName } = props;
  const classes = useStyles();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { t } = useTranslation(["common"]);
  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <FaviconIcon url={url} width={20} height={20} />
      </div>
      <div className={classes.textWrapper}>
        <Tooltip title={projectName} arrow={false}>
          <Typography
            variant="subtitle3SemiBold"
            className={clsx(classes.projectTitleText, classes.textOverflow)}
            data-testid="account-overview-grid-project-column-cell-name"
          >
            {projectName}
          </Typography>
        </Tooltip>
        {segmentationAvailable && (
          <Typography
            variant="captionSemiBold"
            className={classes.segment}
            data-testid="account-overview-grid-project-column-cell-segment"
          >
            {segmentName ?? t("noSegments")}
          </Typography>
        )}
        <Tooltip title={url} arrow={false}>
          <Typography
            variant="subtitle4"
            className={clsx(classes.urlText, classes.textOverflow)}
            data-testid="account-overview-grid-project-column-cell-url"
          >
            {url}
          </Typography>
        </Tooltip>
      </div>
    </div>
  );
}
