import {
  OrderDirection,
  TrendsComputedType,
  TrendsTableSortableField,
} from "../../../graphql";

export enum TableColumnSortOrder {
  "unused",
  "byMostRecentCrawl",
  "byOldestCrawl",
  "byProjectNameAToZ",
  "byProjectNameZToA",
  "bySegmentNameAToZ",
  "bySegmentNameZToA",
  "byTrend",
}

export interface MonitorSortItem {
  field: TrendsTableSortableField;
  type: TableColumnSortOrder;
  sort: OrderDirection;
  trend?: {
    code: string;
    type: TrendsComputedType;
  };
}

export function getSortOrderType(
  field: TrendsTableSortableField,
  direction: OrderDirection,
): TableColumnSortOrder {
  switch (field) {
    case TrendsTableSortableField.ProjectFinishedAt: {
      switch (direction) {
        case OrderDirection.Asc: {
          return TableColumnSortOrder.byOldestCrawl;
        }
        default: {
          return TableColumnSortOrder.byMostRecentCrawl;
        }
      }
    }
    case TrendsTableSortableField.ProjectName: {
      switch (direction) {
        case OrderDirection.Asc: {
          return TableColumnSortOrder.byProjectNameAToZ;
        }
        default: {
          return TableColumnSortOrder.byProjectNameZToA;
        }
      }
    }
    case TrendsTableSortableField.SegmentName: {
      switch (direction) {
        case OrderDirection.Asc: {
          return TableColumnSortOrder.bySegmentNameAToZ;
        }
        default: {
          return TableColumnSortOrder.bySegmentNameZToA;
        }
      }
    }
    default:
      return TableColumnSortOrder.byTrend;
  }
}
