import queryString, { StringifyOptions, UrlObject } from "query-string";
import { useCallback, useMemo } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { MONITOR_ROUTES } from "./monitorRoutes";

function createURL(object: UrlObject, options?: StringifyOptions): string {
  return queryString.stringifyUrl(object, options);
}

interface Options {
  replace?: boolean;
}

interface BasicParams {
  accountId?: string;
}

interface DashboardParams extends BasicParams {
  collectionId?: string;
  dashboardId?: string;
}
interface BoardSettingsParams extends BasicParams {
  collectionId?: string;
  action?: "edit" | "clone";
}

interface AlertParams extends BasicParams {
  alertId?: string;
}

interface RouteObject<T = BasicParams> {
  getUrl: (params?: T) => string;
  visit: (params?: T, options?: Options) => void;
}

interface RouteObjectWithoutParams {
  getUrl: () => string;
  visit: () => void;
}

interface MonitorRoutes {
  dashboardPage: RouteObject<DashboardParams>;
  loginPage: RouteObjectWithoutParams;
  createAlertPage: RouteObject;
  alertsPage: RouteObject<AlertParams>;
  notificationsPage: RouteObject;
  boardSettingsPage: RouteObject<BoardSettingsParams>;
  marketingPage: RouteObject;
}

export function useMonitorRoutes(): MonitorRoutes {
  const { accountId } = useParams<{ accountId: string }>();
  const history = useHistory();

  const generateBasicConfig = useCallback(
    (route: string): RouteObject => {
      const getUrl = (params?: BasicParams): string =>
        generatePath(route, {
          accountId: params?.accountId ?? accountId,
        });

      return {
        getUrl,
        visit: (params?: BasicParams) => {
          history.push(getUrl(params));
        },
      };
    },
    [accountId, history],
  );

  const returnObject = useMemo(
    () => ({
      dashboardPage: {
        getUrl: (params?: DashboardParams) => {
          const accountIdToUse = params?.accountId ?? accountId;

          return generatePath(MONITOR_ROUTES.Dashboard[1], {
            accountId: accountIdToUse,
            collectionId: params?.collectionId,
            dashboardId: params?.dashboardId,
          });
        },
        visit: (params?: DashboardParams, options?: Options) => {
          const url = returnObject.dashboardPage.getUrl(params);
          if (options?.replace) history.replace(url);
          else history.push(url);
        },
      },
      loginPage: {
        getUrl: () => generatePath(MONITOR_ROUTES.Login),
        visit: () => {
          history.push(returnObject.loginPage.getUrl());
        },
      },
      alertsPage: {
        getUrl: (params?: AlertParams) =>
          generatePath(MONITOR_ROUTES.Alerts, {
            accountId: params?.accountId ?? accountId,
            alertId: params?.alertId,
          }),
        visit: (params?: AlertParams) => {
          history.push(returnObject.alertsPage.getUrl(params));
        },
      },
      notificationsPage: generateBasicConfig(MONITOR_ROUTES.Notifications),
      createAlertPage: generateBasicConfig(MONITOR_ROUTES.CreateAlert),
      boardSettingsPage: {
        getUrl: (params?: BoardSettingsParams) => {
          const url = generatePath(MONITOR_ROUTES.BoardSettings, {
            accountId: params?.accountId ?? accountId,
            collectionId: params?.collectionId,
          });
          return createURL({ url, query: { action: params?.action } });
        },
        visit: (params?: BoardSettingsParams, options?: Options) => {
          if (options?.replace)
            history.replace(returnObject.boardSettingsPage.getUrl(params));
          else history.push(returnObject.boardSettingsPage.getUrl(params));
        },
      },
      marketingPage: generateBasicConfig(MONITOR_ROUTES.MarketingPage),
    }),
    [accountId, generateBasicConfig, history],
  );

  return returnObject;
}
