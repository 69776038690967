import { useRef } from "react";
import { makeStyles, MenuItem, Paper, useTheme } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Skeleton } from "@material-ui/lab";
import {
  ApolloError,
  useSession,
  Snackbar,
  Select,
  Check,
  useTranslation,
} from "@lumar/shared";

import { useGenericParams } from "../../../../_common/routing/useGenericParams";
import { assert } from "../../../../_common/assert";
import {
  RoleCode,
  useUpdateDashboardChartMutation,
  CustomChartType,
  useGetReportTemplateByCodeQuery,
  ReportTemplateUnit,
} from "../../../../graphql";
import { ProjectComparisonChartInnerWrapper } from "./ProjectComparisonChartInnerWrapper";
import { ProjectComparisonChartErrorState } from "./ProjectComparisonChartErrorState";
import { ProjectComparisonChartNoViewsState } from "./ProjectComparisonChartNoViewsState";
import {
  useTimeRangeOptions,
  TimeRangeOptionValue,
} from "./helpers/useTimeRangeOptions";
import {
  mapDurationToTimeRange,
  mapTimeRangeToDuration,
} from "./helpers/mapDurationToTimeRange";
import { useSnackbar } from "notistack";
import ResizeObserver from "react-resize-observer";
import { invoke, get } from "lodash";
import {
  ReportCategoryOption,
  ReportOption,
} from "../../../../_common/utils/constants";
import { LineChart } from "./LineChart";
import { SeriesLineOptions } from "highcharts";
import { HealthScoreCategoriesCombobox } from "./components/HealthScoreCategoriesCombobox";
import { useDashboardViews } from "../../../components/DashboardViewsProvider";
import { ViewsCombobox } from "./components/ViewsCombobox";
import { View } from "../../../settings/types";
import { ProjectComparisonChartEmptyDashboardSettings } from "./ProjectComparisonChartEmptyDashboardSettings";
import { ReportsCombobox } from "../../../../_common/components/reports/ReportsCombobox";

const PANEL_HEIGHT = 340;
const NARROW_PANEL_HEIGHT = PANEL_HEIGHT * 1.5;
const HEADER_HEIGHT = 63; // NOTE: Includes 1px extra for the divider.
const NARROW_HEADER_HEIGHT = HEADER_HEIGHT + 36 * 2 + 8 * 2;
export const CHART_MIN_WIDTH = 460;

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    marginBottom: theme.spacing(2),
    minWidth: CHART_MIN_WIDTH,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: NARROW_HEADER_HEIGHT,
    paddingLeft: theme.spacing(2.75),
    paddingRight: theme.spacing(2.75),
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.grey[200],
    paddingTop: 13.5,
    paddingBottom: 13.5,
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "center",
      height: HEADER_HEIGHT,
    },
  },
  dateRangeSelect: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: "auto",
      marginRight: 8,
    },
    "& .Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
  innerWrapper: {
    height: NARROW_PANEL_HEIGHT - NARROW_HEADER_HEIGHT,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      height: PANEL_HEIGHT - HEADER_HEIGHT,
    },
  },
  skeleton: {
    height:
      NARROW_PANEL_HEIGHT -
      NARROW_HEADER_HEIGHT -
      theme.spacing(4) -
      theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      height:
        PANEL_HEIGHT - HEADER_HEIGHT - theme.spacing(4) - theme.spacing(4),
    },
  },
  emptyState: {
    height: NARROW_PANEL_HEIGHT - NARROW_HEADER_HEIGHT,
    [theme.breakpoints.up("lg")]: {
      height: PANEL_HEIGHT - HEADER_HEIGHT,
    },
  },
  reportCombobox: {
    minWidth: 250,
  },
  menuItem: {
    "&.Mui-focusVisible": {
      border: 0,
      boxShadow: "none",
    },
  },
}));

interface Props {
  chartId?: string;
  views: View[];
  duration?: Record<string, string>;
  code?: string;
  isLoading: boolean;
  defaultCode: string;
  series: SeriesLineOptions[];
  error?: ApolloError;
  firstLoad?: boolean;
  chartType: CustomChartType;
  refetchQueries?: string[];
  disabled?: boolean;
  isSettingsEmpty?: boolean;
}

export function ProjectComparisonChart({
  chartId,
  code: initialCode,
  isLoading,
  duration,
  views,
  defaultCode,
  series,
  error,
  firstLoad,
  chartType,
  refetchQueries,
  disabled,
  isSettingsEmpty = false,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const { accountId } = useGenericParams();
  assert(accountId);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["errors", "charts"]);
  const theme = useTheme();
  const viewsList = useDashboardViews();

  const session = useSession();
  const hasRightsToEdit =
    chartId && chartId.length
      ? session.hasSufficientRole(RoleCode.Editor)
      : false;

  const code = initialCode ?? defaultCode;

  const { data: selectedReport, loading: loadingReport } =
    useGetReportTemplateByCodeQuery({
      variables: {
        code,
      },
      skip: chartType !== CustomChartType.MonitorCrawlCompare,
    });

  const [updateChartSettings, { loading: chartUpdateRunning }] =
    useUpdateDashboardChartMutation({
      refetchQueries: refetchQueries ?? ["GetDashboardCharts"],
      awaitRefetchQueries: true,
      onError: (error) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("errors:chartUpdateError", { message: error.message })}
          />,
        );
      },
    });

  function setSelectedProjects(sp: View[]): void {
    if (chartId)
      updateChartSettings({
        variables: {
          customChartId: chartId,
          items: sp.map((e) => e.id),
        },
      });
  }

  const timeRangeOptions = useTimeRangeOptions(isLoading);

  const timeRange = mapDurationToTimeRange(duration);

  function setTimeRange(value: TimeRangeOptionValue): void {
    if (chartId)
      updateChartSettings({
        variables: {
          customChartId: chartId,
          metadata: mapTimeRangeToDuration(value),
        },
      });
  }

  function setCode(code: string): void {
    if (chartId)
      updateChartSettings({
        variables: {
          customChartId: chartId,
          metric: code,
        },
      });
  }

  const loading =
    chartUpdateRunning || isLoading || viewsList.loading || loadingReport;

  const showError =
    (!loading && Boolean(error)) ||
    (!viewsList.loading && Boolean(viewsList.error));

  const showChart = !firstLoad && !error;
  const showNoViews =
    !loading && !error && ((series && series.length === 0) || isSettingsEmpty);

  const chartRef = useRef(null);

  return (
    <Paper
      classes={{ root: classes.paperRoot }}
      data-testid="monitor-dashboard-chart"
    >
      <ResizeObserver
        onResize={() => invoke(get(chartRef.current, "chart"), "reflow")}
      />
      <div style={{ flexDirection: "column", display: "flex" }}>
        <div className={classes.header}>
          {chartType === CustomChartType.MonitorCrawlCompare ? (
            <ReportsCombobox
              classes={{
                root: classes.reportCombobox,
              }}
              variant="select"
              value={selectedReport?.getReportTemplates.nodes[0] || null}
              onChange={(reportTemplate) => {
                setCode((reportTemplate as ReportOption)?.code ?? "");
              }}
              disabled={loading || disabled || !hasRightsToEdit}
              data-pendo="monitor-project-comparison-chart-report-select"
              data-testid="project-comparison-chart-report-combobox"
              loading={loading}
              placeholder={t("charts:reportSelectPlaceholder")}
            />
          ) : (
            <HealthScoreCategoriesCombobox
              tooltip={t("charts:categories") as string}
              classes={{
                root: classes.reportCombobox,
              }}
              variant="select"
              value={{ code: code, name: "", id: "" }}
              onChange={(categoryCode) => {
                setCode((categoryCode as ReportCategoryOption)?.code ?? "");
              }}
              disabled={loading || disabled || !hasRightsToEdit}
              data-pendo="monitor-project-comparison-chart-categorie-select"
              data-testid="project-comparison-chart-category-combobox"
              loading={loading}
            />
          )}
          <Select
            id="demo-simple-select-filled"
            value={timeRange}
            data-pendo="monitor-date-range-select"
            data-testid="monitor-dashboard-chart-range-select"
            disabled={loading || disabled || !hasRightsToEdit}
            variant="outlined"
            className={classes.dateRangeSelect}
            IconComponent={ExpandMoreRoundedIcon}
            renderValue={(value) => {
              return (
                <span>
                  {timeRangeOptions[value as TimeRangeOptionValue].label}
                </span>
              );
            }}
            MenuProps={{
              PaperProps: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                "data-pendo": "monitor-date-range-select-dropdown",
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            onChange={(e) => {
              const newValue = e.target.value as TimeRangeOptionValue;
              setTimeRange(newValue);
            }}
          >
            {timeRangeOptions.map((option) => (
              <MenuItem
                value={option.value}
                key={option.value}
                className={classes.menuItem}
                data-testid={`monitor-dashboard-chart-time-range-item-${option.value}`}
                data-pendoid={`monitor-dashboard-chart-time-range-item`}
              >
                <div style={{ display: "flex" }}>
                  {option.label}
                  {option.value === timeRange ? (
                    <Check
                      style={{
                        width: 15,
                        height: 21,
                        strokeWidth: 4,
                        alignSelf: "right",
                        marginLeft: theme.spacing(1),
                      }}
                      htmlColor={theme.palette.primary.main}
                    />
                  ) : undefined}
                </div>
              </MenuItem>
            ))}
          </Select>
          <ViewsCombobox
            options={viewsList.data ?? []}
            disableBackground={true}
            disabled={(loading && firstLoad) || disabled || !hasRightsToEdit}
            value={views}
            onChange={(selection) => {
              setSelectedProjects(selection);
            }}
            maxSelection={100}
            minSelection={1}
            noSelectionText={t("charts:emptyViews")}
            data-pendo="monitor-project-comparison-chart-project-select"
          />
        </div>
        {firstLoad && (
          <ProjectComparisonChartInnerWrapper className={classes.innerWrapper}>
            <Skeleton variant="rect" className={classes.skeleton} />
          </ProjectComparisonChartInnerWrapper>
        )}
        {showChart && !showNoViews ? (
          <ProjectComparisonChartInnerWrapper className={classes.innerWrapper}>
            <LineChart
              chartType={chartType}
              allowChartUpdate={!isLoading}
              ref={chartRef}
              series={series}
              timeRange={timeRange}
              timeRangeOptions={timeRangeOptions}
              loading={isLoading}
              unit={
                selectedReport?.getReportTemplates.nodes[0]?.metadata?.unit ??
                ReportTemplateUnit.UrLs
              }
            />
          </ProjectComparisonChartInnerWrapper>
        ) : null}
        {showNoViews && !isSettingsEmpty && (
          <ProjectComparisonChartNoViewsState className={classes.emptyState} />
        )}
        {showNoViews && isSettingsEmpty && (
          <ProjectComparisonChartEmptyDashboardSettings
            className={classes.emptyState}
          />
        )}
        {showError && (
          <ProjectComparisonChartErrorState className={classes.emptyState} />
        )}
      </div>
    </Paper>
  );
}
