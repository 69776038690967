import {
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { DashboardTabLabel, EDIT_FIELD_WIDTH } from "./DashboardTabLabel";
import ResizeObserver from "react-resize-observer";
import { InView } from "react-intersection-observer";
import { MAX_TAB_LABEL_WIDTH, SpecialTab } from "../BoardPage";
import {
  CustomDashboardType,
  GetDashboardCollectionQuery,
  RoleCode,
} from "../../graphql";
import {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { DashboardNameEditor } from "./DashboardNameEditor";
import {
  PlusSolid,
  Snackbar,
  SwitchVerticalOutlined,
  ToggleIconButton,
  useTranslation,
  useSession,
  getApiAccountId,
} from "@lumar/shared";
import { DashboardTabList } from "./DashboardTabList";
import { useSnackbar } from "notistack";
import { assert } from "../../_common/assert";
import { differenceBy, intersectionBy, isEqual } from "lodash";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { DashboardTabsCreationMenu } from "./DashboardTabsCreationMenu";
import { DashboardTabReorderDialog } from "./DashboardTabReorderDialog";
import { useParams } from "react-router-dom";
import { useCreateDefaultOrCloneDashboard } from "../dashboards/utils/useCreateDefaultOrCloneDashboard";

const useStyles = makeStyles((theme) =>
  createStyles({
    editField: {
      maxWidth: EDIT_FIELD_WIDTH,
      width: EDIT_FIELD_WIDTH,
      marginBottom: theme.spacing(1.55),
      marginLeft: theme.spacing(0.5),
    },
    flexContainer: {
      overflow: "hidden",
    },
    tabs: {
      "& .MuiTab-wrapper": {
        alignItems: "start",
        display: "block",
      },
      "&.Mui-focusVisible": {
        boxShadow: "none",
      },
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      marginRight: theme.spacing(3),
      color: theme.palette.grey[700],
    },
    hiddenTab: {
      display: "none",
    },
    indicator: {
      height: 3,
    },
    plusButton: {
      backgroundColor: "transparent",
      color: theme.palette.grey[700],
      marginBottom: theme.spacing(1.8),
    },
    tabReorderButton: {
      backgroundColor: "transparent",
      color: theme.palette.grey[700],
      marginLeft: theme.spacing(-1.1),
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.8),
    },
    tooltip: {
      marginTop: -13,
    },
  }),
);

export interface AllDashboards {
  id: string;
  name: string;
  type: CustomDashboardType;
}

export interface DashboardTabProps {
  specialTab?: SpecialTab;
  setSpecialTab: Dispatch<SetStateAction<SpecialTab | undefined>>;
  data?: GetDashboardCollectionQuery;
  setCreationStarted: Dispatch<
    SetStateAction<{
      id?: string | undefined;
      type?: CustomDashboardType | undefined;
      name?: string | undefined;
    } | null>
  >;
  creationStarted: {
    id?: string | undefined;
    type?: CustomDashboardType | undefined;
    name?: string | undefined;
  } | null;
  totalDashboardsNum: number;
}

const ACTIVE_PART_WIDTH = 75;
const UNUSED_PART_WIDTH = 75;
const MAX_SUBDASHBOARDS_NUM = 30;

export function DashboardTabs(props: DashboardTabProps): JSX.Element {
  const { accountId, collectionId, dashboardId } = useParams<{
    accountId: string;
    collectionId: string;
    dashboardId: string;
  }>();
  assert(accountId);
  const { dashboardPage } = useMonitorRoutes();
  const classes = useStyles();
  const theme = useTheme();
  const { hasSufficientRole } = useSession();

  const readOnly = !hasSufficientRole(RoleCode.Editor);

  const { t } = useTranslation(["dashboards", "dashboardTabs"]);

  const [isTabsVisible, setTabsVisibility] = useState(true);

  const [isEditing, setIsEditing] = useState(false);

  const [visibleDashboardIds, setVisibleDashboardIds] = useState<
    { id: string }[]
  >([]);

  const [creationMenuAnchor, setCreationMenuAnchor] =
    useState<null | HTMLElement>(null);

  const { enqueueSnackbar } = useSnackbar();

  const {
    specialTab,
    data,
    creationStarted,
    setCreationStarted,
    totalDashboardsNum,
    setSpecialTab,
  } = props;

  const allDashboards = useMemo(
    () =>
      (data?.getCustomDashboardCollection?.customDashboards?.edges ?? []).map(
        (e) => e.node,
      ),
    [data],
  );

  const createDefaultOrCloneDashboard = useCreateDefaultOrCloneDashboard({
    accountId: getApiAccountId(accountId),
    collectionId,
    refetchQueries: ["GetDashboardCollection"],
  });

  useLayoutEffect(() => {
    const container = document.getElementById("DashboardTabs");
    const virtualDiv = document.getElementById("UsedForCalculations_div");
    const addButton = document.getElementById("addNewDashboardButton");
    const sortButton = document.getElementById("tabReorderButton");

    const { width: buttonWidth } = addButton?.getBoundingClientRect() ?? {
      width: 0,
    };
    const { width: sortButtonWidth } = sortButton?.getBoundingClientRect() ?? {
      width: 0,
    };
    const { width: parentWidth } = container?.getBoundingClientRect() ?? {
      width: 0,
    };

    const fullWidth =
      parentWidth -
      buttonWidth -
      sortButtonWidth -
      (creationStarted ? EDIT_FIELD_WIDTH : 0) -
      UNUSED_PART_WIDTH;
    const { x: virtualX, width: virtualWidth } =
      virtualDiv?.getBoundingClientRect() ?? {
        width: 0,
        x: 0,
      };
    if (
      fullWidth >= virtualWidth &&
      !isEqual(
        allDashboards.map((e) => ({
          id: e.id,
        })),
        visibleDashboardIds,
      )
    ) {
      setVisibleDashboardIds(allDashboards);
      return;
    }

    const compareWidth = fullWidth - ACTIVE_PART_WIDTH;

    const visibleChildrens = new Array<{ id: string }>();
    [...(virtualDiv?.children ?? [])].forEach((e) => {
      const { x: position, width } = e.getBoundingClientRect();

      if (position - virtualX + width <= compareWidth) {
        // eslint-disable-next-line fp/no-mutating-methods
        visibleChildrens.push({ id: e.id });
      }
    });
    if (!isEqual(visibleChildrens, visibleDashboardIds))
      setVisibleDashboardIds(visibleChildrens);
  }, [visibleDashboardIds, allDashboards, creationStarted, specialTab]);

  const visibleDashboards = useMemo(
    () =>
      intersectionBy(
        (data?.getCustomDashboardCollection?.customDashboards?.edges ?? []).map(
          (e) => e.node,
        ),
        visibleDashboardIds,
        "id",
      ),
    [visibleDashboardIds, data],
  );

  const invisibleDashboards = useMemo(
    () =>
      differenceBy(
        (data?.getCustomDashboardCollection?.customDashboards?.edges ?? []).map(
          (e) => e.node,
        ),
        visibleDashboardIds,
        "id",
      ),
    [visibleDashboardIds, data],
  );

  const invisibleDashboardsNum = invisibleDashboards.length;

  const currentDashboard =
    data?.getCustomDashboardCollection?.customDashboards?.edges.find(
      (e) => e.node.id === dashboardId,
    )?.node;

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  if (!dashboardId) return <></>;

  return (
    <>
      <div
        style={{
          position: "absolute",
          whiteSpace: "nowrap",
          visibility: "hidden",
        }}
        id="UsedForCalculations_div"
      >
        {specialTab ? (
          <Tab
            id={specialTab.id}
            value={specialTab.id}
            key={specialTab.id}
            disabled
            label={
              <DashboardTabLabel
                name={specialTab.name}
                id={specialTab.id}
                type={specialTab.type}
                disabled
                maxWidth={MAX_TAB_LABEL_WIDTH}
              />
            }
            className={classes.tabs}
          />
        ) : undefined}
        {data?.getCustomDashboardCollection?.customDashboards?.edges.map(
          (e) => {
            return specialTab?.id !== e.node.id ? (
              <Tab
                id={e.node.id}
                disabled
                label={
                  <DashboardTabLabel
                    name={e.node.name}
                    id={e.node.id}
                    type={e.node.type}
                    disabled
                    maxWidth={MAX_TAB_LABEL_WIDTH}
                  />
                }
                value={e.node.id}
                key={e.node.id}
                className={classes.tabs}
              />
            ) : undefined;
          },
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          maxWidth: "80%",
          minWidth: 300,
          width: "100%",
        }}
        id="DashboardTabs"
      >
        <ResizeObserver
          onResize={() => {
            setVisibleDashboardIds([...visibleDashboardIds]);
          }}
        />
        <InView
          as="div"
          onChange={(inView) => {
            if (inView !== isTabsVisible) setTabsVisibility(inView);
          }}
        >
          <Tabs
            value={dashboardId}
            onChange={(e, newStatus) => {
              dashboardPage.visit({
                accountId,
                collectionId,
                dashboardId: newStatus,
              });
            }}
            style={{
              borderBottom: 0,
              marginBottom: theme.spacing(2),
            }}
            classes={{
              flexContainer: classes.flexContainer,
              indicator: classes.indicator,
            }}
            scrollButtons="off"
          >
            {specialTab ? (
              <Tab
                value={specialTab.id}
                key={specialTab.id}
                data-pendo="board-dashboard-tab"
                data-testid="board-dashboard-tab"
                label={
                  <DashboardTabLabel
                    name={specialTab.name}
                    id={specialTab.id}
                    type={specialTab.type}
                    onEditStarted={() => setIsEditing(true)}
                    onEditEnded={(name) => {
                      setIsEditing(false);
                      if (name) {
                        setSpecialTab({ ...specialTab, name: name });
                      }
                    }}
                    maxWidth={MAX_TAB_LABEL_WIDTH}
                    disableDelete={totalDashboardsNum <= 1}
                    disableCloning={totalDashboardsNum >= MAX_SUBDASHBOARDS_NUM}
                    onClone={(id) =>
                      setCreationStarted({
                        id,
                        type: specialTab.type,
                        name: specialTab.name,
                      })
                    }
                  />
                }
                className={classes.tabs}
              />
            ) : undefined}
            {visibleDashboards.map((vd) => {
              return specialTab?.id !== vd.id ? (
                <Tab
                  id={vd.id}
                  data-pendo="board-dashboard-tab"
                  data-testid="board-dashboard-tab"
                  label={
                    <InView
                      as="div"
                      onChange={(inView, entry) => {
                        if (
                          !inView &&
                          isTabsVisible &&
                          !isEditing &&
                          entry.boundingClientRect.top > 0
                        ) {
                          setSpecialTab(vd);
                        }
                      }}
                    >
                      <DashboardTabLabel
                        name={vd.name}
                        id={vd.id}
                        type={vd.type}
                        onEditStarted={() => setIsEditing(true)}
                        onEditEnded={() => setIsEditing(false)}
                        maxWidth={MAX_TAB_LABEL_WIDTH}
                        disableDelete={totalDashboardsNum <= 1}
                        disableCloning={
                          totalDashboardsNum >= MAX_SUBDASHBOARDS_NUM
                        }
                        onClone={(id) => {
                          setCreationStarted({
                            id,
                            type: vd.type,
                            name: vd.name,
                          });
                        }}
                      />
                    </InView>
                  }
                  value={vd.id}
                  key={vd.id}
                  className={classes.tabs}
                />
              ) : undefined;
            })}
            {visibleDashboards.length
              ? invisibleDashboards.map((e) => {
                  if (
                    e.id === dashboardId &&
                    isTabsVisible &&
                    specialTab?.id !== e.id
                  ) {
                    setSpecialTab(e);
                  }
                  return specialTab?.id !== e.id ? (
                    <Tab
                      id={e.id}
                      data-pendo="board-dashboard-tab"
                      data-testid="board-dashboard-tab"
                      label={
                        <InView
                          as="div"
                          onChange={(inView, entry) => {
                            if (entry.boundingClientRect.y > 0) {
                              if (
                                e.id === dashboardId &&
                                !inView &&
                                isTabsVisible &&
                                !isEditing
                              ) {
                                setSpecialTab(e);
                              }
                            }
                          }}
                        >
                          <DashboardTabLabel
                            name={e.name}
                            id={e.id}
                            type={e.type}
                            onEditStarted={() => setIsEditing(true)}
                            onEditEnded={() => setIsEditing(false)}
                            maxWidth={MAX_TAB_LABEL_WIDTH}
                          />
                        </InView>
                      }
                      value={e.id}
                      key={e.id}
                      className={classes.hiddenTab}
                    />
                  ) : undefined;
                })
              : undefined}
          </Tabs>
        </InView>
        {Boolean(creationStarted) ? (
          <DashboardNameEditor
            minLength={1}
            id="create-new-subdashboard"
            value={
              creationStarted?.name ??
              (creationStarted?.type === CustomDashboardType.Monitor
                ? t("dashboardTabs:defaultSubDashboard")
                : t("dashboardTabs:defaultSubHealthscore"))
            }
            style={{ width: "100%", marginRight: theme.spacing(1) }}
            data-testid="edit-sub-dashboard-item-name-input"
            pendoPrefix="edit-sub-dashboard-item-name"
            refetchQueries={[
              "GetDashboardCollections",
              "GetDashboardCollectionsCount",
            ]}
            onFinishedEditing={async (name, reason) => {
              if (reason === "ok") {
                createDefaultOrCloneDashboard({
                  name: name ?? "Sub-dashboard",
                  dashboardId: creationStarted?.id,
                  type: creationStarted?.type ?? CustomDashboardType.Monitor,
                  views:
                    data?.getCustomDashboardCollection?.customViews.nodes.map(
                      ({ id }) => id,
                    ) ?? [],
                })
                  .catch((error) => {
                    enqueueSnackbar(
                      <Snackbar
                        variant="error"
                        title={t("errors:creatingSubdashboardError", {
                          message: error.message,
                        })}
                      />,
                    );
                  })
                  .then((id) => {
                    if (id) {
                      dashboardPage.visit({
                        accountId,
                        collectionId,
                        dashboardId: id,
                      });
                      enqueueSnackbar(
                        <Snackbar
                          variant="success"
                          title={t("dashboardTabs:creationSucces")}
                        />,
                      );
                    }
                  });
                setCreationStarted(null);
              } else if (reason === "cancel") setCreationStarted(null);
            }}
            variant="small"
            className={classes.editField}
          />
        ) : undefined}
        {Boolean(invisibleDashboardsNum) ? (
          <DashboardTabList
            dashboardNum={invisibleDashboardsNum}
            dashboards={invisibleDashboards}
            disableCloning={totalDashboardsNum >= MAX_SUBDASHBOARDS_NUM}
            disableDelete={totalDashboardsNum <= 1}
            onClone={(id, type, name) => setCreationStarted({ id, type, name })}
            onChange={(id) => {
              const dashboard = invisibleDashboards.find((e) => e.id === id);
              setSpecialTab(dashboard);
              dashboardPage.visit({
                accountId,
                collectionId,
                dashboardId: id,
              });
            }}
          />
        ) : undefined}
        <HideFromInsufficientRole>
          <>
            {allDashboards.length !== 1 ? (
              <Tooltip
                title="Reorder"
                arrow={false}
                classes={{ popper: classes.tooltip }}
              >
                <span id="tabReorderButton">
                  <ToggleIconButton
                    className={classes.tabReorderButton}
                    onClick={() => setIsDialogOpen(true)}
                    disabled={readOnly}
                    data-pendo="dashboard-tab-reorder-toggle-button"
                    data-testid="dashboard-tab-reorder-toggle-button"
                  >
                    <SwitchVerticalOutlined />
                  </ToggleIconButton>
                </span>
              </Tooltip>
            ) : undefined}
            {isDialogOpen && !readOnly ? (
              <DashboardTabReorderDialog
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                allDashboards={allDashboards}
              />
            ) : undefined}
            <Tooltip
              title={
                (totalDashboardsNum >= MAX_SUBDASHBOARDS_NUM
                  ? t("dashboardTabs:maxSubdashboards", {
                      count: MAX_SUBDASHBOARDS_NUM,
                    })
                  : t("dashboardTabs:createNewSubdashboard")) as string
              }
              arrow={false}
              classes={{ popper: classes.tooltip }}
            >
              <span id="addNewDashboardButton">
                <ToggleIconButton
                  disabled={totalDashboardsNum >= MAX_SUBDASHBOARDS_NUM}
                  id="add-sub-dashboard-button"
                  className={classes.plusButton}
                  size="medium"
                  data-pendo="add-sub-dashboard-button"
                  data-testid="add-sub-dashboard-button"
                  onClick={(e) => {
                    setCreationMenuAnchor(e.currentTarget);
                  }}
                  aria-label={
                    totalDashboardsNum >= MAX_SUBDASHBOARDS_NUM
                      ? t("dashboardTabs:maxSubdashboards", {
                          count: MAX_SUBDASHBOARDS_NUM,
                        })
                      : t("dashboardTabs:createNewSubdashboardSpeech")
                  }
                >
                  <PlusSolid />
                </ToggleIconButton>
              </span>
            </Tooltip>
            <DashboardTabsCreationMenu
              anchorEl={creationMenuAnchor}
              onAction={(type) => {
                setCreationMenuAnchor(null);
                setCreationStarted({
                  id: currentDashboard?.type === type ? dashboardId : undefined,
                  type: type,
                });
              }}
              onClose={() => {
                setCreationStarted(null);
                setVisibleDashboardIds([]);
                setCreationMenuAnchor(null);
              }}
            />
          </>
        </HideFromInsufficientRole>
      </div>
    </>
  );
}
