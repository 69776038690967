import { List, makeStyles } from "@material-ui/core";
import { LoadMoreOnScroll } from "../../../_common/components/LoadMoreOnScroll";
import { BasicProject } from "../utils/basicProject";
import { ProjectListItem } from "./ProjectListItem";

interface Props {
  projectList?: {
    node: BasicProject;
  }[];
  selectedProjects?: BasicProject[] | null;
  hasFetchedAllData: boolean;
  onListItemClick: (project: BasicProject, selected: boolean) => void;
  onScroll: () => void;
  searchTerm: string;
}

export const ProjectsList = ({
  projectList,
  selectedProjects,
  hasFetchedAllData,
  onListItemClick,
  onScroll,
  searchTerm,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <List className={classes.list} component="div" aria-label="Projects list">
      {projectList?.map(({ node }, index) => {
        const selected = Boolean(
          selectedProjects?.find((p) => p.id === node.id),
        );
        const isLastItem =
          hasFetchedAllData && index === projectList.length - 1;

        return (
          <ProjectListItem
            key={node.id}
            project={node}
            highlightedText={searchTerm}
            isLastItem={isLastItem}
            onListItemClick={onListItemClick}
            selectable
            selected={selected}
          />
        );
      })}
      {!hasFetchedAllData ? (
        <div style={{ height: 18, overflow: "hidden", marginTop: 8 }}>
          <LoadMoreOnScroll onLoadMore={onScroll} />
        </div>
      ) : null}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    padding: theme.spacing(0, 1),
    height: 350,
    overflowY: "scroll",
  },
}));
