import {
  ConnectionTestResultStatusFilterInput,
  TestResultStatus,
} from "../../graphql";
import { NotificationStatusFilter } from "../filters/constants";

export function getFilterFromNotificationStatusFilter(
  status: NotificationStatusFilter,
): ConnectionTestResultStatusFilterInput {
  if (status === NotificationStatusFilter.Adjustments) {
    return {};
  }
  if (status === NotificationStatusFilter.Unread) {
    return {
      notIn: [TestResultStatus.Read, TestResultStatus.WorkingOnIt],
    };
  }
  if (status === NotificationStatusFilter.Archived) {
    return { eq: TestResultStatus.Read };
  }
  return { eq: TestResultStatus.WorkingOnIt };
}
