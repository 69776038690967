import {
  Check,
  InformationCircleOutlined,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, Tooltip, useTheme } from "@material-ui/core";
import { AutocompleteRenderOptionState } from "@material-ui/lab";

export function AutocompleteOptionWithTooltip({
  title,
  description,
  summary,
  state,
  className,
}: {
  title: string;
  description?: string | null;
  summary?: string | null;
  state: AutocompleteRenderOptionState;
  className?: string;
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(["reports"]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
      data-testid={`report-item-${title.replaceAll(" ", "-")}`}
      className={className}
    >
      <Typography className={classes.text}>{title}</Typography>
      {summary || description ? (
        <Tooltip
          title={
            <>
              <>
                {summary ? (
                  <>
                    <strong style={{ display: "block" }}>
                      {t("reportTitleInfoFirst")}
                    </strong>
                    {summary}
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: theme.palette.grey[600],
                        height: 1,
                        margin: theme.spacing(2, 0, 1, 0),
                      }}
                    />
                  </>
                ) : undefined}
              </>
              <>
                {description ? (
                  <>
                    <strong style={{ display: "block" }}>
                      {summary ? t("reportTitleInfoSecond") : title}
                    </strong>
                    {description}
                  </>
                ) : undefined}
              </>
            </>
          }
          placement="top"
        >
          <InformationCircleOutlined className={classes.infoIcon} />
        </Tooltip>
      ) : undefined}
      {state.selected ? (
        <Check
          style={{ width: 15, height: 21, strokeWidth: 4 }}
          htmlColor={theme.palette.primary.main}
        />
      ) : null}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16.8),
    display: "block",
    color: theme.palette.grey[700],
    marginRight: "auto",
  },
  infoIcon: {
    fontSize: theme.typography.pxToRem(15),
    margin: theme.spacing(0.375),
    color: theme.palette.grey[400],
  },
}));
