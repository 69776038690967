import { SimpleTest } from "./types";

export function getThresholdTrendDataFromTestList(
  testList: SimpleTest[] | SimpleTest,
  code: string,
): [number, number][] | undefined {
  const test = Array.isArray(testList)
    ? testList.find((e) => e?.reportTemplateCode === code)
    : testList;

  return (test?.testResults ?? []).map((e) => [
    e.createdAt?.length ? new Date(e.createdAt).getTime() : 0,
    e.absoluteThreshold ?? 0,
  ]);
}
