import { ApolloCache } from "@lumar/shared";
import {
  GetReportNotificationsDocument,
  GetReportNotificationsQuery,
  GetReportNotificationsQueryVariables,
  Maybe,
  Segment,
  Test,
  TestResult,
} from "../../graphql";

export type PartialReportNotification = Omit<
  Partial<TestResult>,
  "reportStat" | "reportTemplate" | "test" | "segment"
> & {
  test?: Maybe<Omit<Partial<Test>, "testResults">>;
} & {
  segment?: Maybe<Partial<Segment>>;
};

export function updateReportNotificationsInCache<T>(
  cache: ApolloCache<T>,
  queryVariables: GetReportNotificationsQueryVariables,
  callback: (tr: PartialReportNotification) => PartialReportNotification,
): void {
  const cachedData: GetReportNotificationsQuery | null = cache.readQuery({
    query: GetReportNotificationsDocument,
    variables: queryVariables,
  });
  if (!cachedData?.getAccount) return;

  cache.writeQuery({
    query: GetReportNotificationsDocument,
    variables: queryVariables,
    broadcast: true,
    data: {
      ...cachedData,
      getAccount: {
        ...cachedData.getAccount,
        notifications: {
          ...cachedData.getAccount.notifications,
          edges: cachedData.getAccount.notifications.edges.map((e) => {
            return {
              ...e,
              node: { ...e.node, ...callback(e.node) },
            };
          }),
        },
      },
    },
  });
}
