import React from "react";
import { FaviconIcon, Typography } from "@lumar/shared";
import { ButtonBase, makeStyles, useTheme } from "@material-ui/core";
import Highlighter from "react-highlight-words";

const FAVICON_WIDTH = 24;

interface Props {
  name: string;
  primaryDomain: string;
  onClick?: () => void;
  selected: boolean;
  id?: string;
  searchTerm?: string;
  sign?: React.ReactNode;
}

export function AlertListItem({
  name,
  primaryDomain,
  onClick,
  selected,
  id,
  searchTerm,
  sign = <></>,
}: Props): JSX.Element {
  const classes = useStyles({ selected });
  const theme = useTheme();
  return (
    <ButtonBase
      onClick={onClick}
      className={classes.button}
      data-pendo="alert-list-item"
      data-testid={`alert-list-item-${id}`}
    >
      <FaviconIcon
        url={primaryDomain}
        height={FAVICON_WIDTH}
        width={FAVICON_WIDTH}
      />
      <div className={classes.textWrapper}>
        <div style={{ display: "flex", flexFlow: "row", width: "100%" }}>
          <Typography
            component="p"
            variant="subtitle2SemiBold"
            className={classes.primaryText}
            noWrap
            title={name}
          >
            <Highlighter
              textToHighlight={name}
              searchWords={[searchTerm ?? ""]}
              highlightStyle={{
                backgroundColor: theme.palette.ultraviolet[100],
              }}
              autoEscape={true}
            />
          </Typography>
          <div style={{ marginLeft: "auto" }}>{sign}</div>
        </div>
        <Typography
          className={classes.secondaryText}
          noWrap
          title={primaryDomain}
        >
          <Highlighter
            textToHighlight={primaryDomain}
            searchWords={[searchTerm ?? ""]}
            highlightStyle={{
              backgroundColor: theme.palette.ultraviolet[100],
            }}
            autoEscape={true}
          />
        </Typography>
      </div>
    </ButtonBase>
  );
}

const useStyles = makeStyles(({ spacing, typography, palette }) => {
  const selectedPrimaryTextColor = palette.primary.main;
  const selectedBackgroundColor = palette.ultraviolet[100];

  return {
    button: ({ selected }: { selected: boolean }) => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: spacing(1),
      borderRadius: 8,
      padding: 10,
      width: "100%",
      border: selected
        ? `1px solid ${palette.ultraviolet[300]}`
        : "1px solid transparent",
      background: selected ? selectedBackgroundColor : "transparent",
      "& p:first-of-type": {
        color: selected ? selectedPrimaryTextColor : palette.grey[500],
      },
      "&:hover": {
        backgroundColor: selected ? selectedBackgroundColor : palette.grey[100],
        "& p:first-of-type": {
          color: selectedPrimaryTextColor,
        },
      },
    }),
    textWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: 15,
      width: `calc(100% - ${FAVICON_WIDTH}px)`,
      textAlign: "left",
    },
    primaryText: {
      fontSize: typography.pxToRem(14),
      lineHeight: typography.pxToRem(17),
      width: "100%",
      flex: 1,
    },
    secondaryText: {
      fontSize: typography.pxToRem(12),
      lineHeight: typography.pxToRem(15),
      color: palette.grey[500],
      fontWeight: 400,
      width: "100%",
    },
  };
});
