import { Typography, Trans, useTranslation } from "@lumar/shared";
import { makeStyles, Tooltip, TooltipProps, useTheme } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { AbsoluteURLs } from "../../../../_common/routing/absoluteURLs";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  popper: {
    pointerEvents: "inherit",
  },
}));

interface AccountViewsTooltipProps extends Omit<TooltipProps, "title"> {
  show: "account" | "limit" | null;
}

export function AccountViewsTooltip({
  show,
  children,
  ...props
}: AccountViewsTooltipProps): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation("board");
  const theme = useTheme();
  const classes = useStyles();

  const title = (): NonNullable<React.ReactNode> => {
    switch (show) {
      case "limit":
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="subtitle3SemiBold"
              style={{ marginBottom: theme.spacing(1.125) }}
            >
              {t("disabledButtonTooltip.part1")}
            </Typography>
            <Typography variant="caption">
              <Trans
                ns="board"
                i18nKey={"disabledButtonTooltip.part2"}
                components={{
                  tlink: (
                    <a
                      href={AbsoluteURLs.EXTERNAL__AccountsSubscription.getUrl(
                        accountId,
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              />
            </Typography>
          </div>
        );
      case "account":
        return (
          <Typography
            variant="subtitle3SemiBold"
            style={{ marginBottom: theme.spacing(1.125) }}
          >
            {t("disabledButtonTooltip.account")}
          </Typography>
        );
      default:
        return "";
    }
  };

  return (
    <Tooltip
      {...props}
      classes={{
        ...props.classes,
        popper: clsx(props.classes?.popper, classes.popper),
      }}
      title={title()}
    >
      {children}
    </Tooltip>
  );
}
