import React, { ReactNode, useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import {
  ExclamationSolid,
  ExclamationCircleSolid,
  SelectableButtonGroup,
} from "@lumar/shared";
import { useTranslation } from "react-i18next";
import { Severity } from "../../graphql";

const useStyles = makeStyles({
  readOnly: {
    pointerEvents: "none",
  },
});

interface Props {
  isDisabled: boolean;
  severity: Severity;
  onSeverityChange: (severity: Severity) => void;
}

function arePropsEqual(oldProps: Props, newProps: Props): boolean {
  return (
    oldProps.isDisabled === newProps.isDisabled &&
    oldProps.severity === newProps.severity
  );
}

export const SeverityButtons = React.memo(function Buttons({
  isDisabled,
  severity,
  onSeverityChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(["notifications"]);

  const SEVERITY_OPTIONS = useMemo(
    () => [
      {
        value: Severity.Warning,
        label: t(Severity.Warning),
        hideLabel: true,
        tooltipTitle: t(Severity.Warning),
        "data-testid": "warning-severity-button",
        "data-pendo": "rule-warning-severity-button",
        // eslint-disable-next-line react/display-name
        startIcon: (isSelected: boolean): ReactNode => (
          <ExclamationSolid
            style={{ fontSize: theme.typography.pxToRem(16) }}
            htmlColor={
              isSelected ? theme.palette.yellow[400] : theme.palette.grey[200]
            }
          />
        ),
      },
      {
        value: Severity.Fail,
        label: t(Severity.Fail),
        hideLabel: true,
        tooltipTitle: t(Severity.Fail),
        "data-testid": "critical-severity-button",
        "data-pendo": "rule-critical-severity-button",
        // eslint-disable-next-line react/display-name
        startIcon: (isSelected: boolean): ReactNode => (
          <ExclamationCircleSolid
            style={{ fontSize: theme.typography.pxToRem(16) }}
            htmlColor={
              isSelected ? theme.palette.red[400] : theme.palette.grey[200]
            }
          />
        ),
      },
    ],
    [
      theme.palette.grey,
      theme.palette.red,
      theme.palette.yellow,
      theme.typography,
      t,
    ],
  );

  return (
    <SelectableButtonGroup
      dataTestId="severity-button-group"
      className={isDisabled ? classes.readOnly : undefined}
      colorVariant="muted"
      value={severity}
      onValueChange={onSeverityChange}
      options={SEVERITY_OPTIONS}
      size="small"
    />
  );
}, arePropsEqual);
