import { SimpleTestResult } from "../../alerts/_common/utils/types";

export function extractChartDataFromTestResults(
  thresholdTrend: Array<SimpleTestResult> | undefined,
): [number, number][] {
  if (thresholdTrend?.length) {
    return thresholdTrend
      .filter((e) => e.createdAt?.length && e.absoluteThreshold !== undefined)
      .map((trend) => [
        (trend.createdAt?.length
          ? new Date(trend.createdAt)
          : new Date()
        ).getTime(),
        trend.absoluteThreshold ?? 0,
      ]);
  }

  return [];
}
