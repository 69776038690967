import { useMemo } from "react";
import { useTranslation } from "@lumar/shared";

function getTimeNDaysAgoInMulliseconds(daysAgo: number): number {
  const today = new Date();
  today.setDate(today.getDate() - daysAgo);
  today.setHours(0, 0, 0, 0);
  return today.getTime();
}

function getTimeSixMonthsAgoInMulliseconds(): number {
  const today = new Date();
  today.setMonth(today.getMonth() - 6);
  today.setHours(0, 0, 0, 0);
  return today.getTime();
}

export enum TimeRangeOptionValue {
  "last_7_days",
  "last_30_days",
  "last_90_days",
  "last_6_months",
}

export interface TimeRangeOption {
  value: TimeRangeOptionValue;
  label: string;
  startingTimeInMilliseconds: number;
  disabled: boolean;
  "data-pendo": string;
}

export function useTimeRangeOptions(isLoading: boolean): TimeRangeOption[] {
  const { t } = useTranslation("projectComparisonChart");

  return useMemo(
    () => [
      {
        value: TimeRangeOptionValue["last_7_days"],
        label: t("timeRangeOptions.last7Days"),
        startingTimeInMilliseconds: getTimeNDaysAgoInMulliseconds(7),
        disabled: isLoading,
        "data-pendo": "monitor-project-comparison-chart-time-range-7-days",
      },
      {
        value: TimeRangeOptionValue["last_30_days"],
        label: t("timeRangeOptions.last30Days"),
        startingTimeInMilliseconds: getTimeNDaysAgoInMulliseconds(30),
        disabled: isLoading,
        "data-pendo": "monitor-project-comparison-chart-time-range-30-days",
      },
      {
        value: TimeRangeOptionValue["last_90_days"],
        label: t("timeRangeOptions.last90Days"),
        startingTimeInMilliseconds: getTimeNDaysAgoInMulliseconds(90),
        disabled: isLoading,
        "data-pendo": "monitor-project-comparison-chart-time-option-90-days",
      },
      {
        value: TimeRangeOptionValue["last_6_months"],
        label: t("timeRangeOptions.last6Months"),
        startingTimeInMilliseconds: getTimeSixMonthsAgoInMulliseconds(),
        disabled: isLoading,
        "data-pendo": "monitor-project-comparison-chart-time-option-6-months",
      },
    ],
    // NOTE: Want to recalculate current time every time theres a change (via a change to the
    // selected projects or selected metric) so that our x range doesn't become stale, stuck to the
    // time of the component being first mounted. - Saul.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, t],
  );
}
