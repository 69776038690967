import { TimeRangeOptionValue } from "./useTimeRangeOptions";

export function mapDurationToTimeRange(
  duration?: Record<string, string>,
): TimeRangeOptionValue {
  if (duration && "trendRange" in duration) {
    const trendRangeCode = duration["trendRange"];

    switch (trendRangeCode) {
      case "last_7_days":
        return TimeRangeOptionValue["last_7_days"];
      case "last_30_days":
        return TimeRangeOptionValue["last_30_days"];
      case "last_90_days":
        return TimeRangeOptionValue["last_90_days"];
      case "last_6_months":
        return TimeRangeOptionValue["last_6_months"];
      default:
        return TimeRangeOptionValue["last_30_days"];
    }
  }
  return TimeRangeOptionValue["last_30_days"];
}

export function mapTimeRangeToDuration(
  timeRange: TimeRangeOptionValue,
): Record<string, string> {
  switch (timeRange) {
    case TimeRangeOptionValue.last_30_days:
      return { trendRange: "last_30_days" };
    case TimeRangeOptionValue.last_90_days:
      return { trendRange: "last_90_days" };
    case TimeRangeOptionValue.last_6_months:
      return { trendRange: "last_6_months" };
    default:
      return { trendRange: "last_7_days" };
  }
}
