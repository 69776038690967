/* eslint-disable fp/no-mutating-methods */
import {
  DragAndSort,
  Typography,
  useTranslation,
  Snackbar,
} from "@lumar/shared";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
} from "@material-ui/core";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { useState } from "react";
import { AllDashboards } from "./DashboardTabs";
import {
  CustomDashboardType,
  useGetDashboardCollectionQuery,
  useSortCustomDashboardCollectionMutation,
} from "../../graphql";
import { useGenericParams } from "../../_common/routing/useGenericParams";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../_common/utils/getErrorMessage";
import clsx from "clsx";
import dropIndicator from "../../_common/images/drop-indicator-line.png";
import { useHealthScoreEnabled } from "../../_common/hooks/useHealthScoreEnabled";

const useStyles = makeStyles((theme) => ({
  dialogPaper: { width: 440, height: 470 },
  sortIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[300],
  },
  dialogTitle: {
    height: 63,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    "& .list-container > .droppable-placeholder ~ .droppable-placeholder": {
      display: "none",
    },
  },
  sortItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    cursor: "move",
    padding: theme.spacing(0.4, 0, 0.3, 0),
    "&:active": {
      backgroundColor: "white",
      borderRadius: 6,
      boxShadow: theme.shadows[3],
    },
  },
}));

export interface TabReorderDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (value: boolean) => void;
  allDashboards: AllDashboards[];
}

export function DashboardTabReorderDialog(
  props: TabReorderDialogProps,
): JSX.Element {
  const { isDialogOpen, setIsDialogOpen, allDashboards } = props;
  const { accountId, collectionId } = useGenericParams();
  const { t } = useTranslation("dashboardTabs");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [sortDashboards] = useSortCustomDashboardCollectionMutation();

  const [itemList, setItemList] = useState<AllDashboards[]>(allDashboards);

  const hasHealthScoreEnabled = useHealthScoreEnabled();

  const { data, loading, error } = useGetDashboardCollectionQuery({
    variables: {
      accountId,
      customCollectionId: collectionId,
      types: [CustomDashboardType.HealthScores],
    },
    skip: hasHealthScoreEnabled,
  });

  const canShow =
    !loading && !Boolean(error) && (Boolean(data) || hasHealthScoreEnabled);

  const handleDrop = (result: DropResult): void => {
    if (!result.destination) return;
    const newList = [...itemList];
    const [reorderedItem] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, reorderedItem);
    setItemList(newList);
  };

  async function handleSave(): Promise<boolean> {
    try {
      await sortDashboards({
        variables: {
          collectionId: collectionId,
          dashboardIds: [
            ...(!hasHealthScoreEnabled
              ? (data?.getCustomDashboardCollection?.customDashboards?.edges.map(
                  (e) => e.node.id,
                ) ?? [])
              : []),
            ...itemList.map((i) => i.id),
          ],
        },
        refetchQueries: ["GetDashboardCollection"],
      });
      enqueueSnackbar(
        <Snackbar variant="success" title={t("updatedSuccessfully")} />,
      );
      setIsDialogOpen(false);
    } catch (error) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("updateFailed", { message: getErrorMessage(error) })}
        />,
      );
      setIsDialogOpen(false);
      return false;
    }
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getItemStyle = (
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot,
  ): React.CSSProperties => ({
    ...style,
    transform: snapshot.isDragging ? style?.transform : "none",
  });

  return (
    <Dialog
      open={isDialogOpen && canShow}
      onClose={() => setIsDialogOpen(false)}
      fullWidth
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle
        id="tab-reordering-dialog-title"
        className={classes.dialogTitle}
      >
        {t("dialogTitle")}
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable
            droppableId="list-container"
            direction="vertical"
            ignoreContainerClipping
          >
            {(provided) => {
              return (
                <div
                  className="list-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {itemList.map((d, index) => (
                    <Draggable key={d.id} draggableId={`${d.id}`} index={index}>
                      {(provided, snapshot) => {
                        return (
                          <>
                            {!snapshot.isDragging &&
                            provided.draggableProps.style?.transform ? (
                              <div
                                className="droppable-placeholder"
                                style={{
                                  height: 10,
                                  width: "100%",
                                  backgroundImage: `url(${dropIndicator})`,
                                  backgroundRepeat: "no-repeat",
                                }}
                              ></div>
                            ) : undefined}
                            <div
                              className={clsx(
                                classes.sortItem,
                                !snapshot.isDragging &&
                                  provided.draggableProps.style?.transform
                                  ? "sort-item"
                                  : undefined,
                              )}
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              style={getItemStyle(
                                provided.draggableProps.style,
                                snapshot,
                              )}
                            >
                              <DragAndSort className={classes.sortIcon} />
                              <Typography variant="body2">{d.name}</Typography>
                            </div>
                          </>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setIsDialogOpen(false)}
          data-pendo="dashboard-tab-reorder-cancel"
          data-testid="dashboard-tab-reorder-cancel"
        >
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={handleSave}
          data-pendo="dashboard-tab-reorder-save"
          data-testid="dashboard-tab-reorder-save"
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
