import { ApolloError, useSession, useTranslation } from "@lumar/shared";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  CustomChartType,
  RoleCode,
  useProjectComparisonChartQuery,
} from "../../../../graphql";
import { ProjectComparisonChart } from "../../common/project-comparison-chart/ProjectComparisonChart";
import { useDashboardViews } from "../../../components/DashboardViewsProvider";
import { mapProjectComparisonChartDataToSeries } from "./helpers/mapProjectComparisonChartDataToSeries";
import { DEFAULT_REPORT_TEMPLATE_CODE } from "../../utils/useCreateDefaultOrCloneDashboard";
import { SimpleCustomChart } from "../../utils/types";

interface Props {
  chart?: SimpleCustomChart;
  isLoading: boolean;
  error?: ApolloError;
}

export function TrendComparisonChart({
  chart,
  isLoading,
  error,
}: Props): JSX.Element | null {
  const { accountId, dashboardId } = useParams<{
    accountId: string;
    dashboardId: string;
    collectionId: string;
  }>();

  const { t } = useTranslation(["errors", "common"]);

  const session = useSession();
  const hasPermissions = session.hasSufficientRole(RoleCode.Editor);

  const dashboardExists = Boolean(dashboardId);

  const dashboardViews = useDashboardViews();

  const skip = isLoading || !dashboardExists || !Boolean(chart?.id);

  const {
    data: chartData,
    loading: loadingChartData,
    error: errorLoadingChartData,
    previousData: previousChartData,
  } = useProjectComparisonChartQuery({
    skip: skip,
    variables: {
      chartId: chart?.id,
      dashboardId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const series = useMemo(
    () =>
      mapProjectComparisonChartDataToSeries({
        data: loadingChartData ? undefined : chartData,
        accountId,
        reportTemplateCode: chart?.metric ?? DEFAULT_REPORT_TEMPLATE_CODE,
        views: dashboardViews.data ?? [],
        noSegmentText: t("common:noSegments"),
      }),
    [loadingChartData, chartData, accountId, chart, dashboardViews.data, t],
  );

  const firstLoad =
    !Boolean(previousChartData) && (loadingChartData || isLoading);

  return (
    <ProjectComparisonChart
      chartType={CustomChartType.MonitorCrawlCompare}
      defaultCode={DEFAULT_REPORT_TEMPLATE_CODE}
      isLoading={isLoading || loadingChartData}
      isSettingsEmpty={
        !Boolean(
          chartData?.getCustomDashboard?.customDashboardViews?.totalCount,
        )
      }
      series={series ?? []}
      chartId={chart?.id}
      code={chart?.metric}
      duration={chart?.metadata}
      error={error ?? errorLoadingChartData}
      firstLoad={firstLoad}
      views={chart?.items ?? []}
      disabled={!hasPermissions}
      refetchQueries={["GetDashboardCharts", "ProjectComparisonChart"]}
    />
  );
}
