import { AppBar, makeStyles, Toolbar } from "@material-ui/core";
import {
  AccountOptions,
  AppThemeProvider,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { useGenericParams } from "../_common/routing/useGenericParams";
import assert from "assert";
import { useAccountMenuItems } from "./useAccountMenuItems";
import { useRef } from "react";
import { NotificationsMenu } from "../notifications/dropdown-menu/NotificationsMenu";
import { useHasMonitorApp } from "../_common/hooks/useHasMonitorApp";

const TOP_NAVIGATION_HEIGHT = 70;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "transparent",
    minHeight: "0px",
    padding: "0px",
  },
  appBar: {
    backgroundColor: "unset",
    zIndex: 9,
  },
  accountLogo: {
    maxHeight: "100%",
  },
  boxItems: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  appControlsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: TOP_NAVIGATION_HEIGHT,
    width: "100%",
  },
  accountMenuWrapper: {
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  children?: React.ReactNode;
  includeNotificationsMenu?: boolean;
}

export function TopNav({
  children,
  includeNotificationsMenu = true,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["menuitems"]);
  const { accountId } = useGenericParams();

  const accountMenuRef = useRef<HTMLDivElement | null>(null);

  assert(accountId);

  const session = useSession();
  const hasMonitor = useHasMonitorApp();

  const menuItems = useAccountMenuItems(
    session?.role?.code,
    session.isDeepCrawlAdmin,
  );

  return (
    <AppBar className={classes.appBar} position="relative" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <AppThemeProvider>
          <div className={classes.appControlsContainer}>
            {children}
            <div className={classes.boxItems}>
              {includeNotificationsMenu && hasMonitor ? (
                <NotificationsMenu anchorRef={accountMenuRef} />
              ) : null}

              <div ref={accountMenuRef} className={classes.accountMenuWrapper}>
                <AccountOptions
                  accountName={session?.account?.name}
                  accountId={t("accountID", { accountId: accountId })}
                  email={session?.email}
                  role={
                    session.isDeepCrawlAdmin
                      ? "ADMINISTRATOR"
                      : session?.role?.name?.toUpperCase()
                  }
                  menuItems={menuItems}
                  logo={
                    session.account.customLogo && (
                      <img
                        src={session.account.customLogo}
                        className={classes.accountLogo}
                      />
                    )
                  }
                  pendoPrefix="monitor"
                />
              </div>
            </div>
          </div>
        </AppThemeProvider>
      </Toolbar>
    </AppBar>
  );
}
