import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { InputLabel, makeStyles, Select } from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { useTranslation, useSession } from "@lumar/shared";
import { RoleCode } from "../../graphql";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    // Note: these styles that ensure that the input label is visually hidden, but not hidden from screen readers (for accessibility reasons)
    border: 0,
    clip: "rect(1px, 1px, 1px, 1px)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    width: 1,
  },
  menuTitle: {
    color: theme.palette.grey[600],
    height: "35px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
  },
  icon: { height: 20, width: 20, marginRight: 12 },
  iconSmall: { height: 16, width: 16, marginRight: 8 },
  orangeIcon: { color: theme.palette.orange[600] },
  greenIcon: { color: theme.palette.green[600] },
  checkIcon: {
    marginLeft: "12px",
    height: 16,
    width: 16,
    color: theme.palette.primary.main,
  },
}));

export enum ThresholdStatus {
  Accept,
  Decline,
  None,
}

interface Props {
  onChange: (status: ThresholdStatus) => void;
}

export const BulkUpdateThresholdMenu = ({ onChange }: Props): JSX.Element => {
  const { t } = useTranslation("notifications");
  const session = useSession();
  const classes = useStyles();
  const hasEditRights = session.hasSufficientRole(RoleCode.Editor);

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>): void => {
    const newStatus = e.target.value as ThresholdStatus;
    onChange(newStatus);
  };

  return (
    <>
      <InputLabel className={classes.inputLabel} id="label">
        {t("bulkUpdateThreshold")}
      </InputLabel>
      <Select
        labelId="label"
        id="select"
        value={ThresholdStatus.None}
        variant="outlined"
        disabled={!hasEditRights}
        style={{ width: 192, height: 32, overflow: "hidden" }}
        IconComponent={ExpandMoreRoundedIcon}
        data-testid="bulk-update-threshold-menu"
        data-pendo="bulk-update-threshold-menu"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        onChange={handleChange}
        renderValue={(value) => {
          if (value === ThresholdStatus.Accept) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {t("accept")}
              </div>
            );
          }

          if (value === ThresholdStatus.Decline) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {t("decline")}
              </div>
            );
          }

          return <>{t("acceptance")}</>;
        }}
      >
        <MenuItem
          value={ThresholdStatus.Accept}
          data-testid="accept-suggested-menu-item"
          data-pendo="accept-suggested-menu-item"
        >
          {t("accept")}
        </MenuItem>
        <MenuItem
          value={ThresholdStatus.Decline}
          data-testid="decline-suggested-menu-item"
          data-pendo="decline-suggested-menu-item"
        >
          {t("decline")}
        </MenuItem>
        <MenuItem value={ThresholdStatus.None} style={{ display: "none" }} />
      </Select>
    </>
  );
};
