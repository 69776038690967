import {
  TrashSolid,
  Typography,
  updateIfPropsChanged,
  useTranslation,
  useSession,
} from "@lumar/shared";
import {
  ModuleCode,
  RoleCode,
  Segment,
  ThresholdPredicate,
} from "../../graphql";
import {
  makeStyles,
  createStyles,
  Tooltip,
  IconButton,
  useTheme,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import {
  FastField,
  FieldArray,
  FieldArrayRenderProps,
  FieldProps,
  FormikState,
  useFormikContext,
} from "formik";
import { RulesAndThresholdsFormState } from "./utils/types";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { SeverityButtons } from "./SeverityButtons";
import { ThresholdPredicateButtons } from "./ThresholdPredicateButtons";
import {
  ReportCategoryOption,
  ReportOption,
} from "../../_common/utils/constants";
import { LeftBottomPopper } from "../../_common/components/CustomPopper/LeftBottomPopper";
import { RulesAndThresholdsTableHeader } from "./RulesAndThresholdsTableHeader";
import clsx from "clsx";
import { SegmentComboBox } from "../../_common/components/SegmentComboBox";
import React from "react";
import { useParams } from "react-router-dom";
import { DEFAULT_HS_RULE_AND_THRESHOLDS } from "../create/constants";
import { useTotalRulesCount } from "./utils/useTotalRulesCount";
import {
  CategoryChangeTitle,
  CategorySegmentTitle,
  CategoryTitle,
  URL_THRESHOLD_WIDTH,
} from "./table/RulesAndThresholdsTableTitles";
import { useReportCategoriesTree } from "../../_common/hooks/useReportCategoriesTree";
import { ReportCategoriesCombobox } from "../../_common/components/reports/ReportCategoriesCombobox";
import { useInitialHealthScoreThresholdCalculator } from "./utils/useInitialHSThresholdCalculator";
import { RulesAndThresholdsThresholdField } from "./components/RulesAndThresholdsThresholdField";
import { getValidationError } from "./utils/formikHelpers";
import { MAX_NUMBER_OF_RULES } from "./ReportRulesAndThresholdsEditTable";
import { AddRuleButton } from "./components/AddRuleButton";
import { IssuesImprovesCell } from "./components/IssueImprovesCell";
import { IssueWorsensCell } from "./components/IssueWorsensCell";
import { HealthScoreTrendVisualisationCell } from "./components/HealthScoreTrendVisualisationCell";
import { v4 as uuid } from "uuid";

export function CategoryRulesAndThresholdsEditTable(props: {
  reports: ReportOption[];
  hasError: boolean;
  projectId: string;
  crawlId: string;
  className?: string;
  moduleCode?: ModuleCode;
}): JSX.Element {
  const { values, errors } = useFormikContext<RulesAndThresholdsFormState>();

  const [{ loading: categoriesLoading }, { getCategoryList }] =
    useReportCategoriesTree(props.reports, true);

  const options = getCategoryList(2).map((e) => ({
    id: e.code,
    code: e.code,
    name: e.name,
    description: e.description,
    level: e.level,
  }));

  const addPossibleCategoryList = options.filter(
    (element) =>
      !Boolean(
        values.categoryRulesAndThresholds?.find(
          (e) => e.report?.code === element?.code && !Boolean(e.segment),
        ),
      ),
  );

  const { accountId } = useParams<{ accountId: string }>();
  const { maxRulesCount, totalRulesCount, loading, error } = useTotalRulesCount(
    accountId,
    props.projectId,
  );

  const session = useSession();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = session;

  const classes = useStyles();
  const { t } = useTranslation(["alerts", "units", "common"]);
  const theme = useTheme();

  const [{}, calculateInitialThreshold] =
    useInitialHealthScoreThresholdCalculator();

  const readOnly = !session.hasSufficientRole(RoleCode.Editor);

  const accountLimitReached =
    totalRulesCount +
      (values.categoryRulesAndThresholds.length +
        values.reportRulesAndThresholds.length) >=
    maxRulesCount;

  const disableAddButton =
    loading ||
    Boolean(error) ||
    accountLimitReached ||
    addPossibleCategoryList.length === 0;

  const hasCategoryRules = Boolean(values.categoryRulesAndThresholds.length);
  return (
    <div className={clsx(props.className, classes.container)}>
      <Typography variant="subtitle2SemiBold" className={classes.title}>
        {t("alerts:healthscores")}
      </Typography>
      <div className={classes.scrollableContainer}>
        <FieldArray
          name="categoryRulesAndThresholds"
          render={(arrayHelpers) => {
            const { setFieldTouched, setFieldValue, setFormikState } =
              arrayHelpers.form;
            const values = arrayHelpers.form
              .values as RulesAndThresholdsFormState;
            return (
              <>
                {hasCategoryRules ? (
                  <div className={classes.wrapper}>
                    <Table className={classes.table}>
                      <RulesAndThresholdsTableHeader
                        typeCell={<CategoryTitle />}
                        segmentCell={<CategorySegmentTitle />}
                        changeCell={<CategoryChangeTitle />}
                      />
                      <TableBody>
                        {values.categoryRulesAndThresholds.map(
                          (ruleAndThreshold, index) => {
                            const reportsErrorMessage = getValidationError(
                              errors.categoryRulesAndThresholds,
                              index,
                            );
                            const hasUniqueError =
                              typeof reportsErrorMessage === "string";
                            return (
                              <React.Fragment key={index}>
                                <TableRow
                                  className={classes.tableRow}
                                  data-testid={`category-rule-and-threshold-row-${index}`}
                                  data-testid-2={`category-rule-and-threshold-row-${ruleAndThreshold.report?.name.replace(
                                    " ",
                                    "-",
                                  )}${
                                    ruleAndThreshold.segment
                                      ? `-${ruleAndThreshold.segment?.name.replace(
                                          " ",
                                          "-",
                                        )}`
                                      : ""
                                  }`}
                                >
                                  <TableCell className={classes.rowCell}>
                                    <SeverityButtons
                                      isDisabled={readOnly}
                                      severity={ruleAndThreshold.severity}
                                      onSeverityChange={(newSeverity) => {
                                        const fieldName = `categoryRulesAndThresholds[${index}].severity`;
                                        setFieldValue(fieldName, newSeverity);
                                        setFieldTouched(fieldName, true);
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell className={classes.rowCell}>
                                    <FastField
                                      name={`categoryRulesAndThresholds[${index}].report`}
                                      shouldUpdate={updateIfPropsChanged([
                                        "predicate",
                                        "urls",
                                        "error",
                                        "sortedList",
                                        "loading",
                                      ])}
                                      report={ruleAndThreshold.report}
                                      urls={ruleAndThreshold.urls}
                                      predicate={ruleAndThreshold.threshold}
                                      error={hasUniqueError ? "" : undefined}
                                      loading={categoriesLoading}
                                    >
                                      {(
                                        fieldProps: FieldProps<ReportCategoryOption>,
                                      ) => {
                                        const error =
                                          hasUniqueError ||
                                          Boolean(
                                            Array.isArray(errors)
                                              ? errors[index]?.["report"]
                                              : false,
                                          );

                                        return (
                                          <ReportCategoriesCombobox
                                            options={options}
                                            size="small"
                                            data-testid="rule-and-threshold-report-combobox"
                                            disableClearable
                                            disabled={readOnly}
                                            value={fieldProps.field.value}
                                            id={fieldProps.field.name}
                                            data-pendo="rule-and-threshold-report-combobox"
                                            PopperComponent={LeftBottomPopper}
                                            onBlur={fieldProps.field.onBlur}
                                            maxWidth={1000}
                                            error={error}
                                            classes={{
                                              paper: classes.paper,
                                              option: classes.option,
                                            }}
                                            onFocus={() =>
                                              setFieldTouched(
                                                `categoryRulesAndThresholds[${index}].report`,
                                                true,
                                              )
                                            }
                                            loading={categoriesLoading}
                                            multiple={false}
                                            onChange={async (o) => {
                                              if (!readOnly) {
                                                const nvalue =
                                                  await calculateInitialThreshold(
                                                    props.projectId,
                                                    ruleAndThreshold.segment
                                                      ?.id,
                                                    o.code,
                                                    ruleAndThreshold.threshold,
                                                  );

                                                const urlField = `categoryRulesAndThresholds[${index}].urls`;
                                                setFieldValue(urlField, nvalue);
                                                setFieldTouched(urlField, true);
                                                const reportName = `categoryRulesAndThresholds[${index}].report`;
                                                setFieldValue(reportName, o);
                                                setFieldTouched(
                                                  reportName,
                                                  true,
                                                );
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    </FastField>
                                  </TableCell>
                                  {segmentationAvailable && (
                                    <TableCell className={classes.rowCell}>
                                      <FastField
                                        data-pendo="category-rule-and-threshold-segment-combobox"
                                        name={`categoryRulesAndThresholds[${index}].segment`}
                                        size="small"
                                        component={SegmentComboBox}
                                        projectId={props.projectId}
                                        report={ruleAndThreshold.report}
                                        urls={ruleAndThreshold.urls}
                                        predicate={ruleAndThreshold.threshold}
                                        error={hasUniqueError}
                                        selected={ruleAndThreshold.segment}
                                        classes={{
                                          input: classes.segmentTextField,
                                        }}
                                        shouldUpdate={updateIfPropsChanged([
                                          "error",
                                          "predicate",
                                          "urls",
                                          "report",
                                          "projectId",
                                        ])}
                                        onChange={async (
                                          e: Pick<Segment, "id" | "name">,
                                        ) => {
                                          if (!readOnly) {
                                            const nvalue =
                                              await calculateInitialThreshold(
                                                props.projectId,
                                                e?.id,
                                                ruleAndThreshold.report.code,
                                                ruleAndThreshold.threshold,
                                              );
                                            const urlFieldName = `categoryRulesAndThresholds[${index}].urls`;
                                            setFieldValue(urlFieldName, nvalue);
                                            setFieldTouched(urlFieldName, true);
                                            const urlSegmentName = `categoryRulesAndThresholds[${index}].segment`;
                                            setFieldValue(urlSegmentName, e);
                                            setFieldTouched(
                                              urlSegmentName,
                                              true,
                                            );
                                          }
                                        }}
                                      />
                                    </TableCell>
                                  )}
                                  <TableCell className={classes.rowCell}>
                                    <ThresholdPredicateButtons
                                      isDisabled={readOnly}
                                      threshold={ruleAndThreshold.threshold}
                                      report={ruleAndThreshold.report}
                                      segmentId={ruleAndThreshold.segment?.id}
                                      urls={ruleAndThreshold.urls}
                                      onThresholdChange={async (
                                        newThreshold,
                                      ) => {
                                        if (!readOnly) {
                                          const cvalue =
                                            await calculateInitialThreshold(
                                              props.projectId,
                                              ruleAndThreshold.segment?.id,
                                              ruleAndThreshold.report.code,
                                              ruleAndThreshold.threshold,
                                            );
                                          if (
                                            cvalue === ruleAndThreshold.urls
                                          ) {
                                            const nvalue =
                                              await calculateInitialThreshold(
                                                props.projectId,
                                                ruleAndThreshold.segment?.id,
                                                ruleAndThreshold.report.code,
                                                newThreshold,
                                              );
                                            const fieldName = `categoryRulesAndThresholds[${index}].urls`;
                                            setFieldValue(fieldName, nvalue);
                                            setFieldTouched(fieldName, true);
                                          }
                                          const fieldName = `categoryRulesAndThresholds[${index}].threshold`;
                                          setFieldValue(
                                            fieldName,
                                            newThreshold,
                                          );
                                          setFieldTouched(fieldName, true);
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={clsx(
                                      classes.rowCell,
                                      classes.thresholdCell,
                                    )}
                                  >
                                    <RulesAndThresholdsThresholdField
                                      readOnly={readOnly}
                                      arrayHelpers={arrayHelpers}
                                      name={`categoryRulesAndThresholds[${index}].urls`}
                                      ruleAndThreshold={ruleAndThreshold}
                                      unit="score"
                                      data-testid="category-rule-and-threshold-url-input"
                                      data-pendo="category-rule-and-threshold-url-input"
                                    />
                                  </TableCell>

                                  <IssuesImprovesCell
                                    name={`categoryRulesAndThresholds[${index}].thresholdAcceptanceWhenBetter`}
                                    onChange={(e) => {
                                      const fieldName = `categoryRulesAndThresholds[${index}].thresholdAcceptanceWhenBetter`;
                                      setFieldValue(fieldName, e.target.value);
                                      setFieldTouched(fieldName, true);
                                    }}
                                  />
                                  <IssueWorsensCell
                                    name={`categoryRulesAndThresholds[${index}].thresholdAcceptanceWhenWorse`}
                                    onChange={(e) => {
                                      const fieldName = `categoryRulesAndThresholds[${index}].thresholdAcceptanceWhenWorse`;
                                      setFieldValue(fieldName, e.target.value);
                                      setFieldTouched(fieldName, true);
                                    }}
                                  />

                                  <HealthScoreTrendVisualisationCell
                                    projectId={props.projectId}
                                    ruleAndThreshold={ruleAndThreshold}
                                    crawlId={props.crawlId ?? ""}
                                  />
                                  <TableCell className={classes.rowCell}>
                                    <HideFromInsufficientRole>
                                      <Tooltip title="Remove" arrow={false}>
                                        <IconButton
                                          className={classes.iconButton}
                                          size="small"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            setFieldTouched(
                                              "categoryRulesAndThresholds",
                                              true,
                                            );
                                          }}
                                          data-testid="remove-rule-and-threshold"
                                          data-pendo="remove-rule-and-threshold"
                                          aria-label={t("alerts:deleteTest")}
                                        >
                                          <TrashSolid />
                                        </IconButton>
                                      </Tooltip>
                                    </HideFromInsufficientRole>
                                  </TableCell>
                                </TableRow>
                                {hasUniqueError ? (
                                  <TableRow
                                    style={{
                                      height: 25,
                                      boxShadow:
                                        "0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset",
                                    }}
                                  >
                                    <TableCell
                                      colSpan={9}
                                      style={{
                                        background: theme.palette.red[100],
                                        height: 25,
                                        padding: 0,
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          marginLeft: theme.spacing(1.5),
                                        }}
                                        variant="captionSemiBold"
                                      >
                                        {t("common:error")}
                                      </Typography>
                                      {": "}
                                      <Typography variant="caption">
                                        {reportsErrorMessage}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ) : undefined}
                              </React.Fragment>
                            );
                          },
                        )}
                      </TableBody>
                    </Table>
                  </div>
                ) : undefined}
                <AddRuleButton
                  title={t("alerts:addHealthScoreRule")}
                  disabled={
                    !Boolean(options.length) ||
                    values.categoryRulesAndThresholds.length >=
                      MAX_NUMBER_OF_RULES ||
                    disableAddButton
                  }
                  data-testid="add-health-score-rule-and-threshold-button"
                  data-pendo="add-health-score-rule-and-threshold-button"
                  accountLimitReached={accountLimitReached}
                  onClick={async (callback) => {
                    const category = addPossibleCategoryList[0];
                    if (category) {
                      const uuid = addHealthScoreElement(
                        props.moduleCode ?? ModuleCode.Seo,
                        addPossibleCategoryList,
                        arrayHelpers,
                      );
                      calculateInitialThreshold(
                        props.projectId,
                        undefined,
                        category.code,
                      ).then((value) => {
                        setFormikState(
                          (state: FormikState<RulesAndThresholdsFormState>) =>
                            getChangedFormicState(state, uuid, value),
                        );
                      });
                      callback();
                      setFieldTouched("reportRulesAndThresholds", true);
                    }
                  }}
                />
              </>
            );
          }}
        />
      </div>
    </div>
  );
}

function getChangedFormicState(
  state: FormikState<RulesAndThresholdsFormState>,
  searchId: string,
  value: number,
): FormikState<RulesAndThresholdsFormState> {
  const elementIndex = state.values.categoryRulesAndThresholds.findIndex(
    (e) => e.uuid === searchId,
  );

  if (elementIndex !== -1) {
    return {
      ...state,
      values: {
        ...state.values,
        categoryRulesAndThresholds: [
          ...state.values.categoryRulesAndThresholds.slice(0, elementIndex),
          {
            ...state.values.categoryRulesAndThresholds[elementIndex],
            urls: value,
          },
          ...state.values.categoryRulesAndThresholds.slice(elementIndex + 1),
        ],
      },
    };
  }

  return state;
}

function addHealthScoreElement(
  moduleCode: ModuleCode,
  addPossibleTemplateList: {
    id: string;
    code: string;
    name: string;
    description: string | undefined;
    level: number;
  }[],
  arrayHelpers: FieldArrayRenderProps,
): string {
  const id = uuid();
  const category = addPossibleTemplateList[0];

  arrayHelpers.push({
    ...DEFAULT_HS_RULE_AND_THRESHOLDS.find((e) =>
      e.supportedModules?.includes(moduleCode ?? ModuleCode.Seo),
    ),
    report: {
      ...category,
      unit: "score",
      useableWithSegments: true,
    },
    threshold: ThresholdPredicate.LessThan,
    urls: null,
    uuid: id,
  });
  return id;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      "& input": {
        height: 17,
        paddingTop: "6px!important",
        paddingBottom: "6px!important",
      },
      "& .Mui-disabled": {
        color: theme.palette.grey[400],
      },
      minWidth: URL_THRESHOLD_WIDTH,
      padding: "0!important",
    },
    segmentTextField: {
      "& input::placeholder": {
        color: theme.palette.grey[800],
      },
      "& input": {
        height: 17,
        paddingTop: "6px!important",
        paddingBottom: "6px!important",
        color: theme.palette.grey[800],
      },
      "& .Mui-disabled": {
        color: theme.palette.grey[400],
      },
      color: theme.palette.grey[800],
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
    readOnly: {
      pointerEvents: "none",
    },
    iconButton: {
      marginTop: 2,
      borderRadius: 6,
      "& svg": {
        fontSize: 18,
        color: theme.palette.grey[400],
      },
      "&:hover": {
        backgroundColor: theme.palette.red[100],
        "& svg": {
          color: theme.palette.red[600],
        },
      },
    },
    switchRoot: {
      height: 19,
      width: 33,
      marginRight: "auto",
    },
    switchBase: {
      transform: "translate(-1px, -1.5px)",
      "& svg": {
        fontSize: 19,
      },
      "&.Mui-checked": {
        transform: "translate(11.5px, -1.5px)",
      },
    },
    group: {
      position: "sticky",
      top: -theme.spacing(1.2),
      display: "flex",
      alignItems: "center",
      width: "100%",
      backgroundColor: theme.palette.grey[100],
      height: 40,
      zIndex: theme.zIndex.modal + 1,
    },
    icon: {
      color: theme.palette.purple[400],
      width: 20,
      height: 20,
      marginLeft: theme.spacing(2),
    },
    paper: {
      paddingLeft: "0",
      paddingRight: "0",
    },
    scrollableContainer: {
      overflowX: "hidden",
    },
    option: {
      padding: 0,
      "&[aria-disabled='true']": {
        pointerEvents: "all!important",
        cursor: "default",
      },
      "&[aria-selected='true']": {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.ultraviolet[200],
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
    table: {
      color: theme.palette.grey[700],
      tableLayout: "auto",
    },
    tableRow: {
      boxShadow: `0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset`,
      height: 56,
    },
    centeredRowCell: {
      textAlign: "center",
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
    },
    rowCell: {
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
      padding: theme.spacing(1.5, 1.55),
      color: theme.palette.grey[700],
    },
    changeHeader: {
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
      width: 205,
      maxWidth: 205,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    severityCell: {
      width: 60,
      maxWidth: 93,
    },
    ruleCell: {
      width: 50,
      maxWidth: 83,
    },
    wrapper: {
      overflow: "auto",
      borderRadius: theme.spacing(1, 1, 0, 0),
      border: `1px solid ${theme.palette.grey[300]}`,
      borderCollapse: "unset",
      background: theme.palette.background.paper,
    },
    grouppedHeaderCell: {
      boxShadow: `0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      color: theme.palette.grey[800],
    },
    thresholdCell: {
      width: "12%",
    },
    title: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
      lineHeight: theme.typography.pxToRem(18.15),
      marginBottom: theme.spacing(0.5),
    },
    container: {
      display: "flex",
      flexFlow: "column",
    },
  }),
);
