import {
  DestructiveMenuActionConfirmation,
  Snackbar,
  useTranslation,
} from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDeleteDashboardMutation } from "../../../graphql";
import { HideFromInsufficientRole } from "../../../_common/components/HideFromInsufficientRole";
import { useGenericParams } from "../../../_common/routing/useGenericParams";
import { CloneDashboardDialog } from "./CloneDashboardDialog";
import { DashboardOptionsMenu } from "./DashboardOptionsMenu";

export function DashboardOptions({
  loading,
  className,
  dashboardsNum,
}: {
  loading: boolean;
  className?: string;
  dashboardsNum: number;
}): JSX.Element {
  const { dashboardId } = useGenericParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["dashboards", "dashboardHeader", "errors"]);
  const [dialogAnchor, setDialogAnchor] = useState<null | HTMLElement>(null);

  const [confirmationAnchor, setConfirmationAnchor] =
    useState<null | HTMLElement>(null);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const isDialogOpen = Boolean(dialogAnchor);

  const [deleteDashboard] = useDeleteDashboardMutation({
    refetchQueries: ["GetDashboardCollection"],
    onCompleted: (data) => {
      if (data)
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("dashboardHeader:deleteSuccess")}
          />,
        );
    },
    onError: (error) => {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("errors:deleteDashboardError", { message: error.message })}
        />,
      );
    },
  });

  return (
    <>
      <HideFromInsufficientRole>
        <div
          style={{ display: "flex", marginLeft: "auto", maxHeight: 36 }}
          className={className}
        >
          <DashboardOptionsMenu
            dashboardsNum={dashboardsNum}
            disabled={!Boolean(dashboardId)}
            loading={loading}
            onOpen={setConfirmationAnchor}
            onClone={setDialogAnchor}
            onClose={() => {
              setConfirmationAnchor(null);
            }}
            onDelete={() => {
              setConfirmationOpen(true);
            }}
          />
        </div>
      </HideFromInsufficientRole>
      {isDialogOpen ? (
        <CloneDashboardDialog
          anchorEl={dialogAnchor}
          open={isDialogOpen}
          onCloseViaIcon={() => setDialogAnchor(null)}
          onActionButtonClick={() => setDialogAnchor(null)}
          clone={true}
        />
      ) : undefined}

      {confirmationAnchor && confirmationOpen ? (
        <DestructiveMenuActionConfirmation
          title={t("dashboardTabs:delete_confirm_title")}
          description={t("dashboardHeader:delete_confirm_message")}
          onConfirm={async () => {
            await deleteDashboard({
              variables: { customDashboardId: dashboardId },
            });
            setConfirmationOpen(false);
            setConfirmationAnchor(null);
          }}
          onCancel={() => {
            setConfirmationAnchor(null);
            setConfirmationOpen(false);
          }}
          anchorEl={confirmationAnchor}
          confirmText={t("dashboards:delete_menu")}
          pendoPrefix={"monitor-dashboard-header-menu-delete"}
        />
      ) : null}
    </>
  );
}
