import { DataGridTrendValue } from "../types";

type WithDataGridTrendValueKeys = Record<keyof DataGridTrendValue, unknown>;

function isPartialDataGridTrendValue(
  value: unknown,
): value is WithDataGridTrendValueKeys {
  return (
    typeof value === "object" &&
    Boolean(value?.hasOwnProperty("date")) &&
    Boolean(value?.hasOwnProperty("count"))
  );
}

export function isDataGridTrendValue(
  value: unknown,
): value is DataGridTrendValue {
  return (
    isPartialDataGridTrendValue(value) &&
    value.date instanceof Date &&
    typeof value.count === "number"
  );
}
