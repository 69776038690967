import { Box, Button, Paper, makeStyles } from "@material-ui/core";
import { Form, FormikProps } from "formik";
import { useSpring, animated } from "react-spring";
import { useTranslation, FilterOrRuleFieldArray } from "@lumar/shared";

import { ProjectsFilterFormValues } from "./ViewFilter";
import { useViewFilters } from "./custom-filters/useViewFilters";

type FormProps = FormikProps<ProjectsFilterFormValues>;
interface Props {
  values: ProjectsFilterFormValues;
  dirty: FormProps["dirty"];
  isValid: FormProps["isValid"];
  validateForm: FormProps["validateForm"];
  setFieldValue: FormProps["setFieldValue"];
  setFieldTouched: FormProps["setFieldTouched"];
  resetForm: () => void;
}

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-start",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2, 2, 0, 2),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  submitButton: {
    minWidth: 180,
    marginLeft: "auto",
  },
}));

export function ViewFilterForm(props: Props): JSX.Element {
  const { values, setFieldValue, setFieldTouched, resetForm } = props;
  const classes = useStyles();
  const { t } = useTranslation("projectFilter");
  const projectFilters = useViewFilters({
    includeActiveAndScheduledAndTestMetrics: false,
  });

  const springProps = useSpring({
    from: { opacity: 0, marginTop: -15 },
    to: { opacity: 1, marginTop: 0 },
    config: { duration: 150 },
  });

  return (
    <Form>
      <animated.div style={springProps}>
        <Paper>
          <Box p={2}>
            <Box pb={2}>
              <FilterOrRuleFieldArray
                name="filters"
                metrics={projectFilters}
                value={values.filters}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                allowDeletingLastEmptyRule={true}
                pendoPrefix="monitor-dashboard"
              />
            </Box>
            <Box className={classes.actions}>
              <Button
                variant="text"
                onClick={() => resetForm()}
                data-testid="account-overview-filter-form-clear-button"
                data-pendo="monitor-dashboard-filter-clear"
              >
                {t("clearFilterButton")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                classes={{ root: classes.submitButton }}
                data-testid="account-overview-filter-form-submit-button"
                data-pendo="monitor-dashboard-filter-submit"
              >
                {t("applyFilterButton")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </animated.div>
    </Form>
  );
}
