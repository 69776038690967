interface TrendInfo {
  seriesData: [number, number][];
  currentUrlCount: number;
  previousUrlCount: number;
  lastCrawledAt: Date | null;
  crawlId: string | null;
}

export function extractChartDataFromTrends(
  trends?: {
    crawlId?: number | null;
    value?: number | null;
    createdAt: string | null;
  }[],
): TrendInfo {
  if (trends && trends.length) {
    const seriesData: [number, number][] = trends.map((trend) => [
      new Date(trend.createdAt ?? "").getTime(),
      trend.value ?? 0,
    ]);

    const [mostRecentTimeStamp, mostRecentUrlCount] = seriesData[0];
    const id = trends[0].crawlId;
    const previousUrlCount = seriesData.length > 1 ? seriesData[1][1] : 0;

    return {
      seriesData,
      previousUrlCount,
      currentUrlCount: mostRecentUrlCount,
      lastCrawledAt: new Date(mostRecentTimeStamp),
      crawlId: id?.toString() ?? "",
    };
  }

  return {
    seriesData: [],
    currentUrlCount: 0,
    previousUrlCount: 0,
    lastCrawledAt: null,
    crawlId: null,
  };
}
