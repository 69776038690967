import { ApolloError, NetworkStatus, useSession } from "@lumar/shared";
import React from "react";
import { useParams } from "react-router-dom";
import { GetAllViewsQuery, useGetAllViewsQuery } from "../../../../graphql";

interface ViewListLazyQueryReturnType {
  data: GetAllViewsQuery | undefined;
  loading: boolean;
  hasFetchedAllData: boolean;
  error: ApolloError | undefined;
  handleLoadMore: () => void;
}

export function useGetViewListLazyQuery(
  skip: boolean,
): ViewListLazyQueryReturnType {
  const { accountId } = useParams<{ accountId: string }>();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { data, error, loading, networkStatus, fetchMore } =
    useGetAllViewsQuery({
      variables: {
        accountId,
        hasSegmentation: segmentationAvailable,
      },
      skip: skip,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
    });
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const pageInfo = data?.getAccount?.projects?.pageInfo;
  const hasFetchedAllData =
    !loading && !isFetchingMore && !Boolean(pageInfo?.hasNextPage);

  const handleLoadMore = React.useCallback(() => {
    const pageInfo = data?.getAccount?.projects?.pageInfo;

    if (pageInfo?.hasNextPage && pageInfo.endCursor && !isFetchingMore) {
      fetchMore({
        variables: {
          accountId,
          cursor: pageInfo.endCursor,
        },
      });
    }
  }, [fetchMore, data, isFetchingMore, accountId]);

  return {
    data,
    error,
    loading: loading || isFetchingMore,
    hasFetchedAllData,
    handleLoadMore,
  };
}
