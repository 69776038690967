import { useState, useEffect, createContext, useContext } from "react";
import { useLocation } from "react-router-dom";

const MAX_HISTORY_ENTRIES = 99;

const UrlHistoryContext = createContext<string[] | undefined>(undefined);

export function UrlHistoryProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const location = useLocation();
  const [urlHistory, setUrlHistory] = useState<string[]>([]);

  useEffect(() => {
    setUrlHistory((currentHistory) => {
      const recentUrlHistory =
        currentHistory.length > MAX_HISTORY_ENTRIES
          ? currentHistory.slice(-1 * MAX_HISTORY_ENTRIES)
          : currentHistory;
      return [...recentUrlHistory, location.pathname];
    });
  }, [location.pathname]);

  return (
    <UrlHistoryContext.Provider value={urlHistory}>
      {children}
    </UrlHistoryContext.Provider>
  );
}

/**
 * Use this hook to access the url history. The maximum number of entries that are stored is 100.
 */
export function useUrlHistory(): string[] {
  const context = useContext(UrlHistoryContext);

  if (!context) {
    throw new Error(
      "useUrlHistory needs to be used within the context of UrlHistoryProvider",
    );
  }

  return context;
}
