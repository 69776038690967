import React from "react";
import {
  Typography,
  Alert,
  FaviconIcon,
  useTranslation,
  Chip,
  BellOutlined,
} from "@lumar/shared";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useGenericParams } from "../../../_common/routing/useGenericParams";
import { useGetFilteredProjectListQuery } from "../../../graphql";
import { useDebounce } from "../../../_common/hooks/useDebounce";
import {
  CircularProgress,
  InputLabel,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { AlertOption } from "../../../_common/utils/constants";
import { LeftBottomPopper } from "../../../_common/components/CustomPopper/LeftBottomPopper";

const FAVICON_WIDTH = 24;
const NAME_AND_DOMAIN_LEFT_MARGIN = 15;

const useStyles = makeStyles((theme) => ({
  autocompleteOption: {
    padding: "9px 8px",
    height: 46,
  },
  listItemContent: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  nameAndDomainWrapper: {
    marginLeft: NAME_AND_DOMAIN_LEFT_MARGIN,
    display: "flex",
    flexDirection: "column",
    width: `calc(100% - ${FAVICON_WIDTH + NAME_AND_DOMAIN_LEFT_MARGIN}px)`,
  },
  listItemName: {
    lineHeight: theme.typography.pxToRem(17),
    maxWidth: 300,
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  primaryDomain: {
    color: theme.palette.grey[500],
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
  },
  inputLabel: {
    display: "none",
  },
  alertChip: {
    fontWeight: 400,
    "&:hover": {
      cursor: "pointer",
    },
    "& .MuiChip-avatar": {
      height: 16,
      width: 16,
      color: theme.palette.grey[400],
      marginRight: -3,
    },
  },
  chipContainer: {
    alignSelf: "end",
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    height: 35,
  },
  popper: {
    width: "fit-content",
  },
}));

interface Props {
  value: AlertOption | null;
  onChange: (newValue: AlertOption | null) => void;
}

export const ProjectAutocomplete = React.forwardRef(
  function ProjectAutocomplete(
    props: Props,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): JSX.Element {
    const classes = useStyles();
    const [inputValue, setInputValue] = React.useState("");
    const { t } = useTranslation(["common", "alerts"]);

    const debouncedInputValue = useDebounce(inputValue, 300);

    const { accountId } = useGenericParams();

    const { data, loading, error } = useGetFilteredProjectListQuery({
      variables: {
        accountId: accountId ?? "",
        filterTerm: debouncedInputValue || null,
      },
    });

    const projects = data?.getAccount?.projects.edges.map((e) => e.node) ?? [];

    return (
      <div ref={ref}>
        <InputLabel
          className={classes.inputLabel}
          htmlFor="choose-project-input"
        >
          {t("chooseProject")}
        </InputLabel>
        <Autocomplete
          classes={{
            option: classes.autocompleteOption,
            paper: classes.popper,
          }}
          id="choose-project-input"
          data-pendo="choose-project-for-alert"
          data-testid="choose-project-combobox"
          disabled={Boolean(error)}
          options={projects}
          getOptionLabel={(option) => option.name}
          PopperComponent={LeftBottomPopper}
          openOnFocus
          value={props.value}
          onChange={(_, newValue) => {
            props.onChange(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderOption={(option) => {
            const totalCount =
              option.testsTotalCount + option.healthScoreTestsTotalCount;
            return (
              <div style={{ display: "flex", width: "100%" }}>
                <div className={classes.listItemContent}>
                  <FaviconIcon
                    url={option.primaryDomain}
                    width={FAVICON_WIDTH}
                    height={FAVICON_WIDTH}
                  />
                  <div className={classes.nameAndDomainWrapper}>
                    <Typography
                      variant="subtitle3Medium"
                      className={classes.listItemName}
                      noWrap
                    >
                      {option.name}
                    </Typography>
                    <Typography className={classes.primaryDomain} noWrap>
                      {option.primaryDomain}
                    </Typography>
                  </div>
                </div>
                <div className={classes.chipContainer}>
                  {totalCount > 0 ? (
                    <Tooltip
                      title={t("alerts:alertConfiguredExplanation") as string}
                      arrow={false}
                      placement="top"
                    >
                      <Chip
                        label={t("alerts:alertConfigured")}
                        color="lightgrey"
                        className={classes.alertChip}
                        avatar={<BellOutlined />}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            );
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Choose project..."
              variant="outlined"
              autoFocus
              InputProps={{
                ...params.InputProps,
                endAdornment: loading ? (
                  <>
                    <CircularProgress size={20} />
                    {params.InputProps.endAdornment}
                  </>
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
            />
          )}
        />
        {error ? (
          <Alert severity="error" className={classes.alert}>
            <strong>{t("failedToLoadProjects")}</strong> {error.message}
          </Alert>
        ) : null}
      </div>
    );
  },
);
