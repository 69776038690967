import { ReportTrendsTableRow } from "../../../../graphql";

type WithViewReportTrendsTableRowValueKeys = Record<
  keyof ReportTrendsTableRow,
  unknown
>;

function hasViewReportTrendsTableRowValueKeys(
  value: unknown,
): value is WithViewReportTrendsTableRowValueKeys {
  return (
    typeof value === "object" &&
    value !== null &&
    Boolean(value.hasOwnProperty("columns"))
  );
}

export function isViewReportTrendsTableRow(
  value: unknown,
): value is ReportTrendsTableRow {
  if (hasViewReportTrendsTableRowValueKeys(value)) {
    return Array.isArray(value.columns);
  }

  return false;
}
