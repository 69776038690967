import { Checkbox, useTranslation } from "@lumar/shared";
import { useTheme, makeStyles } from "@material-ui/core";
import { AutocompleteRenderOptionState } from "@material-ui/lab";
import { ReportOption } from "../../../utils/constants";
import { AutocompleteOptionWithTooltip } from "../../AutocompleteOptionWithTooltip";
import { ConditionalTooltip } from "../../ConditionalTooltip";

interface ReportOptionElementProps {
  disabled?: boolean;
  option: ReportOption;
  maxSelection?: number;
  state: AutocompleteRenderOptionState;
  multiSelect?: boolean;
  group?: string;
  parentGroup?: string;
  onClick?: () => void;
}

export function ReportOptionElement({
  disabled = false,
  option,
  maxSelection = 10,
  state,
  multiSelect = false,
  onClick,
}: ReportOptionElementProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation("notifications");

  return (
    <ConditionalTooltip
      show={disabled}
      classes={{ tooltip: classes.tooltip }}
      arrow={false}
      title={
        t("maxselectReportTooltip", {
          count: maxSelection,
        }) as string
      }
    >
      <div
        id={`report-${option.id}`}
        style={{
          display: "flex",
          cursor: disabled ? "default" : "pointer",
          width: "100%",
          flex: 1,
          paddingTop: 2,
          paddingBottom: 1,
        }}
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
            e.stopPropagation();
          } else {
            onClick?.();
          }
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0.75, 1, 0.75, 1),
            width: "100%",
          }}
        >
          {multiSelect ? (
            <Checkbox size="medium" checked={state.selected} />
          ) : undefined}
          <AutocompleteOptionWithTooltip
            title={option.name}
            description={option.description}
            summary={option.summary}
            state={{
              inputValue: state.inputValue,
              selected: multiSelect ? false : state.selected,
            }}
            className={classes.option}
          />
        </div>
      </div>
    </ConditionalTooltip>
  );
}

const useStyles = makeStyles((theme) => ({
  option: {
    flex: 1,
    paddingLeft: theme.spacing(2),
  },
  tooltip: {
    maxWidth: 150,
    fontSize: theme.typography.pxToRem(13),
  },
  disabled: {
    pointerEvents: "none",
    cursor: "pointer",
  },
}));
