import { differenceBy, intersectionWith, isEqual } from "lodash";
import { RuleAndThreshold } from "../../_common/utils/types";
import { BaseReport } from "../../../_common/utils/constants";

const getReportCode = <T extends BaseReport>(o: RuleAndThreshold<T>): string =>
  `${o.report.code}--${o.segment?.id ?? "?urls?"}`;

const isRuleAndThresholdChanged = <T extends BaseReport>(
  arrVal: RuleAndThreshold<T>,
  othVal: RuleAndThreshold<T>,
): boolean =>
  arrVal.report.code === othVal.report.code &&
  arrVal.segment?.id === othVal.segment?.id &&
  !isEqual(arrVal, othVal);

interface MapRulesAndThresholdsToApiActionsPayload<T extends BaseReport> {
  rulesToCreate: RuleAndThreshold<T>[];
  rulesToDelete: RuleAndThreshold<T>[];
  rulesToUpdate: RuleAndThreshold<T>[];
}

export function mapRulesAndThresholdsToApiActions<T extends BaseReport>(
  initialValues: RuleAndThreshold<T>[],
  newValues: RuleAndThreshold<T>[],
): MapRulesAndThresholdsToApiActionsPayload<T> {
  const rulesToDelete = differenceBy(initialValues, newValues, getReportCode);
  const rulesToCreate = differenceBy(newValues, initialValues, getReportCode);

  const rulesToUpdate = intersectionWith(
    newValues,
    initialValues,
    isRuleAndThresholdChanged,
  );

  return {
    rulesToCreate,
    rulesToUpdate,
    rulesToDelete,
  };
}
