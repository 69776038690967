import { Dialog, TextField, DialogProps, useTranslation } from "@lumar/shared";
import { useState } from "react";

export interface DashboardNameDialogProps
  extends Omit<DialogProps, "classes" | "children" | "buttons"> {
  onOk: (name: string) => void;
  onCancel: () => void;
}

export const MAX_NAME_LENGTH = 55;
export const MIN_NAME_LENGTH = 1;

export function DashboardNameDialog({
  onOk,
  ...props
}: DashboardNameDialogProps): JSX.Element {
  const [name, setName] = useState<string>("");
  const { t } = useTranslation("dashboards");

  const isNameTooLong = name.length > MAX_NAME_LENGTH;

  return (
    <Dialog
      {...props}
      withGreyFooter={true}
      buttons={[
        {
          variant: "outlined",
          color: "default",
          title: t("cancel"),
          onClick: () => {
            props.onCancel();
            setName("");
          },
          "data-pendo": "monitor-create-dashboard-dialog-cancel",
        },
        {
          disabled: name.length < MIN_NAME_LENGTH || isNameTooLong,
          variant: "contained",
          title: t("create_dashboard"),
          autoFocus: true,
          color: "primary",
          onClick: () => {
            onOk?.(name.trim());
            setName("");
          },
          "data-pendo": "monitor-create-dashboard-dialog-submit",
          "data-testid": "dashboard-dialog-create-dashboard-button",
        },
      ]}
      pendoPrefix="monitor-create-dashboard"
    >
      <div style={{ width: "100%", marginBottom: 31, marginTop: 10 }}>
        <TextField
          label={t("name")}
          style={{ width: "450px" }}
          onChange={(event) => setName((event.target.value ?? "").trim())}
          autoFocus={true}
          inputProps={{
            "data-pendo": "monitor-create-dashboard-dialog-name-input",
          }}
          error={isNameTooLong}
          data-testid="create-dashboard-dialog-input"
          helperText={isNameTooLong ? t("nameTooLong") : undefined}
          onKeyUp={(e) => {
            if (
              !(name.length < MIN_NAME_LENGTH || isNameTooLong) &&
              e.key === "Enter"
            ) {
              onOk?.(name.trim());
              setName("");
            }
          }}
        />
      </div>
    </Dialog>
  );
}
