/* eslint-disable react-hooks/exhaustive-deps */
import { RefObject, useLayoutEffect, useState } from "react";

export function useIsElementOverflown<
  ELEMENT extends HTMLElement | null | undefined,
>(ref: RefObject<ELEMENT>): boolean {
  const [isOverflown, setIsOverflown] = useState(false);

  useLayoutEffect(() => {
    if (ref) {
      const element = ref.current;
      if (element) {
        const value = element.scrollWidth > element.clientWidth;
        setIsOverflown(value);
      }
    }
  });
  return isOverflown;
}
