import {
  Button,
  Typography,
  Snackbar,
  useTranslation,
  Trans,
  useSession,
  ApolloError,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { Formik, Form, FormikHelpers, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import React, { RefObject, useState } from "react";
import { RulesAndThresholdsFormState } from "../../_common/utils/types";
import { CustomSnackbar } from "./CustomSnackbar";
import { InView } from "react-intersection-observer";
import clsx from "clsx";
import { GetAlertWithTestsQuery, RoleCode } from "../../../graphql";
import { useValidationSchema } from "../../_common/utils/useValidationSchema";
import { HideFromInsufficientRole } from "../../../_common/components/HideFromInsufficientRole";
import { useSaveEmailAlertsMutation } from "../../_common/utils/useSaveEmailAlertsMutation";
import { useSaveSlackWebhooksMutation } from "../../_common/utils/useSaveSlackWebhooksMutation";
import { getErrorMessage } from "../../../_common/utils/getErrorMessage";
import { useHistory } from "react-router";
import { useMonitorRoutes } from "../../../_common/routing/useMonitorRoutes";
import { useSaveMsTeamsWebhooksMutations } from "../../_common/utils/useSaveMsTeamsWebhooksMutation";
import { useSaveReportRulesAndThresholdsMutation } from "../../manage/utils/useSaveReportRulesAndThresholdsMutation";
import { useSaveHealthScoreRulesAndThresholdsMutation } from "../../manage/utils/useSaveHealthScoreRulesAndThresholdsMutation";
import { useCreateNewRulesFromData } from "../../copy/utils/createNewRulesFromData";
import { useParams } from "react-router-dom";
import { useTotalRulesCount } from "../../_common/utils/useTotalRulesCount";
import { ConditionalTooltip } from "../../../_common/components/ConditionalTooltip";
import { AbsoluteURLs } from "../../../_common/routing/absoluteURLs";
import { useRefreshProjects } from "../../copy/utils/useRefreshProjects";

export const TITLE_HEIGHT = 63;

const useStyles = makeStyles((theme) => ({
  container: {
    height: TITLE_HEIGHT,
  },
  animated: {
    overflow: "hidden",
  },
  stickyBottom: {
    position: "sticky",
    zIndex: theme.zIndex.snackbar - 1,
    bottom: 0,
    backgroundColor: "#D1D5DB4C",
    backdropFilter: "blur(8px)",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    height: TITLE_HEIGHT,
    padding: theme.spacing(0, 3),
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    height: TITLE_HEIGHT,
    padding: theme.spacing(0, 3),
  },
  cancelButton: {
    marginLeft: "auto",
    marginRight: 12,
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

interface RulesAndThresholdsFormProp {
  children: React.ReactNode;
  projectId: string;
  onHeaderChange?: (visible: boolean) => void;
  initialValues?: RulesAndThresholdsFormState;
  originalAlert?: GetAlertWithTestsQuery;
  title: string;
  onFinished?: (data: RulesAndThresholdsFormState | null | undefined) => void;
  isCopyingToExistingAlert: boolean;
  enableReinitialize: boolean;
  formikRef?: RefObject<FormikProps<RulesAndThresholdsFormState>>;
}

export function RulesAndThresholdsForm({
  children,
  projectId,
  onHeaderChange,
  initialValues,
  originalAlert,
  title,
  isCopyingToExistingAlert,
  enableReinitialize,
  onFinished,
  formikRef,
}: RulesAndThresholdsFormProp): JSX.Element {
  const classes = useStyles();
  const { accountId } = useParams<{ accountId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["common", "alerts"]);
  const session = useSession();
  const refreshProjects = useRefreshProjects();

  const [headerVisible, setHeaderVisible] = useState<boolean>(true);

  const [saveReportRulesAndThreshold] =
    useSaveReportRulesAndThresholdsMutation();
  const [saveHealthScoreRulesAndThreshold] =
    useSaveHealthScoreRulesAndThresholdsMutation();
  const [saveEmailAlerts] = useSaveEmailAlertsMutation();
  const [saveSlackWebhooks] = useSaveSlackWebhooksMutation();
  const [saveMsTeamsWebhooks] = useSaveMsTeamsWebhooksMutations();

  const schema = useValidationSchema();
  const history = useHistory<{ prevPath: string } | undefined>();
  const { alertsPage } = useMonitorRoutes();

  const initialAlert = useCreateNewRulesFromData(originalAlert, true);
  const admin = session.hasSufficientRole(RoleCode.Admin);

  const isInitiallyEmpty =
    !Boolean(initialValues?.categoryRulesAndThresholds.length) &&
    !Boolean(initialValues?.reportRulesAndThresholds.length);

  const {
    maxRulesCount,
    totalRulesCount,
    loading: loadingTotalCount,
    error,
  } = useTotalRulesCount(accountId);

  const disableButton = loadingTotalCount || Boolean(error);

  const handleSubmit = async (
    values: RulesAndThresholdsFormState,
    actions: FormikHelpers<RulesAndThresholdsFormState>,
  ): Promise<void> => {
    try {
      await Promise.all([
        saveReportRulesAndThreshold(
          initialAlert.reportRulesAndThresholds,
          values.reportRulesAndThresholds ?? [],
          projectId,
        ),
        saveHealthScoreRulesAndThreshold(
          initialAlert.categoryRulesAndThresholds,
          values.categoryRulesAndThresholds ?? [],
          projectId,
        ),
        saveEmailAlerts(
          originalAlert?.alert?.emailAlerts?.nodes ?? [],
          values.emailAlerts ?? [],
          projectId,
        ),
        saveSlackWebhooks(
          originalAlert?.alert?.slackWebhooks?.nodes ?? [],
          values.slackWebhooks ?? [],
          projectId,
        ),
        saveMsTeamsWebhooks(
          originalAlert?.alert?.webhooks?.nodes ?? [],
          values.microsoftTeamsWebhooks ?? [],
          projectId,
        ),
      ]);
      actions.setSubmitting(false);
      onFinished?.(values);
      const isPreviousPageManageAlerts =
        history.location.state?.prevPath.includes("/alerts");
      if (!Boolean(initialValues?.reportRulesAndThresholds.length)) {
        if (isPreviousPageManageAlerts) {
          alertsPage.visit({ alertId: projectId });
        } else {
          history.goBack();
        }
      }
      if (isCopyingToExistingAlert) {
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("alerts:alertUpdatedSuccessfully")}
          />,
        );
      } else {
        enqueueSnackbar("custom", {
          // eslint-disable-next-line react/display-name
          content: (key) => (
            <CustomSnackbar
              id={key}
              alertId={projectId}
              accountId={accountId}
            />
          ),
        });
      }
    } catch (e) {
      const title =
        e instanceof ApolloError &&
        e.graphQLErrors[0]?.extensions?.["code"] ===
          "ACCOUNT_MAX_PROJECT_TESTS_LIMIT_EXCEEDED"
          ? t("alerts:alertCreationFailedWithLimit", { count: maxRulesCount })
          : t("alerts:alertCreationFailed", {
              message: getErrorMessage(e),
            });
      enqueueSnackbar(<Snackbar variant="error" title={title} />);
    }
    refreshProjects();
  };

  const handleCancel = (): void => {
    onFinished?.(null);
    history.goBack();
  };
  const accountLimitReached = totalRulesCount >= maxRulesCount;
  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={
        initialValues ??
        ({
          reportRulesAndThresholds: [],
          categoryRulesAndThresholds: [],
          emailAlerts: [],
          slackWebhooks: [],
          microsoftTeamsWebhooks: [],
        } as RulesAndThresholdsFormState)
      }
      enableReinitialize={enableReinitialize}
      innerRef={formikRef}
    >
      {({ isSubmitting, values }) => {
        const disable =
          isInitiallyEmpty &&
          !Boolean(values.categoryRulesAndThresholds?.length) &&
          !Boolean(values.reportRulesAndThresholds?.length);
        const buttons = (
          <HideFromInsufficientRole>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              size="large"
              type="button"
              onClick={handleCancel}
              data-pendo="cancel-create-alert"
            >
              {t("common:cancel")}
            </Button>
            <ConditionalTooltip
              interactive
              title={
                <Trans
                  ns="alerts"
                  i18nKey={
                    admin ? "accountLimitReached" : "accountLimitReachedEditor"
                  }
                  components={{
                    subscriptionLink: (
                      <a
                        href={AbsoluteURLs.EXTERNAL__AccountsSubscription.getUrl(
                          accountId,
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              }
              show={accountLimitReached}
            >
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  loading={isSubmitting}
                  data-pendo="save-and-close-create-alert"
                  data-testid="create-alert-save-button"
                  disabled={disableButton || accountLimitReached || disable}
                >
                  {isCopyingToExistingAlert
                    ? t("common:saveUpdates")
                    : t("common:saveAndClose")}
                </Button>
              </span>
            </ConditionalTooltip>
          </HideFromInsufficientRole>
        );
        return (
          <Form style={{ minHeight: "100vh" }}>
            <InView
              as="div"
              className={classes.container}
              onChange={(inView) => {
                onHeaderChange?.(inView);
                setHeaderVisible(inView);
              }}
            >
              <div>
                <div className={classes.titleContainer}>
                  <Typography variant="h6SemiBold" className={classes.title}>
                    {title}
                  </Typography>
                  {buttons}
                </div>
              </div>
            </InView>
            {children}
            {!headerVisible ? (
              <div className={clsx(classes.container, classes.stickyBottom)}>
                <div className={classes.buttonContainer}>{buttons}</div>
              </div>
            ) : undefined}
          </Form>
        );
      }}
    </Formik>
  );
}
