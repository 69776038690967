import { makeStyles, useTheme } from "@material-ui/core";
import { HidableBlock } from "../../../../_common/components/Hideable/HideableBlock";
import { InstructionsList } from "../../../../_common/components/InstructionsList/InstructionsList";
import { Typography, useTranslation, Trans } from "@lumar/shared";
import step1 from "../images/teams-instructions-step-1.png";
import step2 from "../images/teams-instructions-step-2.png";
import step3 from "../images/teams-instructions-step-3.png";
import step4 from "../images/teams-instructions-step-4.png";
import step5 from "../images/teams-instructions-step-5.png";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
    letterSpacing: "-0.2px",
  },
  image: {
    maxWidth: "100%",
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(5.75),
  },
  link: {
    color: theme.palette.blue[600],
    "&:hover": {
      color: theme.palette.blue[700],
    },
  },
}));

export function MicrosoftTeamsInstructions(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation("msTeamsNotifications");

  const instructions: JSX.Element[] = [
    <div style={{ display: "grid" }} key="instruction-step-1">
      <Typography variant="body2">
        <Trans i18nKey="msTeamsNotifications:instructions.step1">
          <strong>Create a channel</strong> for your notifications in Teams.
          Click icon … on your notifications channel and select Connectors.
        </Trans>
      </Typography>
      <img width={511} className={classes.image} src={step1} alt="step 1" />
    </div>,
    <div style={{ display: "grid" }} key="instruction-step-2">
      <Typography variant="body2">
        <Trans i18nKey="msTeamsNotifications:instructions.step2">
          <strong>Search for</strong> and{" "}
          <strong>select the Incoming Webhook</strong> connector and{" "}
          <strong>click Add.</strong>
        </Trans>
      </Typography>
      <img width={390} className={classes.image} src={step2} alt="step 2" />
    </div>,
    <div style={{ display: "grid" }} key="instruction-step-3">
      <Typography variant="body2">
        <Trans i18nKey="msTeamsNotifications:instructions.step3">
          Click <strong>Configure</strong>
        </Trans>
      </Typography>
      <img width={393} className={classes.image} src={step3} alt="step 3" />
    </div>,
    <div style={{ display: "grid" }} key="instruction-step-4">
      <Typography variant="body2">
        <Trans i18nKey="msTeamsNotifications:instructions.step4">
          <strong>Enter a name</strong> for this Incoming Webhook connector. You
          can also upload an image to associate with the data from this incoming
          Webhook if you like. <strong>Click Create.</strong>
        </Trans>
      </Typography>
      <img width={390} className={classes.image} src={step4} alt="step 4" />
    </div>,
    <div style={{ display: "grid" }} key="instruction-step-5">
      <Typography variant="body2">
        <Trans i18nKey="msTeamsNotifications:instructions.step5">
          <strong>Copy the webhook URL</strong> that is displayed and save it.{" "}
          <strong>Choose Done.</strong>
        </Trans>
      </Typography>
      <img width={390} className={classes.image} src={step5} alt="step 5" />
    </div>,
    <div style={{ display: "grid" }} key="instruction-step-6">
      <Typography variant="body2">
        <Trans i18nKey="msTeamsNotifications:instructions.step6">
          <strong>Enter the webhook URL</strong> in the field above.
        </Trans>
      </Typography>
    </div>,
  ];

  return (
    <HidableBlock
      title={t("instructions.title")}
      descriptor="instructions"
      dataIdDescriptor="monitor-msteams-webhooks"
    >
      <InstructionsList
        description={
          <Typography
            style={{ marginBottom: theme.spacing(6.5) }}
            variant="caption"
          >
            {t("instructions.description")}
          </Typography>
        }
        instructions={instructions}
        dataId="monitor-msteams-webhooks"
      />
    </HidableBlock>
  );
}
