import { useSession } from "@lumar/shared";
import React from "react";
import { Redirect } from "react-router-dom";
import { RoleCode } from "../../graphql";

interface Props {
  children: React.ReactNode;
  /**
   * The minimum required role needed in order to see whatever content is passed to the `children` prop. The default is `RoleCode.Editor`
   */
  minimumRequiredRole?: RoleCode;
  to: string;
}

/**
 * Use this component to conditionally show content or redirect based on the user's role.
 */
export function RedirectIfInsufficientRole({
  children,
  minimumRequiredRole = RoleCode.Editor,
  to,
}: Props): JSX.Element {
  const session = useSession();

  const hasSufficientRole = session.hasSufficientRole(minimumRequiredRole);

  if (hasSufficientRole) {
    return <>{children}</>;
  }

  return <Redirect to={to} />;
}
