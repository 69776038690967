import { Snackbar, Typography, useTranslation } from "@lumar/shared";
import { makeStyles, List } from "@material-ui/core";
import { ReactNode, useEffect, useRef } from "react";
import { MonitorSideBarCategory, SideBarListItem } from "./SideBarListItem";
import { LoadMoreOnScroll } from "../../_common/components/LoadMoreOnScroll";
import {
  GetDashboardCollectionsDocument,
  GetDashboardCollectionsQuery,
  useMoveCustomDashboardCollectionMutation,
} from "../../graphql";
import { useGenericParams } from "../../_common/routing/useGenericParams";
import { useSnackbar } from "notistack";
import {
  DndContext,
  DragEndEvent,
  KeyboardCode,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useDroppable,
  useSensor,
  useSensors,
  Announcements,
} from "@dnd-kit/core";
import {
  SortableContext,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

const DND_CONTAINER_ID = "list-container";

const useStyles = makeStyles((theme) => ({
  childrenContainer: {
    borderBottomColor: "#D1D5DB",
    marginBottom: "10px",
    width: "100%",
  },
  lastChild: {
    borderBottom: "none",
  },
  list: {
    paddingTop: 0,
    width: "100%",
    "& .droppable-placeholder ~ .droppable-placeholder": {
      display: "none",
    },
    overflow: "hidden",
  },
  background: {
    background: theme.palette.grey[100],
    borderRadius: theme.spacing(0.75),
  },
}));

export interface SideBarListProps {
  config: MonitorSideBarCategory[];
  title?: ReactNode | string;
  canFetchMore?: boolean;
  fetchMore?: () => void;
}

function isString(value: unknown): boolean {
  return typeof value === "string" || value instanceof String;
}

function arraymove(
  array: {
    node: {
      id: string;
      name: string;
      position: number;
    };
  }[],
  fromIndex: number,
  toIndex: number | null | undefined,
): {
  node: {
    id: string;
    name: string;
    position: number;
  };
}[] {
  if (toIndex === null || toIndex === undefined) return array;
  const newArray = [...array];

  const element = newArray[fromIndex];
  // eslint-disable-next-line fp/no-mutating-methods
  newArray.splice(fromIndex, 1);
  // eslint-disable-next-line fp/no-mutating-methods
  newArray.splice(toIndex, 0, element);
  return newArray;
}

export function SideBarList({
  title,
  config,
  canFetchMore = false,
  fetchMore,
}: SideBarListProps): JSX.Element {
  const classes = useStyles();
  const ref = useRef<HTMLUListElement | null>(null);
  const { setNodeRef } = useDroppable({ id: DND_CONTAINER_ID });
  const { accountId } = useGenericParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["sidenav", "dnd"]);

  useEffect(() => {
    const element = ref.current?.querySelector("[data-selected=true]");
    element?.scrollIntoView(false);
  }, []);

  const [moveCurrentDashboardCollection] =
    useMoveCustomDashboardCollectionMutation();

  const handleDrop = async (event: DragEndEvent): Promise<void> => {
    if (!event.over || event.active.id === event.over.id) return;

    const result = {
      source: config.find((e) => e.id === event.active.id),
      destination: config.find((e) => e.id === event.over?.id),
    };

    if (result.source !== undefined && result.destination !== undefined) {
      try {
        await moveCurrentDashboardCollection({
          variables: {
            collectionId: result.source.id,
            position: result.destination.position,
          },
          optimisticResponse: {
            moveCustomDashboardCollection: {
              customDashboardCollection: {
                id: result.source.id,
                position: result.destination.position,
              },
            },
          },
          update: (cache) => {
            cache.updateQuery(
              {
                query: GetDashboardCollectionsDocument,
                variables: { accountId },
              },
              (data: GetDashboardCollectionsQuery | null) => {
                if (data?.getAccount?.customDashboardCollections.edges.length) {
                  const newCollectionEdges = arraymove(
                    data?.getAccount?.customDashboardCollections.edges,
                    result.source?.position ?? 0,
                    result.destination?.position ?? 0,
                  ).map((e, index) => ({ ...e, position: index }));
                  return {
                    ...data,
                    getAccount: {
                      ...data.getAccount,
                      customDashboardCollections: {
                        ...data.getAccount?.customDashboardCollections,
                        edges: newCollectionEdges,
                      },
                    },
                  };
                }
                return data;
              },
            );
          },
        });
      } catch {
        enqueueSnackbar(<Snackbar variant="error" title={t("error")} />);
      }
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 5 },
    }),
    useSensor(TouchSensor, {
      activationConstraint: { distance: 5 },
    }),
    useSensor(KeyboardSensor, {
      keyboardCodes: {
        start: [KeyboardCode.Space],
        cancel: [KeyboardCode.Esc],
        end: [KeyboardCode.Space],
      },
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const announcements: Announcements = {
    onDragStart({ active }) {
      const element = config.find((e) => e.id === active.id);
      return t("dnd:pickedUp", { name: element?.name });
    },
    onDragOver({ active, over }) {
      const what = config.find((e) => e.id === active.id);
      if (over?.id) {
        const where = config.find((e) => e.id === over.id);
        return t("dnd:movedTo", {
          name: what?.name,
          position: where?.position,
        });
      }

      return t("dnd:notIn", { name: what?.name });
    },
    onDragEnd({ active, over }) {
      const what = config.find((e) => e.id === active.id);
      if (over?.id) {
        const where = config.find((e) => e.id === over.id);
        return t("dnd:droppedTo", {
          name: what?.name,
          position: where?.position,
        });
      }

      return t("dnd:dropped", { name: what?.name });
    },
    onDragCancel({ active }) {
      const what = config.find((e) => e.id === active.id);
      return t("dnd:cancel", { name: what?.name });
    },
  };

  return (
    <div
      style={{
        overflow: "hidden",
        marginTop: -15,
        paddingTop: 15,
        marginBottom: -15,
      }}
    >
      {isString(title) ? (
        <Typography variant="captionSemiBold">{title}</Typography>
      ) : (
        <>{title}</>
      )}
      <DndContext
        onDragEnd={handleDrop}
        measuring={{ droppable: { strategy: MeasuringStrategy.WhileDragging } }}
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        accessibility={{
          announcements: announcements,
        }}
      >
        <SortableContext
          id={DND_CONTAINER_ID}
          items={config}
          strategy={rectSortingStrategy}
        >
          <List
            className={classes.list}
            ref={(el) => {
              setNodeRef(el);
              ref.current = el;
            }}
          >
            {config.map((config) => {
              return (
                <div className={classes.childrenContainer} key={config.id}>
                  <SideBarListItem
                    {...config}
                    data-pendo="monitor-sidenav-dashboard-item"
                    data-testid={`dashboard-sidebar-item-${config.name.replaceAll(
                      " ",
                      "-",
                    )}`}
                  />
                </div>
              );
            })}
            {canFetchMore && (
              <LoadMoreOnScroll onLoadMore={() => fetchMore?.()} />
            )}
          </List>
        </SortableContext>
      </DndContext>
    </div>
  );
}
