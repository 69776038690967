import {
  SlackSolid,
  ExclamationCircleSolid,
  MailSolid,
  MsTeamsSolid,
  useTranslation,
} from "@lumar/shared";
import {
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Tooltip,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import { RulesAndThresholdsFormState } from "../../utils/types";
import { NotificationChannelType } from "./CreateNewNotificationMenu";

const useStyles = makeStyles((theme) =>
  createStyles({
    tabs: {
      backgroundColor: "transparent",
      borderBottom: 0,
    },
    indicator: {
      display: "none",
    },
    icon: {
      marginRight: theme.spacing(1),
      fontSize: 16,
    },
    exclamationIcon: {
      fontSize: theme.typography.pxToRem(16),
      marginLeft: theme.spacing(0.5),
      color: theme.palette.red[600],
    },
  }),
);

interface Props {
  value: NotificationChannelType;
  onChange: (newValue: NotificationChannelType) => void;
  tabsToShow: NotificationChannelType[];
}

export function NotificationChannelTabs({
  value,
  onChange,
  tabsToShow,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["alerts"]);
  const { errors } = useFormikContext<RulesAndThresholdsFormState>();

  const tabsConfig = {
    [NotificationChannelType.Email]: {
      title: t("email"),
      icon: <MailSolid className={classes.icon} />,
      errors: errors.emailAlerts,
      id: "email",
    },
    [NotificationChannelType.Slack]: {
      title: t("slack"),
      icon: <SlackSolid className={classes.icon} />,
      errors: errors.slackWebhooks,
      id: "slack",
    },
    [NotificationChannelType.MsTeams]: {
      title: t("msteams"),
      icon: <MsTeamsSolid className={classes.icon} />,
      errors: errors.microsoftTeamsWebhooks,
      id: "msteams",
    },
  };

  return (
    <StyledTabs
      orientation="vertical"
      value={value}
      onChange={(_, value) => {
        onChange(value);
      }}
    >
      {tabsToShow.map((tab) => {
        const config = tabsConfig[tab];
        return (
          <StyledTab
            key={config.id}
            label={
              <>
                {config.icon}
                {config.title}
                {config.errors ? <ErrorTooltip errors={config.errors} /> : null}
              </>
            }
            value={tab}
            data-testid={`channel-${config.id}-tab`}
            data-pendo={`channel-${config.id}-tab`}
          />
        );
      })}
    </StyledTabs>
  );
}

interface ErrorTooltipProps {
  errors: string | string[];
}

function ErrorTooltip({ errors }: ErrorTooltipProps): JSX.Element {
  const theme = useTheme();

  return (
    <StyledTooltip
      title={Array.isArray(errors) ? (errors.find(Boolean) ?? "") : errors}
      hidden={false}
      arrow
      placement="top"
    >
      <ExclamationCircleSolid
        style={{
          fontSize: theme.typography.pxToRem(16),
          marginLeft: theme.spacing(0.5),
          color: theme.palette.red[600],
        }}
      />
    </StyledTooltip>
  );
}

const StyledTabs = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: 0,
  },
  indicator: {
    display: "none",
  },
})(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    minHeight: 36,
    borderRadius: theme.shape.borderRadius,
    minWidth: "200px!important",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  selected: {
    backgroundColor: theme.palette.ultraviolet[100],
    "&:hover": {
      backgroundColor: theme.palette.ultraviolet[100],
    },
    "& .MuiTab-wrapper": {
      color: theme.palette.ultraviolet[700],
    },
  },
  wrapper: {
    color: theme.palette.grey[700],
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}))(Tab);

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.red[600],
  },
  arrow: {
    color: theme.palette.red[600],
  },
}))(Tooltip);
