import { ReportOption } from "../../../../_common/utils/constants";
import { ColumnHeader } from "../types";
import { findReportTemplate } from "./findReportTemplate";

export function mapReportTemplateCodesToColumnHeaders(
  reportTemplateCodes: string[],
  reportTemplates?: ReportOption[],
): ColumnHeader[] {
  return reportTemplateCodes.map((reportTemplateCode) => {
    const reportTemplate = findReportTemplate(
      reportTemplateCode,
      reportTemplates,
    );
    return {
      // NOTE: Is the index instead of the reportTemplateCode because we want the order order of the
      // column to stay the same so the column doesn't move,when the report code changes.
      id: reportTemplateCode,
      code: reportTemplateCode,
      name: reportTemplate?.name,
      supportedModules: reportTemplate?.supportedModules,
    };
  });
}
