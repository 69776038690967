import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import ResizeObserver from "react-resize-observer";
import {
  DataGridPro,
  GridColumnHeaderParams,
  GridValueGetterParams,
  GridTripleDotsVerticalIcon,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
  BlueGridPagination,
  ConnectionFilter,
  Snackbar,
  isNotEmptyConnectionFilter,
  useTranslation,
  useSession,
} from "@lumar/shared";

import {
  OrderDirection,
  ProjectOrderField,
  RoleCode,
  TableReportStatsDataRowsQuery,
  TrendsComputedType,
  TrendsTableSortableField,
  useTableReportStatsDataRowsQuery,
  useUpdateDashboardTableMutation,
} from "../../../../graphql";
import { useGenericParams } from "../../../../_common/routing/useGenericParams";
import { assert } from "../../../../_common/assert";
import {
  RowData,
  mapAccountOverviewDataToDataGridRows,
} from "../helpers/mapAccountOverviewDataToGridRows";
import { useReportTemplateAccumulator } from "../../../../_common/hooks/useReportTemplateAccumulator";
import { mapReportTemplateCodesToColumnHeaders } from "../helpers/mapReportTemplateCodesToColumnHeaders";
import { AccountOverviewTableCell } from "./components/AccountOverviewTableCell";
import { ProjectNameColumnHeaderText } from "../../common/grid/ProjectNameColumnHeaderText";
import { useURLSearchParams } from "../../../../_common/routing/useURLSearchParams";
import { ViewFilter } from "../project-filter/ViewFilter";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { isDataGridReportValue } from "../helpers/isDataGridReportValue";
import { LoadingOverlay } from "./grid-overlays/LoadingOverlay";
import {
  EmptyStateManager,
  EmptyStateType,
} from "../../common/grid/EmptyStateManager";
import { AbsoluteURLs } from "../../../../_common/routing/absoluteURLs";
import {
  getSortOrderType,
  MonitorSortItem,
  TableColumnSortOrder,
} from "../../helpers/MonitorSortItem";
import { useSnackbar } from "notistack";
import { useTransformFiltersToCustomProjectFilters } from "../../common/grid/helpers/TransformFiltersToCustomProjectFilters";

import {
  transformToPersistedProjectFilter,
  transformToQueriedProjectFilter,
} from "../../common/grid/helpers/transformToProjectFilter";
import { MonitorGridFooter } from "../../common/grid/MonitorGridFooter";
import { GridOverlayColumn } from "../../common/grid/GridOverlayColumn";
import { ColumnHeaderWithComboBox } from "./components/ColumnHeaderWithComboBox";
import { MonitorGridHeader } from "../../common/grid/MonitorGridHeader";
import { getDataGridStyles } from "../../common/grid/utils/getDataGridStyles";
import { useColumnHeightDiff } from "../../common/grid/utils/useColumnHeightDiff";
import { HideFromInsufficientRole } from "../../../../_common/components/HideFromInsufficientRole";
import { transformOldOrderBy } from "../../common/grid/helpers/transformOldOrderBy";
import { DataGridStickyScroll } from "../../common/grid/DataGridStickyScroll";
import { TableSorting } from "../../common/grid/sorting/TableSorting";
import { useTableSortingList } from "./helpers/useTableSortingList";
import { NoSegmentReportAlert } from "../../common/components/NoSegmentReportAlert";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");

const GRID_COLUMN_PADDING_WIDTH = 11;
const PROJECT_NAME_COLUMN_WIDTH = 271 + GRID_COLUMN_PADDING_WIDTH;
const DATA_GRID_HEADER_HEIGHT = 41;
const ROW_HEIGHT = 80;
export const GRID_FOOTER_HEIGHT = 50;

export const PROJECT_NAME_COLUMN_KEY = "name";

const defaultPageSize = 10;

interface AccountOverviewGridProps {
  tableId: string;
  orderBy?:
    | {
        field: ProjectOrderField;
        direction: OrderDirection;
      }[]
    | {
        field: TrendsTableSortableField;
        direction: OrderDirection;
      }[]
    | null;
  filters: ConnectionFilter;
  columns: string[];
  loading?: boolean;
}

export function AccountOverviewGrid({
  tableId,
  columns,
  filters,
  orderBy: oldOrderBy,
  loading: loadingDashboard,
}: AccountOverviewGridProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation([
    "errors",
    "common",
    "accountDashboard",
    "sortingPopup",
  ]);

  const { accountId } = useGenericParams();
  assert(accountId);
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();

  const urlSearchParams = useURLSearchParams();
  const history = useHistory();
  const page = Number(urlSearchParams.get("page")) || 0;

  const savedPageSize = localStorage.getItem("dashboardTablePageSize") ?? "";

  const filter = useMemo(
    () => transformToQueriedProjectFilter(filters),
    [filters],
  );

  const orderBy = transformOldOrderBy(oldOrderBy);

  const pagination = JSON.parse(
    atob(urlSearchParams.get("pagination") ?? btoa("{}")),
  );

  const pageSize =
    pagination?.first ?? (parseInt(savedPageSize) || null) ?? defaultPageSize;

  const hasRightsToEdit =
    tableId && tableId.length
      ? session.hasSufficientRole(RoleCode.Editor)
      : false;

  const gridRef = useGridApiRef();

  const [width, setWidth] = useState<number>(PROJECT_NAME_COLUMN_WIDTH);

  const [newColumnIndex, setNewColumnIndex] = useState<number | undefined>(
    undefined,
  );

  const tempData = useRef<TableReportStatsDataRowsQuery | undefined>(undefined);
  const changedReportTemplateBeingUpdated = useRef<string | null>(null);
  const reportTemplateCodesBeingPersisted = useRef<string[] | null>(null);

  const [updateTableSettings, { loading: tableUpdateRunning }] =
    useUpdateDashboardTableMutation({
      refetchQueries: ["GetDashboard"],
      awaitRefetchQueries: true,
      onError: (error) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("tableUpdateError", { message: error.message })}
          />,
        );
        changedReportTemplateBeingUpdated.current = null;
        reportTemplateCodesBeingPersisted.current = null;
        tempData.current = undefined;
      },
      onCompleted: () => {
        reportTemplateCodesBeingPersisted.current = null;
      },
    });

  const setReportTemplateCodesInTable = (
    changedTemplate: string,
    templates: string[],
    sorting?: MonitorSortItem,
  ): void => {
    changedReportTemplateBeingUpdated.current = changedTemplate;
    reportTemplateCodesBeingPersisted.current = templates;
    tempData.current = accountOverviewData;
    updateTableSettings({
      variables: {
        customTableId: tableId,
        columns: templates,
        orderBy: sorting
          ? [
              {
                field: sorting.field,
                direction: sorting.sort,
                trend: sorting.trend,
              },
            ]
          : undefined,
      },
      refetchQueries: [
        "GetDashboard",
        ...(sorting ? [] : ["TableReportStatsDataRows"]),
      ],
    });
  };

  const getTableSortingList = useTableSortingList();

  const {
    reports: reportTemplates,
    categories,
    loading: reportTemplatesLoading,
    error: reportTemplatesError,
  } = useReportTemplateAccumulator({});

  const reportTemplateCodesInTable = useMemo(() => {
    if (reportTemplates && !reportTemplatesLoading) {
      const filteredColumns = columns.filter((e) =>
        reportTemplates.find((r) => r.code === e),
      );
      return filteredColumns;
    }
    return columns;
  }, [reportTemplates, reportTemplatesLoading, columns]);

  const sortingList = useMemo(() => {
    return getTableSortingList(reportTemplates, reportTemplateCodesInTable);
  }, [reportTemplates, reportTemplateCodesInTable, getTableSortingList]);

  function createSortModel(orderBy: {
    field: TrendsTableSortableField;
    direction: OrderDirection;
    trend?: {
      code: string;
      type: TrendsComputedType;
    };
  }): MonitorSortItem {
    const sortOrderType = getSortOrderType(orderBy.field, orderBy.direction);
    if (
      orderBy.trend &&
      !reportTemplateCodesInTable.includes(orderBy.trend.code)
    )
      return defaultSortModel;
    return {
      field: orderBy.field,
      sort: orderBy.direction,
      type: sortOrderType,
      trend: orderBy.trend,
    };
  }

  const defaultSortModel: MonitorSortItem = {
    type: TableColumnSortOrder.byMostRecentCrawl,
    field: TrendsTableSortableField.ProjectFinishedAt,
    sort: OrderDirection.Desc,
  };

  const sortModel =
    orderBy && orderBy.length ? createSortModel(orderBy[0]) : defaultSortModel;

  const setSortModel = (sortmodel: MonitorSortItem): void => {
    tempData.current = accountOverviewData;
    updateTableSettings({
      variables: {
        customTableId: tableId,
        orderBy: [
          {
            field: sortmodel.field,
            direction: sortmodel.sort,
            trend: sortmodel.trend,
          },
        ],
        columns: reportTemplateCodesInTable,
      },
    }).then(() => {
      resetPagination();
    });
  };

  const setFilterAndResetPagination = (filter: ConnectionFilter): void => {
    updateTableSettings({
      variables: {
        customTableId: tableId,
        filter: transformToPersistedProjectFilter(filter),
        columns: reportTemplateCodesInTable,
      },
    }).then(() => {
      resetPagination();
    });
  };

  const {
    data: accountOverviewData,
    loading: loadingACData,
    error,
    refetch: refetchData,
  } = useTableReportStatsDataRowsQuery({
    variables: {
      tableId,
      filter: filter,
      first: pageSize,
      after: pagination.after,
      orderBy: {
        field: sortModel.field,
        direction: sortModel.sort,
        trendsSortOptions: sortModel.trend
          ? {
              code: sortModel.trend.code,
              field: "basic",
              computedType: sortModel.trend.type,
            }
          : undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      tempData.current = undefined;
      changedReportTemplateBeingUpdated.current = null;
    },
  });

  useEffect(() => {
    if (
      columns.length &&
      !reportTemplateCodesInTable.length &&
      !loadingACData
    ) {
      updateTableSettings({
        variables: {
          customTableId: tableId,
          columns: ["all_pages"],
        },
        refetchQueries: ["GetDashboard"],
        awaitRefetchQueries: true,
      }).then(() => refetchData());
    }
  }, [
    columns.length,
    reportTemplateCodesInTable.length,
    tableId,
    updateTableSettings,
    loadingACData,
    refetchData,
  ]);

  const data = accountOverviewData ?? tempData.current;
  const projectsCount = data?.getCustomTable?.table?.totalCount ?? 0;

  const columnHeaders = mapReportTemplateCodesToColumnHeaders(
    [
      PROJECT_NAME_COLUMN_KEY,
      ...(reportTemplateCodesBeingPersisted.current ||
        reportTemplateCodesInTable),
    ],
    reportTemplates,
  );

  const autocompleteOptions = reportTemplates ?? [];

  function resetPagination(): void {
    if (page > 0) {
      urlSearchParams.delete("page");
      urlSearchParams.delete("pagination");
      history.push({ search: urlSearchParams.toString() });
    }
  }

  function setServerPaginationParams(goingToPage: number, ps?: number): void {
    const cursor =
      goingToPage === 0
        ? undefined
        : btoa((goingToPage * (ps ?? pageSize)).toString());
    const args = { after: cursor, first: ps ?? pageSize };
    urlSearchParams.set("pagination", btoa(JSON.stringify(args)));
  }

  const isLoading =
    loadingACData ||
    reportTemplatesLoading ||
    Boolean(loadingDashboard) ||
    Boolean(columns.length && !reportTemplateCodesInTable.length);

  const isLoadingError = reportTemplatesError || error;
  const isOverlayNeeded = isLoading ? false : projectsCount === 0;
  const isFiltered = isNotEmptyConnectionFilter(filter);

  const showStickyFirstColumn = !isOverlayNeeded && !isLoadingError;

  const tableData = useMemo(
    () =>
      data && reportTemplates
        ? mapAccountOverviewDataToDataGridRows(data, reportTemplates)
        : [],
    [data, reportTemplates],
  );

  const overlayItems: {
    name: string;
    segmentName?: string;
    primaryDomain: string;
    projectId: string;
    segmentId?: string;
  }[] = tableData;

  const formRef = useRef<HTMLDivElement>(null);

  const scrollToTopOfTable = (): void => {
    window.scrollTo({
      behavior: formRef.current ? "smooth" : "auto",
      top: formRef.current ? formRef.current.offsetTop : 0,
    });
  };
  const pageSizeChangeRef = useRef(false);
  pageSizeChangeRef.current = false;

  const [resizeHeight, setResizeHeight] = useState<number>(0);
  const columnHeightDiff = useColumnHeightDiff(isLoading);

  return (
    <div data-testid="account-overview-grid" ref={formRef}>
      <MonitorGridHeader
        title={
          projectsCount
            ? t("accountDashboard:tableTitleWithCount", {
                count: projectsCount,
              })
            : t("accountDashboard:tableTitle")
        }
        loading={isLoading}
        titleTooltipText={t("accountDashboard:tableTitleTooltipText")}
      >
        <HideFromInsufficientRole>
          <div style={{ flex: 1 }}></div>
          <TableSorting
            disabled={isLoading || tableUpdateRunning || !hasRightsToEdit}
            current={sortModel}
            columns={sortingList}
            onApply={(sort) => {
              setSortModel(sort);
            }}
            pendoId="account-overview-score-table"
            testId="account-overview-table"
          />
          <ViewFilter
            filter={useTransformFiltersToCustomProjectFilters(filter)}
            setFilter={setFilterAndResetPagination}
            disabled={isLoading || !hasRightsToEdit || tableUpdateRunning}
          />
        </HideFromInsufficientRole>
      </MonitorGridHeader>

      <div style={{ position: "relative" }}>
        {showStickyFirstColumn && !isOverlayNeeded ? (
          <GridOverlayColumn
            sorting={sortModel}
            width={width}
            items={overlayItems}
            loading={reportTemplatesLoading || loadingDashboard}
            headerHeight={DATA_GRID_HEADER_HEIGHT}
            rowHeight={ROW_HEIGHT}
            components={{ LoadingOverlay }}
            onColumnSort={(model: MonitorSortItem) => {
              setSortModel(model);
            }}
            disableMenu={!hasRightsToEdit}
            resizeHeight={resizeHeight}
          />
        ) : null}
        <ResizeObserver
          onResize={(rect) => {
            setResizeHeight(rect.height - columnHeightDiff);
          }}
        />
        <DataGridStickyScroll
          width={width}
          // We are using this workaround because of a bug in MUI DataGrid.
          // The bug causes the scrollbars to malfunction after the grid recovers from an error state.
          // By forcing React to recreate the element, we can avoid this issue.
          key={isOverlayNeeded ? "error" : "normal"}
        >
          <DataGridPro
            apiRef={gridRef}
            classes={{
              root: clsx(classes.root, classes.sticky),
              columnHeader: classes.columnHeader,
            }}
            components={{
              ErrorOverlay: EmptyStateManager,
              Pagination: BlueGridPagination,
              Footer: MonitorGridFooter,
              NoRowsOverlay: React.Fragment,
              LoadingOverlay: LoadingOverlay,
              ColumnMenuIcon: () => (
                <GridTripleDotsVerticalIcon
                  data-testid="account-overview-grid-project-column-menu-sort-menu-toggle"
                  data-pendo="monitor-dashboard-sort-toggle"
                />
              ),
            }}
            componentsProps={{
              pagination: {
                gridName: "account-overview-grid",
                disabled: isLoading,
                pendoPrefix: "monitor-dashboard",
                autoHide: true,
                classes: {
                  navigationLeft: classes.buttonOutlined,
                  navigationRight: classes.buttonOutlined,
                  leftIcon: classes.leftIconOutlined,
                  rightIcon: classes.rightIconOutlined,
                },
              },
              columnMenu: {
                selected: sortModel.type,
                onSelectionChange: (model: MonitorSortItem) => {
                  setSortModel(model);
                },
              },
              errorOverlay: {
                isError: isLoadingError,
                isFiltered: isFiltered,
                isEmpty: projectsCount === 0,
                onClick: (type: EmptyStateType) => {
                  switch (type) {
                    case EmptyStateType.Error: {
                      window.location.reload();
                      break;
                    }
                    case EmptyStateType.NoResult:
                      setFilterAndResetPagination({});
                      break;
                    case EmptyStateType.NoRows:
                      window.location.href = window.location.href =
                        AbsoluteURLs.EXTERNAL__AnalyzeProjectsList.getUrl({
                          accountId,
                        });
                      break;
                  }
                },
              },
            }}
            error={isLoadingError || isOverlayNeeded ? true : undefined}
            loading={isLoading}
            rows={tableData}
            rowHeight={ROW_HEIGHT}
            headerHeight={DATA_GRID_HEADER_HEIGHT}
            autoHeight={true}
            disableSelectionOnClick={true}
            disableColumnSelector={true}
            disableVirtualization={true}
            hideFooterRowCount={false}
            pagination
            pageSize={pageSize}
            onPageSizeChange={(ps) => {
              pageSizeChangeRef.current = true;
              localStorage.setItem("dashboardTablePageSize", ps.toString());
              setServerPaginationParams(0, ps);
              urlSearchParams.delete("page");
              history.push({ search: urlSearchParams.toString() });
            }}
            page={page}
            rowCount={projectsCount}
            paginationMode={"server"}
            sortingMode={"server"}
            rowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
            disableColumnReorder={true}
            onPageChange={(page) => {
              if (pageSizeChangeRef.current) return;
              setServerPaginationParams(page);
              urlSearchParams.set("page", String(page));
              history.push({ search: urlSearchParams.toString() });
              scrollToTopOfTable();
            }}
            columns={columnHeaders.map((columnHeader, columnHeaderIndex) => {
              const isProjectNameColumn =
                columnHeader.id === PROJECT_NAME_COLUMN_KEY;
              const isFirstTemplateColumn: boolean = columnHeaderIndex === 1;

              const isReportTemplateMissing =
                columnHeader.name === undefined &&
                columnHeader.code !== PROJECT_NAME_COLUMN_KEY &&
                !isLoading;
              const width = isProjectNameColumn
                ? PROJECT_NAME_COLUMN_WIDTH
                : 242;
              return {
                field: columnHeader.id,
                headerName: columnHeader.name,
                width: columnHeaders.length === 2 ? 600 : width,
                minWidth: isProjectNameColumn ? PROJECT_NAME_COLUMN_WIDTH : 242,
                flex: 1,
                sortable: false,
                autoHeight: true,
                editable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  // NOTE: For when have the table data but not the full report template data (for all headers).
                  if (isProjectNameColumn) return <></>;

                  const { value, row } = params;
                  const matchingAutocompleteOption = autocompleteOptions.find(
                    (a) => a.code === columnHeader.code,
                  );

                  if (isReportTemplateMissing) {
                    return <></>;
                  }

                  const isUpdating =
                    changedReportTemplateBeingUpdated.current ===
                      columnHeader.code && tableUpdateRunning;

                  const reloadNeeded =
                    isUpdating ||
                    (isLoading &&
                      changedReportTemplateBeingUpdated.current === null);

                  if (reloadNeeded) {
                    return <Skeleton variant="rect" width={240} height={30} />;
                  }

                  const isValueAReport = isDataGridReportValue(value);
                  const doesSegmentHaveNoReport =
                    row?.segmentId && !row?.segmentLastGeneratedAt;

                  if (!row?.lastFinishedCrawlId || doesSegmentHaveNoReport) {
                    return (
                      <NoSegmentReportAlert
                        showAlert={isFirstTemplateColumn}
                        causeMessage={
                          row?.lastFinishedCrawlId
                            ? t("accountDashboard:noSegmentMessage")
                            : t("accountDashboard:noCrawls")
                        }
                        recomendationMessage={
                          row?.lastFinishedCrawlId
                            ? ""
                            : t("accountDashboard:noCrawlMessage")
                        }
                      />
                    );
                  }
                  if (matchingAutocompleteOption) {
                    return (
                      <AccountOverviewTableCell
                        key={
                          row.projectId + row.segmentId ??
                          "" + columnHeader.code
                        }
                        columnId={columnHeader.id}
                        accountId={accountId}
                        row={row as RowData}
                        crawlId={row.lastFinishedCrawlId}
                        reportTemplate={matchingAutocompleteOption}
                        report={isValueAReport ? value : undefined}
                        width={params.colDef.computedWidth / 2 - 23}
                      />
                    );
                  }
                  // NOTE: For when have the table data but not the full report template data (for all headers).
                  if (!isProjectNameColumn) {
                    return <Skeleton variant="rect" width={240} height={30} />;
                  }
                },
                renderHeader: (param: GridColumnHeaderParams) => {
                  const isProjectNameColumn =
                    columnHeader.id === PROJECT_NAME_COLUMN_KEY;
                  const matchingAutocompleteOption = autocompleteOptions.find(
                    (a) => a.code === columnHeader.code,
                  );
                  if (
                    (matchingAutocompleteOption || isReportTemplateMissing) &&
                    !isProjectNameColumn
                  ) {
                    return (
                      <ColumnHeaderWithComboBox
                        key={columnHeader.code}
                        testId={`column-header-${param.field}`}
                        columnId={param.field}
                        columnIndex={columnHeaderIndex}
                        columnHeaders={columnHeaders}
                        reports={autocompleteOptions}
                        categories={categories ?? []}
                        reportTemplateCodesInTable={
                          reportTemplateCodesBeingPersisted.current ||
                          reportTemplateCodesInTable
                        }
                        setReportTemplateCodesInTable={(changed, codes) => {
                          if (
                            columnHeader.code === sortModel.trend?.code &&
                            sortModel.field === TrendsTableSortableField.Trend
                          ) {
                            setReportTemplateCodesInTable(
                              changed,
                              codes,
                              defaultSortModel,
                            );
                          } else {
                            setReportTemplateCodesInTable(changed, codes);
                          }
                        }}
                        editingIndex={newColumnIndex}
                        setEditingIndex={setNewColumnIndex}
                        setReportTemplatesBeingPersisted={(a) =>
                          (reportTemplateCodesBeingPersisted.current = a)
                        }
                        sorting={sortModel}
                        setSorting={(sort) => {
                          setSortModel(sort);
                        }}
                      />
                    );
                  } else {
                    return isProjectNameColumn ? (
                      <>
                        <ResizeObserver
                          onResize={(rect) => setWidth(rect.width)}
                        />
                        <ProjectNameColumnHeaderText empty={true} />
                      </>
                    ) : (
                      <Skeleton variant="rect" width={240} height={30} />
                    );
                  }
                },
                valueGetter: (param: GridValueGetterParams) => {
                  const columnId = param.field;
                  // NOTE: Is reportTemplateCode for all columns bar the project name column.
                  const columnCode = columnHeaders.find(
                    (columnHeader) => columnHeader.id === columnId,
                  )?.code;
                  assert(columnCode);
                  if (columnCode === PROJECT_NAME_COLUMN_KEY) {
                    return param.row[columnCode];
                  }
                  if (
                    param.row.reports === null ||
                    !Boolean(param.row.lastFinishedCrawlId) ||
                    (param.row.segmentId && !param.row.segmentLastGeneratedAt)
                  )
                    return null;
                  return param.row.reports[columnCode]
                    ? param.row.reports[columnCode]
                    : [];
                },
              };
            })}
          />
        </DataGridStickyScroll>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    sticky: {
      "& .MuiDataGrid-dataContainer": {
        top: DATA_GRID_HEADER_HEIGHT,
      },
      "& .MuiDataGrid-window": {
        top: "0px !important",
      },
      "& .MuiDataGrid-windowContainer": {
        marginBottom: -DATA_GRID_HEADER_HEIGHT,
      },
      "& .MuiDataGrid-columnsContainer": {
        position: "sticky",
        zIndex: 2,
      },
    },
    errorIcon: {
      color: theme.palette.red[400],
    },
    columnHeader: {
      background: theme.palette.background.paper,
    },
    leftIconOutlined: {
      color: theme.palette.grey[700],
      width: 16,
      height: 16,
      marginRight: theme.spacing(1.25),
    },
    rightIconOutlined: {
      color: theme.palette.grey[700],
      width: 16,
      height: 16,
      marginLeft: theme.spacing(1.25),
    },
    buttonOutlined: {
      background: "#FFFFFF",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(0.75),
      padding: theme.spacing(0.5625, 1.5, 0.5625, 1.5),
      marginTop: theme.spacing(1),
    },
    ...getDataGridStyles(theme),
  }),
);
