import { TestResultStatus } from "../../graphql";
import { NotificationStatusFilter } from "../filters/constants";

export function notificationStatusToTestResultStatus(
  status: NotificationStatusFilter,
): TestResultStatus | null {
  switch (status) {
    case NotificationStatusFilter.Adjustments:
      return null;
    case NotificationStatusFilter.Archived:
      return TestResultStatus.Read;
    case NotificationStatusFilter.Unread:
      return TestResultStatus.Unresolved;
    case NotificationStatusFilter.WorkingOnIt:
      return TestResultStatus.WorkingOnIt;
  }
}
