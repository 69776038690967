import { differenceBy, isString, get, set } from "lodash";

export enum Action {
  Create,
  Delete,
}

export function mapAlertNotificationToAction<T>(
  initialValues: T[],
  newValues: string[],
  predicate: string,
): (T & { action: Action })[] {
  const toDelete = differenceBy(initialValues, newValues, (e) =>
    isString(e) ? e : get(e, predicate),
  ).map((alert) => ({ ...alert, action: Action.Delete }));

  const toCreate = differenceBy(newValues, initialValues, (e) =>
    isString(e) ? e : get(e, predicate),
  ).map((value) => {
    const a = {
      action: Action.Create,
    };
    set(a, predicate, value);
    return a as T & { action: Action };
  });
  return [...toCreate, ...toDelete];
}
