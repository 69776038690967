import React from "react";

interface Props {
  children: React.ReactNode;
  show: boolean;
}

/**
 * Use this component to conditionally hide/show content.
 */
export function ConditionallyShow({ children, show }: Props): JSX.Element {
  if (show) {
    return <>{children}</>;
  }

  return <></>;
}
