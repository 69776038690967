import { useTranslation } from "@lumar/shared";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
  Link,
} from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  accordionTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  accordionRoot: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "6px !important",
    "&.Mui-expanded:last-child": {
      marginBottom: "42px",
    },
    "&::before": {
      background: "none",
    },
  },
  instructionsTitle: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
  },
  showInstructions: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  accordionSummaryRoot: {
    padding: theme.spacing(0, 3),
    minHeight: "0px !important",
  },
  accordionSummaryExpanded: {
    "& .MuiAccordionSummary-content": {
      margin: "13px 0px 12px 0px",
    },
  },
  accordionDetails: {
    padding: theme.spacing(2, 3, 3, 3),
  },
  bold: {
    fontWeight: 600,
  },
}));

export interface HidableBlockProps {
  children: JSX.Element;
  title: string;
  descriptor: string;
  dataIdDescriptor: string;
}

export function HidableBlock(props: HidableBlockProps): JSX.Element {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation("common");
  function handleChange(): void {
    setIsExpanded((current) => !current);
  }

  return (
    <>
      <Accordion
        classes={{
          root: classes.accordionRoot,
        }}
        data-testid={`${props.dataIdDescriptor}-accordion`}
        data-pendo={`monitor-${props.dataIdDescriptor}`}
        expanded={isExpanded}
        onChange={handleChange}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-testid={`${props.dataIdDescriptor}-accordion-summary`}
          data-pendo={`monitor-${props.dataIdDescriptor}-accordion`}
          classes={{
            root: classes.accordionSummaryRoot,
            expanded: classes.accordionSummaryExpanded,
          }}
        >
          <div className={classes.accordionTitleWrapper}>
            <Typography
              className={classes.instructionsTitle}
              color="textPrimary"
            >
              {props.title}
            </Typography>
            <Typography className={classes.showInstructions}>
              {isExpanded
                ? t(`hide-${props.descriptor}`)
                : t(`show-${props.descriptor}`)}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div style={{ display: "flex", flexFlow: "column" }}>
            {props.children}
            <Link
              style={{ textAlign: "right", marginBottom: -10 }}
              className={classes.showInstructions}
              component="p"
              href="#"
              variant="body1"
              onClick={handleChange}
            >
              {t(`hide-${props.descriptor}`)}
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
