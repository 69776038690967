import { Typography } from "@lumar/shared";
import { useTheme, makeStyles } from "@material-ui/core";
import { AutocompleteRenderGroupParams } from "@material-ui/lab";
import { ReactElement } from "react";
import { FullReportCategory } from "../../../utils/constants";
import {
  isReactElement,
  isReactElementArray,
} from "../../utils/isReactElementArray";
import { getReactElementAttrib } from "../utils/getReactElementAttrib";

interface ReportOptionGroupElementProps {
  group: AutocompleteRenderGroupParams;
  categories?: FullReportCategory[];
}

export function ReportOptionGroupElement({
  group,
  categories,
}: ReportOptionGroupElementProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const selectedGroup = group.group === "";
  const category =
    categories?.find((e) => e.code === group.group) ??
    ({
      icon: undefined,
      name: group.group,
    } as FullReportCategory);

  const groups =
    isReactElementArray(group.children) && !selectedGroup
      ? group.children.reduce(
          (cv, e) => {
            if (isReactElement(e)) {
              const parent = getReactElementAttrib(e, "group");
              // eslint-disable-next-line fp/no-mutation
              if (parent) cv[parent] = [...(cv[parent] ?? []), e];
            }
            return cv;
          },
          {} as Record<string, ReactElement[]>,
        )
      : {};
  const hasGroups = Boolean(Object.keys(groups).length);
  const children = hasGroups
    ? Object.keys(groups).map((key) => {
        const childrens = groups[key];
        const category = categories?.find((cat) => cat.code === key);
        if (category) {
          return (
            <li
              key={`${group.key}-${category.parentName}-${category.code}_subgroup`}
              style={{ marker: "none" }}
            >
              {!selectedGroup ? (
                <div className={classes.group2}>
                  {category.icon ? (
                    <category.icon className={classes.icon} />
                  ) : undefined}
                  <Typography
                    variant="subtitle3SemiBold"
                    style={{
                      paddingLeft: theme.spacing(1.5),
                      fontSize: theme.typography.pxToRem(14),
                    }}
                  >
                    {category.name}
                  </Typography>
                </div>
              ) : undefined}
              <ul style={{ paddingInlineStart: 0 }}>{childrens}</ul>
            </li>
          );
        }
        return groups[key];
      })
    : group.children;

  return (
    <li key={group.key}>
      {!selectedGroup ? (
        <div className={hasGroups ? classes.group : classes.group2}>
          {category.icon ? (
            <category.icon className={classes.icon} />
          ) : undefined}
          <Typography
            variant="subtitle3SemiBold"
            style={{
              paddingLeft: theme.spacing(1.5),
              fontSize: theme.typography.pxToRem(14),
            }}
          >
            {category.name}
          </Typography>
        </div>
      ) : undefined}
      <ul style={{ paddingInlineStart: 0 }}>{children}</ul>
    </li>
  );
}

const useStyles = makeStyles((theme) => ({
  group: {
    position: "sticky",
    top: -theme.spacing(1),
    zIndex: theme.zIndex.modal + 2,
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#EBEFF3",
    height: 33,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  group2: {
    position: "sticky",
    top: 25,
    zIndex: theme.zIndex.modal + 1,
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    height: 38,
  },
  icon: {
    color: theme.palette.purple[400],
    width: 20,
    height: 20,
    marginLeft: theme.spacing(2),
  },
}));
