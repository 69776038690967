import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import ResizeObserver from "react-resize-observer";
import {
  DataGridPro,
  GridValueGetterParams,
  GridColumnHeaderParams,
  GridTripleDotsVerticalIcon,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
  BlueGridPagination,
  ConnectionFilter,
  Snackbar,
  isNotEmptyConnectionFilter,
  useTranslation,
  useSession,
} from "@lumar/shared";

import {
  OrderDirection,
  ProjectOrderField,
  RoleCode,
  TableHealthScoreStatsDataRowsQuery,
  TrendsComputedType,
  TrendsTableSortableField,
  useTableHealthScoreStatsDataRowsQuery,
  useUpdateDashboardTableMutation,
} from "../../../../graphql";
import { useGenericParams } from "../../../../_common/routing/useGenericParams";
import { assert } from "../../../../_common/assert";
import { useURLSearchParams } from "../../../../_common/routing/useURLSearchParams";
import { LicenseInfo } from "@mui/x-data-grid-pro";

import { AbsoluteURLs } from "../../../../_common/routing/absoluteURLs";
import {
  EmptyStateManager,
  EmptyStateType,
} from "../../common/grid/EmptyStateManager";
import { LoadingOverlay } from "../../account-overview/account-overview-grid/grid-overlays/LoadingOverlay";
import { useTransformFiltersToCustomProjectFilters } from "../../common/grid/helpers/TransformFiltersToCustomProjectFilters";
import {
  transformToPersistedProjectFilter,
  transformToQueriedProjectFilter,
} from "../../common/grid/helpers/transformToProjectFilter";
import { ProjectNameColumnHeaderText } from "../../common/grid/ProjectNameColumnHeaderText";
import { isDataGridReportValue } from "../../account-overview/helpers/isDataGridReportValue";
import { ViewFilter } from "../../account-overview/project-filter/ViewFilter";
import { GridOverlayColumn } from "../../common/grid/GridOverlayColumn";
import { ReportCategoryOption } from "../../../../_common/utils/constants";
import { HealthScoreTableCell } from "./components/HealthScoreTableCell";
import { useSnackbar } from "notistack";
import { getDataGridStyles } from "../../common/grid/utils/getDataGridStyles";
import { useColumnHeightDiff } from "../../common/grid/utils/useColumnHeightDiff";
import { HideFromInsufficientRole } from "../../../../_common/components/HideFromInsufficientRole";
import { transformTableDataToGridRows } from "./utils/transformTableDataToGridRows";
import { transformOldOrderBy } from "../../common/grid/helpers/transformOldOrderBy";
import { DataGridStickyScroll } from "../../common/grid/DataGridStickyScroll";
import {
  getSortOrderType,
  MonitorSortItem,
  TableColumnSortOrder,
} from "../../helpers/MonitorSortItem";
import { NoSegmentReportAlert } from "../../common/components/NoSegmentReportAlert";
import { useTableSortingList } from "./helpers/useTableSortingList";
import { MonitorGridHeader } from "../../common/grid/MonitorGridHeader";
import { TableSorting } from "../../common/grid/sorting/TableSorting";
import { MonitorGridFooter } from "../../common/grid/MonitorGridFooter";
import { HSColumnHeaderWithComboBox } from "./components/HSColumnHeaderWithComboBox";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");

export const OVERALL_HS_CODE = "top";
export const A11Y_HS_CODE = "accessibility";
const PROJECT_NAME_COLUMN_WIDTH = 271;
const DATA_GRID_HEADER_HEIGHT = 41;
const ROW_HEIGHT = 80;
export const PROJECT_NAME_COLUMN_KEY = "name";

const useStyles = makeStyles((theme) =>
  createStyles({
    sticky: {
      "& .MuiDataGrid-dataContainer": {
        top: DATA_GRID_HEADER_HEIGHT,
      },
      "& .MuiDataGrid-window": {
        top: "0px !important",
      },
      "& .MuiDataGrid-windowContainer": {
        marginBottom: -DATA_GRID_HEADER_HEIGHT,
      },
      "& .MuiDataGrid-columnsContainer": {
        position: "sticky",
        zIndex: 2,
      },
    },
    errorIcon: {
      color: theme.palette.red[400],
    },
    columnHeader: {
      background: theme.palette.background.paper,
    },
    leftIconOutlined: {
      color: theme.palette.grey[700],
      width: 16,
      height: 16,
      marginRight: theme.spacing(1.25),
    },
    rightIconOutlined: {
      color: theme.palette.grey[700],
      width: 16,
      height: 16,
      marginLeft: theme.spacing(1.25),
    },
    buttonOutlined: {
      background: "#FFFFFF",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(0.75),
      padding: theme.spacing(0.5625, 1.5, 0.5625, 1.5),
      marginTop: theme.spacing(1),
    },
    ...getDataGridStyles(theme),
  }),
);

const defaultPageSize = 10;

interface HealthScoreTableProps {
  tableId?: string;
  orderBy?:
    | {
        field: ProjectOrderField;
        direction: OrderDirection;
      }[]
    | {
        field: TrendsTableSortableField;
        direction: OrderDirection;
      }[]
    | null;
  filters: ConnectionFilter;
  columns: ReportCategoryOption[];
  categories: ReportCategoryOption[];
  loading?: boolean;
}

export function HealthScoreTable({
  tableId,
  columns: tableColumns,
  filters,
  orderBy: oldOrderBy,
  loading: loadingDashboard,
  categories,
}: HealthScoreTableProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation([
    "errors",
    "common",
    "accountDashboard",
    "sortingPopup",
  ]);
  const { accountId } = useGenericParams();
  assert(accountId);
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();

  const urlSearchParams = useURLSearchParams();
  const history = useHistory();
  const page = Number(urlSearchParams.get("page")) || 0;

  const savedPageSize = localStorage.getItem("dashboardTablePageSize") ?? "";

  const filter = useMemo(
    () => transformToQueriedProjectFilter(filters),
    [filters],
  );

  const columns = useMemo(() => {
    if (categories?.length && !loadingDashboard)
      return tableColumns.filter((e) =>
        categories.find((c) => c.code === e.code),
      );
    return tableColumns;
  }, [categories, loadingDashboard, tableColumns]);

  const orderBy = transformOldOrderBy(oldOrderBy);

  const pagination = JSON.parse(
    atob(urlSearchParams.get("pagination") ?? btoa("{}")),
  );
  const getTableSortingList = useTableSortingList();
  const pageSize =
    pagination?.first ?? (parseInt(savedPageSize) || null) ?? defaultPageSize;

  const hasRightsToEdit =
    tableId && tableId.length
      ? session.hasSufficientRole(RoleCode.Editor)
      : false;

  const gridRef = useGridApiRef();

  const [width, setWidth] = useState<number>(PROJECT_NAME_COLUMN_WIDTH);

  const [newColumnIndex, setNewColumnIndex] = useState<number | undefined>(
    undefined,
  );

  const tempData = useRef<TableHealthScoreStatsDataRowsQuery | undefined>(
    undefined,
  );
  const changedReportTemplateBeingUpdated = useRef<string | null>(null);
  const reportTemplateCategoriesBeingPersisted = useRef<string[] | null>(null);

  const [updateTableSettings, { loading: tableUpdateRunning }] =
    useUpdateDashboardTableMutation({
      refetchQueries: ["GetDashboard"],
      awaitRefetchQueries: true,
      onError: (error) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("tableUpdateError", { message: error.message })}
          />,
        );
        changedReportTemplateBeingUpdated.current = null;
        reportTemplateCategoriesBeingPersisted.current = null;
        tempData.current = undefined;
      },
      onCompleted: () => {
        reportTemplateCategoriesBeingPersisted.current = null;
      },
    });

  useEffect(() => {
    if (tableColumns.length && !columns.length) {
      updateTableSettings({
        variables: {
          customTableId: tableId,
          columns: ["top"],
        },
        refetchQueries: ["GetDashboard", "TableReportStatsDataRows"],
      });
    }
  }, [columns.length, tableColumns.length, tableId, updateTableSettings]);

  const setReportTemplateCategoriesInTable = (
    changedTemplate: string,
    savedCategories: string[],
    sorting?: MonitorSortItem,
  ): void => {
    changedReportTemplateBeingUpdated.current = changedTemplate;
    reportTemplateCategoriesBeingPersisted.current = savedCategories;
    tempData.current = healthscoreData;
    updateTableSettings({
      variables: {
        customTableId: tableId,
        columns: savedCategories,
        orderBy: sorting
          ? [
              {
                field: sorting.field,
                direction: sorting.sort,
                trend: sorting.trend,
              },
            ]
          : undefined,
      },
      refetchQueries: [
        "GetDashboard",
        ...(sorting ? [] : ["TableHealthScoreStatsDataRows"]),
      ],
    });
  };

  const sortingList = useMemo(() => {
    return getTableSortingList(columns);
  }, [columns, getTableSortingList]);

  function createSortModel(orderBy: {
    field: TrendsTableSortableField;
    direction: OrderDirection;
    trend?: {
      code: string;
      type: TrendsComputedType;
    };
  }): MonitorSortItem {
    const sortOrderType = getSortOrderType(orderBy.field, orderBy.direction);
    return {
      field: orderBy.field,
      sort: orderBy.direction,
      type: sortOrderType,
      trend: orderBy.trend,
    };
  }

  const defaultSortModel: MonitorSortItem = {
    type: TableColumnSortOrder.byMostRecentCrawl,
    field: TrendsTableSortableField.ProjectFinishedAt,
    sort: OrderDirection.Desc,
  };

  const sortModel =
    orderBy && orderBy.length ? createSortModel(orderBy[0]) : defaultSortModel;

  const setSortModel = (sortmodel: MonitorSortItem): void => {
    tempData.current = healthscoreData;
    updateTableSettings({
      variables: {
        customTableId: tableId,
        orderBy: [
          {
            field: sortmodel.field,
            direction: sortmodel.sort,
            trend: sortmodel.trend,
          },
        ],
        columns: columns.map((e) => e.code),
      },
    }).then(() => {
      resetPagination();
    });
  };

  const setFilterAndResetPagination = (filter: ConnectionFilter): void => {
    updateTableSettings({
      variables: {
        customTableId: tableId,
        filter: transformToPersistedProjectFilter(filter),
        columns: columns.map((e) => e.code),
      },
    }).then(() => {
      resetPagination();
    });
  };

  const {
    data: healthscoreData,
    loading,
    error: tableError,
  } = useTableHealthScoreStatsDataRowsQuery({
    variables: {
      tableId,
      filter: filter,
      first: pageSize,
      after: pagination.after,
      orderBy: {
        field: sortModel.field,
        direction: sortModel.sort,
        trendsSortOptions: sortModel.trend
          ? {
              code: sortModel.trend.code,
              field: "health_score",
              computedType: sortModel.trend.type,
            }
          : undefined,
      },
    },
    onCompleted: () => {
      tempData.current = undefined;
      changedReportTemplateBeingUpdated.current = null;
    },
  });

  const data = healthscoreData ?? tempData.current;
  const projectsCount = data?.getCustomTable?.table?.totalCount || 0;

  const columnHeaders = [
    { id: "0", code: "name", name: PROJECT_NAME_COLUMN_KEY },
    ...(reportTemplateCategoriesBeingPersisted.current
      ?.map((e) => categories.find((c) => c.code === e))
      ?.filter((e): e is ReportCategoryOption => Boolean(e)) ??
      columns.map((e, index) => ({
        id: (index + 1).toString(),
        code: e.code,
        name: e.name,
      })) ??
      []),
  ];

  function resetPagination(): void {
    if (page > 0) {
      urlSearchParams.delete("page");
      urlSearchParams.delete("pagination");
      history.push({ search: urlSearchParams.toString() });
    }
  }

  function setServerPaginationParams(goingToPage: number, ps?: number): void {
    const cursor =
      goingToPage === 0
        ? undefined
        : btoa((goingToPage * (ps ?? pageSize)).toString());
    const args = { after: cursor, first: ps ?? pageSize };
    urlSearchParams.set("pagination", btoa(JSON.stringify(args)));
  }
  const isLoading = loading || Boolean(loadingDashboard);

  const isLoadingError = tableError;
  const isOverlayNeeded = isLoading ? false : projectsCount === 0;
  const isFiltered = isNotEmptyConnectionFilter(filter);

  const showStickyFirstColumn = !isOverlayNeeded && !isLoadingError;

  const tableRows = useMemo(
    () => (data ? transformTableDataToGridRows(data) : []),
    [data],
  );

  const overlayItems: {
    name: string;
    primaryDomain: string;
    projectId: string;
  }[] = tableRows;

  const formRef = useRef<HTMLDivElement>(null);

  const scrollToTopOfTable = (): void => {
    window.scrollTo({
      behavior: formRef.current ? "smooth" : "auto",
      top: formRef.current ? formRef.current.offsetTop : 0,
    });
  };
  const pageSizeChangeRef = useRef(false);
  pageSizeChangeRef.current = false;

  const [resizeHeight, setResizeHeight] = useState<number>(0);
  const columnHeightDiff = useColumnHeightDiff(isLoading);

  return (
    <div data-testid="account-overview-grid" ref={formRef}>
      <MonitorGridHeader
        title={
          projectsCount
            ? t("accountDashboard:tableTitleWithCount", {
                count: projectsCount,
              })
            : t("accountDashboard:tableTitle")
        }
        loading={isLoading}
        titleTooltipText={t("accountDashboard:tableTitleTooltipText")}
      >
        <HideFromInsufficientRole>
          <div style={{ flex: 1 }}></div>
          <TableSorting
            disabled={isLoading || tableUpdateRunning || !hasRightsToEdit}
            current={sortModel}
            columns={sortingList}
            onApply={(sort) => {
              setSortModel(sort);
            }}
            pendoId="health-score-table"
            testId="health-score-table"
          />
          <ViewFilter
            filter={useTransformFiltersToCustomProjectFilters(filter)}
            setFilter={setFilterAndResetPagination}
            disabled={isLoading || !hasRightsToEdit || tableUpdateRunning}
          />
        </HideFromInsufficientRole>
      </MonitorGridHeader>

      <div style={{ position: "relative" }}>
        {showStickyFirstColumn && !isOverlayNeeded ? (
          <GridOverlayColumn
            sorting={sortModel}
            width={width}
            items={overlayItems}
            loading={loadingDashboard}
            headerHeight={DATA_GRID_HEADER_HEIGHT}
            rowHeight={ROW_HEIGHT}
            components={{ LoadingOverlay }}
            onColumnSort={(model: MonitorSortItem) => {
              setSortModel(model);
            }}
            disableMenu={!hasRightsToEdit}
            resizeHeight={resizeHeight}
          />
        ) : null}
        <ResizeObserver
          onResize={(rect) => {
            setResizeHeight(rect.height - columnHeightDiff);
          }}
        />
        <DataGridStickyScroll
          width={width}
          // We are using this workaround because of a bug in MUI DataGrid.
          // The bug causes the scrollbars to malfunction after the grid recovers from an error state.
          // By forcing React to recreate the element, we can avoid this issue.
          key={isOverlayNeeded ? "error" : "normal"}
        >
          <DataGridPro
            apiRef={gridRef}
            classes={{
              root: clsx(classes.root, classes.sticky),
              columnHeader: classes.columnHeader,
            }}
            components={{
              ErrorOverlay: EmptyStateManager,
              Pagination: BlueGridPagination,
              Footer: MonitorGridFooter,
              NoRowsOverlay: React.Fragment,
              LoadingOverlay: LoadingOverlay,
              ColumnMenuIcon: () => (
                <GridTripleDotsVerticalIcon
                  data-testid="account-overview-grid-project-column-menu-sort-menu-toggle"
                  data-pendo="monitor-dashboard-sort-toggle"
                />
              ),
            }}
            componentsProps={{
              pagination: {
                gridName: "account-overview-grid",
                disabled: isLoading,
                pendoPrefix: "monitor-dashboard",
                autoHide: true,
                classes: {
                  navigationLeft: classes.buttonOutlined,
                  navigationRight: classes.buttonOutlined,
                  leftIcon: classes.leftIconOutlined,
                  rightIcon: classes.rightIconOutlined,
                },
              },
              columnMenu: {
                selected: sortModel.type,
                onSelectionChange: (model: MonitorSortItem) => {
                  setSortModel(model);
                },
              },
              errorOverlay: {
                isError: isLoadingError,
                isFiltered: isFiltered,
                isEmpty: projectsCount === 0,
                onClick: (type: EmptyStateType) => {
                  switch (type) {
                    case EmptyStateType.Error: {
                      window.location.reload();
                      break;
                    }
                    case EmptyStateType.NoResult:
                      setFilterAndResetPagination({});
                      break;
                    case EmptyStateType.NoRows:
                      window.location.href = window.location.href =
                        AbsoluteURLs.EXTERNAL__AnalyzeProjectsList.getUrl({
                          accountId,
                        });
                      break;
                  }
                },
              },
            }}
            error={isLoadingError || isOverlayNeeded ? true : undefined}
            loading={isLoading}
            rows={tableRows}
            rowHeight={ROW_HEIGHT}
            headerHeight={DATA_GRID_HEADER_HEIGHT}
            autoHeight={true}
            disableSelectionOnClick={true}
            disableColumnSelector={true}
            disableVirtualization={true}
            hideFooterRowCount={false}
            pagination
            pageSize={pageSize}
            onPageSizeChange={(ps) => {
              pageSizeChangeRef.current = true;
              localStorage.setItem("dashboardTablePageSize", ps.toString());
              setServerPaginationParams(0, ps);
              urlSearchParams.delete("page");
              history.push({ search: urlSearchParams.toString() });
            }}
            page={page}
            rowCount={projectsCount}
            paginationMode={"server"}
            sortingMode={"server"}
            rowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
            disableColumnReorder={true}
            onPageChange={(page) => {
              if (pageSizeChangeRef.current) return;
              setServerPaginationParams(page);
              urlSearchParams.set("page", String(page));
              history.push({ search: urlSearchParams.toString() });
              scrollToTopOfTable();
            }}
            columns={columnHeaders.map((columnHeader, columnHeaderIndex) => {
              const isProjectNameColumn =
                columnHeader.code === PROJECT_NAME_COLUMN_KEY;
              const isFirstTemplateColumn = columnHeader.id === "1";
              const isOverallCollumn =
                columnHeader.code === OVERALL_HS_CODE ||
                columnHeader.code === A11Y_HS_CODE;
              const width = isProjectNameColumn
                ? PROJECT_NAME_COLUMN_WIDTH
                : 242;
              return {
                field: columnHeader.code,
                headerName: columnHeader.name,
                width: columnHeaders.length === 2 ? 600 : width,
                minWidth: isProjectNameColumn ? PROJECT_NAME_COLUMN_WIDTH : 242,
                flex: 1,
                sortable: false,
                autoHeight: true,
                editable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  // NOTE: For when have the table data but not the full report template data (for all headers).
                  if (isProjectNameColumn) return <></>;

                  const { value, row } = params;
                  const isUpdating =
                    changedReportTemplateBeingUpdated.current ===
                      columnHeader.code && tableUpdateRunning;

                  const reloadNeeded =
                    isUpdating ||
                    (isLoading &&
                      changedReportTemplateBeingUpdated.current === null);

                  if (reloadNeeded) {
                    return <Skeleton variant="rect" width={240} height={30} />;
                  }

                  const isValueAReport = isDataGridReportValue(value);
                  const doesReportHaveNoTrend =
                    isValueAReport && !value?.trends?.length;
                  const doesSegmentHaveNoReport =
                    row?.segmentId && !row?.segmentLastGeneratedAt;

                  if (!row?.lastFinishedCrawlId || doesSegmentHaveNoReport) {
                    return (
                      <NoSegmentReportAlert
                        showAlert={isFirstTemplateColumn}
                        causeMessage={
                          row?.lastFinishedCrawlId
                            ? t("accountDashboard:noSegmentMessage")
                            : t("accountDashboard:noCrawls")
                        }
                        recomendationMessage={
                          row?.lastFinishedCrawlId
                            ? ""
                            : t("accountDashboard:noCrawlMessage")
                        }
                      />
                    );
                  }
                  if (doesReportHaveNoTrend) {
                    return <div></div>;
                  } else {
                    const category = columns.find(
                      (e) => e.code === params.field,
                    );
                    return (
                      <HealthScoreTableCell
                        key={
                          row.projectId + row.segmentId ??
                          "" + columnHeader.code
                        }
                        columnId={columnHeader.id}
                        accountId={accountId}
                        projectId={params.row.projectId}
                        segmentId={params.row.segmentId}
                        crawlId={params.row.lastFinishedCrawlId}
                        report={isValueAReport ? value : undefined}
                        width={params.colDef.computedWidth / 2 - 23}
                        category={{ code: params.field, name: category?.name }}
                        isOverview={isOverallCollumn}
                      />
                    );
                  }
                  // NOTE: For when have the table data but not the full report template data (for all headers).
                  if (!isProjectNameColumn) {
                    return <Skeleton variant="rect" width={240} height={30} />;
                  }
                },
                // eslint-disable-next-line react/display-name
                renderHeader: (param: GridColumnHeaderParams) => {
                  const isProjectNameColumn =
                    param.field === PROJECT_NAME_COLUMN_KEY;
                  return isProjectNameColumn ? (
                    <>
                      <ResizeObserver
                        onResize={(rect) => setWidth(rect.width)}
                      />
                      <ProjectNameColumnHeaderText empty={false} />
                    </>
                  ) : (
                    <HSColumnHeaderWithComboBox
                      key={param.field}
                      testId={`column-header-${param.field}`}
                      columnId={param.field}
                      columnIndex={columnHeaderIndex}
                      columnHeaders={columnHeaders}
                      categories={categories}
                      reportTemplateCategoriesInTable={
                        reportTemplateCategoriesBeingPersisted.current ||
                        columns.map((e) => e.code)
                      }
                      setReportTemplateCategoriesInTable={(changed, codes) => {
                        if (
                          columnHeader.code === sortModel.trend?.code &&
                          sortModel.field === TrendsTableSortableField.Trend
                        ) {
                          setReportTemplateCategoriesInTable(
                            changed,
                            codes,
                            defaultSortModel,
                          );
                        } else {
                          setReportTemplateCategoriesInTable(changed, codes);
                        }
                      }}
                      editingIndex={newColumnIndex}
                      setEditingIndex={setNewColumnIndex}
                      setReportTemplateCategoriesBeingPersisted={(a) => {
                        reportTemplateCategoriesBeingPersisted.current = a;
                      }}
                      sorting={sortModel}
                      setSorting={(sort) => {
                        setSortModel(sort);
                      }}
                    />
                  );
                },
                valueGetter: (param: GridValueGetterParams) => {
                  if (param.field === PROJECT_NAME_COLUMN_KEY) {
                    return PROJECT_NAME_COLUMN_KEY;
                  }
                  if (
                    param.row.categories === null ||
                    !Boolean(param.row.lastFinishedCrawlId) ||
                    (param.row.segmentId && !param.row.segmentLastGeneratedAt)
                  )
                    return null;
                  return param.row.categories[param.field] ?? [];
                },
              };
            })}
          />
        </DataGridStickyScroll>
      </div>
    </div>
  );
}
