import Highcharts from "highcharts";

interface PointWithCrawlFrequency extends Highcharts.Point {
  crawlFrequency?: string;
}

export function isPointWithCrawlFrequency(
  point: Highcharts.Point,
): point is PointWithCrawlFrequency {
  return "crawlFrequency" in point;
}
