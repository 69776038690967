import { categoryIconConfig, ApolloError } from "@lumar/shared";
import { useGetReportCategoriesQuery } from "../../graphql";
import { FullReportCategory } from "../utils/constants";
import { useMemo } from "react";

export function useReportCategories(
  addTopCategories?: boolean,
): [
  { loading: boolean; error: ApolloError | undefined },
  (id: string) => FullReportCategory | undefined,
  FullReportCategory[],
] {
  const { loading, data, error } = useGetReportCategoriesQuery();
  const topCategories = useMemo(
    () => data?.getReportCategories.nodes.filter((e) => !Boolean(e.parentCode)),
    [data?.getReportCategories.nodes],
  );

  const categories = useMemo(
    () => [
      ...(data?.getReportCategories.nodes
        .filter((c) => {
          return topCategories?.find((e) => e.code === c.parentCode);
        })
        .map((e) => ({
          code: e.code,
          name: e.name,
          parentName: topCategories?.find((tc) => tc.code === e.parentCode)
            ?.name,
          parentCode: e.parentCode ?? undefined,
          description: e.description,
          icon: categoryIconConfig.get(e.code),
          position: e.position,
          subCategories:
            data?.getReportCategories.nodes
              .filter((s) => s.parentCode === e.code)
              ?.map((s) => ({
                id: s.code,
              })) ?? [],
        })) ?? []),
      ...(addTopCategories
        ? (topCategories ?? []).map((e) => ({
            code: e.code,
            name: e.name,
            parentName: e.name,
            parentCode: e.parentCode ?? undefined,
            description: e.description,
            icon: categoryIconConfig.get(e.code),
            position: e.position,
            subCategories:
              data?.getReportCategories.nodes
                .filter((s) => s.parentCode === e.code)
                ?.map((s) => ({
                  id: s.code,
                })) ?? [],
          }))
        : []),
    ],
    [addTopCategories, data?.getReportCategories.nodes, topCategories],
  );

  function getCategoryByID(id: string): FullReportCategory | undefined {
    const cat = categories.find(
      (c) => c.code === id || c.subCategories?.find((sc) => sc.id === id),
    );
    return cat;
  }

  return [{ loading: loading, error: error }, getCategoryByID, categories];
}
