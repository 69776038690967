import { Typography, useTranslation } from "@lumar/shared";
import {
  Accordion,
  AccordionDetails,
  Divider,
  useTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { useState } from "react";
import { Template } from "./utils/templates";
import {
  useGetReportTemplateCategoriesByCodeQuery,
  useGetReportTemplatesByCodeQuery,
} from "../../../graphql";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    border: 0,
    filter: "none",
    boxShadow: "none",
  },
  accordionExpanded: {
    margin: "0 !important",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
    border: "1.5px solid",
    borderColor: theme.palette.grey[300],
    borderRadius: "8px 8px 8px 8px",
  },
  accordionDetailsRoot: {
    padding: theme.spacing(0),
  },
}));

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export function TemplatesAccordion({
  template,
}: {
  template: Template;
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(true);
  const { t } = useTranslation(["categories"]);

  const { data: reportData, loading: loadingReportData } =
    useGetReportTemplatesByCodeQuery({
      variables: {
        codes: template.subCodes ?? [],
      },
      skip: template.isHealthScore,
    });
  const { data: categoryData, loading: loadingCategoryData } =
    useGetReportTemplateCategoriesByCodeQuery({
      variables: {
        codes: template.subCodes ?? [],
      },
      skip: !template.isHealthScore,
    });

  const data =
    reportData?.getReportTemplates?.edges ??
    categoryData?.getReportCategories?.edges ??
    [];

  const loading = loadingReportData || loadingCategoryData;

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, newValue) => setExpanded(newValue)}
      TransitionProps={{ unmountOnExit: true }}
      data-testid="templates-accordion"
      classes={{
        root: classes.accordionRoot,
        expanded: classes.accordionExpanded,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            {template.name}
          </Typography>
          <Typography
            style={{ color: theme.palette.grey[600] }}
            variant="captionMedium"
          >
            {template.description}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionDetails}
        classes={{ root: classes.accordionDetailsRoot }}
      >
        {template.subCodes?.map((x) => {
          const reportTemplate = data?.find(({ node: e }) => e.code === x)
            ?.node ?? { name: "", description: "", code: "" };
          return (
            <React.Fragment key={x}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  padding: theme.spacing(1.5, 1.8, 1.5, 1.8),
                }}
              >
                {loading ? (
                  <Skeleton variant="rect" width={240} height={30} />
                ) : (
                  <>
                    <Typography
                      style={{
                        marginBottom: theme.spacing(0.2),
                        color: theme.palette.grey[800],
                        fontWeight: 500,
                      }}
                      variant="subtitle3"
                    >
                      {reportTemplate?.code === "top" ||
                      reportTemplate?.code === "accessibility"
                        ? t(reportTemplate?.code)
                        : (reportTemplate?.name ?? "")}
                    </Typography>
                    <Typography
                      style={{ color: theme.palette.grey[600] }}
                      variant="captionMedium"
                    >
                      {reportTemplate?.description ?? ""}
                    </Typography>
                  </>
                )}
              </div>
              <Divider />
            </React.Fragment>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
