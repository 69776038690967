/* eslint-disable fp/no-mutation */
import React from "react";
import useScrollbarSize from "react-scrollbar-size";

interface ScrollElements {
  overlay: HTMLDivElement;
  scrollSticky: HTMLDivElement;
  scrollFull: HTMLDivElement;
  stickyFakeContent: HTMLDivElement;
  fullFakeContent: HTMLDivElement;
}

const GRID_HEADER_HEIGHT = 40;

export function DataGridStickyScroll({
  children,
  width = 0,
  ...props
}: {
  children: React.ReactNode;
  width: number | undefined;
}): JSX.Element {
  const elements = React.useRef<ScrollElements | null>(null);
  const container = React.useRef<HTMLDivElement | null>(null);

  const lastScroll = React.useRef(0);

  const { height: scrollBarHeight } = useScrollbarSize();

  const observeRoot = useMutationObserver((root) => {
    const windowContainer = root.querySelector(
      ".MuiDataGrid-windowContainer",
    ) as HTMLDivElement;
    if (windowContainer && !elements.current) {
      const scrollElements = createScrollOverlay(scrollBarHeight);
      elements.current = scrollElements;
      windowContainer.appendChild(scrollElements.overlay);
      observeInView(scrollElements.scrollFull);
      observeScrollSticky(scrollElements.scrollSticky);
      observeScrollFull(scrollElements.scrollFull);
    }

    container.current = root.querySelector(
      ".MuiDataGrid-window",
    ) as HTMLDivElement;
    if (container.current) {
      observeTargetScroll(container.current);
    }

    const content = root.querySelector(
      ".MuiDataGrid-dataContainer",
    ) as HTMLDivElement;
    if (content) {
      observeContent(content);
    }

    return Boolean(windowContainer && container.current && content);
  });

  if (elements.current?.scrollSticky)
    elements.current.scrollSticky.style.marginLeft = `${width}px`;

  const observeContent = useResizeObserver((element) => {
    if (elements.current === null) return;
    elements.current.stickyFakeContent.style.width = `${
      element.offsetWidth - width
    }px`;
    elements.current.fullFakeContent.style.width = `${element.offsetWidth}px`;
  });

  function updateScroll(
    source: HTMLDivElement,
    target: HTMLDivElement[],
  ): void {
    if (source.scrollLeft === lastScroll.current) return;
    lastScroll.current = source.scrollLeft;
    target.forEach((e) => (e.scrollLeft = lastScroll.current));
  }

  const observeInView = useInViewObserver((inView) => {
    if (container.current === null || !elements.current) return;

    elements.current.scrollSticky.style.visibility = inView
      ? "hidden"
      : "visible";
  });

  const observeScrollSticky = useScrollObserver((element) => {
    if (container.current === null) return;
    updateScroll(
      element,
      elements.current
        ? [container.current, elements.current.scrollFull]
        : [container.current],
    );
  });

  const observeScrollFull = useScrollObserver((element) => {
    if (container.current === null) return;
    updateScroll(
      element,
      elements.current
        ? [container.current, elements.current.scrollSticky]
        : [container.current],
    );
  });

  const observeTargetScroll = useScrollObserver((element) => {
    if (elements.current === null) return;
    updateScroll(element, [
      elements.current.scrollSticky,
      elements.current.scrollFull,
    ]);
  });

  return (
    <div
      {...props}
      ref={(root) => {
        if (!root) return;
        observeRoot(root);
      }}
    >
      {children}
    </div>
  );
}

function createScrollOverlay(scrollBarHeight: number): ScrollElements {
  const overlay = document.createElement("div");
  overlay.style.position = "absolute";
  overlay.style.top = `${GRID_HEADER_HEIGHT}px`;
  overlay.style.bottom = "0px";
  overlay.style.left = "0px";
  overlay.style.right = "0px";
  overlay.style.pointerEvents = "none";

  const scrollFull = document.createElement("div");
  scrollFull.style.overflowX = "auto";
  scrollFull.style.position = "absolute";
  scrollFull.style.bottom = `0px`;
  scrollFull.style.left = "0px";
  scrollFull.style.right = "0px";
  scrollFull.style.pointerEvents = "auto";
  scrollFull.style.zIndex = "3";

  const scrollSticky = document.createElement("div");
  scrollSticky.style.overflowX = "auto";
  scrollSticky.style.position = "sticky";
  scrollSticky.style.top = `calc(100vh - ${scrollBarHeight}px)`;
  scrollSticky.style.pointerEvents = "auto";
  scrollSticky.style.visibility = "hidden";
  scrollSticky.style.zIndex = "2";

  const stickyFakeContent = document.createElement("div");
  stickyFakeContent.style.height = "1px";
  const fullFakeContent = document.createElement("div");
  fullFakeContent.style.height = "1px";

  scrollSticky.appendChild(stickyFakeContent);
  scrollFull.appendChild(fullFakeContent);
  overlay.appendChild(scrollFull);
  overlay.appendChild(scrollSticky);

  return {
    overlay,
    scrollSticky,
    stickyFakeContent,
    scrollFull,
    fullFakeContent,
  };
}

function useMutationObserver(
  callback: (element: HTMLDivElement) => boolean,
): (element: HTMLDivElement | null) => void {
  const observerRef = React.useRef<MutationObserver | undefined>();

  const cleanup = React.useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = undefined;
    }
  }, []);

  React.useEffect(() => () => cleanup(), [cleanup]);

  return (element) => {
    cleanup();

    if (element) {
      observerRef.current = new MutationObserver(() => {
        const result = callback(element);
        if (result) cleanup();
      });
      observerRef.current.observe(element, {
        childList: true,
        subtree: true,
      });
    }
  };
}

function useResizeObserver(
  callback: (element: HTMLDivElement) => void,
): (element: HTMLDivElement | null) => void {
  const observerRef = React.useRef<ResizeObserver | undefined>();

  const cleanup = React.useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = undefined;
    }
  }, []);

  React.useEffect(() => () => cleanup(), [cleanup]);

  return (element) => {
    cleanup();

    if (element) {
      observerRef.current = new ResizeObserver(() => callback(element));
      observerRef.current.observe(element);
      callback(element);
    }
  };
}

function useScrollObserver(
  callback: (element: HTMLDivElement) => void,
): (element: HTMLDivElement | null) => void {
  const ref = React.useRef<
    | {
        element: HTMLDivElement;
        listener: (this: HTMLDivElement, ev: Event) => void;
      }
    | undefined
  >(undefined);

  const cleanup = React.useCallback(() => {
    if (ref.current) {
      ref.current.element.removeEventListener("scroll", ref.current.listener);
      ref.current = undefined;
    }
  }, []);

  React.useEffect(() => () => cleanup(), [cleanup]);

  return (element) => {
    cleanup();

    if (element) {
      const listener = (): void => callback(element);
      element.addEventListener("scroll", listener);
      ref.current = { element, listener };
    }
  };
}

function useInViewObserver(
  callback: (inView: boolean) => void,
): (element: HTMLDivElement | null) => void {
  const ref = React.useRef<
    | {
        element: HTMLDivElement;
        observer: IntersectionObserver;
      }
    | undefined
  >(undefined);

  const cleanup = React.useCallback(() => {
    if (ref.current) {
      ref.current.observer.disconnect();
      ref.current = undefined;
    }
  }, []);

  React.useEffect(() => () => cleanup(), [cleanup]);

  return (element) => {
    cleanup();

    if (element) {
      const observer = new IntersectionObserver(
        function (entries) {
          callback(entries[0].isIntersecting);
        },
        { threshold: [0] },
      );

      observer.observe(element);

      ref.current = { element, observer };
    }
  };
}
