import { insertIf, useSession } from "@lumar/shared";
import { ModuleCode } from "../../graphql";

export function useGetAccountModuleCodes(): ModuleCode[] {
  const { hasAddon } = useSession();

  const availableModules = [
    ...insertIf(hasAddon("seo"), ModuleCode.Seo),
    ...insertIf(hasAddon("accessibility"), ModuleCode.Accessibility),
    ...insertIf(hasAddon("site-speed"), ModuleCode.SiteSpeed),
  ];
  return availableModules;
}
