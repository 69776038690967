import { ApolloError, NetworkStatus } from "@lumar/shared";
import React, { useMemo } from "react";
import { useGetDashboardViewsQuery } from "../../graphql";
import { View } from "../settings/types";

interface DashboardViewsContextType {
  data?: View[];
  loading?: boolean;
  error?: ApolloError;
}

const DashboardViewsContext = React.createContext<DashboardViewsContextType>(
  {},
);

interface Props {
  dashboardId?: string;
  loading?: boolean;
  children: React.ReactNode;
}

export const useDashboardViews = (): DashboardViewsContextType =>
  React.useContext(DashboardViewsContext);

export function DashboardViewsProvider({
  dashboardId,
  loading,
  children,
}: Props): JSX.Element {
  const skip = loading || !Boolean(dashboardId);
  const {
    data,
    loading: loadingData,
    error,
    fetchMore,
    networkStatus,
  } = useGetDashboardViewsQuery({
    variables: {
      dashboardId,
    },
    fetchPolicy: "cache-first",
    skip,
    notifyOnNetworkStatusChange: true,
  });

  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const pageInfo = data?.getCustomDashboard?.customViews?.pageInfo;

  const hasFetchedAllData =
    !loadingData && !isFetchingMore && !Boolean(pageInfo?.hasNextPage);

  const needsToFetchMore =
    !loadingData &&
    !isFetchingMore &&
    pageInfo?.hasNextPage &&
    pageInfo.endCursor;

  const isLoading =
    loadingData || Boolean(pageInfo?.hasNextPage) || isFetchingMore;

  if (needsToFetchMore)
    fetchMore({
      variables: {
        dashboardId,
        cursor: pageInfo?.endCursor,
      },
    });

  const providerData: View[] | undefined = useMemo(() => {
    return hasFetchedAllData
      ? data?.getCustomDashboard?.customViews?.edges.map(({ node: value }) => ({
          id: value.id,
          projectId: value.project.id,
          segmentId: value.segment?.id,
          projectName: value.project.name,
          segmentName: value.segment?.name,
          primaryDomain: value.project.primaryDomain,
          scheduleFrequency: value.project.schedule?.scheduleFrequency,
          industryCode: value.project.industryCode,
          lastFinishedCrawlId: value.project.lastFinishedCrawl?.id,
        }))
      : [];
  }, [data, hasFetchedAllData]);

  return (
    <DashboardViewsContext.Provider
      value={{
        loading: isLoading || loading,
        error,
        data: providerData,
      }}
    >
      {children}
    </DashboardViewsContext.Provider>
  );
}
