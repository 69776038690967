import {
  GetCategoryIndustryBenchmarksQuery,
  useGetCategoryIndustryBenchmarksQuery,
} from "../../../../../graphql";
import React from "react";

export type Benchmark = {
  reportCategoryCode: string;
  industryCode: string;
  healthScore: number;
};

export function useCategoryIndustryBenchmarks(): {
  data: GetCategoryIndustryBenchmarksQuery | undefined;
  loading: boolean;
  getCategoryIndustryBenchmark: (
    categoryCode: string,
    industryCode: string,
  ) => number | undefined;
} {
  const { data, loading, error, fetchMore } =
    useGetCategoryIndustryBenchmarksQuery({
      fetchPolicy: "cache-first",
    });

  const pageInfo = data?.getIndustryBenchmarks.pageInfo;
  React.useEffect(() => {
    if (pageInfo?.hasNextPage) {
      fetchMore({ variables: { cursor: pageInfo.endCursor } });
    }
  }, [pageInfo, fetchMore]);

  const getCategoryIndustryBenchmark = React.useCallback(
    (categoryCode: string, industryCode: string): number | undefined => {
      const value = data?.getIndustryBenchmarks.edges.find(
        (benchmark) =>
          benchmark.node.reportCategoryCode === categoryCode &&
          benchmark.node.industryCode === industryCode,
      )?.node.healthScore;
      return value;
    },
    [data],
  );

  return {
    getCategoryIndustryBenchmark,
    data,
    loading: loading || (Boolean(pageInfo?.hasNextPage) && !Boolean(error)),
  };
}
