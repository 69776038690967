import { getRawAccountId, getRawProjectId, getRawCrawlId } from "@lumar/shared";
import { ReactNode } from "react";
import { ReportTypeCode } from "../../../graphql";
import { AbsoluteURLs } from "../../../_common/routing/absoluteURLs";

export function AnalyticsHubReportLink(props: {
  accountId: string;
  projectId: string;
  crawlId: string;
  reportTemplateCode: string;
  className?: string;
  children: ReactNode;
  "data-testid"?: string;
  "data-pendo": string;
  segmentId?: string;
}): JSX.Element {
  const {
    reportTemplateCode,
    children,
    "data-testid": dataTestId,
    "data-pendo": dataPendo,
    segmentId,
  } = props;
  const allIdsOk =
    Boolean(props.accountId.length) &&
    Boolean(props.projectId.length) &&
    Boolean(props.crawlId.length);
  if (!allIdsOk) return <>{children}</>;

  const accountId = getRawAccountId(props.accountId);
  const projectId = getRawProjectId(props.projectId);
  const crawlId = getRawCrawlId(props.crawlId);

  return (
    <a
      className={props.className}
      href={AbsoluteURLs.EXTERNAL__AnalyzeReport.getUrl({
        accountId,
        projectId,
        crawlId,
        reportTemplateCode,
        reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
        segmentId,
      })}
      data-testid={dataTestId}
      data-pendo={dataPendo}
    >
      {children}
    </a>
  );
}
