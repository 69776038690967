import Highcharts from "highcharts";

import { isPointWithHref } from "./isPointWithHref";

export function onChartPointClick(
  event: Highcharts.PointClickEventObject,
): void {
  const point = (event.point ?? event.target) as Highcharts.Point;

  if (isPointWithHref(point) && point.href) {
    const { href } = point;

    if (event.ctrlKey || event.metaKey) {
      window.open(href, "_blank", "noreferrer");
    } else {
      window.location.href = href;
    }
  }
}
