import {
  GetReportTrendsQuery,
  ThresholdPredicate,
  useGetReportTrendsQuery,
  ReportStatTrendItem,
} from "../../../graphql";

interface Report {
  totalWeight?: number | null;
  trend: Pick<ReportStatTrendItem, "basic" | "createdAt" | "crawlId">[];
  reportTemplate: {
    totalSign?: number | null;
    code: string;
  };
}

function standardDeviation(values: number[]): number {
  const sum = values.reduce((accumulator, current) => {
    return accumulator + current;
  }, 0);
  const length = values.length;
  const mean = sum / length;

  const standardDeviation = values.reduce((accumulator, current) => {
    const val = Math.pow(current - mean, 2);
    return accumulator + val;
  }, 0);

  const value = Math.sqrt(standardDeviation / length);
  return Math.ceil(value) === 0 ? 1 : value;
}

function movingAverage(values: number[]): number {
  const sum = values.reduce((accumulator, current) => {
    return accumulator + current;
  }, 0);
  const length = values.length;

  return sum / length;
}

export function calculateInitialThresholdFromData(
  data?: GetReportTrendsQuery,
  predicate?: ThresholdPredicate,
  reportCode?: string,
  segmentId?: string,
): number {
  if (!data) {
    return 100;
  }
  const getReport = (
    data: GetReportTrendsQuery,
    reportCode?: string,
    segmentId?: string,
  ): Report | undefined => {
    if (reportCode) {
      return data.getProject?.lastFinishedCrawl?.reportStats.find(
        (r) =>
          r.reportTemplate.code === reportCode && r.segment?.id === segmentId,
      );
    } else {
      return data?.getProject?.lastFinishedCrawl?.reportStats[0];
    }
  };

  const report = getReport(data, reportCode, segmentId);

  const reportTrends = report ? report.trend.slice(0, 30) : [];
  if (reportTrends.length === 0 || !report) {
    return 100;
  }
  const trends: { createdAt: string; value: number }[] = reportTrends.map(
    (tr) => ({
      createdAt: tr.createdAt ?? "",
      value: tr.basic ?? 0,
    }),
  );

  const sign = Math.sign(
    report.totalWeight ?? report.reportTemplate.totalSign ?? -1,
  );

  const pred =
    predicate ??
    (sign <= 0
      ? ThresholdPredicate.GreaterThanOrEqual
      : ThresholdPredicate.LessThan);

  if (trends.length === 1) {
    return Math.max(
      1,
      (trends[0].value ?? 0) +
        (pred === ThresholdPredicate.GreaterThanOrEqual ? 1 : 0),
    );
  }

  // eslint-disable-next-line fp/no-mutating-methods
  const seriesData = (
    trends.map((tr) => [new Date(tr.createdAt).getTime(), tr.value]) ?? []
  )
    .sort((a, b) => b[0] - a[0])
    .slice(0, 10)
    .map((tr) => tr[1]);

  const average = movingAverage(seriesData);
  const percent = average * 0.5;

  const deviation = Math.min(standardDeviation(seriesData), percent);
  const value = Math.ceil(
    movingAverage(seriesData) +
      (pred === ThresholdPredicate.GreaterThanOrEqual ? deviation : -deviation),
  );
  return Math.max(value, 1);
}

export function useInitialReportThresholdCalculator(
  projectId?: string,
  segmentId?: string,
  reportTemplateCode = "",
): [
  { loading: boolean; value?: number; firstLoad: boolean },
  (
    id: string,
    segmentId: string | undefined,
    p: string,
    predicate?: ThresholdPredicate,
  ) => Promise<number>,
] {
  const {
    loading,
    data,
    refetch: fetchTrend,
    previousData,
  } = useGetReportTrendsQuery({
    variables: {
      projectId,
      segmentId,
      reportTemplateCodes: [reportTemplateCode],
    },
    context: {
      includeInBatch: true,
    },
    skip: !projectId || !reportTemplateCode,
    fetchPolicy: "cache-first",
  });

  const calculateInitialThreshold = async (
    projectId: string,
    segmentId: string | undefined,
    reportTemplateCode: string,
    predicate?: ThresholdPredicate,
  ): Promise<number> => {
    const ret = reportTemplateCode
      ? await fetchTrend({
          projectId,
          segmentId,
          reportTemplateCodes: [reportTemplateCode],
        })
      : undefined;
    return calculateInitialThresholdFromData(
      ret?.data,
      predicate,
      reportTemplateCode,
      segmentId,
    );
  };
  return [
    {
      loading,
      value: calculateInitialThresholdFromData(data),
      firstLoad: !Boolean(previousData),
    },
    calculateInitialThreshold,
  ];
}
