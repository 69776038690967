import React from "react";
import {
  Add,
  EmptyState,
  ArrowCircleRightSolid,
  EmptyStateProps,
  useTranslation,
  useSession,
} from "@lumar/shared";
import { Box, makeStyles, withStyles } from "@material-ui/core";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import noAlertsAnimation from "../../../_animations/no-alerts-animation.json";
import sleepingCatAnimation from "../../../_animations/sleeping-cat-animation.json";
import { Skeleton } from "@material-ui/lab";
import { assert } from "../../../_common/assert";
import { RoleCode } from "../../../graphql";
import { useMonitorRoutes } from "../../../_common/routing/useMonitorRoutes";

interface Props {
  numberOfAlerts: number;
  numberOfProjects: number;
  loading: boolean;
}
const useStyles = makeStyles(() => ({
  root: {
    "& .EmptyState-box-description": {
      maxWidth: 360,
    },
  },
}));

export const NoAlertSelectedView = ({
  numberOfAlerts,
  numberOfProjects,
  loading,
}: Props): JSX.Element => {
  const { title, description, actions } = useEmptyStateConfig(
    numberOfAlerts,
    numberOfProjects,
  );

  const classes = useStyles();
  return (
    <Container>
      <EmptyState
        className={classes.root}
        icon={
          loading ? (
            <Skeleton
              width={215}
              height={144}
              variant="rect"
              style={{ borderRadius: 8 }}
            />
          ) : (
            <Lottie
              loop
              animationData={
                Boolean(numberOfAlerts)
                  ? noAlertsAnimation
                  : sleepingCatAnimation
              }
              play
              style={{ width: 215, margin: "auto" }}
            />
          )
        }
        title={loading ? "Loading..." : title}
        description={
          loading ? <Skeleton width={250} height={18} /> : description
        }
        actions={loading ? undefined : actions}
      />
    </Container>
  );
};

const Container = withStyles({
  root: {
    maxWidth: 392,
    margin: "140px auto 0px auto",
    textAlign: "center",
  },
})(Box);

function useEmptyStateConfig(
  numberOfAlerts: number,
  numberOfProjects: number,
): Pick<EmptyStateProps, "title" | "description" | "actions"> {
  const { t } = useTranslation(["common", "alert"]);
  const session = useSession();

  const { createAlertPage, dashboardPage } = useMonitorRoutes();

  assert(session);
  const hasSufficientRole = session.hasSufficientRole(RoleCode.Editor);

  if (numberOfAlerts) {
    return {
      title: t("alerts:noAlertSelectedTitle", { count: numberOfAlerts }),
      description: t("alerts:noAlertSelectedDescription"),
      actions: undefined,
    };
  }

  if (numberOfProjects) {
    return {
      title: t("alerts:noAlertsCreatedTitle"),
      description: t("alerts:noAlertsCreatedDescription"),
      actions: hasSufficientRole
        ? [
            {
              type: "button",
              title: t("alerts:createNewAlert"),
              onClick: () => {
                createAlertPage.visit();
              },
              icon: <Add />,
              "data-pendo": "no-alerts-create-new-alert",
            },
          ]
        : undefined,
    };
  }

  return {
    title: t("alerts:noProjectsTitle"),
    description: t("alerts:noProjectsDescription"),
    actions: [
      {
        type: "button",
        title: t("common:goToDashboard"),
        onClick: () => {
          dashboardPage.visit();
        },
        icon: <ArrowCircleRightSolid />,
        "data-pendo": "no-projects-go-to-dashboard",
      },
    ],
  };
}
