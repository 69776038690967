export function mapOrFormFiltersToDatasourceConnectionFilter(filter) {
    return {
        _or: filter.map((or) => {
            return {
                _and: or._and.map((and) => ({
                    [and.metricCode]: { [and.predicateKey]: and.predicateValue },
                })),
            };
        }),
    };
}
