import {
  Button,
  ChevronDownSolid,
  DuplicateOutlined,
  SettingsIcon,
  TrashOutlined,
  Typography,
  useTranslation,
  getApiAccountId,
} from "@lumar/shared";
import {
  ButtonGroup,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import { useState, useRef, SetStateAction } from "react";
import { useGetDashboardCollectionsQuery } from "../../graphql";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";

const useStyles = makeStyles((theme) =>
  createStyles({
    menuButtonActive: {
      marginLeft: 7,
    },
    menuButton: {
      backgroundColor: "white",
      marginLeft: 7,
    },
    menu: {
      width: 175,
      border: 0,
      paddingLeft: 8,
      paddingRight: 8,
      boxShadow:
        "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    },
    menuItem: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    copyIcon: {
      color: theme.palette.ultraviolet[400],
    },
    menuText: { width: "100%", marginLeft: 15 },
    menuSubheader: {
      color: theme.palette.grey[600],
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(15),
      padding: theme.spacing(1),
      opacity: "1!important",
    },
    boardActions: {
      borderTopRightRadius: 61,
      borderBottomRightRadius: 61,
      maxWidth: 40,
      width: 40,
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    boardActionsAction: {
      backgroundColor: theme.palette.grey[300],
    },
    boardSettings: {
      borderTopLeftRadius: 61,
      borderBottomLeftRadius: 61,
      maxWidth: 86,
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  }),
);

interface Props {
  disabled?: boolean;
  loading?: boolean;
  onOpen: (value: SetStateAction<HTMLElement | null>) => void;
  onClose: () => void;
  onDelete: () => void;
}

export const DashboardCollectionOptionsMenu = ({
  disabled: disabledFromOutside,
  loading: loadingFromOutside,
  onOpen,
  onClose,
  onDelete,
}: Props): JSX.Element => {
  const { accountId, collectionId } = useParams<{
    accountId: string;
    collectionId: string;
  }>();

  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation(["board", "dashboards", "common"]);
  const theme = useTheme();
  const { boardSettingsPage } = useMonitorRoutes();

  const isMenuOpen = Boolean(menuAnchor);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
    onOpen(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setMenuAnchor(null);
    onClose();
  };

  const { data, loading, error } = useGetDashboardCollectionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      accountId: getApiAccountId(accountId),
    },
  });

  const totalBoardCount =
    data?.getAccount?.customDashboardCollections.totalCount ?? 0;

  const hasError = Boolean(error);
  const isLoading = loading || loadingFromOutside;
  const disabled = Boolean(hasError) || isLoading || disabledFromOutside;

  return (
    <>
      <ButtonGroup style={{ marginRight: theme.spacing(1) }}>
        <Button
          disabled={disabled}
          className={classes.boardSettings}
          startIcon={<SettingsIcon />}
          onClick={() =>
            boardSettingsPage.visit({
              accountId,
              collectionId,
              action: "edit",
            })
          }
          aria-label={t("board:openBoardSettings")}
          data-pendo="monitor-board-settings-toggle"
          data-testid="monitor-board-settings-toggle"
        >
          {t("board:board")}
        </Button>
        <Button
          disabled={disabled}
          className={clsx(
            classes.boardActions,
            isMenuOpen ? classes.boardActionsAction : undefined,
          )}
          onClick={handleOpenMenu}
          data-pendo={"monitor-board-header-menu-toggle"}
          data-testid="board-action-menu"
          ref={buttonRef}
          aria-label={t("board:boardOptions")}
        >
          <ChevronDownSolid
            style={{ width: 20, height: 20 }}
            htmlColor={theme.palette.grey[500]}
          />
        </Button>
      </ButtonGroup>
      <Menu
        classes={{
          paper: classes.menu,
        }}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        data-testid="board-menu"
      >
        <MenuItem
          className={classes.menuSubheader}
          disabled
          style={{ color: theme.palette.grey[600] }}
          role="label"
        >
          {t("board:boardOptions")}
        </MenuItem>
        <MenuItem
          data-testid="board-menu-clone"
          className={classes.menuItem}
          onClick={() => {
            setMenuAnchor(null);
            boardSettingsPage.visit({
              accountId,
              collectionId,
              action: "clone",
            });
          }}
          data-pendo="monitor-board-header-menu-clone"
        >
          <DuplicateOutlined fontSize="small" className={classes.copyIcon} />
          <Typography variant="body2" className={classes.menuText}>
            {t("common:clone")}
          </Typography>
        </MenuItem>
        {totalBoardCount > 1 ? (
          <MenuItem
            data-testid="board-menu-delete"
            className={classes.menuItem}
            onClick={() => {
              setMenuAnchor(null);
              onDelete();
            }}
            data-pendo={"monitor-board-header-menu-delete"}
          >
            <TrashOutlined fontSize="small" color="error" />
            <Typography variant="body2" className={classes.menuText}>
              {t("common:delete")}
            </Typography>
          </MenuItem>
        ) : undefined}
      </Menu>
    </>
  );
};
