import { useNumberFormatter, useSession, useTranslation } from "@lumar/shared";
import { Theme } from "@material-ui/core";
import Highcharts, { SeriesLineOptions, Options } from "highcharts";
import { useMemo } from "react";

import { get } from "lodash";
import { ReportTemplateUnit } from "../../../../../graphql";
import { assert } from "../../../../../_common/assert";
import { GENERATED_PALETTE } from "./generatedPalette";
import { isPointWithCrawlFrequency } from "./isPointWithCrawlFrequency";
import { onChartPointClick } from "./onChartPointClick";

// eslint-disable-next-line fp/no-mutating-methods
Highcharts.AST.allowedAttributes.push("viewBox");

export function useProjectComparisonChartOptions({
  series,
  timeCurrentlyInMilliseconds,
  startingTimeInMilliseconds,
  theme,
  responsiveAxis,
  maxY,
  type = "monitor",
  unit = ReportTemplateUnit.UrLs,
}: {
  series: SeriesLineOptions[] | undefined;
  timeCurrentlyInMilliseconds: number;
  startingTimeInMilliseconds: number;
  theme: Theme;
  responsiveAxis?: boolean;
  maxY?: number | null;
  type?: "monitor" | "healthscore";
  unit?: ReportTemplateUnit | null;
}): Options {
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { t } = useTranslation(["common", "units"]);
  const formatNumber = useNumberFormatter();

  const visualisationColors = useMemo(
    () => [
      theme.palette.pink[500],
      theme.palette.purple[500],
      theme.palette.blue[400],
      theme.palette.yellow[300],
      theme.palette.green[400],
      theme.palette.orange[500],
      theme.palette.lightBlue[400],
      theme.palette.teal[300],
      theme.palette.grey[400],
      theme.palette.rose[300],
      ...GENERATED_PALETTE,
    ],
    [theme],
  );

  /* eslint-disable fp/no-this */
  return useMemo(() => {
    return {
      title: {
        text: undefined,
      },
      exporting: {
        enabled: false,
      },
      series,
      chart: {
        spacing: [10, 10, 0, 5],
        style: {
          fontSize: theme.typography.pxToRem(13),
          fontFamily: theme.typography.fontFamily,
        },
      },
      xAxis: {
        type: "datetime",
        allowDecimals: false,
        title: {
          text: null,
        },
        min: startingTimeInMilliseconds,
        max: timeCurrentlyInMilliseconds,
        lineColor: theme.palette.grey["200"],
        lineWidth: 1,
        tickLength: 0,
        tickPixelInterval: 200,
        labels: {
          padding: "20px",
          y: theme.spacing(3) + 13,
          style: {
            fontSize: theme.typography.pxToRem(13),
            fontFamily: theme.typography.fontFamily,
            fontWeight: "500",
            color: theme.palette.grey[500],
          },
        },
        dateTimeLabelFormats: {
          month: "%e %b",
          day: "%e %b",
          hour: "%e %b",
          minute: "%e %b",
          week: "%e %b",
          millisecond: "%e %b",
          second: "%e %b",
        },
      },
      yAxis: {
        max: maxY,
        allowDecimals: false,
        title: {
          text: null,
        },
        tickPixelInterval: 50,
        opposite: true,
        labels: {
          enabled: true,
          style: {
            fontSize: theme.typography.pxToRem(13),
            fontFamily: theme.typography.fontFamily,
            fontWeight: "500",
            color: theme.palette.grey[500],
          },
        },
        min: responsiveAxis ? null : 0,
      },
      colors: visualisationColors,
      plotOptions: {
        series: {
          cursor: "pointer",
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 5,
          },
          point: {
            events: {
              click: onChartPointClick,
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        padding: theme.spacing(2.25),
        borderRadius: theme.shape.borderRadius,
        borderWidth: 0,
        shadow: false,
        backgroundColor: theme.palette.grey[800],
        style: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.pxToRem(12),
          lineHeight: theme.typography.pxToRem(18),
          color: theme.palette.grey[50],
        },
        formatter: function () {
          assert(isPointWithCrawlFrequency(this.point));
          const { x, y, crawlFrequency } = this.point;
          assert(typeof y === "number");
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const sign = get(this.point, "sign") as any;
          const name = this.series.name;
          const segment = get(this.series.userOptions, "segment");
          const isMonitor = type === "monitor";
          // Note: we are avoiding using `renderToStaticMarkup` and passing in a React component, and also avoiding using the MUI theme because
          // it was leading to poor rendering performance for the tooltip. See more: https://deepcrawl.atlassian.net/browse/MON-173

          const colorLineStyle =
            isMonitor || !Boolean(sign)
              ? `display:none`
              : `height:150px;width:4px;border-radius: 100px;margin-right:10px;background-color:${
                  sign < 0 ? theme.palette.red[500] : theme.palette.green[500]
                };flex-shrink:0`;
          const valueText = isMonitor
            ? `
          <div style="display: flex; align-content: center; margin-bottom: 8px;align-items: baseline;">
          <h1 style="color:white;margin-right:7px">${formatNumber(y)} </h1> ${t(
            `units:${unit ?? ReportTemplateUnit.UrLs}`,
            { count: y },
          )}
          </div>
            `
            : `
              <div style="display: flex; flex-direction:column;align-content: center; margin-bottom: 8px;">    
                <div style="display: flex;">    
                  <h1 style="color:white">${formatNumber(y)} </h1>
                  <div style="font-size:${theme.typography.pxToRem(
                    12,
                  )}; color=${
                    theme.palette.grey[400]
                  };margin-top:20px"> / 100 health score</div>
                </div>
              </div>
                `;

          return `
          <div style="display:flex;min-width:192px;max-width:300px;font-weight:400;font-family:Inter,sans-serif;padding-right:16px;width:100%">
            <div style="${colorLineStyle}"></div>
            <div style="flex:1;padding-rigth:16px;max-width:100%">
              <div style="display: flex; align-content: center; margin-bottom: 8px;">
                ${t("projectComparisonChart:crawlDateAndTime", {
                  crawlDate: new Date(x),
                })}
              </div>
              <div style="font-weight:600;margin-bottom:6px;width:100%;text-overflow: ellipsis;overflow-x:hidden;">
              ${name}
              </div>
              ${
                segmentationAvailable
                  ? `<div style="border: 1px solid rgb(229, 231, 235);height: 20px;border-radius: 4px;width:fit-content;">
                <div style="font-size: 0.75rem;font-family: 'Inter', sans-serif;font-weight: 500;margin: 0;box-sizing: inherit;white-space: nowrap;">
                  <div style="padding-left: 8px;padding-right: 8px;text-overflow: ellipsis;width:fit-content;max-width:140px;overflow-x:hidden;">
                    ${segment}
                  </div>
                </div>
              </div>`
                  : ""
              }
              ${valueText}
              <div style="display: flex; align-content: center; margin-bottom: 0px;">
                ${
                  crawlFrequency
                    ? t("common:crawlFrequency", { crawlFrequency })
                    : t("common:none")
                }
              </div>
            </div>
          </div>`;
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              minWidth: 860,
            },
            chartOptions: {
              legend: {
                enabled: false,
              },
            },
          },
          {
            condition: {
              maxWidth: 859,
            },
            chartOptions: {
              legend: {
                enabled: false,
              },
            },
          },
        ],
      },
    };
  }, [
    series,
    theme,
    startingTimeInMilliseconds,
    timeCurrentlyInMilliseconds,
    maxY,
    responsiveAxis,
    visualisationColors,
    type,
    formatNumber,
    t,
    unit,
    segmentationAvailable,
  ]);
}
