/* eslint-disable fp/no-loops */
import { Snackbar, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import {
  useAddViewsToBoardMutation,
  useUpdateDashboardChartMutation,
} from "../../../../graphql";
import { useGenericParams } from "../../../../_common/routing/useGenericParams";
import { getErrorMessage } from "../../../../_common/utils/getErrorMessage";
import { SimpleView } from "../../types";

export const ADD_VIEW_LIMIT = 100.0;

export function useAddViewsToCollection(
  onCompleted?: (collectionId: string) => void,
): (
  collectionId: string,
  chartIds: string[],
  values: SimpleView[],
  noSuccessMessage?: boolean,
) => Promise<string[] | undefined> {
  const { accountId } = useGenericParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["board"]);
  const cutomViews = useRef<
    { id: string; finishedAt?: string | null; segmentId?: string | null }[]
  >([]);
  const [addViewsToBoard] = useAddViewsToBoardMutation({
    fetchPolicy: "no-cache",
  });
  const [updateChart] = useUpdateDashboardChartMutation();

  async function addViewsToCollection(
    collectionId: string,
    chartIds: string[],
    values: SimpleView[],
    noSuccessMessage?: boolean,
  ): Promise<string[] | undefined> {
    try {
      const length = Math.ceil(values.length / ADD_VIEW_LIMIT);
      const counter = Array.from(Array(length).keys());
      cutomViews.current = [];
      for (const e of counter) {
        const slice = values.slice(
          e * ADD_VIEW_LIMIT,
          (e + 1) * ADD_VIEW_LIMIT,
        );
        const { data } = await addViewsToBoard({
          variables: {
            collectionId,
            addToDashboards: true,
            views: slice.map((value) => ({
              accountId,
              projectId: value.projectId,
              segmentId: value.segmentId,
            })),
          },
        });

        cutomViews.current = [
          ...cutomViews.current,
          ...(data?.addCustomViewsToCustomDashboardCollection.customViews.map(
            ({ id, project, segmentId }) => ({
              id: id,
              finishedAt: project.finishedAt,
              segmentId: Boolean(segmentId) ? segmentId?.toString() : undefined,
            }),
          ) ?? []),
        ];
      }
      if (cutomViews.current.length) {
        // eslint-disable-next-line fp/no-mutating-methods
        cutomViews.current.sort(function (a, b) {
          if (!a.segmentId && b.segmentId) return -1;
          if (a.segmentId && !b.segmentId) return 1;
          if (!a.finishedAt) return 1;
          if (!b.finishedAt) return -1;
          const d1 = new Date(b.finishedAt);
          const d2 = new Date(a.finishedAt);
          return d1.getTime() - d2.getTime();
        });

        for (const chartId of chartIds) {
          await updateChart({
            variables: {
              customChartId: chartId,
              items: cutomViews.current.slice(0, 10).map(({ id }) => id),
            },
          });
        }
      }
      if (!noSuccessMessage)
        enqueueSnackbar(
          <Snackbar variant="success" title={t("createdSuccessfully")} />,
        );
      onCompleted?.(collectionId);
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("creationFailed", { message: getErrorMessage(e) })}
        />,
      );
      return undefined;
    }
    return cutomViews.current.slice(0, 10).map(({ id }) => id);
  }

  return addViewsToCollection;
}
