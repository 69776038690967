import {
  InputLabel,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ChangeEvent } from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { TestAutoThresholdAcceptance } from "../../graphql";
import { useTranslation } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    // Note: these styles that ensure that the input label is visually hidden, but not hidden from screen readers (for accessibility reasons)
    border: 0,
    clip: "rect(1px, 1px, 1px, 1px)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    width: 1,
  },
  select: {
    minWidth: 150,
    flex: 1,
    "& [class*=MuiOutlinedInput-input]": {
      paddingTop: 6.5,
      paddingBottom: 6.5,
      paddingRight: 26,
    },
  },
  menuTitle: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    fontWeight: 500,
    padding: theme.spacing(1),
  },
  menuItemText: {
    color: theme.palette.grey[700],
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    marginLeft: theme.spacing(1),
  },
  menuItem: {
    "&.Mui-selected": {
      background: "white",
    },
  },
}));

interface Props {
  value: TestAutoThresholdAcceptance;
  onChange: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
  disabled?: boolean;
  label: string;
  subheader: string;
  "data-pendo"?: string;
  "data-testid"?: string;
}

export const ThresholdAcceptanceMenu = ({
  value,
  onChange,
  disabled,
  label,
  subheader,
  ...props
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("alerts");

  const options = {
    [TestAutoThresholdAcceptance.AutoAcceptAbsolute]: t(
      "rulesAndThresholds.autoAcceptThreshold",
    ),
    [TestAutoThresholdAcceptance.Suggest]: t(
      "rulesAndThresholds.manuallyAcceptThreshold",
    ),
    [TestAutoThresholdAcceptance.None]: t(
      "rulesAndThresholds.dontSuggestThreshold",
    ),
  };

  return (
    <>
      <InputLabel id="label" className={classes.inputLabel}>
        {label}
      </InputLabel>
      <Select
        {...props}
        labelId="label"
        id="select"
        value={value}
        onChange={onChange}
        disabled={disabled}
        variant="outlined"
        className={classes.select}
        IconComponent={ExpandMoreRoundedIcon}
        renderValue={(value) => (
          <span>{options[value as TestAutoThresholdAcceptance]}</span>
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        <ListSubheader
          className={classes.menuTitle}
          // Note: this stops the menu from closing if the subheader is clicked
          onClickCapture={(e) => e.stopPropagation()}
        >
          {subheader}
        </ListSubheader>
        {Object.entries(options).map(([optionValue, label]) => (
          <MenuItem
            key={optionValue}
            value={optionValue}
            className={classes.menuItem}
          >
            {value === optionValue ? (
              <SelectedCircleIcon />
            ) : (
              <UnselectedCircleIcon />
            )}
            <Typography className={classes.menuItemText}>{label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const SelectedCircleIcon = (): JSX.Element => {
  const theme = useTheme();
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7.5"
        fill="white"
        stroke={theme.palette.primary.main}
      />
      <circle cx="8" cy="8" r="5" fill={theme.palette.primary.main} />
    </svg>
  );
};

const UnselectedCircleIcon = (): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7.5" fill="white" stroke="#D1D5DB" />
    </svg>
  );
};
