import { makeStyles } from "@material-ui/core";
import {
  UserGroupOutlined,
  UserOutlined,
  OfficeBuildingOutlined,
  PuzzleOutlined,
  LightningBoltOutlined,
  LogoutOutlined,
  AccountMenuItem,
  AccountMenuItems,
  UserArrowRightOutlined,
  CreditCardOutlined,
  BatteryHighOutlined,
  useAccountSelectionSubMenu,
  useTranslation,
  getRawAccountId,
  useAdminAccountSearch,
  useSession,
  useBrand,
  insertIf,
} from "@lumar/shared";
import clsx from "clsx";
import { RoleCode } from "../graphql";
import { useGenericParams } from "../_common/routing/useGenericParams";
import { AbsoluteURLs } from "../_common/routing/absoluteURLs";
import { useMonitorRoutes } from "../_common/routing/useMonitorRoutes";

const useIconStyles = makeStyles((theme) => ({
  icon: {
    height: "20px",
  },
  logout: {
    color: theme.palette.red[500],
  },
}));

function getRolePriority(roleCode: RoleCode): number {
  switch (roleCode) {
    case RoleCode.Admin:
      return 3;
    case RoleCode.Editor:
      return 2;
    case RoleCode.Viewer:
      return 1;
    default:
      return 0;
  }
}

interface NavigationMenuItem extends AccountMenuItem {
  minimumRole?: RoleCode;
}

type NavigationMenuItems = {
  name?: string;
  items: (NavigationMenuItem | undefined)[];
}[];

// eslint-disable-next-line max-lines-per-function
export function useAccountMenuItems(
  currentUserRole: RoleCode,
  isDeepCrawlAdmin: boolean,
): AccountMenuItems {
  const classes = useIconStyles();
  const { t } = useTranslation(["menuitems"]);
  const { accountId } = useGenericParams();
  const brand = useBrand();

  const { dashboardPage } = useMonitorRoutes();

  const session = useSession();

  function handleAccountSwitch(id: string): void {
    if (id === accountId) return;
    dashboardPage.visit({
      accountId: id,
    });
  }

  // eslint-disable-next-line fp/no-mutating-methods
  const availableAccounts = session.allRelationships
    .filter(
      ({ account }) =>
        account.availableApps.monitorAvailable ||
        getRawAccountId(account.id) === accountId,
    )
    .sort((a, b) => a.account.name.localeCompare(b.account.name));
  const shouldShowAccountSwitcher =
    isDeepCrawlAdmin ||
    Boolean(
      availableAccounts.find(
        ({ account }) => getRawAccountId(account.id) !== accountId,
      ),
    );

  const accountsSubMenu = useAccountSelectionSubMenu({
    accounts: availableAccounts.map((x) => ({
      id: getRawAccountId(x.account.id),
      name: x.account.name,
    })),
    selectedAccountId: accountId,
    onAccountSelected: handleAccountSwitch,
    isDeepCrawlAdmin,
    searchPlaceholder: t("accountSearch"),
    noResultMessage: t("noAccountFound"),
    formatValue: (id) => t("id", { accountId: id }),
    adminAccountSearch: useAdminAccountSearch(),
  });

  const menuItems: NavigationMenuItems = [
    {
      items: [
        shouldShowAccountSwitcher
          ? {
              name: t("switchAccount"),
              icon: <UserArrowRightOutlined className={classes.icon} />,
              dataAttributes: {
                "data-pendo": "auto-headernav-accounts-account-select",
                "data-testid": "account-select-menu-item",
              },
              minimumRole: RoleCode.Viewer,
              subMenu: accountsSubMenu,
            }
          : undefined,
      ],
    },
    {
      name: t("userSettings"),
      items: [
        ...insertIf(brand.featureAvailability.myProfilePage, {
          name: t("user"),
          icon: <UserOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "monitor-headernav-accounts-user",
            "data-testid": "user-menu-item",
          },
          link: accountId
            ? AbsoluteURLs.EXTERNAL__AccountsUser.getUrl(accountId)
            : undefined,
          isLinkExternal: true,
          minimumRole: RoleCode.Viewer,
        }),
        {
          name: t("apps"),
          icon: <PuzzleOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "monitor-headernav-accounts-connectedapps",
            "data-testid": "connected-apps-menu-item",
          },
          link: accountId
            ? AbsoluteURLs.EXTERNAL__AccountsConnectedApps.getUrl(accountId)
            : undefined,
          isLinkExternal: true,
          minimumRole: RoleCode.Editor,
        },
        ...insertIf(brand.featureAvailability.apiAccessPage, {
          name: t("api"),
          icon: <LightningBoltOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "monitor-headernav-accounts-api",
            "data-testid": "api-access-menu-item",
          },
          link: accountId
            ? AbsoluteURLs.EXTERNAL__AccountsApiAccess.getUrl(accountId)
            : undefined,
          isLinkExternal: true,
          minimumRole: RoleCode.Viewer,
        }),
      ],
    },
    {
      name: t("accountSettings"),
      items: [
        {
          name: t("account"),
          icon: <OfficeBuildingOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "monitor-headernav-accounts-account",
            "data-testid": "account-menu-item",
          },
          isLinkExternal: true,
          link: accountId
            ? AbsoluteURLs.EXTERNAL__AccountsAccount.getUrl(accountId)
            : undefined,
          minimumRole: RoleCode.Viewer,
        },
        ...insertIf(brand.featureAvailability.teamsPage, {
          name: t("team"),
          icon: <UserGroupOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "monitor-headernav-accounts-org",
            "data-testid": "team-menu-item",
          },
          link: accountId
            ? AbsoluteURLs.EXTERNAL__AccountsTeam.getUrl(accountId)
            : undefined,
          isLinkExternal: true,
          minimumRole: RoleCode.Admin,
        }),
        {
          name: t("subscription"),
          icon: <CreditCardOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "monitor-headernav-accounts-subscription",
            "data-testid": "subscription-menu-item",
          },
          link: accountId
            ? AbsoluteURLs.EXTERNAL__AccountsSubscription.getUrl(accountId)
            : undefined,
          isLinkExternal: true,
          minimumRole: RoleCode.Admin,
        },
        {
          name: t("credit"),
          icon: <BatteryHighOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "monitor-headernav-accounts-credit",
            "data-testid": "credit-usage-menu-item",
          },
          link: accountId
            ? AbsoluteURLs.EXTERNAL__AccountsCreditReports.getUrl(accountId)
            : undefined,
          isLinkExternal: true,
          minimumRole: RoleCode.Admin,
        },
      ],
    },
  ];

  function filterByRole(
    item: NavigationMenuItem | undefined,
  ): item is NavigationMenuItem {
    if (item === undefined) return false;
    if (isDeepCrawlAdmin) return true;
    if (!item.minimumRole) return false;
    return (
      getRolePriority(currentUserRole) >= getRolePriority(item.minimumRole)
    );
  }

  const filteredMenuItems = menuItems.reduce((result, group) => {
    const items = group.items.filter(filterByRole);
    return items.length ? [...result, { ...group, items }] : result;
  }, [] as AccountMenuItems);

  return [
    ...filteredMenuItems,
    {
      items: [
        {
          name: t("logout"),
          icon: (
            <LogoutOutlined className={clsx(classes.icon, classes.logout)} />
          ),
          onClick: () => {
            session.invalidateSession();
          },
          dataAttributes: {
            "data-testid": "logout-link",
            "data-pendo": "monitor-headernav-accounts-logout",
          },
        },
      ],
    },
  ];
}
