import {
  Alert,
  EmptyState,
  Snackbar,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { Collapse, Paper, makeStyles } from "@material-ui/core";
import {
  CustomChartType,
  RoleCode,
  useCreateDashboardChartMutation,
  useGetDashboardChartsQuery,
  useGetDashboardQuery,
} from "../../../graphql";
import { assert } from "../../../_common/assert";
import { useGenericParams } from "../../../_common/routing/useGenericParams";
import { HealthScoreComparisonChart } from "./chart/HealthScoreComparisonChart";
import { HealthScoreTable } from "./health-score-table/HealthScoreTable";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import errorLottie from "../../../_animations/error-animation.json";
import { useURLSearchParams } from "../../../_common/routing/useURLSearchParams";
import { useHistory } from "react-router-dom";
import { pick } from "lodash";
import { useMemo } from "react";
import { DEFAULT_REPORT_TEMPLATE_CATEGORY_CODE } from "../utils/useCreateDefaultOrCloneDashboard";
import { useSnackbar } from "notistack";
import { useDashboardViews } from "../../components/DashboardViewsProvider";
import { useReportCategoriesTree } from "../../../_common/hooks/useReportCategoriesTree";
import { useReportTemplateAccumulator } from "../../../_common/hooks/useReportTemplateAccumulator";
import { useGetAccountModuleCodes } from "../../../_common/hooks/useGetAccountModuleCodes";

const defaultChartArray = [
  {
    id: undefined,
    metric: undefined,
    metadata: undefined,
    items: undefined,
  },
];

const useStyles = makeStyles({
  emptystate: {
    height: "calc(100vh - 130px)",
  },
});

export function HealthScoreTab({
  showChart,
}: {
  showChart: boolean;
}): JSX.Element {
  const classes = useStyles();
  const session = useSession();

  const { accountId, dashboardId } = useGenericParams();
  assert(accountId);
  const { t } = useTranslation(["dashboards", "errors", "categories"]);

  const { enqueueSnackbar } = useSnackbar();

  const urlSearchParams = useURLSearchParams();
  const history = useHistory();

  const dashboardViews = useDashboardViews();

  const moduleCodes = useGetAccountModuleCodes();

  const readOnly = !session.hasSufficientRole(RoleCode.Editor);

  if (!urlSearchParams.has("type")) {
    urlSearchParams.set("type", "healthscores");
    history.replace({ search: urlSearchParams.toString() });
  }

  const {
    reports,
    error: templatesError,
    loading: loadingReports,
  } = useReportTemplateAccumulator({
    moduleCodes,
  });

  const [
    { loading: categoriesLoading, error: categoriesError },
    { getCategoryList },
  ] = useReportCategoriesTree(reports, true);

  const sortedCategories =
    categoriesLoading || loadingReports
      ? []
      : getCategoryList(3).map((e) => ({
          id: e.code,
          code: e.code,
          name: e.name,
          description: e.description,
          level: e.level,
        }));

  const {
    data: dashboardData,
    loading: dashboardLoading,
    error: dashboardError,
  } = useGetDashboardQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: { customDashboardId: dashboardId },
    skip: !dashboardId,
  });

  const {
    data: chartsData,
    loading: chartsLoading,
    error: chartsError,
  } = useGetDashboardChartsQuery({
    fetchPolicy: "no-cache",
    variables: {
      customDashboardId: dashboardId,
      type: CustomChartType.HealthScoreCrawlCompare,
    },
    skip:
      !dashboardId ||
      categoriesLoading ||
      Boolean(dashboardViews.loading) ||
      loadingReports,
    onCompleted: async (data) => {
      if (
        !Boolean(data.getCustomDashboard?.customCharts?.nodes.length) &&
        dashboardViews.data?.length
      )
        await createDefaultChart({
          customViews: dashboardViews.data?.slice(0, 6).map((e) => e.id) ?? [],
        });
    },
  });

  const [createChart, { loading: chartCreationRunning }] =
    useCreateDashboardChartMutation({
      refetchQueries: ["GetDashboardCharts"],
      awaitRefetchQueries: true,
      onError: (error) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("errors:chartUpdateError", { message: error.message })}
          />,
        );
      },
    });

  function createDefaultChart(values: {
    metadata?: Record<string, string>;
    metric?: string;
    customViews: string[];
  }): void {
    if (!readOnly) {
      createChart({
        variables: {
          customDashboardId: dashboardId,
          metadata: values.metadata ?? { trendRange: "last_30_days" },
          metric: values.metric ?? DEFAULT_REPORT_TEMPLATE_CATEGORY_CODE,
          customViews: values.customViews,
          type: CustomChartType.HealthScoreCrawlCompare,
        },
      });
    }
  }

  const categories = sortedCategories
    .filter((c) => c.name !== "")
    .map(({ code, name, description, level }) => ({
      id: code,
      code,
      name,
      description,
      level,
    }));

  const somethingWrong =
    Boolean(dashboardError) ||
    !Boolean(dashboardId) ||
    Boolean(categoriesError) ||
    Boolean(chartsError) ||
    Boolean(dashboardViews?.error) ||
    templatesError;

  const isNoDashboardFound =
    !dashboardData &&
    !dashboardId &&
    !dashboardLoading &&
    !categoriesLoading &&
    !chartsLoading;

  const customCharts = useMemo(
    () =>
      chartsData?.getCustomDashboard?.customCharts?.nodes.map((chart) => ({
        ...pick(chart, ["id", "metric", "metadata"]),
        items: chart.customViews.nodes.map((e) => ({
          id: e.id,
          projectId: e.project.id,
          segmentId: e.segment?.id,
          projectName: e.project.name,
          segmentName: e.segment?.name,
          primaryDomain: e.project.primaryDomain,
          industryCode: e.project.industryCode,
        })),
      })),
    [chartsData?.getCustomDashboard?.customCharts?.nodes],
  );
  return (
    <>
      {isNoDashboardFound ? (
        <Alert severity="error">{t("noDashboardFound")}</Alert>
      ) : Boolean(somethingWrong) ? (
        <Paper>
          <EmptyState
            className={classes.emptystate}
            width="100%"
            height={500}
            title={t("dashboardLoadingError")}
            description={t("dashboardLoadingErrorDescription")}
            icon={
              <Lottie
                loop
                animationData={errorLottie}
                play
                style={{ width: "80px", margin: "auto" }}
              />
            }
            actions={[
              {
                type: "button",
                title: t("errorButton"),
                onClick: () => {
                  window.location.reload();
                },
              },
            ]}
          />
        </Paper>
      ) : (
        <>
          <Collapse in={showChart}>
            {(customCharts?.length ? customCharts : defaultChartArray).map(
              (chart) => {
                return (
                  <HealthScoreComparisonChart
                    key={chart.id ?? "temporary_empty_chart"}
                    chart={chart}
                    isLoading={
                      chartsLoading ||
                      !Boolean(chartsData) ||
                      chartCreationRunning
                    }
                    error={dashboardError}
                  />
                );
              },
            )}
          </Collapse>
          {dashboardData?.getCustomDashboard?.customTables?.nodes.map(
            (table) => {
              const columns = table.columns.length
                ? table.columns.map((e) => {
                    const value = categories.find((c) => c.code === e);
                    return {
                      id: value?.code ?? "not_found",
                      code: value?.code ?? "not_found",
                      name: value?.name ?? "Not found",
                      description: value?.name,
                    };
                  })
                : [];
              return (
                <HealthScoreTable
                  key={table.id}
                  columns={columns}
                  categories={categories}
                  filters={table.filter ?? {}}
                  tableId={table.id}
                  loading={categoriesLoading || loadingReports}
                  orderBy={table.orderBy}
                />
              );
            },
          )}
        </>
      )}
    </>
  );
}
