import { ApolloError } from "@lumar/shared";
import { useMemo } from "react";
import { View } from "../../types";
import { useGetViewListLazyQuery } from "./useGetViewListLazyQuery";
import { useGetSegmentsForProjectQuery } from "../../../../graphql";

export interface GetViewListAccumulatorReturnType {
  data: View[];
  segmentList?: string[];
  loading: boolean;
  error: ApolloError | undefined;
}

export function useGetViewListAccumulator(
  skip: boolean,
): GetViewListAccumulatorReturnType {
  const {
    data: loadedData,
    loading,
    error,
    handleLoadMore,
    hasFetchedAllData,
  } = useGetViewListLazyQuery(skip);

  const {
    loading: loadingSegments,
    error: segmentError,
    fetchMore,
  } = useGetSegmentsForProjectQuery({
    fetchPolicy: "cache-first",
    skip: true,
    notifyOnNetworkStatusChange: true,
  });

  const loadMoreSegmentsForProject =
    loadedData?.getAccount?.projects.edges.find(
      (e) => e.node.segments?.pageInfo.hasNextPage,
    );

  if (loadMoreSegmentsForProject && !loadingSegments)
    fetchMore({
      variables: {
        projectId: loadMoreSegmentsForProject.node.id,
        cursor: loadMoreSegmentsForProject.node.segments?.pageInfo.endCursor,
      },
    });

  if (!Boolean(error) && !hasFetchedAllData) handleLoadMore();
  const isLoading = loading || !hasFetchedAllData;

  const viewData = useMemo(() => {
    const views = (
      hasFetchedAllData ? (loadedData?.getAccount?.projects?.edges ?? []) : []
    ).reduce(
      (acumulator, { node }) => [
        ...acumulator,
        {
          id: `${node.id}$`,
          projectId: node.id,
          projectName: node.name,
          primaryDomain: node.primaryDomain,
          scheduleFrequency: node.schedule?.scheduleFrequency,
        },
        ...(node.segments?.edges.map((s) => {
          return {
            id: `${node.id}${s.node.id ? "--" + s.node.id : ""}`,
            projectId: node.id,
            segmentId: s.node.id,
            projectName: node.name,
            segmentName: s.node.name,
            primaryDomain: node.primaryDomain,
            scheduleFrequency: node.schedule?.scheduleFrequency,
            industryCode: node.industryCode,
          };
        }) ?? []),
      ],
      [] as View[],
    );
    return views;
  }, [loadedData, hasFetchedAllData]);

  const segmentList = useMemo(() => {
    return viewData.reduce((acumulator, value) => {
      const found =
        Boolean(acumulator.find((e) => e === value.segmentName)) ||
        !Boolean(value.segmentName);
      return !Boolean(found)
        ? [...acumulator, value.segmentName ?? ""]
        : acumulator;
    }, [] as string[]);
  }, [viewData]);

  const retError = error ?? segmentError;
  return {
    data: viewData,
    segmentList,
    loading:
      (isLoading || loadingSegments || Boolean(loadMoreSegmentsForProject)) &&
      !Boolean(retError),
    error: retError,
  };
}
