import {
  ConnectionFilter,
  isNotEmptyConnectionFilter,
  mapDatasourceConnectionFilterToOrFormFilters,
} from "@lumar/shared";
import { CustomDashboardViewConnectionFilterInput } from "../../../../../graphql";
import { formatFilters } from "../../../account-overview/project-filter/custom-filters/formatFilters";

const changeList: Record<string, string> = {
  sitePrimary: "projectPrimaryDomain",
  name: "projectName",
  createdAt: "projectCreatedAt",
  finishedAt: "projectFinishedAt",
  lastCrawlCrawlingAt: "projectLastCrawlCrawlingAt",
  lastCrawlStatus: "projectLastCrawlStatus",
  allPagesTotal: "projectAllPagesTotal",
};

function RenameFilters(filter: ConnectionFilter): ConnectionFilter {
  return Object.fromEntries(
    Object.entries(filter).map(([key, value]) => {
      switch (key) {
        case "_or":
        case "_and":
          return [
            key,
            (value as ConnectionFilter[])?.map((or) => RenameFilters(or)),
          ];
        default: {
          return [changeList[key] ?? key, value];
        }
      }
    }),
  );
}

export function transformToQueriedProjectFilter(
  filter: ConnectionFilter,
): ConnectionFilter {
  if (!isNotEmptyConnectionFilter(filter)) return {};
  return RenameFilters(filter);
}

export function transformToPersistedProjectFilter(
  filter: ConnectionFilter,
): CustomDashboardViewConnectionFilterInput | undefined {
  return isNotEmptyConnectionFilter(filter)
    ? {
        _or: mapDatasourceConnectionFilterToOrFormFilters(filter).map((or) => ({
          _and: formatFilters({
            filters: or._and,
            includeActiveAndScheduledFilters: false,
          })._and,
        })),
      }
    : filter;
}
