import { useApolloClient, useSession } from "@lumar/shared";
import {
  useCreateHealthScoreRulesAndThresholdsMutation,
  useUpdateHealthScoreRulesAndThresholdsMutation,
  useDeleteHealthScoreRulesAndThresholdsMutation,
  ThresholdType,
  TestAutoThresholdAcceptance,
  CreateHealthScoreTestInput,
  UpdateHealthScoreTestInput,
} from "../../../graphql";
import { RuleAndThreshold } from "../../_common/utils/types";
import { mapRulesAndThresholdsToApiActions } from "./mapRulesAndThresholdsToApiActions";
import { BaseReport } from "../../../_common/utils/constants";
import { useHealthScoreEnabled } from "../../../_common/hooks/useHealthScoreEnabled";

type MutationFunction = <T extends BaseReport>(
  initialValues: RuleAndThreshold<T>[],
  newValues: RuleAndThreshold<T>[],
  projectId: string,
) => Promise<unknown>;

export function useSaveHealthScoreRulesAndThresholdsMutation(): [
  MutationFunction,
  { loading: boolean },
] {
  const [createRulesAndThresholds, { loading: createLoading }] =
    useCreateHealthScoreRulesAndThresholdsMutation();
  const [updateRulesAndThresholds, { loading: updateLoading }] =
    useUpdateHealthScoreRulesAndThresholdsMutation();
  const [deleteRulesAndThresholds, { loading: deleteLoading }] =
    useDeleteHealthScoreRulesAndThresholdsMutation();

  const { cache } = useApolloClient();
  const { hasFeatureFlagEnabled } = useSession();

  const hasHealthscores =
    useHealthScoreEnabled() && hasFeatureFlagEnabled("health-score-alerts");

  const isLoading = createLoading || updateLoading || deleteLoading;

  async function saveRulesAndThresholds<T extends BaseReport>(
    initialValues: RuleAndThreshold<T>[],
    newValues: RuleAndThreshold<T>[],
    projectId: string,
  ): Promise<void> {
    if (!hasHealthscores) return;

    const { rulesToCreate, rulesToDelete, rulesToUpdate } =
      mapRulesAndThresholdsToApiActions(initialValues, newValues);

    const createRulesInput: CreateHealthScoreTestInput[] = rulesToCreate.map(
      (rule) => ({
        severity: rule.severity,
        absoluteThreshold: rule.urls ? rule.urls / 100.0 : undefined,
        testSuiteId: projectId,
        thresholdPredicate: rule.threshold,
        reportCategoryCode: rule.report.code,
        thresholdType: ThresholdType.Absolute,
        automaticThresholdAcceptanceWhenTestResultIsWorse:
          rule.thresholdAcceptanceWhenWorse ?? TestAutoThresholdAcceptance.None,
        automaticThresholdAcceptanceWhenTestResultIsBetter:
          rule.thresholdAcceptanceWhenBetter ??
          TestAutoThresholdAcceptance.None,
        segmentId: rule.segment?.id,
      }),
    );

    const updateRulesInput: UpdateHealthScoreTestInput[] = rulesToUpdate.map(
      (rule) => {
        const initialTest = initialValues.find(
          (value) =>
            value.report.code === rule.report.code &&
            value.segment?.id === rule.segment?.id,
        );

        return {
          severity: rule.severity,
          absoluteThreshold: rule.urls ? rule.urls / 100.0 : undefined,
          thresholdPredicate: rule.threshold,
          healthScoreTestId: initialTest?.id,
          thresholdType: ThresholdType.Absolute,
          automaticThresholdAcceptanceWhenTestResultIsWorse:
            rule.thresholdAcceptanceWhenWorse ??
            TestAutoThresholdAcceptance.None,
          automaticThresholdAcceptanceWhenTestResultIsBetter:
            rule.thresholdAcceptanceWhenBetter ??
            TestAutoThresholdAcceptance.None,
        };
      },
    );

    const deleteRulesInput = rulesToDelete.map((rule) => rule.id);

    // Note: the reason we are deleting the tests first is because of the API limit of only allowing 100 tests. If the user has 100 tests saved,
    // and then tries to update the report for a test and save, we need to delete the old test first before creating the new one. Otherwise, the
    // API will throw an error.
    if (deleteRulesInput.length) {
      await deleteRulesAndThresholds({
        variables: {
          testIds: deleteRulesInput,
        },
        refetchQueries: ["GetTestsTotalCount"],
      });
    }

    await Promise.all([
      updateRulesInput.length
        ? updateRulesAndThresholds({
            variables: {
              rulesAndThresholds: updateRulesInput,
            },
          })
        : null,
      createRulesInput.length
        ? createRulesAndThresholds({
            variables: {
              rulesAndThresholds: createRulesInput,
            },
            refetchQueries: ["GetTestsTotalCount"],
          })
        : null,
    ]);

    if (deleteRulesInput.length || createRulesInput.length) {
      // Note: this keeps the data for the `GetProjectListForDialog` query up-to-date without having to refetch the whole query.
      // This means that the CopyAlertDialog will accurately show which projects have rules set up.
      cache.modify({
        id: cache.identify({
          __typename: "Project",
          id: projectId,
        }),
        fields: {
          healthScoreTestsTotalCount: (cachedCount: number) =>
            cachedCount + createRulesInput.length - deleteRulesInput.length,
        },
      });
    }
  }

  return [saveRulesAndThresholds, { loading: isLoading }];
}
