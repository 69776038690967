import { HealthScoreTrendsTableRow } from "../../../../../graphql";

type WithHealthScoreTrendsTableRowValueKeys = Record<
  keyof HealthScoreTrendsTableRow,
  unknown
>;

function hasHealthScoreTrendsTableRowValueKeys(
  value: unknown,
): value is WithHealthScoreTrendsTableRowValueKeys {
  return (
    typeof value === "object" &&
    value !== null &&
    Boolean(value.hasOwnProperty("columns"))
  );
}

export function isHealthScoreTrendsTableRow(
  value: unknown,
): value is HealthScoreTrendsTableRow {
  if (hasHealthScoreTrendsTableRowValueKeys(value)) {
    return Array.isArray(value.columns);
  }

  return false;
}
