import { ReportTrendData } from "../../graphql";
import { isReportTrendItem } from "./isReportTrendItem";

type WithTrendEntryKeys = Record<keyof ReportTrendData, unknown>;

function hasTrendEntryKeys(value: unknown): value is WithTrendEntryKeys {
  return (
    typeof value === "object" &&
    value !== null &&
    Boolean(value.hasOwnProperty("points")) &&
    Boolean(value.hasOwnProperty("reportTemplateCode"))
  );
}

export function isTrends(value: unknown): value is ReportTrendData[] {
  return (
    Array.isArray(value) &&
    value.every((trendEntry) => {
      if (hasTrendEntryKeys(trendEntry)) {
        return (
          Array.isArray(trendEntry.points) &&
          trendEntry.points.every(isReportTrendItem) &&
          typeof trendEntry.reportTemplateCode === "string"
        );
      }

      return false;
    })
  );
}
