import { CogSolid } from "@lumar/shared";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    borderRadius: theme.shape.borderRadius,
    "& svg": {
      fontSize: 18,
      color: theme.palette.ultraviolet[400],
    },
    "&.Mui-disabled svg": {
      color: theme.palette.grey[200],
    },
    "&:hover": {
      backgroundColor: theme.palette.ultraviolet[100],
      "& svg": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export function ManageAlertButton({
  alertId,
  disabled,
}: {
  alertId: string;
  disabled: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("alerts");
  const { alertsPage } = useMonitorRoutes();

  return (
    <Tooltip
      title={
        !disabled ? (t("edit") as string) : (t("cannotManageAlert") as string)
      }
      arrow={false}
    >
      <span>
        <IconButton
          size="small"
          data-pendo="notifications-page-manage-notification-list-item-button"
          data-testid="manage-alert-icon-button"
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            alertsPage.visit({
              alertId,
            });
          }}
          className={classes.iconButton}
        >
          <CogSolid />
        </IconButton>
      </span>
    </Tooltip>
  );
}
