import { v4 as uuid } from "uuid";
export function mapDatasourceConnectionFilterToOrFormFilters(filter) {
    return filter._or.map((or) => {
        return {
            id: uuid(),
            _and: or._and.map((and) => {
                if (and.customMetrics) {
                    const customMetricName = Object.keys(and.customMetrics)[0];
                    const metricCode = `customMetrics.${customMetricName}`;
                    const predicateKey = Object.keys(and.customMetrics[customMetricName])[0];
                    const predicateValue = and.customMetrics[customMetricName][predicateKey];
                    return {
                        id: uuid(),
                        metricCode,
                        predicateKey,
                        predicateValue,
                    };
                }
                const predicate = and;
                const metricCode = Object.keys(predicate)[0];
                const predicateKey = Object.keys(predicate[metricCode])[0];
                const predicateValue = predicate[Object.keys(predicate)[0]][predicateKey];
                return {
                    id: uuid(),
                    metricCode,
                    predicateKey,
                    predicateValue,
                };
            }),
        };
    });
}
