import { ChevronRightSolid } from "@lumar/shared";
import {
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  useTheme,
} from "@material-ui/core";
import {
  ElementType,
  forwardRef,
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  RefAttributes,
  useImperativeHandle,
  useRef,
  useState,
  FocusEvent,
} from "react";

export type NestedMenuItemProps = Omit<MenuItemProps, "button"> & {
  parentMenuOpen: boolean;
  component?: ElementType;
  label?: ReactNode;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  children?: ReactNode;
  className?: string;
  tabIndex?: number;
  disabled?: boolean;
  ContainerProps?: HTMLAttributes<HTMLElement> &
    RefAttributes<HTMLElement | null>;
  MenuProps?: Partial<Omit<MenuProps, "children">>;
  button?: true | undefined;
};

export const NestedMenuItem = forwardRef<
  HTMLLIElement | null,
  NestedMenuItemProps
>(function NestedMenuItem(props, ref) {
  const theme = useTheme();
  const {
    parentMenuOpen,
    label,
    rightIcon = (
      <ChevronRightSolid style={{ fontSize: theme.typography.pxToRem(15) }} />
    ),
    leftIcon = null,
    children,
    className,
    tabIndex: tabIndexProp,
    ContainerProps: ContainerPropsProp = {},
    MenuProps,
    ...MenuItemProps
  } = props;

  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

  const menuItemRef = useRef<HTMLLIElement | null>(null);
  useImperativeHandle(ref, () => menuItemRef.current!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const containerRef = useRef<HTMLDivElement | null>(null);
  useImperativeHandle(containerRefProp, () => containerRef.current);

  const menuContainerRef = useRef<HTMLDivElement | null>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = (e: MouseEvent<HTMLElement>): void => {
    setIsSubMenuOpen(true);

    if (ContainerProps.onMouseEnter) {
      ContainerProps.onMouseEnter(e);
    }
  };
  const handleMouseLeave = (e: MouseEvent<HTMLElement>): void => {
    setIsSubMenuOpen(false);

    if (ContainerProps.onMouseLeave) {
      ContainerProps.onMouseLeave(e);
    }
  };

  // Check if any immediate children are active
  const isSubmenuFocused = (): boolean => {
    const active = containerRef.current?.ownerDocument.activeElement ?? null;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, fp/no-loops
    for (const child of menuContainerRef.current!.children) {
      if (child === active) {
        return true;
      }
    }

    return false;
  };

  const handleFocus = (e: FocusEvent<HTMLElement>): void => {
    if (e.target === containerRef.current) {
      setIsSubMenuOpen(true);
    }

    if (ContainerProps.onFocus) {
      ContainerProps.onFocus(e);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === "Escape") {
      return;
    }

    if (isSubmenuFocused()) {
      e.stopPropagation();
    }

    const active = containerRef.current?.ownerDocument.activeElement;

    if (e.key === "ArrowLeft" && isSubmenuFocused()) {
      containerRef.current?.focus();
    }

    if (
      e.key === "ArrowRight" &&
      e.target === containerRef.current &&
      e.target === active
    ) {
      const firstChild = menuContainerRef.current
        ?.children[0] as HTMLDivElement;
      firstChild?.focus();
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;

  // Root element must have a `tabIndex` attribute for keyboard navigation
  const tabIndex = useRef<number | undefined>(undefined);
  if (!props.disabled) {
    tabIndex.current = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  return (
    <div
      {...ContainerProps}
      ref={containerRef}
      onFocus={handleFocus}
      tabIndex={tabIndex.current}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
    >
      <MenuItem {...MenuItemProps} className={className} ref={menuItemRef}>
        <div style={{ display: "flex", width: "100%" }}>
          {leftIcon}
          <div style={{ flex: 1 }}> {label}</div> {rightIcon}
        </div>
      </MenuItem>
      <Menu
        // Set pointer events to 'none' to prevent the invisible Popover div
        // from capturing events for clicks and hovers
        style={{ pointerEvents: "none" }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
        {...MenuProps}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: "auto" }}>
          {children}
        </div>
      </Menu>
    </div>
  );
});
