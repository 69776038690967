import { ApolloError } from "@lumar/shared";
import { useGetTestsTotalCountQuery } from "../../../graphql";

export function useTotalRulesCount(
  accountId: string,
  projectId?: string,
): {
  totalRulesCount: number;
  maxRulesCount: number;
  loading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useGetTestsTotalCountQuery({
    variables: {
      accountId,
      needProject: Boolean(projectId),
      projectId: projectId ?? "0",
    },
  });

  return {
    maxRulesCount: data?.getAccount?.maxProjectTests ?? 0,
    totalRulesCount:
      (data?.getAccount?.projectTestsTotalCount ?? 0) +
      (data?.getAccount?.projectHealthScoreTestsTotalCount ?? 0) -
      ((data?.getAccount?.project?.testsTotalCount ?? 0) +
        (data?.getAccount?.project?.healthScoreTestsTotalCount ?? 0)),
    loading: loading,
    error,
  };
}
