import { InformationCircleOutlined, Typography } from "@lumar/shared";
import { Tooltip, TooltipProps, makeStyles } from "@material-ui/core";

interface RulesAndThresholdsTableTitleProps {
  title: string;
  startIcon?: React.ReactNode | null;
  tooltipTitle: TooltipProps["title"];
  className?: string;
}

export function RulesAndThresholdsTableTitle({
  title,
  tooltipTitle,
  className,
  startIcon,
}: RulesAndThresholdsTableTitleProps): JSX.Element {
  const classes = useStyles();

  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={className}
    >
      {startIcon}
      <Typography className={classes.text}>{title}</Typography>
      <Tooltip
        title={tooltipTitle}
        interactive
        arrow={false}
        classes={{ tooltip: classes.tooltip }}
      >
        <InformationCircleOutlined className={classes.icon} />
      </Tooltip>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  text: {
    marginRight: 4,
    color: theme.palette.grey[600],
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
  },
  icon: {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[100],
    height: 16,
    width: 16,
    borderRadius: 6,
    padding: 2,
  },
  tooltip: {
    maxWidth: 500,
    padding: theme.spacing(3),
    color: theme.palette.grey[300],
  },
}));
