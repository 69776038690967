import {
  Dialog,
  TextField,
  DialogProps,
  Snackbar,
  useTranslation,
} from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useParams } from "react-router";
import { useCloneDashboardMutation } from "../../../graphql";
import { useMonitorRoutes } from "../../../_common/routing/useMonitorRoutes";
import {
  MAX_NAME_LENGTH,
  MIN_NAME_LENGTH,
} from "../account-overview/name-dialog/DashboardNameDialog";

interface Props
  extends Pick<DialogProps, "anchorEl" | "onCloseViaIcon" | "open"> {
  onActionButtonClick: () => void;
  clone?: boolean;
}

export function CloneDashboardDialog({
  onActionButtonClick,
  clone,
  ...rest
}: Props): JSX.Element {
  const [name, setName] = useState<string>("");
  const { t } = useTranslation(["dashboards", "dashboardHeader"]);
  const { enqueueSnackbar } = useSnackbar();
  const { dashboardPage } = useMonitorRoutes();

  const { accountId, collectionId, dashboardId } = useParams<{
    accountId: string;
    collectionId: string;
    dashboardId: string;
  }>();

  const [cloneCurrentDashboardCollection] = useCloneDashboardMutation({
    refetchQueries: ["GetDashboardCollection"],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data) {
        dashboardPage.visit({
          collectionId,
          accountId,
          dashboardId: data.copyCustomDashboard.customDashboard.id,
        });
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("dashboardHeader:cloneSuccess")}
          />,
        );
      }
    },
    onError: (e) => {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("dashboardHeader:cloneError", { message: e.message })}
        />,
      );
    },
  });

  function handleConfirmationClick(): void {
    onActionButtonClick();
    if (clone) {
      cloneCurrentDashboardCollection({
        variables: {
          collectionId,
          dashboardId,
          name,
        },
      });
    }
    setName("");
  }

  const isNameTooLong = name.length > MAX_NAME_LENGTH;

  return (
    <Dialog
      {...rest}
      anchorOrigin={{
        vertical: 45,
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      title={t("dashboardHeader:cloneDashboard")}
      withGreyFooter={true}
      buttons={[
        {
          variant: "outlined",
          color: "default",
          title: t("cancel"),
          onClick: () => {
            onActionButtonClick();
            setName("");
          },
          "data-pendo": "monitor-create-dashboard-dialog-cancel",
        },
        {
          disabled: name.length < MIN_NAME_LENGTH || isNameTooLong,
          variant: "contained",
          title: t("dashboardHeader:cloneDashboard"),
          autoFocus: true,
          color: "primary",
          onClick: handleConfirmationClick,
          "data-pendo": "monitor-create-dashboard-dialog-submit",
          "data-testid": "dashboard-dialog-create-dashboard-button",
        },
      ]}
      pendoPrefix="monitor-create-dashboard"
    >
      <div style={{ width: "100%", marginBottom: 31, marginTop: 10 }}>
        <TextField
          label={t("dashboardName")}
          style={{ width: "450px" }}
          onChange={(event) => setName((event.target.value ?? "").trim())}
          onKeyUp={(e) => {
            if (
              !(name.length < MIN_NAME_LENGTH || isNameTooLong) &&
              e.key === "Enter"
            )
              handleConfirmationClick();
          }}
          autoFocus={true}
          inputProps={{
            "data-pendo": "monitor-create-dashboard-dialog-name-input",
          }}
          error={isNameTooLong}
          data-testid="create-dashboard-dialog-input"
          helperText={isNameTooLong ? t("nameTooLong") : undefined}
        />
      </div>
    </Dialog>
  );
}
