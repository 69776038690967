import { Typography, useSession, useTranslation } from "@lumar/shared";
import { createStyles, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { RoleCode, useGetDashboardCollectionNameQuery } from "../graphql";
import { useParams } from "react-router";
import { DashboardNameEditor } from "./components/DashboardNameEditor";
import { TopNav } from "../navigation/TopNav";
import { DashboardCollectionOptions } from "./components/DashboardCollectionOptions";
import clsx from "clsx";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexFlow: "row",
      width: "100%",
      position: "relative",
      top: 10,
      marginBottom: theme.spacing(1),
    },
    name: {
      color: theme.palette.grey[900],
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    nameWithEditing: {
      transition: "background-color 200ms linear",
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(-1),
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
        transition: "background-color 200ms linear",
      },
      borderRadius: theme.shape.borderRadius,
    },
    editor: {
      paddingRight: theme.spacing(1),
      maxWidth: 600,
    },
    alertButtonIcon: {
      width: 20,
      height: 20,
      color: theme.palette.yellow[400],
      marginRight: 6,
    },
    newAlertButton: {
      color: theme.palette.grey[700],
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(17),
      borderRadius: 8,
      padding: "7px 8px",
      "&:hover": {
        background: theme.palette.grey[200],
        cursor: "pointer",
      },
    },
    hiddenButton: {
      width: 0,
      height: 0,
      appearance: "none",
      padding: 0,
      margin: 0,
      borderStyle: "none",
      background: "transparent",
      "&:focus + $name": {
        boxShadow:
          "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #6933C4",
      },
    },
  }),
);

export function BoardHeader(): JSX.Element {
  const { collectionId } = useParams<{
    accountId: string;
    collectionId: string;
  }>();
  const classes = useStyles();
  const [isEditingName, setEditingName] = useState<boolean>(false);
  const { t } = useTranslation(["board"]);
  const session = useSession();
  const hasEnoughRight = session.hasSufficientRole(RoleCode.Editor);
  const {
    data: dashboardNameData,
    loading,
    error: dashboardError,
  } = useGetDashboardCollectionNameQuery({
    notifyOnNetworkStatusChange: true,
    skip: !collectionId,
    variables: {
      collectionId: collectionId,
    },
  });

  const hasError = Boolean(dashboardError);

  return (
    <TopNav>
      <div className={classes.root}>
        {isEditingName ? (
          <div style={{ flex: 1 }}>
            <DashboardNameEditor
              value={
                dashboardNameData?.getCustomDashboardCollection?.name ?? ""
              }
              className={classes.editor}
              style={{ width: "100%" }}
              data-testid="edit-dashboard-name-input"
              pendoPrefix="edit-dashboard-name"
              collectionId={collectionId}
              onFinishedEditing={() => setEditingName(false)}
              refetchQueries={[
                "GetDashboardCollectionName",
                "GetDashboardCollections",
                "GetDashboardCollectionsCount",
              ]}
            />
          </div>
        ) : (
          <>
            <button
              className={classes.hiddenButton}
              onClick={() => setEditingName(true)}
              aria-label={t("editBoardName", {
                text: dashboardNameData?.getCustomDashboardCollection?.name,
              })}
            />
            <Typography
              className={clsx({
                [classes.name]: true,
                [classes.nameWithEditing]: hasEnoughRight,
              })}
              noWrap
              variant="h1SemiBold"
              data-testid="dashboard-name"
              onClick={() => {
                if (hasEnoughRight) setEditingName(true);
              }}
            >
              {(!hasError &&
                dashboardNameData?.getCustomDashboardCollection?.name) ??
                ""}
            </Typography>
          </>
        )}
      </div>
      <DashboardCollectionOptions loading={loading} />
    </TopNav>
  );
}
