import { createStyles, makeStyles, TableCell } from "@material-ui/core";
import { FastField, FieldProps } from "formik";
import { ThresholdAcceptanceMenu } from "../ThresholdAcceptanceMenu";
import { ChangeEvent } from "react";
import { RoleCode, TestAutoThresholdAcceptance } from "../../../graphql";
import clsx from "clsx";
import { useSession, useTranslation } from "@lumar/shared";

interface IssueWorsensCellProps {
  name: string;
  onChange: (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
}

export function IssueWorsensCell({
  name,
  onChange,
}: IssueWorsensCellProps): JSX.Element {
  const { t } = useTranslation(["alerts"]);
  const session = useSession();
  const readOnly = !session.hasSufficientRole(RoleCode.Editor);
  const classes = useStyles();
  return (
    <TableCell className={clsx(classes.rowCell, classes.issuesCell)}>
      <FastField name={name}>
        {(fieldProps: FieldProps<TestAutoThresholdAcceptance>) => {
          return (
            <ThresholdAcceptanceMenu
              value={fieldProps.field.value}
              onChange={onChange}
              disabled={readOnly}
              label={t("rulesAndThresholds.thresholdSettingWhenWorse")}
              subheader={t("rulesAndThresholds.ifIssueWorsens")}
              data-pendo={"threshold-acceptance-when-worse"}
              data-testid={"threshold-acceptance-when-worse"}
            />
          );
        }}
      </FastField>
    </TableCell>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    rowCell: {
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
      padding: theme.spacing(1.5, 1.55),
      color: theme.palette.grey[700],
    },
    issuesCell: {
      background: "#EBEFF3",
    },
  }),
);
