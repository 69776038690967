import { SearchOutlined, XCircleSolid, useTranslation } from "@lumar/shared";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  TextFieldProps,
} from "@material-ui/core";

const useStyles = makeStyles({
  inputLabel: {
    // Note: these styles that ensure that the input label is visually hidden, but not hidden from screen readers (for accessibility reasons)
    border: 0,
    clip: "rect(1px, 1px, 1px, 1px)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    width: 1,
  },
  clearButton: {
    padding: 4,
    marginRight: 7,
  },
});

interface Props {
  className?: string;
  value: string;
  disabled?: boolean;
  onChange: TextFieldProps["onChange"];
  onClear: () => void;
}

export const ProjectSearchBar = ({
  value,
  onChange,
  onClear,
  className,
  disabled,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  return (
    <>
      <InputLabel htmlFor="project-search-bar" className={classes.inputLabel}>
        {t("searchProjects")}
      </InputLabel>
      <TextField
        id="project-search-bar"
        name="project-search-bar"
        placeholder={t("searchPlaceholder")}
        variant="outlined"
        fullWidth
        value={value}
        disabled={disabled}
        onChange={onChange}
        className={className}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: value ? (
            <InputAdornment position="end">
              <IconButton
                title={t("clearButton")}
                className={classes.clearButton}
                onClick={onClear}
              >
                <XCircleSolid fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </>
  );
};
