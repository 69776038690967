import { useSession, useTranslation } from "@lumar/shared";
import { useCallback } from "react";
import { TrendsComputedType } from "../../../../../graphql";
import { ReportCategoryOption } from "../../../../../_common/utils/constants";
import {
  getTableDefaultSortingOrders,
  getTableDefaultSortingList,
} from "../../../common/grid/sorting/helpers";
import { TableSortingItem } from "../../../common/grid/sorting/TableSortingPopup";

export function useTableSortingList(): (
  categories: ReportCategoryOption[],
) => TableSortingItem[] {
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { t } = useTranslation("sortingPopup");
  return useCallback(
    (categories) => {
      const TABLE_DEFAULT_SORTING_ORDERS = getTableDefaultSortingOrders(
        segmentationAvailable,
        t,
      );
      return [
        ...getTableDefaultSortingList(segmentationAvailable, t),
        ...categories.map((element) => {
          return {
            code: element?.code,
            title: element?.name ?? "",
            sortBy: [
              {
                code: TrendsComputedType.Last,
                title: t("sortingPopup:score"),
                order: TABLE_DEFAULT_SORTING_ORDERS,
              },
              {
                code: TrendsComputedType.AbsDiff,
                title: t("sortingPopup:absolute"),
                order: TABLE_DEFAULT_SORTING_ORDERS,
              },
            ],
          };
        }),
      ];
    },
    [segmentationAvailable, t],
  );
}
