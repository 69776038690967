import { ConnectionFilter, PredicateValue } from "@lumar/shared";
import {
  ConnectionPredicate,
  CustomDashboardViewConnectionFilterInput,
  ProjectConnectionFilterInput,
} from "../../../../../graphql";
import { Omit, isEqual, isNil, pickBy, get, set } from "lodash";
import { assert } from "../../../../../_common/assert";
import { isNotEmptyProjectConnectionFilterInput } from "../../../account-overview/project-filter/utils/isNotEmptyProjectConnectionFilterInput";
import React from "react";

type NotNull<T> = T extends null ? never : T;

type SetNotNullable<T extends object, TKeys extends keyof T> = Omit<
  T,
  TKeys
> & {
  [P in TKeys]: NotNull<T[P]>;
};

type NotNullable = SetNotNullable<
  Record<string, Record<string, PredicateValue>>,
  string
>;

const dateMetric = [
  "projectCreatedAt",
  "projectLastCrawlCrawlingAt",
  "projectFinishedAt",
];

function transformToCustomProjectFilter(
  isOr: boolean,
  inputFilters: CustomDashboardViewConnectionFilterInput[] | undefined,
): Record<string, Record<string, PredicateValue>> {
  assert(inputFilters);
  switch (inputFilters.length) {
    case 2: {
      const isNameOrDomain =
        inputFilters[0]?.["projectName"] &&
        isEqual(
          inputFilters[0]?.["projectName"],
          inputFilters[1]?.["projectPrimaryDomain"],
        );

      if (isNameOrDomain)
        return {
          nameOrDomain: <Record<string, PredicateValue>>(
            inputFilters[0]["projectName"]
          ),
        };

      // eslint-disable-next-line fp/no-loops
      for (const metric of dateMetric) {
        const isThisMetric = inputFilters.every((e) =>
          e.hasOwnProperty(metric),
        );
        if (isThisMetric) {
          if (
            isOr &&
            get(inputFilters[0], `${metric}.${ConnectionPredicate.Ge}`)
          ) {
            return set(
              {},
              `${metric}.${ConnectionPredicate.Ne}`,
              get(inputFilters[0], `${metric}.${ConnectionPredicate.Ge}`),
            );
          } else if (
            get(inputFilters[1], `${metric}.${ConnectionPredicate.Le}`)
          )
            return set(
              {},
              `${metric}.${ConnectionPredicate.Eq}`,
              get(inputFilters[1], `${metric}.${ConnectionPredicate.Le}`),
            );
        }
      }
      break;
    }
    case 4: {
      const isAllLastCrawlStatus =
        inputFilters.every((e) => e.projectLastCrawlStatus) && isOr;
      if (isAllLastCrawlStatus) return { running: { eq: "true" } };
      break;
    }
    case 1: {
      const isRunningNE = isEqual(inputFilters?.[0].projectLastCrawlStatus, {
        notIn: ["Crawling", "Finalizing", "Discovering", "Queued"],
      });
      if (isRunningNE) return { running: { eq: "false" } };
      return <NotNullable>(
        pickBy(inputFilters?.[0] ?? {}, (value: unknown) => !isNil(value))
      );
    }
  }
  return <NotNullable>(
    pickBy(inputFilters?.[0] ?? {}, (value: unknown) => !isNil(value))
  );
}

export function useTransformFiltersToCustomProjectFilters(
  inputFilters: ProjectConnectionFilterInput,
): ConnectionFilter {
  const ret = React.useMemo(() => {
    return isNotEmptyProjectConnectionFilterInput(inputFilters)
      ? {
          _or:
            inputFilters._or?.map((or) => {
              return {
                _and:
                  or._and?.map((and) => {
                    if (and._and)
                      return transformToCustomProjectFilter(false, and._and);

                    return transformToCustomProjectFilter(
                      true,
                      and._or ? and._or : [and],
                    );
                  }) ?? [],
              };
            }) ?? [],
        }
      : {};
  }, [inputFilters]);

  return ret;
}
