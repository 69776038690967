import {
  SortAscendingOutlined,
  SortDescendingOutlined,
  insertIf,
  useSession,
  useTranslation,
} from "@lumar/shared";
import {
  MonitorSortItem,
  TableColumnSortOrder,
} from "../../../helpers/MonitorSortItem";
import {
  OrderDirection,
  TrendsTableSortableField,
} from "../../../../../graphql";
import { makeStyles } from "@material-ui/core";

export interface MonitorSortItemWithPendo extends MonitorSortItem {
  ["data-pendo"]: string;
  title: string;
  icon: React.ReactNode;
}

export function useSortOptions(): MonitorSortItemWithPendo[] {
  const { t } = useTranslation(["accountDashboard"]);
  const classes = useStyles();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();

  return [
    {
      type: TableColumnSortOrder.byMostRecentCrawl,
      field: TrendsTableSortableField.ProjectFinishedAt,
      sort: OrderDirection.Desc,
      title: t("accountDashboard:projectNameColumnSortOrder.byMostRecentCrawl"),
      "data-pendo": "monitor-dashboard-sort-by-most-recent-crawl",
      icon: <SortDescendingOutlined className={classes.greyIcon} />,
    },
    {
      type: TableColumnSortOrder.byOldestCrawl,
      field: TrendsTableSortableField.ProjectFinishedAt,
      sort: OrderDirection.Asc,
      title: t("accountDashboard:projectNameColumnSortOrder.byOldestCrawl"),
      "data-pendo": "monitor-dashboard-sort-by-oldest-crawl",
      icon: <SortAscendingOutlined className={classes.greyIcon} />,
    },
    {
      type: TableColumnSortOrder.byProjectNameAToZ,
      field: TrendsTableSortableField.ProjectName,
      sort: OrderDirection.Asc,
      title: t("accountDashboard:projectNameColumnSortOrder.byProjectNameAToZ"),
      "data-pendo": "monitor-dashboard-sort-by-project-name-a-to-z",
      icon: <SortAscendingOutlined className={classes.greyIcon} />,
    },
    {
      type: TableColumnSortOrder.byProjectNameZToA,
      field: TrendsTableSortableField.ProjectName,
      sort: OrderDirection.Desc,
      title: t("accountDashboard:projectNameColumnSortOrder.byProjectNameZToA"),
      "data-pendo": "monitor-dashboard-sort-by-project-name-z-to-a",
      icon: <SortDescendingOutlined className={classes.greyIcon} />,
    },
    ...insertIf(segmentationAvailable, {
      type: TableColumnSortOrder.bySegmentNameAToZ,
      field: TrendsTableSortableField.SegmentName,
      sort: OrderDirection.Asc,
      title: t("accountDashboard:projectNameColumnSortOrder.bySegmentNameAToZ"),
      "data-pendo": "monitor-dashboard-sort-by-segment-name-a-to-z",
      icon: <SortAscendingOutlined className={classes.greyIcon} />,
    }),
    ...insertIf(segmentationAvailable, {
      type: TableColumnSortOrder.bySegmentNameZToA,
      field: TrendsTableSortableField.SegmentName,
      sort: OrderDirection.Desc,
      title: t("accountDashboard:projectNameColumnSortOrder.bySegmentNameZToA"),
      "data-pendo": "monitor-dashboard-sort-by-segment-name-z-to-a",
      icon: <SortDescendingOutlined className={classes.greyIcon} />,
    }),
  ];
}

const useStyles = makeStyles((theme) => ({
  greyIcon: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(17),
    marginRight: theme.spacing(1),
  },
}));
