import { ApolloError, NetworkStatus } from "@lumar/shared";
import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetProjectListForDialogQuery,
  GetProjectListForDialogQuery,
} from "../../../graphql";

interface ProjectListLazyQueryReturnType {
  data: GetProjectListForDialogQuery | undefined;
  loading: boolean;
  hasFetchedAllData: boolean;
  error: ApolloError | undefined;
  handleLoadMore: () => void;
}

export function useGetProjectListLazyQuery(
  skip: boolean,
  searchTerm: string | null,
): ProjectListLazyQueryReturnType {
  const { accountId } = useParams<{ accountId: string }>();

  const { data, error, loading, networkStatus, fetchMore } =
    useGetProjectListForDialogQuery({
      variables: {
        accountId,
        searchTerm,
      },
      skip: skip,
      fetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    });

  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const pageInfo = data?.getAccount?.monitorProjects?.pageInfo;
  const hasFetchedAllData =
    !loading && !isFetchingMore && !Boolean(pageInfo?.hasNextPage);

  const handleLoadMore = React.useCallback(() => {
    const pageInfo = data?.getAccount?.monitorProjects?.pageInfo;

    if (pageInfo?.hasNextPage && pageInfo.endCursor && !isFetchingMore) {
      fetchMore({
        variables: {
          accountId,
          searchTerm,
          cursor: pageInfo.endCursor,
        },
      });
    }
  }, [fetchMore, data, isFetchingMore, accountId, searchTerm]);

  return {
    data,
    error,
    loading,
    hasFetchedAllData,
    handleLoadMore,
  };
}
