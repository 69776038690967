import React, { useState } from "react";
import {
  ToggleIconButton,
  DotsHorizontalOutlined,
  TrashOutlined,
  EditOutlined,
  DestructiveMenuActionConfirmation,
  DuplicateOutlined,
  useTranslation,
} from "@lumar/shared";
import {
  Typography,
  useTheme,
  Tooltip,
  MenuItem,
  Menu,
} from "@material-ui/core";

interface Props {
  showMenuButton: boolean;
  showMenuButtonTooltip: boolean;
  onRenameButtonClick: () => void;
  onDeleteAction: () => void;
  onCloneAction: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onBlur?: () => void;
  variant?: "standard" | "outlined";
  colorVariant?: "blue" | "red" | "grey" | "primary";
  buttonBKColor?: string;
  disableDelete?: boolean;
  disableCloning?: boolean;
}

export const DashboardTabActionMenu = ({
  showMenuButton,
  showMenuButtonTooltip,
  onRenameButtonClick,
  onDeleteAction,
  onCloneAction,
  onMouseEnter,
  onMouseLeave,
  onBlur,
  variant,
  colorVariant,
  buttonBKColor,
  disableDelete,
  disableCloning,
}: Props): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation(["dashboardTabs", "errors", "dashboards"]);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [confirmationAnchor, setConfirmationAnchor] =
    useState<null | HTMLElement>(null);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const isMenuOpen = Boolean(menuAnchor);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement> &
      React.MouseEvent<HTMLDivElement>,
  ): void => {
    setMenuAnchor(event.currentTarget);
    setConfirmationAnchor(event.currentTarget.parentElement);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCloseMenu = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setMenuAnchor(null);
    onMouseLeave();
  };

  const handleRenameMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement>,
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    setMenuAnchor(null);
    onRenameButtonClick();
  };

  const handleCloneMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement>,
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    setMenuAnchor(null);
    onCloneAction();
  };

  const handleDeleteMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement>,
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    setMenuAnchor(null);
    setConfirmationOpen(true);
  };

  const handleConfirmDeletionButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    setConfirmationOpen(false);
    setConfirmationAnchor(null);
    onDeleteAction();
  };

  const handleCancelDeletionButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    setConfirmationAnchor(null);
    setConfirmationOpen(false);
  };
  const greyBackground = isMenuOpen
    ? theme.palette.grey[500]
    : theme.palette.grey[300];
  const greyColor = isMenuOpen ? "white" : undefined;
  const colorVariantBackground =
    (colorVariant ?? "grey") === "grey" ? greyBackground : undefined;
  return (
    <>
      {showMenuButton || isMenuOpen ? (
        <Tooltip
          placement="bottom"
          title={t("dashboards:actions") as string}
          open={showMenuButtonTooltip}
          arrow={false}
        >
          <ToggleIconButton
            style={{
              padding: theme.spacing(1.125),
              marginLeft: "3px",
              alignSelf: "center",
              width: 20,
              height: 20,
              backgroundColor: buttonBKColor ?? colorVariantBackground,
              color: greyColor,
            }}
            colorVariant={colorVariant ?? "grey"}
            variant={variant}
            size="small"
            onClick={handleOpenMenu}
            active={isMenuOpen}
            data-pendo={"monitor-sub-dashboard-item-menu-toggle"}
            data-testid="sub-dashboards-list-item-action-menu"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onBlur={onBlur}
            component="div"
          >
            <DotsHorizontalOutlined />
          </ToggleIconButton>
        </Tooltip>
      ) : undefined}

      <Menu
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        data-testid="dashboard-menu"
        disableRestoreFocus
        disableEnforceFocus
      >
        <MenuItem
          data-testid="sub-dashboard-item-menu-rename"
          onClick={handleRenameMenuItemClick}
          data-pendo={"monitor-sub-dashboard-item-menu-rename"}
        >
          <EditOutlined
            fontSize="small"
            htmlColor={theme.palette.ultraviolet[400]}
          />
          <Typography variant="body2" style={{ width: "100%", marginLeft: 15 }}>
            {t("dashboardTabs:rename_menu")}
          </Typography>
        </MenuItem>
        {disableCloning ? undefined : (
          <MenuItem
            data-testid="sub-dashboard-item-menu-duplicate"
            onClick={handleCloneMenuItemClick}
            data-pendo={"monitor-sub-dashboard-item-menu-duplicate"}
          >
            <DuplicateOutlined
              fontSize="small"
              htmlColor={theme.palette.ultraviolet[400]}
            />
            <Typography
              variant="body2"
              style={{ width: "100%", marginLeft: 15 }}
            >
              {t("dashboardTabs:duplicate")}
            </Typography>
          </MenuItem>
        )}
        {disableDelete ? undefined : (
          <MenuItem
            data-testid="sub-dashboard-item-menu-delete"
            onClick={handleDeleteMenuItemClick}
            data-pendo={"monitor-sub-dashboard-item-menu-delete"}
            disabled={disableDelete}
          >
            <TrashOutlined fontSize="small" color="error" />
            <Typography
              variant="body2"
              style={{ width: "100%", marginLeft: 15 }}
            >
              {t("dashboardTabs:delete_menu")}
            </Typography>
          </MenuItem>
        )}
      </Menu>

      {confirmationAnchor && confirmationOpen ? (
        <DestructiveMenuActionConfirmation
          title={t("dashboardTabs:delete_confirm_title")}
          description={t("dashboardTabs:delete_confirm_message")}
          onConfirm={handleConfirmDeletionButtonClick}
          onCancel={handleCancelDeletionButtonClick}
          confirmText={t("dashboards:delete_menu")}
          anchorEl={confirmationAnchor}
          pendoPrefix={"monitor-sub-dashboard-item-menu-delete"}
        />
      ) : null}
    </>
  );
};
