import { EmptyState, useTranslation } from "@lumar/shared";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";

import { ReactNode } from "react";
import error from "../../../../_animations/error-animation.json";
import noresult from "../../../../_animations/noresult-animation.json";
import noprojects from "../../../../_animations/noproject-animation.json";

export interface EmptyStateSettings {
  type: number;
  title: string | null;
  description: ReactNode | string;
  icon: ReactNode;
  action?: {
    icon?: ReactNode;
    title: string;
    ["data-pendo"]?: string;
  };
}

export enum EmptyStateType {
  Error,
  NoRows,
  NoResult,
}

export interface EmptyStateManagerProps {
  isError?: boolean;
  isFiltered?: boolean;
  isEmpty?: boolean;
  onClick?: (type: EmptyStateType) => void;
}

export function EmptyStateManager(props: EmptyStateManagerProps): JSX.Element {
  const { isError, isFiltered, isEmpty } = props;
  const { t } = useTranslation("tableEmptyState");

  const type = (): EmptyStateType => {
    if (isError) return EmptyStateType.Error;
    if (isFiltered && isEmpty) return EmptyStateType.NoResult;
    return isEmpty ? EmptyStateType.NoRows : EmptyStateType.Error;
  };

  const options: EmptyStateSettings[] = [
    {
      type: EmptyStateType.Error,
      title: t("error"),
      description: t("errorLoadingTable"),
      icon: (
        <Lottie loop animationData={error} play style={{ width: "80px" }} />
      ),
      action: {
        title: t("errorButton"),
      },
    },
    {
      type: EmptyStateType.NoRows,
      title: t("noProjects"),
      description: t("noProjectsDescription"),
      icon: (
        <Lottie
          loop
          animationData={noprojects}
          play
          style={{ width: "80px" }}
        />
      ),
    },
    {
      type: EmptyStateType.NoResult,
      title: t("noResults"),
      description: t("noResultsDescription"),
      icon: (
        <Lottie loop animationData={noresult} play style={{ width: "80px" }} />
      ),
      action: {
        title: t("noResultsButton"),
        "data-pendo": "monitor-dashboard-no-filtered-results-go-to-analyze",
      },
    },
  ];
  const current = options[type()];
  return (
    <EmptyState
      width="100%"
      height={500}
      title={current.title}
      description={current.description}
      icon={current.icon}
      actions={
        current.action
          ? [
              {
                type: "button",
                ...current.action,
                onClick: () => props.onClick?.(current.type),
              },
            ]
          : undefined
      }
    />
  );
}
