import React from "react";
import { Typography, useTranslation } from "@lumar/shared";
import { Divider, makeStyles } from "@material-ui/core";

import { FastField } from "formik";
import { CheckboxWithLabel } from "../../../_common/forms/CheckboxWithLabel";
import { TemplatesAccordion } from "./TemplatesAccordion";
import { CheckboxLabel } from "./CheckboxLabel";
import { useTemplates } from "./utils/templates";
import { getCodeValuesInArray } from "./utils/utility";
import { useGetAccountModuleCodes } from "../../../_common/hooks/useGetAccountModuleCodes";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  infoCircleTitle: {
    color: theme.palette.grey[400],
    width: 18,
    height: 18,
    marginLeft: theme.spacing(1.5),
    position: "relative",
    top: 4,
  },
  dialogContentDiv: {
    display: "flex",
    flexDirection: "row",
  },
  suggestedTemplates: {
    display: "flex",
    flexDirection: "column",
    width: 327,
    marginTop: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(1.5),
  },
  reports: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(3.5),
  },
  checkBox: { marginBottom: theme.spacing(1.6) },
  healthScoreDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  includedReports: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  includedReportsDivider: {
    marginTop: theme.spacing(2),
  },
}));

interface TemplatesViewProps {
  values: Record<string, boolean>;
  useHealthScore?: boolean;
}

export function TemplatesView({
  values,
  useHealthScore = false,
}: TemplatesViewProps): JSX.Element {
  const moduleCodes = useGetAccountModuleCodes();

  const classes = useStyles();
  const { t } = useTranslation(["dashboards", "dashboardTemplates"]);
  const { templates } = useTemplates();

  const checked = getCodeValuesInArray(values);
  return (
    <>
      <div className={classes.dialogContentDiv}>
        <div className={classes.suggestedTemplates}>
          <Typography variant="subtitle2SemiBold">
            {t("suggestedTemplates")}
          </Typography>
          <Typography variant="body2" className={classes.description}>
            {t("suggestedDescription")}
          </Typography>
          <div className={classes.reports}>
            {templates.map((e) =>
              !e.isHealthScore &&
              moduleCodes.includes(e.moduleCode) &&
              e.visible ? (
                <FastField
                  key={e.id}
                  name={e.code}
                  component={CheckboxWithLabel}
                  defaultValue={false}
                  indeterminate={false}
                  type="checkbox"
                  Label={{
                    label: (
                      <CheckboxLabel
                        labelText={e.name}
                        tooltipTitle={e.description ?? ""}
                      />
                    ),
                  }}
                  className={classes.checkBox}
                  data-testid={e.id}
                  data-pendo={e.id}
                />
              ) : undefined,
            )}

            {useHealthScore ? (
              <>
                <Divider className={classes.healthScoreDivider} />
                {templates.map((e) =>
                  e.isHealthScore &&
                  moduleCodes.includes(e.moduleCode) &&
                  e.visible ? (
                    <FastField
                      key={e.id}
                      name={e.code}
                      component={CheckboxWithLabel}
                      defaultValue={false}
                      indeterminate={false}
                      type="checkbox"
                      Label={{
                        label: (
                          <CheckboxLabel
                            labelText={e.name}
                            tooltipTitle={e.description ?? ""}
                          />
                        ),
                      }}
                      className={classes.checkBox}
                      data-testid={e.id}
                      data-pendo={e.id}
                    />
                  ) : undefined,
                )}
              </>
            ) : undefined}
          </div>
        </div>
        <div className={classes.includedReports}>
          <Typography variant="subtitle2SemiBold">
            {t("includedReports")}
          </Typography>
          <Divider className={classes.includedReportsDivider} />
          {templates.map((d) => {
            if (checked.includes(d.code)) {
              return <TemplatesAccordion template={d} key={d.code} />;
            }
          })}
        </div>
      </div>
    </>
  );
}
