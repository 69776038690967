import { InformationCircleOutlined, Typography } from "@lumar/shared";
import { makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  infoCircleCheckbox: {
    color: theme.palette.grey[400],
    width: 17,
    height: 17,
    marginLeft: "auto",
  },
}));

export function CheckboxLabel({
  labelText,
  tooltipTitle,
}: {
  labelText: string;
  tooltipTitle: string;
}): JSX.Element {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: 281,
      }}
    >
      <Typography variant="body2">{labelText}</Typography>
      <Tooltip title={tooltipTitle} arrow={false}>
        <InformationCircleOutlined className={classes.infoCircleCheckbox} />
      </Tooltip>
    </div>
  );
}
