import { EmptyState, useTranslation } from "@lumar/shared";

export function ProjectComparisonChartEmptyDashboardSettings(props: {
  className: string;
}): JSX.Element {
  const { t } = useTranslation(["errors", "projectComparisonChart"]);

  return (
    <EmptyState
      title={t("projectComparisonChart:noActiveProjects")}
      description={t("projectComparisonChart:noActiveProjectsDescription")}
      className={props.className}
    />
  );
}
