import {
  makeStyles,
  createStyles,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import {
  ReportChangeTitle,
  IfImprovesTitle,
  IfWorsensTitle,
  ReportTitle,
  RuleTitle,
  ReportSegmentTitle,
  SeverityTitle,
  ThresholdTitle,
} from "./table/RulesAndThresholdsTableTitles";
import clsx from "clsx";
import { useSession } from "@lumar/shared";

export interface RulesAndThresholdsTableHeadersProps {
  typeCell?: React.ReactNode | null;
  segmentCell?: React.ReactNode | null;
  changeCell?: React.ReactNode | null;
}

export function RulesAndThresholdsTableHeader(
  props: RulesAndThresholdsTableHeadersProps,
): JSX.Element {
  const classes = useStyles();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={clsx(classes.headerCell, classes.severityCell)}>
          <SeverityTitle />
        </TableCell>
        <TableCell className={classes.headerCell}>
          {props.typeCell ?? <ReportTitle />}
        </TableCell>
        {segmentationAvailable && (
          <TableCell className={classes.headerCell}>
            {props.segmentCell ?? <ReportSegmentTitle />}
          </TableCell>
        )}
        <TableCell className={clsx(classes.headerCell, classes.ruleCell)}>
          <RuleTitle />
        </TableCell>
        <TableCell className={clsx(classes.headerCell, classes.thresholdCell)}>
          <ThresholdTitle />
        </TableCell>
        <TableCell className={clsx(classes.headerCell, classes.issuesCell)}>
          <IfImprovesTitle />
        </TableCell>
        <TableCell className={clsx(classes.headerCell, classes.issuesCell)}>
          <IfWorsensTitle />
        </TableCell>
        <TableCell className={classes.changeHeader}>
          {props.changeCell ?? <ReportChangeTitle />}
        </TableCell>
        <TableCell
          className={clsx(classes.headerCell, classes.deleteCell)}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    grouppedHeaderCell: {
      boxShadow: `0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      color: theme.palette.grey[800],
    },
    headerCell: {
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      color: theme.palette.grey[800],
    },
    changeHeader: {
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
      width: 205,
      maxWidth: 205,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    issuesCell: {
      width: 175,
      maxWidth: 175,
      background: "#EBEFF3",
    },
    thresholdCell: {
      minWidth: 100,
      maxWidth: 150,
    },
    ruleCell: {
      width: 50,
      maxWidth: 83,
    },
    severityCell: {
      width: 60,
      maxWidth: 93,
    },
    deleteCell: {
      width: 20,
      maxWidth: 20,
    },
  }),
);
