import {
  Typography,
  ComboBox,
  ChevronDownSolid,
  DotsHorizontalSolid,
  PlusSmallOutlined,
  TrashOutlined,
  ToggleIconButton,
  useSession,
  useTranslation,
  SortDescendingOutlined,
  SortAscendingOutlined,
  Check,
  ArrowUp,
  ArrowDown,
} from "@lumar/shared";
import {
  PopperProps,
  makeStyles,
  createStyles,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useState } from "react";
import {
  OrderDirection,
  RoleCode,
  TrendsComputedType,
  TrendsTableSortableField,
} from "../../../../../graphql";
import { LeftBottomPopper } from "../../../../../_common/components/CustomPopper/LeftBottomPopper";
import {
  FullReportCategory,
  ReportOption,
} from "../../../../../_common/utils/constants";
import { ColumnHeader } from "../../types";
import { PROJECT_NAME_COLUMN_KEY } from "../AccountOverviewGrid";
import clsx from "clsx";
import {
  MonitorSortItem,
  TableColumnSortOrder,
} from "../../../helpers/MonitorSortItem";
import { ReportOptionGroupElement } from "../../../../../_common/components/reports/components/ReportOptionGroupElement";
import { ReportOptionElement } from "../../../../../_common/components/reports/components/ReportOptionElement";
import { NestedMenuItem } from "../../../../../_common/components/menu/NestedMenuItem";

const MAX_ALLOWED_COLUMNS = 15;

function PopperWithoutClickPropagation(props: PopperProps): JSX.Element {
  return (
    <LeftBottomPopper
      {...props}
      data-testid="column-header-combobox-popper"
      onClick={(event) => {
        event?.stopPropagation();
        return true;
      }}
    />
  );
}

interface ColumnHeaderWithComboBoxProps {
  testId?: string;
  columnId: string;
  columnIndex: number;
  columnHeaders: ColumnHeader[];
  reports: ReportOption[];
  categories: FullReportCategory[];
  reportTemplateCodesInTable: string[];
  setReportTemplateCodesInTable: (
    changedTemplateCode: string,
    reportTemplateCodes: string[],
  ) => void;
  editingIndex?: number;
  setEditingIndex?: (index: number | undefined) => void;
  setReportTemplatesBeingPersisted: (value: string[] | null) => void;
  setSorting?: (sort: MonitorSortItem) => void;
  sorting?: MonitorSortItem;
}

function removeUsedTemplatesFromTheList(
  autocompleteOptions: ReportOption[],
  columnHeaders: ColumnHeader[],
  currentValue: ReportOption | undefined,
): ReportOption[] {
  if (currentValue) {
    return autocompleteOptions.filter((obj) => {
      return !columnHeaders.find(
        (a) => a.code === obj.code && a.code !== currentValue.code,
      );
    });
  }
  return autocompleteOptions;
}

export function ColumnHeaderWithComboBox(
  props: ColumnHeaderWithComboBoxProps,
): JSX.Element {
  const classes = useStyles();
  const session = useSession();
  const { t } = useTranslation(["accountDashboard", "sortingPopup"]);
  const theme = useTheme();

  const [isMouseOver, setIsMouseOver] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const hasRightsToEdit = session.hasSufficientRole(RoleCode.Editor);

  const {
    columnId,
    columnIndex,
    columnHeaders,
    reports,
    categories,
    reportTemplateCodesInTable,
    setReportTemplateCodesInTable,
    editingIndex,
    setEditingIndex,
    setReportTemplatesBeingPersisted,
    setSorting,
    sorting,
  } = props;

  const [editing_, setEditing_] = useState(false);
  const editing = editing_ || editingIndex === columnIndex;

  function setEditing(): void {
    if (editingIndex === columnIndex) {
      setEditingIndex?.(undefined);
    } else {
      setEditing_(false);
    }
  }

  const currentValue = reports.find(
    (a) => a.code === columnHeaders.find((e) => e.id === columnId)?.code,
  );

  function getIndex(): number {
    return columnHeaders
      .filter((columnHeader) => columnHeader.code !== PROJECT_NAME_COLUMN_KEY)
      .findIndex((columnHeader) => columnHeader.id === columnId);
  }

  const options = removeUsedTemplatesFromTheList(
    reports,
    columnHeaders,
    currentValue,
  );

  const unusedTemplates = options.filter((o) => o.code !== currentValue?.code);

  const selectedReport = reports.find(
    (a) => a.code === columnHeaders.find((e) => e.id === columnId)?.code,
  );

  const isTrendSorting =
    sorting?.field === TrendsTableSortableField.Trend &&
    sorting?.trend?.code === columnId &&
    hasRightsToEdit;

  const isTrendSortingAsc =
    isTrendSorting && sorting?.sort === OrderDirection.Asc;
  const isTrendSortingDesc =
    isTrendSorting && sorting?.sort === OrderDirection.Desc;

  return (
    <div
      data-testid={props.testId}
      className={classes.column}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {editing ? (
        <ComboBox
          data-testid="column-header-combobox"
          className={classes.autocompleteRoot}
          PopperComponent={PopperWithoutClickPropagation}
          id={columnId + "-combo-id"}
          options={options}
          classes={{
            paper: classes.paper,
            option: classes.option,
          }}
          value={selectedReport}
          onBlur={() => {
            setReportTemplatesBeingPersisted(null);
          }}
          fullWidth={true}
          disableClearable={true}
          getOptionLabel={(element) => element.name}
          renderGroup={(option) => {
            return (
              <ReportOptionGroupElement
                key={option.key}
                group={option}
                categories={categories}
              />
            );
          }}
          groupBy={(option) => {
            return option.category?.parentName?.length
              ? option.category?.parentName
              : "SEO";
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          renderOption={(option, state) => {
            return (
              <ReportOptionElement
                option={option}
                state={state}
                disabled={false}
                maxSelection={1}
                group={option.category?.code}
                parentGroup={option.category?.parentName}
                onClick={() => {
                  const index = getIndex();
                  setReportTemplateCodesInTable(option.code, [
                    ...reportTemplateCodesInTable.slice(0, index),
                    option.code,
                    ...reportTemplateCodesInTable.slice(index + 1),
                  ]);
                  setEditing();
                }}
              />
            );
          }}
          inputProps={{
            "data-testid": "column-header-combobox-input",
            onBlur: () => {
              setEditing();
            },
            onKeyDown: (event) => {
              if (event.key === "Escape") {
                setReportTemplatesBeingPersisted(null);
                setEditing();
              }
            },
            autoFocus: true,
          }}
          open
          selectOnFocus={true}
          onChange={(_, newValue) => {
            setEditing();
            const index = getIndex();
            setReportTemplateCodesInTable(newValue.code, [
              ...reportTemplateCodesInTable.slice(0, index),
              newValue.code,
              ...reportTemplateCodesInTable.slice(index + 1),
            ]);
          }}
          data-pendo={`monitor-dashboard-column-${columnIndex}-report-select`}
        />
      ) : (
        <div className={classes.headerWrapper}>
          <div
            className={clsx(
              classes.headerRoot,
              isMouseOver ? classes.headerRootStyle : undefined,
            )}
            onClick={() => {
              if (hasRightsToEdit) setEditing_(true);
            }}
          >
            <Typography className={classes.headerLabel}>
              {selectedReport?.name ?? ""}
            </Typography>
            {hasRightsToEdit && isMouseOver ? (
              <IconButton
                data-testid="column-header-button"
                size="small"
                className={classes.headerChevronDownBtn}
                onClick={() => {
                  setEditing_(true);
                }}
                data-pendo={`monitor-dashboard-column-${columnIndex}-report-edit`}
              >
                <ChevronDownSolid className={classes.chevronDown} />
              </IconButton>
            ) : undefined}
          </div>
          {(isMouseOver || Boolean(anchorEl)) && hasRightsToEdit ? (
            <>
              <ToggleIconButton
                data-testid="column-header-edit-button"
                size="small"
                className={classes.headerDots}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                data-pendo={`monitor-dashboard-column-${columnIndex}-column-actions`}
              >
                <DotsHorizontalSolid />
              </ToggleIconButton>
            </>
          ) : undefined}
          {isTrendSorting && (
            <Button
              variant="text"
              data-pendo={`monitor-dashboard-column-${columnIndex}-column-sort`}
              data-testid="column-header-sort-button"
              style={{
                maxWidth: 10,
                minWidth: 10,
                marginRight: theme.spacing(0.5),
                boxShadow: "none",
              }}
              onClick={() => {
                setSorting?.({
                  ...sorting,
                  sort:
                    sorting.sort === OrderDirection.Asc
                      ? OrderDirection.Desc
                      : OrderDirection.Asc,
                });
              }}
            >
              {isTrendSortingAsc && (
                <ArrowUp
                  style={{
                    fontSize: theme.typography.pxToRem(14),
                  }}
                />
              )}
              {isTrendSortingDesc && (
                <ArrowDown
                  style={{
                    fontSize: theme.typography.pxToRem(14),
                  }}
                />
              )}
            </Button>
          )}
          {Boolean(anchorEl) ? (
            <Menu
              id="action-menu"
              data-testid="action-menu"
              data-pendo={`action-menu-${columnIndex}`}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
                setIsMouseOver(false);
              }}
              anchorEl={anchorEl}
              keepMounted
              getContentAnchorEl={null}
              classes={{ paper: classes.menu }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <div className={classes.menuHeader}>
                <Typography variant="subtitle4Medium">
                  Column actions
                </Typography>
              </div>
              {columnHeaders.length === MAX_ALLOWED_COLUMNS ? undefined : (
                <div>
                  <MenuItem
                    data-testid="action-menu-insert-left"
                    data-pendo={`action-menu-insert-left-${columnIndex}`}
                    onClick={() => {
                      const index = getIndex();
                      const newValueCode = unusedTemplates[0].code;
                      setReportTemplatesBeingPersisted([
                        ...reportTemplateCodesInTable.slice(0, index),
                        newValueCode,
                        ...reportTemplateCodesInTable.slice(index),
                      ]);
                      setEditingIndex?.(columnIndex);
                      setAnchorEl(null);
                    }}
                  >
                    <PlusSmallOutlined className={classes.greyIcon} />
                    <Typography variant="body2" className={classes.text}>
                      {t("insertLeft")}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    data-testid="action-menu-insert-right"
                    data-pendo={`action-menu-insert-right-${columnIndex}`}
                    onClick={() => {
                      const index = getIndex();
                      const newValueCode = unusedTemplates[0].code;
                      setReportTemplatesBeingPersisted([
                        ...reportTemplateCodesInTable.slice(0, index + 1),
                        newValueCode,
                        ...reportTemplateCodesInTable.slice(index + 1),
                      ]);
                      setEditingIndex?.(columnIndex + 1);
                      setAnchorEl(null);
                    }}
                  >
                    <PlusSmallOutlined className={classes.greyIcon} />
                    <Typography variant="body2" className={classes.text}>
                      {t("insertRight")}
                    </Typography>
                  </MenuItem>
                </div>
              )}
              <NestedMenuItem
                data-testid="action-menu-sort-desc"
                data-pendo={`action-menu-sort-desc-${columnIndex}`}
                parentMenuOpen={Boolean(anchorEl)}
                label={
                  <Typography variant="body2" className={classes.text}>
                    {t("sortDesc")}
                  </Typography>
                }
                leftIcon={
                  <SortDescendingOutlined className={classes.greyIcon} />
                }
              >
                {subMenuForSorting.map((e) => {
                  const selected =
                    isTrendSorting &&
                    sorting?.trend?.type === e.type &&
                    sorting?.sort === OrderDirection.Desc;

                  return (
                    <MenuItem
                      key={e.type}
                      data-testid={`action-menu-sort-desc-${e.type}`}
                      data-pendo={`action-menu-sort-desc-${e.type}-${columnIndex}`}
                      onClick={() => {
                        setAnchorEl(null);
                        setIsMouseOver(false);
                        setSorting?.({
                          ...defaultDesc,
                          trend: {
                            code: columnId,
                            type: e.type,
                          },
                        });
                      }}
                      selected={selected}
                    >
                      <Typography variant="body2" className={classes.menuText}>
                        {t(e.label)}
                      </Typography>
                      {selected ? (
                        <Check
                          style={{
                            marginLeft: "auto",
                            fontSize: theme.typography.pxToRem(15),
                          }}
                        />
                      ) : undefined}
                    </MenuItem>
                  );
                })}
              </NestedMenuItem>

              <NestedMenuItem
                data-testid="action-menu-sort-asc"
                data-pendo={`action-menu-sort-asc-${columnIndex}`}
                parentMenuOpen={Boolean(anchorEl)}
                label={
                  <Typography variant="body2" className={classes.text}>
                    {t("sortAsc")}
                  </Typography>
                }
                leftIcon={
                  <SortAscendingOutlined className={classes.greyIcon} />
                }
              >
                {subMenuForSorting.map((e) => {
                  const selected =
                    isTrendSorting &&
                    sorting?.trend?.type === e.type &&
                    sorting?.sort === OrderDirection.Asc;
                  return (
                    <MenuItem
                      key={e.type}
                      data-testid={`action-menu-sort-asc-${e.type}`}
                      data-pendo={`action-menu-sort-asc-${e.type}-${columnIndex}`}
                      onClick={() => {
                        setIsMouseOver(false);
                        setAnchorEl(null);
                        setSorting?.({
                          ...defaultAsc,
                          trend: {
                            code: columnId,
                            type: e.type,
                          },
                        });
                      }}
                      selected={selected}
                    >
                      <Typography variant="body2" className={classes.menuText}>
                        {t(e.label)}
                      </Typography>
                      {selected ? (
                        <Check
                          style={{
                            marginLeft: "auto",
                            fontSize: theme.typography.pxToRem(15),
                          }}
                        />
                      ) : undefined}
                    </MenuItem>
                  );
                })}
              </NestedMenuItem>

              {columnHeaders.length <= 2 ? undefined : (
                <MenuItem
                  data-testid="action-menu-delete"
                  data-pendo={`action-menu-delete-${columnIndex}`}
                  onClick={() => {
                    const index = getIndex();
                    setReportTemplateCodesInTable("", [
                      ...reportTemplateCodesInTable.slice(0, index),
                      ...reportTemplateCodesInTable.slice(index + 1),
                    ]);
                    setAnchorEl(null);
                  }}
                >
                  <TrashOutlined className={classes.redIcon} />
                  <Typography variant="body2" className={classes.text}>
                    {t("removeColumn")}
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          ) : undefined}
        </div>
      )}
    </div>
  );
}

const defaultDesc = {
  field: TrendsTableSortableField.Trend,
  sort: OrderDirection.Desc,
  type: TableColumnSortOrder.unused,
};
const defaultAsc = {
  field: TrendsTableSortableField.Trend,
  sort: OrderDirection.Asc,
  type: TableColumnSortOrder.unused,
};

const subMenuForSorting = [
  {
    type: TrendsComputedType.Last,
    label: "sortingPopup:totalURLs",
  },
  {
    type: TrendsComputedType.AbsDiff,
    label: "sortingPopup:absolute",
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    column: {
      display: "flex",
      width: "100%",
    },
    headerWrapper: {
      width: "100%",
      height: 40,
      display: "flex",
      alignItems: "center",
    },
    headerRoot: {
      marginTop: "auto",
      marginBottom: "auto",
      display: "flex",
      flexFlow: "row",
      width: "100%",
      padding: theme.spacing(0, 1, 0, 1),
    },
    headerRootStyle: {
      cursor: "pointer",
      borderRadius: 6,
      margin: theme.spacing(1, 1, 1, 0),
      "&:hover": {
        color: theme.palette.ultraviolet[700],
        background: theme.palette.grey[100],
      },
    },
    headerChevronDownBtn: {
      maxHeight: 30,
      height: 30,
      padding: theme.spacing(1, 1.5, 1, 1.5),
      minWidth: 30,
      marginRight: -13,
      marginTop: 1,
      "&:hover": {
        background: "none",
      },
    },
    chevronDown: {
      fontSize: theme.typography.pxToRem(18),
    },
    headerDots: {
      cursor: "pointer",
      borderRadius: 6,
      marginRight: theme.spacing(1),
      "&:hover": {
        color: theme.palette.ultraviolet[700],
        background: theme.palette.ultraviolet[100],
      },
    },
    headerLabel: {
      marginTop: "auto",
      marginBottom: "auto",
      flex: 1,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(17),
      lineClamp: 2,
      display: "-webkit-box",
      verticalAlign: "middle",
      boxOrient: "vertical",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "initial",
    },
    autocompleteRoot: {
      marginTop: 5,
    },
    group: {
      position: "sticky",
      top: -theme.spacing(1.2),
      display: "flex",
      alignItems: "center",
      width: "100%",
      backgroundColor: theme.palette.grey[100],
      height: 40,
      zIndex: theme.zIndex.modal + 1,
    },
    icon: {
      color: theme.palette.purple[400],
      width: 20,
      height: 20,
      marginLeft: theme.spacing(2),
    },
    paper: {
      paddingLeft: "0",
      paddingRight: "0",
    },
    menu: {
      border: 0,
      width: 209,
      paddingLeft: 8,
      paddingRight: 8,
      boxShadow:
        "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
      color: theme.palette.grey[700],
    },
    redIcon: {
      color: theme.palette.red[600],
      fontSize: theme.typography.pxToRem(17),
    },
    text: {
      marginLeft: theme.spacing(1),
      color: theme.palette.grey[700],
    },
    menuText: {
      color: theme.palette.grey[700],
      marginRight: theme.spacing(1),
    },
    greyIcon: {
      color: theme.palette.grey[700],
      fontSize: theme.typography.pxToRem(20),
    },
    menuHeader: {
      padding: theme.spacing(0.8, 1, 1, 1),
    },
    option: {
      padding: 0,
      "&[aria-disabled='true']": {
        pointerEvents: "all!important",
        cursor: "default",
      },
      "&[aria-selected='true']": {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.ultraviolet[200],
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
  }),
);
