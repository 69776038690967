import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import {
  CustomDashboardType,
  useCreateDashboardMutation,
  useGetBoardViewsLimitedQuery,
} from "../../../../graphql";
import { getErrorMessage } from "../../../../_common/utils/getErrorMessage";
import { useTemplates } from "./templates";
import { getCharts, getDashboardName, getTables } from "./utility";
import { CreateDashboardsFromTemplateResponse } from "./useCreateDashboardsFromTemplate";
import { ApolloError, Snackbar, useTranslation } from "@lumar/shared";

export function useCreateDashboardFromTemplate(
  refetchQueries?: string[],
): (
  collectionId: string,
  code: string,
  name?: string,
  viewIds?: string[],
) => Promise<CreateDashboardsFromTemplateResponse> {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const { templates } = useTemplates();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["dashboards", "errors"]);

  const { refetch: getBoardViews } = useGetBoardViewsLimitedQuery({
    skip: true,
    fetchPolicy: "cache-first",
  });

  const [createDashboard] = useCreateDashboardMutation({
    refetchQueries,
  });

  async function createDashboardFromTemplate(
    collectionId: string,
    code: string,
    name?: string,
    viewIds?: string[],
  ): Promise<CreateDashboardsFromTemplateResponse> {
    try {
      const template = templates.find((e) => e.code === code);

      const isHealthScore = Boolean(template?.isHealthScore);
      const graphViews =
        viewIds ??
        (
          await getBoardViews({
            collectionId: collectionId,
          })
        ).data.getCustomDashboardCollection?.customViews.nodes.map((e) => e.id);
      const dashboard = await createDashboard({
        variables: {
          accountId,
          collectionId: collectionId,
          name: name ?? getDashboardName(code, templates),
          tables: getTables(code, templates),
          charts: graphViews?.length
            ? getCharts(code, templates, graphViews)
            : [],
          type: isHealthScore
            ? CustomDashboardType.HealthScores
            : CustomDashboardType.Monitor,
        },
      });
      return {
        status: true,
        createdDashboardIds: [
          dashboard.data?.createCustomDashboard?.customDashboard.id,
        ],
      };
    } catch (error) {
      const errorCode =
        error instanceof ApolloError
          ? error?.graphQLErrors?.[0]?.extensions?.code
          : "";

      const message =
        errorCode ===
        "CUSTOM_DASHBOARDS_COLLECTION_MAX_CUSTOM_DASHBOARDS_LIMIT_EXCEEDED"
          ? t("errors:maxDashboardCountExceeded")
          : getErrorMessage(error);

      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("errorMsg", { message: message })}
        />,
      );
      return {
        status: false,
        error: error instanceof ApolloError ? error : undefined,
        createdDashboardIds: [],
      };
    }
  }

  return createDashboardFromTemplate;
}
