import React from "react";

interface Props extends React.HTMLProps<HTMLAnchorElement> {
  shouldRenderAsLink: boolean;
}

export function ConditionalLink({
  shouldRenderAsLink,
  children,
  ...anchorProps
}: Props): JSX.Element {
  if (shouldRenderAsLink) {
    return <a {...anchorProps}>{children}</a>;
  }

  return <>{children}</>;
}
