import {
  Alert,
  Button,
  ChartBarSolid,
  PlusSolid,
  Typography,
  getApiAccountId,
  useTranslation,
} from "@lumar/shared";
import { CircularProgress, makeStyles, Tooltip } from "@material-ui/core";
import { useLayoutEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import {
  CustomDashboardType,
  GetDashboardCollectionsQuery,
  useGetDashboardCollectionQuery,
  useGetDashboardCollectionsQuery,
} from "../../graphql";
import { assert } from "../../_common/assert";
import { useGenericParams } from "../../_common/routing/useGenericParams";
import { SideBarList } from "./SideBarList";
import { MonitorSideBarCategory } from "./SideBarListItem";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    width: "100%",
    marginBottom: 27,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addNewButton: {
    border: `1px solid ${theme.palette.grey[300]}`,
    background: "inherit",
    "&:hover": {
      background: theme.palette.grey[300],
    },
    marginRight: 4,
  },
}));

function generateDashboardList(
  data: GetDashboardCollectionsQuery | undefined,
  createUrl: ({ dashboardId }: { dashboardId?: string }) => string,
): MonitorSideBarCategory[] {
  const boards = data?.getAccount?.customDashboardCollections?.edges;
  if (!boards?.length) return [];
  const ret = boards?.map(({ node: e }, index) => ({
    id: e.id,
    position: index,
    name: e.name,
    tooltipText: e.name,
    icon: ChartBarSolid,
    href: createUrl({ dashboardId: e.id }),
  }));
  return ret;
}

function isOneDashboardSelected(
  currentPath: string,
  category: MonitorSideBarCategory[],
  collectionId?: string,
): boolean {
  if (!Boolean(collectionId)) return false;
  return Boolean(
    category?.find((element) =>
      (currentPath + "/").startsWith(element.href + "/"),
    ),
  );
}

export function SidebarContent(): JSX.Element {
  const { accountId, collectionId } = useGenericParams();
  assert(accountId);
  const { pathname } = useLocation();
  const { t } = useTranslation(["dashboards", "errors", "common"]);
  const classes = useStyles();
  const { dashboardPage, boardSettingsPage } = useMonitorRoutes();

  const history = useHistory();

  const {
    data,
    error: collectionError,
    loading: loadingCollections,
    fetchMore,
  } = useGetDashboardCollectionsQuery({
    variables: {
      accountId: getApiAccountId(accountId),
    },
    fetchPolicy: "cache-first",
  });

  const {
    data: dashboardData,
    error: dashboardError,
    loading: dashboardLoading,
  } = useGetDashboardCollectionQuery({
    variables: {
      accountId: getApiAccountId(accountId),
      customCollectionId: collectionId,
      types: [CustomDashboardType.HealthScores, CustomDashboardType.Monitor],
    },
    skip: !Boolean(collectionId),
    fetchPolicy: "cache-first",
  });

  const loadingData = loadingCollections || dashboardLoading;
  const error = collectionError ?? dashboardError;

  const dashboardList = useMemo(
    () => generateDashboardList(data, dashboardPage.getUrl),
    [data, dashboardPage.getUrl],
  );

  const pageInfo = data?.getAccount?.customDashboardCollections?.pageInfo;
  const searchingDashboard =
    Boolean(collectionId) &&
    !loadingData &&
    dashboardData?.getCustomDashboardCollection?.id &&
    !isOneDashboardSelected(pathname, dashboardList, collectionId) &&
    pageInfo?.hasNextPage &&
    !Boolean(error);

  const loading = loadingData || searchingDashboard;

  useLayoutEffect(() => {
    if (searchingDashboard)
      fetchMore({
        variables: {
          cursor: pageInfo?.endCursor,
        },
      });
    else if (
      !isOneDashboardSelected(pathname, dashboardList, collectionId) &&
      dashboardList.length &&
      !loading
    ) {
      const path = dashboardPage.getUrl({
        collectionId: dashboardList?.[0]?.id,
      });
      history.replace(path);
    }
  }, [
    collectionId,
    dashboardList,
    pathname,
    history,
    dashboardPage,
    loading,
    searchingDashboard,
    fetchMore,
    pageInfo?.endCursor,
  ]);

  if (collectionError)
    return (
      <Alert size="small" severity="error">
        <strong>{t("error")}</strong> {collectionError.message}
      </Alert>
    );

  return loadingCollections ? (
    <CircularProgress
      color="primary"
      style={{
        width: 18,
        height: 18,
      }}
    />
  ) : (
    <SideBarList
      canFetchMore={
        !loading &&
        data?.getAccount?.customDashboardCollections.pageInfo?.hasNextPage
      }
      fetchMore={() =>
        data?.getAccount?.customDashboardCollections.pageInfo?.hasNextPage &&
        fetchMore({
          variables: {
            accountId: getApiAccountId(accountId),
            cursor:
              data?.getAccount?.customDashboardCollections.pageInfo?.endCursor,
          },
        })
      }
      title={
        dashboardList.length ? (
          <div className={classes.titleContainer}>
            <Typography variant="captionSemiBold">
              {t("my_dashboard", { count: dashboardList.length })}
            </Typography>
            <HideFromInsufficientRole>
              <Tooltip
                title={t("create_new_dashboard") as string}
                arrow={false}
              >
                <Button
                  aria-label="add"
                  size="small"
                  className={classes.addNewButton}
                  style={{ float: "right" }}
                  onClick={() => {
                    boardSettingsPage.visit({ accountId });
                  }}
                  data-pendo="monitor-sidenav-add-dashboard"
                  data-testid="monitor-sidenav-add-dashboard"
                  startIcon={<PlusSolid fontSize="inherit" />}
                  variant="outlined"
                  disableElevation
                >
                  {t("common:new")}
                </Button>
              </Tooltip>
            </HideFromInsufficientRole>
          </div>
        ) : undefined
      }
      config={dashboardList}
    />
  );
}
