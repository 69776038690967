import { useMemo, useCallback, useState, useRef, memo } from "react";
import {
  makeStyles,
  Theme,
  Link,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import arePropsEqual from "react-fast-compare";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import PopperJs from "popper.js";
import {
  useTranslation,
  FilterButton,
  ConnectionFilter,
  FilterOrRuleFormValue,
  mapDatasourceConnectionFilterToOrFormFilters,
  getConnectionFilterCount,
  getDefaultFormOrFilter,
  mapOrFormFiltersToDatasourceConnectionFilter,
  sortMetricsAlphabetically,
  isNotEmptyConnectionFilter,
  useFilterOrRuleArrayValidationSchema,
} from "@lumar/shared";

import { ViewFilterForm } from "./ViewFilterForm";
import { assert } from "../../../../_common/assert";
import { useViewFilters } from "./custom-filters/useViewFilters";

const useStyles = makeStyles((theme: Theme) => ({
  filterPopper: {
    zIndex: theme.zIndex.drawer,
    marginTop: 10,
  },
  clearButton: {
    marginRight: 10,
  },
}));

export interface ProjectsFilterFormValues {
  filters: FilterOrRuleFormValue[];
}

interface ProjectFilterProps {
  filter: ConnectionFilter;
  setFilter: (filter: ConnectionFilter) => void;
  disabled?: boolean;
}

function ViewFilterInner(props: ProjectFilterProps): JSX.Element {
  const { filter, setFilter, disabled } = props;
  const classes = useStyles();
  const { t } = useTranslation("projectFilter");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isFilterVisible = Boolean(anchorEl);
  const filterPopperId = isFilterVisible ? "project-filter-popper" : undefined;
  const filterPopperRef = useRef<PopperJs | null>(null);
  const getFilterOrRuleArrayValidationSchema =
    useFilterOrRuleArrayValidationSchema();
  const filterCount = getConnectionFilterCount(filter);

  const resetFilters = (): void => {
    setFilter({});
  };

  const projectMetricsWithPredicates = useViewFilters({
    includeActiveAndScheduledAndTestMetrics: false,
  });

  const initialValues = useMemo(() => {
    if (!projectMetricsWithPredicates) {
      return { filters: [] };
    }

    if (isNotEmptyConnectionFilter(filter)) {
      return {
        filters: mapDatasourceConnectionFilterToOrFormFilters(filter),
      };
    }

    return {
      filters: [
        getDefaultFormOrFilter(
          sortMetricsAlphabetically(projectMetricsWithPredicates),
        ),
      ],
    };
  }, [filter, projectMetricsWithPredicates]);

  const validationSchema = useMemo(
    () =>
      projectMetricsWithPredicates
        ? Yup.object({
            filters: getFilterOrRuleArrayValidationSchema(
              projectMetricsWithPredicates,
            ),
          })
        : undefined,
    [getFilterOrRuleArrayValidationSchema, projectMetricsWithPredicates],
  );

  const onSubmit = useCallback(
    (
      values: ProjectsFilterFormValues,
      formikHelpers: FormikHelpers<ProjectsFilterFormValues>,
    ) => {
      assert(validationSchema);
      // FIXME: Formik doesn't respect `transform` and `trim` in yup schema.
      // Formik devs will fix it in this pr: https://github.com/jaredpalmer/formik/pull/2255
      // This is why `schema.cast` has to be used.
      setFilter(
        mapOrFormFiltersToDatasourceConnectionFilter(
          validationSchema.cast(values)?.filters || [],
        ),
      );
      setAnchorEl(null);
      // NOTE: On submit, regardless of outcome, the filter popper is closed. The toggle is set
      // to disabled by the "disabled" prop which prevents the dialog being re-opened until the
      // parent decides it is time to re-enable the form. The "disabled" prop from the parent is
      // the source of truth for whether the form is submitting or not rather than Formiks
      // "isSubmitting". We risk the form remaining in an "isSubmitting" state if it is re-opened
      // once the disabled props is set to false, because Formik still thinks it's submitting
      // until we mark setSubmitting(false). This hard to do from outside the Formik form. Best to
      //  do away with Formik's isSubmitting, set it to false straight after validation, and rely
      // on only the disabled prop as our single source of truth.
      formikHelpers.setSubmitting(false);
    },
    [setFilter, validationSchema, setAnchorEl],
  );
  const hasFilter = filterCount > 0;

  return (
    <Formik
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={false}
      validateOnMount={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}

      // Note: Not adding `onReset` callback because it is called when new `initialValues` are provided.
      // Example issue: Updating filter through history makes Formik form trigger onReset which usually empties the filter search param after navigating.
      // - Michal
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        validateForm,
        resetForm,
        isValid,
        dirty,
      }) => {
        return (
          <>
            <div>
              {hasFilter ? (
                <Link
                  className={classes.clearButton}
                  component="button"
                  underline="none"
                  variant="body2"
                  disabled={disabled}
                  onClick={() => {
                    resetFilters();
                  }}
                  data-testid="account-overview-filter-clear-button"
                  data-pendo="monitor-dashboard-filter-clear-in-header"
                >
                  {t("clearFilterButton")}
                </Link>
              ) : null}
              <FilterButton
                label={t("filtersButton")}
                disabled={disabled}
                onClick={(event) => {
                  resetForm();
                  setAnchorEl(anchorEl ? null : event.currentTarget);
                }}
                data-testid="account-overview-filter-toggle-button"
                data-pendo="monitor-dashboard-filter-toggle"
                chipLabel={filterCount > 0 ? filterCount : undefined}
              />
            </div>
            <Popper
              id={filterPopperId}
              open={isFilterVisible}
              anchorEl={anchorEl}
              placement="bottom-end"
              popperRef={filterPopperRef}
              className={classes.filterPopper}
            >
              <ClickAwayListener
                onClickAway={() => {
                  resetForm();
                  setAnchorEl(null);
                }}
              >
                <div>
                  <ViewFilterForm
                    values={values}
                    isValid={isValid}
                    dirty={dirty}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateForm={validateForm}
                    resetForm={() => {
                      resetFilters();
                      resetForm();
                    }}
                  />
                </div>
              </ClickAwayListener>
            </Popper>
          </>
        );
      }}
    </Formik>
  );
}

export const ViewFilter = memo(ViewFilterInner, arePropsEqual);
