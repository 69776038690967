import React from "react";
import { makeStyles, MenuItem, Select, useTheme } from "@material-ui/core";
import {
  ExclamationCircleSolid,
  ExclamationSolid,
  Typography,
  CheckSmallIcon,
  useTranslation,
} from "@lumar/shared";
import clsx from "clsx";
import { Severity } from "../../graphql";
interface Props {
  value: Severity | null;
  onChange: (severity: Severity | null) => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(20),
  },
  iconFail: {
    color: `${theme.palette.red[400]} !important`,
  },
  iconWarning: {
    color: `${theme.palette.yellow[400]} !important`,
  },
  select: {
    fontWeight: 500,
    color: theme.palette.grey[700],
    maxWidth: 150,
    height: 35.625,
    "& .MuiSelect-select": {
      marginTop: -1,
      width: "100%",
      display: "flex",
      height: 16,
    },
    "&:hover fieldset": {
      borderColor: `black!important`,
    },
    "& .check": {
      display: "none",
    },
  },
  selectBackground: {
    background: `${theme.palette.ultraviolet[100]}!important`,
    "& .MuiSelect-root:hover": {
      background: `${theme.palette.ultraviolet[100]}!important`,
    },
    "& .MuiSelect-root:focus": {
      background: `${theme.palette.ultraviolet[100]}!important`,
    },
    "&:hover": {
      "& fieldset": {
        borderColor: `${theme.palette.ultraviolet[400]}!important`,
      },
    },
    fontWeight: 500,
    color: theme.palette.grey[700],
    "& fieldset": {
      borderColor: `${theme.palette.ultraviolet[400]}!important`,
    },
  },
  selectMenu: {
    "&.Mui-selected": {
      color: `${theme.palette.grey[700]}!important`,
    },
  },
}));

export function SeverityFilter({
  value,
  disabled,
  onChange,
}: Props): JSX.Element {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const newValue = event.target.value as string;
    const isSeverity =
      newValue === Severity.Fail || newValue === Severity.Warning;
    onChange(isSeverity ? newValue : null);
  };
  const theme = useTheme();

  const classes = useStyles();

  const { t } = useTranslation(["notifications"]);

  const currentValue = value ?? "All";

  return (
    <>
      <Select
        disabled={disabled}
        labelId="demo-simple-select-outlined-label"
        id={`notifications-page-severity-select`}
        value={currentValue}
        onChange={handleChange}
        variant="outlined"
        className={clsx(
          currentValue != "All" ? classes.selectBackground : "",
          classes.select,
        )}
        data-pendo="notifications-page-severity-filter-select"
        data-testid="notifications-page-severity-filter-select"
      >
        <MenuItem value="All" className={classes.selectMenu}>
          <Typography variant="body2" style={{ marginTop: 1 }}>
            {t("allseverities")}
          </Typography>
          {currentValue === "All" ? (
            <CheckSmallIcon
              className="check"
              htmlColor={theme.palette.primary.main}
              style={{ marginLeft: 10 }}
            />
          ) : undefined}
        </MenuItem>
        <MenuItem
          value={Severity.Fail}
          style={{ display: "flex" }}
          className={classes.selectMenu}
          data-testid="Critical-severity-menu-item"
        >
          <ExclamationCircleSolid
            className={clsx(classes.icon, classes.iconFail)}
            width="20px"
            height="20px"
          />
          <Typography variant="body2">{t(Severity.Fail)}</Typography>
          {currentValue === Severity.Fail ? (
            <CheckSmallIcon
              className="check"
              htmlColor={theme.palette.primary.main}
              style={{ marginLeft: 10 }}
            />
          ) : undefined}
        </MenuItem>
        <MenuItem
          value={Severity.Warning}
          className={classes.selectMenu}
          data-testid="Warning-severity-menu-item"
        >
          <ExclamationSolid
            className={clsx(classes.icon, classes.iconWarning)}
            width="20px"
            height="20px"
          />
          <Typography variant="body2">{t(Severity.Warning)}</Typography>
          {currentValue === Severity.Warning ? (
            <CheckSmallIcon
              className="check"
              htmlColor={theme.palette.primary.main}
              style={{ marginLeft: 10 }}
            />
          ) : undefined}
        </MenuItem>
      </Select>
    </>
  );
}
