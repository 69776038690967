import { Alert, Button } from "@lumar/shared";

export const SimpleFallbackError = (
  error: Error,
  resetError: () => void,
): JSX.Element => (
  <Alert severity="error">
    <strong>An unexpected error occurred.</strong> <pre>{error.message}</pre>
    <Button onClick={resetError} variant="outlined">
      Reload
    </Button>
  </Alert>
);
