import { ApolloError, Snackbar, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import {
  CustomDashboardType,
  useCreateDashboardMutation,
  useGetBoardViewsLimitedQuery,
} from "../../../../graphql";
import { getErrorMessage } from "../../../../_common/utils/getErrorMessage";
import { useTemplates } from "./templates";
import { getCharts, getDashboardName, getTables } from "./utility";

export interface CreateDashboardsFromTemplateResponse {
  status: boolean;
  error?: ApolloError;
  createdDashboardIds: string[];
}

export function useCreateDashboardsFromTemplate(
  refetchQueries?: string[],
): (
  collectionId: string,
  codes: string[],
  viewIds?: string[],
) => Promise<CreateDashboardsFromTemplateResponse> {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const { templates } = useTemplates();
  const createdDashboardIds = useRef<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["dashboards", "errors"]);

  const [createDashboard] = useCreateDashboardMutation({
    refetchQueries,
  });

  const { refetch: getBoardViews } = useGetBoardViewsLimitedQuery({
    skip: true,
    fetchPolicy: "cache-first",
  });

  async function createDashboardsFromTemplate(
    collectionId: string,
    codes: string[],
    viewIds?: string[],
  ): Promise<CreateDashboardsFromTemplateResponse> {
    createdDashboardIds.current = [];
    if (!Boolean(codes.length))
      return { status: true, createdDashboardIds: [] };
    try {
      const graphViews =
        viewIds ??
        (
          await getBoardViews({
            collectionId: collectionId,
          })
        ).data.getCustomDashboardCollection?.customViews.nodes.map((e) => e.id);
      // eslint-disable-next-line fp/no-loops
      for (const templateCode of codes) {
        const template = templates.find((e) => e.code === templateCode);
        const isHealthScore = Boolean(template?.isHealthScore);
        const dashboard = await createDashboard({
          variables: {
            accountId,
            collectionId: collectionId,
            name: getDashboardName(templateCode, templates),
            tables: getTables(templateCode, templates),
            charts: graphViews?.length
              ? getCharts(templateCode, templates, graphViews)
              : [],
            type: isHealthScore
              ? CustomDashboardType.HealthScores
              : CustomDashboardType.Monitor,
          },
        });
        createdDashboardIds.current = [
          ...createdDashboardIds.current,
          ...(dashboard.data?.createCustomDashboard?.customDashboard.id
            ? [dashboard.data?.createCustomDashboard?.customDashboard.id]
            : []),
        ];
      }
      return { status: true, createdDashboardIds: createdDashboardIds.current };
    } catch (error) {
      const errorCode =
        error instanceof ApolloError
          ? error?.graphQLErrors?.[0]?.extensions?.code
          : "";

      const message =
        errorCode ===
        "CUSTOM_DASHBOARDS_COLLECTION_MAX_CUSTOM_DASHBOARDS_LIMIT_EXCEEDED"
          ? t("errors:maxDashboardCountExceeded")
          : getErrorMessage(error);

      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("errorMsg", { message: message })}
        />,
      );
      return {
        status: false,
        error: error instanceof ApolloError ? error : undefined,
        createdDashboardIds: createdDashboardIds.current,
      };
    }
  }

  return createDashboardsFromTemplate;
}
