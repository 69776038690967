const ACCOUNTS_PREFIX = "/accounts/:accountId";

export const MONITOR_ROUTES = {
  Index: "/",
  Login: "/login",
  Dashboard: [
    ACCOUNTS_PREFIX,
    `${ACCOUNTS_PREFIX}/dashboard/:collectionId?/:dashboardId?`,
  ],
  CreateAlert: `${ACCOUNTS_PREFIX}/alerts/create`,
  Alerts: `${ACCOUNTS_PREFIX}/alerts/:alertId?`,
  Notifications: `${ACCOUNTS_PREFIX}/notifications`,
  MarketingPage: `${ACCOUNTS_PREFIX}/marketingPage`,
  BoardSettings: `${ACCOUNTS_PREFIX}/board/settings/:collectionId?`,
};
