import { useState } from "react";
import {
  DotsHorizontalOutlined,
  ToggleIconButton,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, MenuItem, Menu, Tooltip } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import {
  MonitorSortItem,
  TableColumnSortOrder,
} from "../../helpers/MonitorSortItem";
import { useSortOptions } from "./utils/useSortOptions";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    margin: theme.spacing(0, 1, 0, 1),
    display: "flex",
    flexFlow: "row",
  },
  title: {
    flex: 1,
    minWidth: 180,
  },
  check: {
    float: "right",
    height: 16,
    color: theme.palette.ultraviolet[400],
  },
}));

export function ProjectNameColumnMenu(props: {
  onMenuItemClick: (sortType: MonitorSortItem) => void;
  selected: TableColumnSortOrder;
  onClose?: () => void;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();
  const { t } = useTranslation(["accountDashboard"]);
  const sortOptions = useSortOptions();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    props.onClose?.();
  };

  return (
    <div>
      <Tooltip title={t("sortRows") as string} arrow={false}>
        <ToggleIconButton
          aria-controls="simple-menu"
          size="small"
          aria-haspopup="true"
          data-testid="account-overview-grid-project-column-menu-sort-menu-toggle"
          onClick={handleClick}
        >
          <DotsHorizontalOutlined />
        </ToggleIconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {sortOptions.map((option) => {
          const isSelected = props.selected === option.type;

          return (
            <MenuItem
              key={option.type}
              selected={isSelected}
              onClick={() => {
                handleClose();
                props.onMenuItemClick(option);
              }}
              data-testid="account-overview-grid-project-column-header-sort-menu-item"
            >
              {option.icon}
              <div className={classes.title}>{option.title}</div>
              {isSelected && <Check className={classes.check} />}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
