import Highcharts from "highcharts";

interface PointWithHref extends Highcharts.Point {
  href?: string;
}

export function isPointWithHref(
  point: Highcharts.Point,
): point is PointWithHref {
  return "href" in point;
}
