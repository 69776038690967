/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Theme } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getDataGridStyles(theme: Theme) {
  return {
    root: {
      color: "rgba(0,0,0,.85)",
      border: 0,
      background: "transparent",
      borderStyle: "none",
      fontFamily: theme.typography.fontFamily,
      WebkitFontSmoothing: "auto",
      // Note: this ensures that the first column is the same height as the table, so that it doesn't look weird when data is loading.
      // More info: https://deepcrawl.atlassian.net/browse/MONHB-372
      //      minHeight:
      //        ROW_HEIGHT * 10 + DATA_GRID_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT,
      letterSpacing: "normal",
      borderRadius: 0,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      "& .MuiDataGrid-main": {
        background: theme.palette.background.paper,
        borderRadius: "0px 0px 0px 0px",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.grey[200],
        filter:
          "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))",
        boxShadow: "none",
        // Note: doing this to remove the drop-shadow from the top of the table
        clipPath:
          "polygon(-50px 0,calc(100% + 50px) 0,calc(100% + 50px) calc(100% + 50px),-50px calc(100% + 50px))",
      },
      "& .MuiDataGrid-row": {
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        "&:hover": {
          background: theme.palette.grey[50],
        },
      },
      "& .MuiDataGrid-root": {
        padding: 10,
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-row[role='row']": {
        width: "100%",
      },
      "& .MuiDataGrid-row .MuiDataGrid-cell": {
        "&:not([data-colindex='0'])[role='cell'][data-rowindex][data-value]:hover":
          {
            backgroundColor: theme.palette.grey[100],
          },
        "&:not([data-value])[role='cell'][data-rowindex]": {
          display: "none",
          borderRight: "0px",
        },
        "&:not([data-value])[role='cell'][data-rowindex][data-colindex='1']": {
          display: "flex",
          flex: 1,
          maxWidth: "none!important",
        },
      },

      "& .MuiDataGrid-columnHeader": {
        padding: 0,
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          padding: theme.spacing(0, 0.3, 0, 0.2),
        },
        "&[data-field='0']": {
          padding: theme.spacing(0, 1, 0, 0.2),
        },
      },
      "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        borderRight: `1px solid ${"#f0f0f0"}`,
      },
      "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${"#f0f0f0"}`,
      },
      "& .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
        borderBottom: "none",
      },
      "& .MuiDataGrid-menuIcon": {
        visibility: "visible",
        transform: "rotate(-90deg)",
        "& button": {
          borderRadius: theme.shape.borderRadius,
          "&:hover": {
            backgroundColor: theme.palette.grey[200],
          },
        },
      },
      "& .MuiDataGrid-cell": {
        color: "rgba(0,0,0,.85)",
        borderRight: `1px solid ${"#f0f0f0"}`,
      },
      "& .MuiPaginationItem-root": {
        borderRadius: 0,
      },
      "& .MuiDataGrid-toolbarContainer": {
        backgroundColor: "#EBEFF3",
        padding: "9px 10px 17px 10px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.grey[300],
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        marginBottom: "-8px",
      },
    },
  };
}
