import {
  TagInput,
  ToggleIconButton,
  TrashSolid,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { createStyles, makeStyles, Tooltip } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { HideFromInsufficientRole } from "../../../../_common/components/HideFromInsufficientRole";
import { RulesAndThresholdsFormState } from "../../utils/types";
import { MicrosoftTeamsInstructions } from "./MicrosoftTeamsInstructions";
import { RoleCode } from "../../../../graphql";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(1),
      flex: 1,
      "& .TagInput-chip-error": {
        backgroundColor: theme.palette.red[600],
        color: theme.palette.background.paper,
        "&:focus": {
          backgroundColor: `${theme.palette.red[400]} !important`,
        },
      },
      "& .TagInput-chip-error:hover": {
        backgroundColor: theme.palette.red[400],
        color: theme.palette.background.paper,
      },
    },
    textFieldContainer: {
      display: "flex",
      maxWidth: 700,
      alignItems: "flex-start",
    },
    description: {
      maxWidth: 600,
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.grey[500],
      lineHeight: theme.typography.pxToRem(14),
    },
    divider: {
      marginTop: theme.spacing(2.75),
      marginBottom: theme.spacing(2.125),
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      width: "100%",
    },
  }),
);

export function MicrosoftTeamsNotificationPanel({
  onRemovePanel,
  disabled = false,
}: {
  onRemovePanel?: () => void;
  disabled?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const session = useSession();
  const { t } = useTranslation("msTeamsNotifications");

  const hasSufficientRole = session.hasSufficientRole(RoleCode.Editor);

  const { values, setFieldValue, setFieldTouched, errors } =
    useFormikContext<RulesAndThresholdsFormState>();

  const error = errors?.microsoftTeamsWebhooks
    ? Array.isArray(errors?.microsoftTeamsWebhooks)
      ? errors?.microsoftTeamsWebhooks.find(Boolean)
      : errors?.microsoftTeamsWebhooks
    : undefined;

  function handleRemoveChannel(): void {
    setFieldValue(`microsoftTeamsWebhooks`, []);
    onRemovePanel?.();
  }

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
      }}
    >
      <Typography variant="subtitle2SemiBold">{t("title")}</Typography>
      <Typography variant="caption" className={classes.description}>
        {t("description")}
      </Typography>
      <div className={classes.textFieldContainer}>
        <Field
          className={classes.root}
          style={{ maxWidth: 600 }}
          name={`microsoftTeamsWebhooks`}
          component={TagInput}
          value={values.microsoftTeamsWebhooks}
          errors={errors?.microsoftTeamsWebhooks}
          error={error}
          addOnSpace
          addOnComma
          disabled={!hasSufficientRole || disabled}
          variant="outlined"
          InputProps={{
            placeholder: t("textFieldPlaceholder"),
            autoFocus:
              !values.microsoftTeamsWebhooks ||
              values.microsoftTeamsWebhooks.length === 0,
          }}
          onChange={(value: string[]) => {
            setFieldTouched(`microsoftTeamsWebhooks`, true);
            setFieldValue(`microsoftTeamsWebhooks`, value);
          }}
          data-pendo="msteams-notification-channel-input"
        />
        {values?.microsoftTeamsWebhooks?.length && !disabled ? (
          <HideFromInsufficientRole>
            <Tooltip title="Remove" arrow={false}>
              <ToggleIconButton
                colorVariant="red"
                size="large"
                variant="outlined"
                onClick={handleRemoveChannel}
                data-pendo="msteams-notification-channel-delete-alert"
              >
                <TrashSolid />
              </ToggleIconButton>
            </Tooltip>
          </HideFromInsufficientRole>
        ) : undefined}
      </div>
      <div className={classes.divider} />
      <div style={{ maxWidth: 700 }}>
        <MicrosoftTeamsInstructions />
      </div>
    </div>
  );
}
