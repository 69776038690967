import { TFunction } from "i18next";
import { OrderDirection } from "../../../../../graphql";
import { TableSortingItem } from "./TableSortingPopup";
import { insertIf } from "@lumar/shared";

export const TABLE_SORTING_DETAILS_CODE = "table_details_code";

export function getTableDefaultSortingOrders(
  segmentsAvailable: boolean,
  t: TFunction<"sortingPopup">,
): { type: OrderDirection; title: string }[] {
  return [
    { type: OrderDirection.Asc, title: t("sortingPopup:numeric_asc") },
    { type: OrderDirection.Desc, title: t("sortingPopup:numeric_desc") },
  ];
}

export function getTableDefaultSortingList(
  segmentsAvailable: boolean,
  t: TFunction<"sortingPopup">,
): TableSortingItem[] {
  return [
    {
      code: TABLE_SORTING_DETAILS_CODE,
      title: t(`sortingPopup:${TABLE_SORTING_DETAILS_CODE}`),
      sortBy: [
        ...insertIf(segmentsAvailable, {
          code: "segmentName",
          order: [
            {
              type: OrderDirection.Asc,
              title: t("sortingPopup:string_asc"),
            },
            {
              type: OrderDirection.Desc,
              title: t("sortingPopup:string_desc"),
            },
          ],
          title: t("sortingPopup:segment_name"),
        }),
        {
          code: "projectName",
          order: [
            {
              type: OrderDirection.Asc,
              title: t("sortingPopup:string_asc"),
            },
            {
              type: OrderDirection.Desc,
              title: t("sortingPopup:string_desc"),
            },
          ],
          title: t("sortingPopup:project_name"),
        },
        {
          code: "projectFinishedAt",
          order: [
            {
              type: OrderDirection.Asc,
              title: t("sortingPopup:numeric_asc"),
            },
            {
              type: OrderDirection.Desc,
              title: t("sortingPopup:numeric_desc"),
            },
          ],
          title: t("sortingPopup:crawl_finished_date"),
        },
      ],
    },
  ];
}
