import { Alert } from "@lumar/shared";

export function NoSegmentReportAlert({
  showAlert,
  causeMessage,
  recomendationMessage,
}: {
  showAlert: boolean;
  causeMessage: string;
  recomendationMessage: string;
}): JSX.Element {
  return showAlert ? (
    <Alert severity="info">
      <strong>{causeMessage}</strong>
      &nbsp;
      {recomendationMessage}
    </Alert>
  ) : (
    <></>
  );
}
