import { useSnackbar } from "notistack";
import {
  GetHealthScoreNotificationsQueryVariables,
  TestResultStatus,
  useHealthScoreNotificationAcceptSuggestedThresholdMutation,
  useHealthScoreNotificationRejectSuggestedThresholdMutation,
  useUpdateHealthScoreNotificationStatusMutation,
  useUpdateReportRulesAndThresholdsMutation,
} from "../../graphql";
import { MonitorNotification } from "../types";
import { ApolloError, Snackbar, useTranslation } from "@lumar/shared";
import { getErrorMessage } from "../../_common/utils/getErrorMessage";
import { updateHealthScoreNotificationsInCache } from "./updateHealthScoreNotificationsInCache";

export function useHandleHealthScoreNotificationChanges(
  queryVariables?: GetHealthScoreNotificationsQueryVariables,
): {
  handleAcceptSuggestedThreshold: (
    notification: MonitorNotification,
    threshold: number,
  ) => Promise<void>;
  handleDeclineSuggestedThreshold: (
    notification: MonitorNotification,
  ) => Promise<void>;
  handleStatusUpdate: (
    notification: MonitorNotification,
    newStatus: TestResultStatus,
  ) => Promise<ApolloError | undefined>;
  loading: boolean;
} {
  const { t } = useTranslation([
    "common",
    "alerts",
    "notifications",
    "errors",
    "units",
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const [acceptSuggestedThreshold, { loading }] =
    useHealthScoreNotificationAcceptSuggestedThresholdMutation();

  const [rejectSuggestedThreshold] =
    useHealthScoreNotificationRejectSuggestedThresholdMutation();

  const [updateReportRulesAndThresholds] =
    useUpdateReportRulesAndThresholdsMutation({
      refetchQueries: [
        "GetHealthScoreNotifications",
        "HealthScoreNotificationTotalCounts",
        "HealthScoreNotificationsRequiresManualApprovalCount",
        "GetHealthScoreNotificationsCount",
      ],
      awaitRefetchQueries: true,
    });

  const [updateNotificationStatus] =
    useUpdateHealthScoreNotificationStatusMutation({
      refetchQueries: [
        "GetHealthScoreNotifications",
        "HealthScoreNotificationTotalCounts",
        "HealthScoreNotificationsRequiresManualApprovalCount",
        "GetHealthScoreNotificationsCount",
      ],
      awaitRefetchQueries: true,
    });

  async function handleStatusUpdate(
    notification: MonitorNotification,
    newStatus: TestResultStatus,
  ): Promise<ApolloError | undefined> {
    const statusToSet =
      status === newStatus ? TestResultStatus.Unresolved : newStatus;
    try {
      const ret = await updateNotificationStatus({
        variables: {
          testResultId: notification.id,
          status: statusToSet,
        },
      });
      if (ret.errors) {
        return new ApolloError({ graphQLErrors: ret.errors });
      }
      if (statusToSet === TestResultStatus.Unresolved) {
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("notifications:statusRemoved")}
          />,
        );
      } else {
        if (newStatus === TestResultStatus.Read) {
          enqueueSnackbar(
            <Snackbar
              variant="success"
              title={t("notifications:movedToArchived")}
            />,
          );
        } else if (newStatus === TestResultStatus.WorkingOnIt) {
          enqueueSnackbar(
            <Snackbar
              variant="success"
              title={t("notifications:movedToWorkingOnIt")}
            />,
          );
        }
      }
    } catch (error) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("failedToUpdateStatus", { message: getErrorMessage(error) })}
        />,
      );
      return new ApolloError({ errorMessage: "Unsuccessful" });
    }
  }

  async function handleAcceptSuggestedThreshold(
    notification: MonitorNotification,
    threshold: number,
  ): Promise<void> {
    const hasSuggestedThresholdBeenEdited =
      threshold !== notification?.suggestedAbsoluteThreshold;
    try {
      await acceptSuggestedThreshold({
        variables: {
          testResultIds: [notification.id],
        },
        refetchQueries: [
          "GetHealthScoreNotifications",
          "HealthScoreNotificationTotalCounts",
          "HealthScoreNotificationsRequiresManualApprovalCount",
        ],
        awaitRefetchQueries: true,
        update: (cache, { data, errors }) => {
          if (data && !errors && queryVariables)
            updateHealthScoreNotificationsInCache(
              cache,
              queryVariables,
              (n) => {
                if (n.id !== notification.id) return {};
                return {
                  suggestedAbsoluteThresholdAcceptedAt:
                    new Date().toISOString(),
                };
              },
            );
        },
      });

      if (hasSuggestedThresholdBeenEdited) {
        await updateReportRulesAndThresholds({
          variables: {
            rulesAndThresholds: {
              testId: notification.test?.id,
              absoluteThreshold: threshold,
            },
          },
        });
      }

      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t("notifications:ruleUpdatedSuccessfully")}
        />,
      );
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={`${t("errors:genericError")} ${getErrorMessage(e)}`}
        />,
      );
    }
  }

  async function handleDeclineSuggestedThreshold(
    notification: MonitorNotification,
  ): Promise<void> {
    try {
      await rejectSuggestedThreshold({
        variables: {
          testResultIds: [notification.id],
        },
        refetchQueries: [
          "GetHealthScoreNotifications",
          "HealthScoreNotificationTotalCounts",
          "HealthScoreNotificationsRequiresManualApprovalCount",
        ],
        awaitRefetchQueries: true,
        update: (cache, { data, errors }) => {
          if (data && !errors && queryVariables)
            updateHealthScoreNotificationsInCache(
              cache,
              queryVariables,
              (n) => {
                if (n.id !== notification.id) return {};
                return {
                  suggestedAbsoluteThresholdRejectedAt:
                    new Date().toISOString(),
                };
              },
            );
        },
      });
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t("notifications:ruleDeclinedSuccessfully")}
        />,
      );
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={`${t("errors:genericError")} ${getErrorMessage(e)}`}
        />,
      );
    }
  }

  return {
    handleAcceptSuggestedThreshold,
    handleDeclineSuggestedThreshold,
    handleStatusUpdate,
    loading,
  };
}
