import { ApolloError } from "@lumar/shared";
import {
  DatasourceCode,
  ModuleCode,
  ReportTemplateUnit,
  useGetProjectCustomExtractionsQuery,
  useReportTemplatesWithCategoriesQuery,
} from "../../graphql";
import { ReportTemplateConnectionFilterInput } from "../../graphql";
import { FullReportCategory, ReportOption } from "../utils/constants";
import { useReportCategories } from "./useReportCategories";
import { useMemo } from "react";
import { intersection } from "lodash";
import { useGetAccountModuleCodes } from "./useGetAccountModuleCodes";

const adminFilter: ReportTemplateConnectionFilterInput = {
  admin: { eq: false },
  code: { notContains: "custom_extraction_" },
};

export function useReportTemplateAccumulator({
  options,
  moduleCodes,
  projectId,
}: {
  options?: ReportOption[];
  moduleCodes?: ModuleCode[];
  projectId?: string;
}): {
  reports?: ReportOption[];
  categories?: FullReportCategory[];
  loading: boolean;
  error?: ApolloError;
} {
  const defaultModuleCodes = useGetAccountModuleCodes();
  const suportedModuleCodes = moduleCodes?.length
    ? moduleCodes
    : defaultModuleCodes;

  const [
    { loading: loadingCategories, error: categoriesError },
    getCategoryByCode,
    categories,
  ] = useReportCategories(true);

  const {
    loading: loadingProjectExtraction,
    error: errorLoadingProjectExtraction,
    data: projectExtractions,
  } = useGetProjectCustomExtractionsQuery({
    variables: {
      projectId: projectId,
    },
    skip: !Boolean(projectId),
  });

  const { loading, error, data } = useReportTemplatesWithCategoriesQuery({
    variables: {
      filter: adminFilter,
    },
    skip: Boolean(options),
    fetchPolicy: "cache-first",
  });

  const mainCategories = useMemo(
    () =>
      // eslint-disable-next-line fp/no-mutating-methods
      categories
        .filter((e) => !Boolean(e.parentCode))
        .sort((a, b) => (a.position ?? 0) - (b.position ?? 0)),
    [categories],
  );

  const reports = useMemo(
    () =>
      [
        ...mainCategories.reduce<ReportOption[]>((list, category) => {
          return [
            ...list,
            // eslint-disable-next-line fp/no-mutating-methods
            ...[
              ...(data?.reportTemplates.nodes
                .filter((e) => {
                  const cat = getCategoryByCode(
                    e.primaryReportCategoryCode ?? e.categories[0]?.code,
                  );
                  return (
                    Boolean(
                      intersection(e.supportedModules, suportedModuleCodes)
                        .length,
                    ) &&
                    (cat?.parentCode === category.code ||
                      cat?.code === category.code)
                  );
                })
                .map((e) => ({
                  id: e.code,
                  ...e,
                  category: getCategoryByCode(
                    e.primaryReportCategoryCode ?? e.categories[0]?.code,
                  ),
                  useableWithSegments: canReportTemplateBeUsedWithSegments(
                    e.datasourceCodeEnum,
                  ),
                  unit: e.metadata?.unit ?? ReportTemplateUnit.UrLs,
                  supportedModules: e.supportedModules,
                })) ?? []),
            ].sort(
              (a, b) =>
                (a.category?.position ?? 0) - (b.category?.position ?? 0),
            ),
          ];
        }, []),
        ...(projectExtractions?.project?.customExtractions ?? []).map((e) => {
          const reportTemplate = projectExtractions?.reportTemplates.nodes.find(
            (t) => t.code === e.reportTemplateCode,
          );
          return {
            id: e.reportTemplateCode,
            ...reportTemplate,
            code: e.reportTemplateCode,
            name: e.label,
            category: reportTemplate
              ? getCategoryByCode(
                  reportTemplate.primaryReportCategoryCode ??
                    reportTemplate.categories[0]?.code,
                )
              : undefined,
            useableWithSegments: canReportTemplateBeUsedWithSegments(
              reportTemplate?.datasourceCodeEnum ?? DatasourceCode.CrawlUrls,
            ),
            unit: reportTemplate?.metadata?.unit ?? ReportTemplateUnit.UrLs,
            supportedModules: reportTemplate?.supportedModules,
          };
        }),
      ].filter((e) => e.category),
    [
      data?.reportTemplates.nodes,
      getCategoryByCode,
      projectExtractions?.project?.customExtractions,
      projectExtractions?.reportTemplates.nodes,
      suportedModuleCodes,
      mainCategories,
    ],
  );

  return {
    loading: loading || loadingCategories || loadingProjectExtraction,
    error: error ?? categoriesError ?? errorLoadingProjectExtraction,
    categories: categories ?? [],
    reports,
  };
}

export function canReportTemplateBeUsedWithSegments(
  code: DatasourceCode,
): boolean {
  switch (code) {
    case DatasourceCode.CrawlUrls:
    case DatasourceCode.CrawlAccessibilityIssueInstances:
    case DatasourceCode.CrawlAccessibilityIssues:
      return true;
    default:
      return false;
  }
}
