import {
  OrderDirection,
  ProjectOrderField,
  TrendsComputedType,
  TrendsTableSortableField,
} from "../../../../../graphql";

function isFieldProjectOrderField(value: OldType | NewType): value is OldType {
  return Object.values(ProjectOrderField).includes(
    value.field as ProjectOrderField,
  );
}

type OldType = {
  field: ProjectOrderField;
  direction: OrderDirection;
};

type NewType = {
  field: TrendsTableSortableField;
  direction: OrderDirection;
  trend?: {
    code: string;
    type: TrendsComputedType;
  };
};

export function transformOldOrderBy(
  orderBy: OldType[] | NewType[] | null | undefined,
): NewType[] | null {
  if (orderBy) {
    return orderBy.map((e) => {
      if (isFieldProjectOrderField(e)) {
        if (e.field === ProjectOrderField.FinishedAt)
          return {
            field: TrendsTableSortableField.ProjectFinishedAt,
            direction: e.direction,
          };
        else {
          return {
            field: TrendsTableSortableField.ProjectName,
            direction: e.direction,
          };
        }
      }
      return e;
    });
  }
  return null;
}
