import { EmptyState, SmileyMeh, useTranslation } from "@lumar/shared";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";

import error from "../../../../_animations/error-animation.json";
import noresult from "../../../../_animations/noresult-animation.json";
import noprojects from "../../../../_animations/noproject-animation.json";
import { EmptyStateSettings } from "../../../dashboards/common/grid/EmptyStateManager";
import { useTheme } from "@material-ui/core";

export enum DSEmptyStateType {
  Error,
  NoViews,
  NoResult,
  NoBoardViews,
}

export interface DSEmptyStateManagerProps {
  isError?: boolean;
  isFiltered?: boolean;
  isEmpty?: boolean;
  isResultEmpty?: boolean;
  isParentEmpty?: boolean;
  onClick?: (type: DSEmptyStateType) => void;
}

export function DSEmptyStateManager(
  props: DSEmptyStateManagerProps,
): JSX.Element {
  const { isError, isFiltered, isEmpty, isParentEmpty, isResultEmpty } = props;
  const theme = useTheme();
  const { t } = useTranslation("dashboard");

  const type = (): DSEmptyStateType => {
    if (isError) return DSEmptyStateType.Error;
    if (isParentEmpty) return DSEmptyStateType.NoBoardViews;
    if (isFiltered && isResultEmpty && !isParentEmpty)
      return DSEmptyStateType.NoResult;
    if (isEmpty) return DSEmptyStateType.NoViews;
    return DSEmptyStateType.Error;
  };

  const options: EmptyStateSettings[] = [
    {
      type: DSEmptyStateType.Error,
      title: t("settings.emptyStates.error"),
      description: t("settings.emptyStates.errorLoadingViews"),
      icon: (
        <Lottie
          loop
          animationData={error}
          play
          style={{ width: "80px", margin: "auto" }}
        />
      ),
      action: {
        title: t("settings.emptyStates.errorButton"),
      },
    },
    {
      type: DSEmptyStateType.NoViews,
      title: null,
      description: t("settings.emptyStates.emptyStateDescription"),
      icon: (
        <SmileyMeh
          style={{
            color: theme.palette.yellow[500],
            fontSize: theme.typography.pxToRem(32),
          }}
        />
      ),
    },
    {
      type: DSEmptyStateType.NoResult,
      title: t("settings.emptyStates.noResults"),
      description: t("settings.emptyStates.noResultsDescription"),
      icon: (
        <Lottie
          loop
          animationData={noresult}
          play
          style={{ width: "80px", margin: "auto" }}
        />
      ),
      action: {
        title: t("settings.emptyStates.noResultsButton"),
        "data-pendo": "monitor-dashboard-no-filtered-results-go-to-analyze",
      },
    },
    {
      type: DSEmptyStateType.NoBoardViews,
      title: null,
      description: t("settings.emptyStates.emptyBoardStateDescription"),
      icon: (
        <Lottie
          loop
          animationData={noprojects}
          play
          style={{ width: "80px", margin: "auto" }}
        />
      ),
      action: {
        title: t("settings.emptyStates.noProjectsButton"),
        "data-pendo": "monitor-dashboard-no-view-go-to-board-settings",
      },
    },
  ];
  const current = options[type()];
  return (
    <EmptyState
      width="100%"
      height={"35vh"}
      title={current.title}
      description={current.description}
      icon={current.icon}
      actions={
        current.action
          ? [
              {
                type: "button",
                ...current.action,
                onClick: () => props.onClick?.(current.type),
              },
            ]
          : undefined
      }
    />
  );
}
