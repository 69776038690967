import { ReportTrendItem } from "../../graphql";

type WithReportTrendItemKeys = Record<keyof ReportTrendItem, unknown>;

function hasReportTrendItemKeys(
  value: unknown,
): value is WithReportTrendItemKeys {
  return (
    typeof value === "object" &&
    value !== null &&
    Boolean(value.hasOwnProperty("crawlId")) &&
    Boolean(value.hasOwnProperty("createdAt")) &&
    Boolean(value.hasOwnProperty("basic"))
  );
}

export function isReportTrendItem(value: unknown): value is ReportTrendItem {
  if (hasReportTrendItemKeys(value)) {
    return (
      typeof value.crawlId === "number" &&
      (typeof value.createdAt === "string" || value.createdAt === null) &&
      typeof value.basic === "number"
    );
  }
  return false;
}
