import { getRawCrawlId, getApiSegmentId } from "@lumar/shared";
import { TFunction } from "i18next";
import queryString, { UrlObject } from "query-string";
import { generatePath } from "react-router-dom";
import { assert } from "../assert";
import { removeTrailingSlash } from "./removeTrailingSlash";

const accountsDomain = process.env.REACT_APP_ACCOUNTS_APP_URL
  ? removeTrailingSlash(process.env.REACT_APP_ACCOUNTS_APP_URL)
  : "";

function createAccountsUrl(path: string, accountId: string): string {
  const object: UrlObject = {
    url: generatePath(path, { accountId }),
    query: {
      referral: window.location.href,
    },
  };

  return `${accountsDomain}${queryString.stringifyUrl(object)}`;
}

export const AbsoluteURLs = {
  EXTERNAL__AnalyzeLogin: {
    getUrl(): string {
      assert(process.env.REACT_APP_ANALYZE_APP_URL);
      const domain = removeTrailingSlash(process.env.REACT_APP_ANALYZE_APP_URL);

      return domain + "/login";
    },
  },
  EXTERNAL__AnalyzeProjectsList: {
    ROUTE: "/accounts/:accountId/projects",
    getUrl({ accountId }: { accountId: string }): string {
      assert(process.env.REACT_APP_ANALYZE_APP_URL);
      const domain = removeTrailingSlash(process.env.REACT_APP_ANALYZE_APP_URL);

      const path = generatePath(
        AbsoluteURLs.EXTERNAL__AnalyzeProjectsList.ROUTE,
        {
          accountId,
        },
      );

      return domain + path;
    },
  },
  EXTERNAL__AnalyzeReport: {
    ROUTE:
      "/accounts/:accountId/projects/:projectId/crawls/:crawlId/reports/:reportTemplateCodeWithTypeCode",
    getUrl({
      accountId,
      projectId,
      crawlId,
      reportTemplateCode,
      reportTypeCode,
      segmentId,
    }: {
      accountId: string;
      projectId: string;
      crawlId: string;
      reportTypeCode: string;
      reportTemplateCode: string;
      segmentId?: string;
    }): string {
      assert(process.env.REACT_APP_ANALYZE_APP_URL);
      const domain = removeTrailingSlash(process.env.REACT_APP_ANALYZE_APP_URL);

      const reportTemplateCodeWithTypeCode = [
        reportTemplateCode,
        reportTypeCode,
      ].join("_");

      const path = generatePath(AbsoluteURLs.EXTERNAL__AnalyzeReport.ROUTE, {
        accountId,
        projectId,
        crawlId: getRawCrawlId(crawlId),
        reportTemplateCodeWithTypeCode,
      });
      if (segmentId)
        return queryString.stringifyUrl({
          url: domain + path,
          query: { segmentId: getApiSegmentId(segmentId) },
        });
      return domain + path;
    },
  },
  EXTERNAL__AnalyzeHealthScore: {
    //https://analyze.dev.lumar.io/accounts/1555/projects/375097/crawls/1689837?category=overview&type=dashboard
    ROUTE: "/accounts/:accountId/projects/:projectId/crawls/:crawlId",
    getUrl({
      accountId,
      projectId,
      crawlId,
      reportCategoryCode,
      segmentId,
    }: {
      accountId: string;
      projectId: string;
      crawlId: string;
      reportCategoryCode: string;
      segmentId?: string;
    }): string {
      assert(process.env.REACT_APP_ANALYZE_APP_URL);
      const domain = removeTrailingSlash(process.env.REACT_APP_ANALYZE_APP_URL);

      const path = generatePath(
        AbsoluteURLs.EXTERNAL__AnalyzeHealthScore.ROUTE,
        {
          accountId,
          projectId,
          crawlId,
        },
      );

      return queryString.stringifyUrl({
        url: domain + path,
        query: {
          category: reportCategoryCode,
          type: "dashboard",
          segmentId: segmentId ? getApiSegmentId(segmentId) : undefined,
        },
      });
    },
  },
  EXTERNAL__AccountsUser: {
    ROUTE: "/accounts/:accountId/user",
    getUrl(accountId: string): string {
      return createAccountsUrl(
        AbsoluteURLs.EXTERNAL__AccountsUser.ROUTE,
        accountId,
      );
    },
  },
  EXTERNAL__AccountsConnectedApps: {
    ROUTE: "/accounts/:accountId/connected-apps",
    getUrl(accountId: string): string {
      return createAccountsUrl(
        AbsoluteURLs.EXTERNAL__AccountsConnectedApps.ROUTE,
        accountId,
      );
    },
  },
  EXTERNAL__AccountsApiAccess: {
    ROUTE: "/accounts/:accountId/api-access",
    getUrl(accountId: string): string {
      return createAccountsUrl(
        AbsoluteURLs.EXTERNAL__AccountsApiAccess.ROUTE,
        accountId,
      );
    },
  },
  EXTERNAL__AccountsAccount: {
    ROUTE: "/accounts/:accountId",
    getUrl(accountId: string): string {
      return createAccountsUrl(
        AbsoluteURLs.EXTERNAL__AccountsAccount.ROUTE,
        accountId,
      );
    },
  },
  EXTERNAL__AccountsTeam: {
    ROUTE: "/accounts/:accountId/team",
    getUrl(accountId: string): string {
      return createAccountsUrl(
        AbsoluteURLs.EXTERNAL__AccountsTeam.ROUTE,
        accountId,
      );
    },
  },
  EXTERNAL__AccountsSubscription: {
    ROUTE: "/accounts/:accountId/subscription",
    getUrl(accountId: string): string {
      return createAccountsUrl(
        AbsoluteURLs.EXTERNAL__AccountsSubscription.ROUTE,
        accountId,
      );
    },
  },
  EXTERNAL__AccountsCreditReports: {
    ROUTE: "/accounts/:accountId/credit-usage",
    getUrl(accountId: string): string {
      return createAccountsUrl(
        AbsoluteURLs.EXTERNAL__AccountsCreditReports.ROUTE,
        accountId,
      );
    },
  },
  EXTERNAL__ProductGuide: {
    ROUTE: "/product-guides/new-releases/lumar-monitor/",
    getUrl(
      t: TFunction<("notifications" | "common" | "navigation")[]>,
    ): string {
      return t("navigation:monitorProductGuideLink");
    },
  },
};
