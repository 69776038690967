import { ReportCategoryOption } from "../../../../../_common/utils/constants";
import { ClassNameMap } from "@material-ui/styles";
import { AutocompleteClassKey } from "@material-ui/lab";
import { ReportCategoriesCombobox } from "../../../../../_common/components/reports/ReportCategoriesCombobox";
import { useReportCategoriesTree } from "../../../../../_common/hooks/useReportCategoriesTree";
import { useReportTemplateAccumulator } from "../../../../../_common/hooks/useReportTemplateAccumulator";
import { useGetAccountModuleCodes } from "../../../../../_common/hooks/useGetAccountModuleCodes";

interface Props {
  value: ReportCategoryOption;
  onChange: (newCategory: ReportCategoryOption) => void;
  disabled?: boolean;
  className?: string;
  classes?: Partial<ClassNameMap<AutocompleteClassKey>>;
  "data-pendo"?: string;
  "data-testid"?: string;
  loading?: boolean;
  maxWidth?: number;
  variant?: "autocomplete" | "select";
  tooltip?: string;
}

export function HealthScoreCategoriesCombobox({
  loading,
  onChange,
  value,
  ...props
}: Props): JSX.Element {
  const moduleCodes = useGetAccountModuleCodes();

  const { reports, loading: loadingReports } = useReportTemplateAccumulator({
    moduleCodes,
  });

  const [{ loading: categoriesLoading }, { getCategoryList }] =
    useReportCategoriesTree(reports, true);

  const options = getCategoryList(2).map((e) => ({
    id: e.code,
    code: e.code,
    name: e.name,
    description: e.description,
    level: e.level,
  }));

  return (
    <ReportCategoriesCombobox
      {...props}
      disableClearable
      onChange={(newValue) => {
        if (newValue) onChange(newValue as ReportCategoryOption);
      }}
      value={value}
      loading={loading || categoriesLoading || loadingReports}
      options={options}
    />
  );
}
