import { ScheduleFrequencyCode } from "../../../../graphql";
import { View } from "../../types";

export interface GetViewListFilter {
  projectNameSearchTherm?: string;
  segmentNameSearchTherm?: string | null;
  frequency?: ScheduleFrequencyCode | null;
}

export function filterViewList(
  list: View[],
  filter?: GetViewListFilter,
  blackList?: View[],
): View[] {
  if (!filter) return list;
  const flist = list.filter((element) => {
    if (blackList?.length) {
      const find = blackList.filter(
        (e) =>
          e.projectId === element.projectId &&
          e.segmentId === element.segmentId,
      );
      if (find?.length) return false;
    }
    if (filter.projectNameSearchTherm?.length) {
      const term = filter.projectNameSearchTherm.toLowerCase();
      const pass =
        element.primaryDomain?.toLowerCase().includes(term) ||
        element.projectName.toLowerCase().includes(term);
      if (!pass) return false;
    }
    if (filter.segmentNameSearchTherm?.length) {
      const pass = element.segmentName
        ?.toLowerCase()
        .includes(filter.segmentNameSearchTherm.toLowerCase());
      if (!pass) return false;
    }
    if (filter.segmentNameSearchTherm === null && element.segmentId) {
      return false;
    }
    if (filter.frequency === null && element.scheduleFrequency) return false;
    if (
      filter.frequency &&
      filter.frequency !== element.scheduleFrequency?.code
    )
      return false;
    return true;
  });
  return flist;
}
