import { useRef, useState } from "react";
import {
  SvgIcon,
  makeStyles,
  Grid,
  Typography,
  useTheme,
  Tooltip,
} from "@material-ui/core";

import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { DashboardNameEditor } from "../../board/components/DashboardNameEditor";
import clsx from "clsx";
import { ListItemActionMenu } from "./ListItemActionMenu";
import { Link, useLocation } from "react-router-dom";
import { GalleryGridView, useLayoutApi } from "@lumar/shared";
import { useGetDashboardCollectionsCountQuery } from "../../graphql";
import { useGenericParams } from "../../_common/routing/useGenericParams";
import { assert } from "../../_common/assert";
import { useSortable } from "@dnd-kit/sortable";

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: 25,
    width: 35.21,
    height: 35.21,
    paddingRight: 10,
    paddingLeft: 6,
    position: "relative",
    color: "#4B5563",
  },
  listItemText: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  menuButtonActive: {
    marginLeft: 7,
  },
  menuButton: {
    backgroundColor: "white",
    marginLeft: 7,
  },
  buttonIcon: {
    marginBottom: 3,
  },
  root: {
    padding: 0,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
      color: "black",
      "& $listItemIcon": {
        color: `${theme.palette.grey[600]}!important`,
      },
      "& $actionButton": {
        display: "inline-flex",
      },
    },
    "&:focus": {
      boxShadow: "unset!important",
      border: 0,
    },
  },
  parentHighlight: {
    color: "black",
    backgroundColor: theme.palette.ultraviolet[200],
    "& $listItemIcon": { color: theme.palette.primary.main },
    "&:hover": {
      backgroundColor: theme.palette.ultraviolet[300],
      color: "black",
      "& $listItemIcon": {
        color: `${theme.palette.primary.main}!important`,
      },
    },
  },
  dashboardNameGrid: {
    flexWrap: "unset",
    width: "100%",
    height: 36,
    paddingRight: 6,
  },
  link: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    minWidth: 0,
    textDecoration: "none",
    color: "inherit",
  },
  actionButton: {
    display: "none",
  },
}));

interface Props {
  id: string;
  originalName: string;
  icon?: typeof SvgIcon;
  highlighted?: boolean;
  href: string;
  isSelected?: boolean;
  isEditingName?: boolean;
  setEditingName?: (editing: boolean) => void;
}

export function BoardListItem({
  isEditingName = false,
  setEditingName,
  ...props
}: Props): JSX.Element {
  const { accountId } = useGenericParams();
  assert(accountId);

  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();

  const [isHovered, setIsHovered] = useState(false);
  const { unblockSidebar } = useLayoutApi();
  const [isHoveringOverMenuButton, setIsHoveringOverMenuButton] =
    useState(false);
  const boardNameRef = useRef<HTMLSpanElement | null>(null);

  const isDashboardNameTruncated =
    boardNameRef.current &&
    boardNameRef.current.offsetWidth < boardNameRef.current.scrollWidth;

  const startEditing = (): void => {
    setEditingName?.(true);
  };

  const { data } = useGetDashboardCollectionsCountQuery({
    variables: { accountId },
  });
  const isLastDashboard =
    (data?.getAccount?.customDashboardCollections.totalCount ?? 0) <= 1;

  const { isDragging } = useSortable({ id: props.id });

  const insideElements = (
    <>
      {props.icon ? (
        <GalleryGridView
          className={classes.listItemIcon}
          style={
            props.highlighted || isHovered
              ? { color: theme.palette.ultraviolet[600] }
              : undefined
          }
        >
          <props.icon fontSize="small"></props.icon>
        </GalleryGridView>
      ) : null}
      <Typography
        variant="body2"
        className={classes.listItemText}
        ref={boardNameRef}
      >
        {props.originalName}
      </Typography>
    </>
  );

  return (
    <>
      {isEditingName ? (
        <DashboardNameEditor
          value={props.originalName}
          style={{ width: "100%" }}
          data-testid="edit-dashboard-item-name-input"
          pendoPrefix="edit-dashboard-item-name"
          refetchQueries={[
            "GetDashboardCollectionName",
            "GetDashboardCollections",
            "GetDashboardCollectionsCount",
          ]}
          onFinishedEditing={() => {
            setEditingName?.(false);
            unblockSidebar();
          }}
          collectionId={props.id}
          variant="small"
        />
      ) : (
        <Tooltip
          placement="right-end"
          arrow={false}
          title={isDashboardNameTruncated ? props.originalName : ""}
          open={isHovered && !isHoveringOverMenuButton}
        >
          <div
            draggable={false}
            className={clsx(
              classes.root,
              props.highlighted && classes.parentHighlight,
            )}
            style={{ width: "100%" }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              className={classes.dashboardNameGrid}
              draggable={false}
            >
              {isDragging ? (
                <div className={classes.link}>{insideElements}</div>
              ) : (
                <>
                  <Link
                    style={{ marginLeft: 2 }}
                    to={props.isSelected ? pathname : props.href}
                    className={classes.link}
                  >
                    {insideElements}
                  </Link>
                  <HideFromInsufficientRole>
                    <ListItemActionMenu
                      className={classes.actionButton}
                      collectionId={props.id}
                      showMenuButtonTooltip={isHoveringOverMenuButton}
                      onRenameButtonClick={startEditing}
                      onMouseEnter={() => setIsHoveringOverMenuButton(true)}
                      onMouseLeave={(close) => {
                        if (close) setIsHovered(false);
                        setIsHoveringOverMenuButton(false);
                      }}
                      disableDelete={isLastDashboard}
                    />
                  </HideFromInsufficientRole>
                </>
              )}
            </Grid>
          </div>
        </Tooltip>
      )}
    </>
  );
}
