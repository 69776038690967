import {
  isToday,
  isYesterday,
  differenceInDays,
  differenceInWeeks,
} from "date-fns";
import { MonitorNotification } from "../types";

export function getNotificationCategoryList(
  notifications: MonitorNotification[],
): { category: string; notifications: MonitorNotification[] }[] {
  // Note: using a map instead of an object because we need to preserve key entry order
  const map = new Map<string, MonitorNotification[]>();

  notifications.forEach((notification) => {
    const timeStamp = notification.createdAt;

    const dateCategory = determineDateCategory(timeStamp);

    if (map.has(dateCategory)) {
      const existingValuesInCategory = map.get(dateCategory) ?? [];
      map.set(dateCategory, [...existingValuesInCategory, notification]);
    } else {
      map.set(dateCategory, [notification]);
    }
  });

  return Array.from(map, ([category, notifications]) => ({
    category,
    notifications,
  }));
}

function determineDateCategory(timestamp: string | null): string {
  if (!timestamp) {
    return "Unknown";
  }

  const date = new Date(timestamp);

  if (isToday(date)) {
    return "Today";
  }
  if (isYesterday(date)) {
    return "Yesterday";
  }

  const now = new Date();
  const numberOfDaysAgo = differenceInDays(now, date);

  if (numberOfDaysAgo < 7) {
    return `${numberOfDaysAgo} day${numberOfDaysAgo > 1 ? "s" : ""} ago`;
  }

  const numberOfWeeksAgo = differenceInWeeks(now, date);

  if (numberOfWeeksAgo <= 4) {
    return `${numberOfWeeksAgo} week${numberOfWeeksAgo > 1 ? "s" : ""} ago`;
  }

  return "Over 1 month ago";
}
