import {
  Button,
  Checkbox,
  Typography,
  useNumberFormatter,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, useTheme, Chip } from "@material-ui/core";
import { Severity, TestResultStatus } from "../../graphql";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import {
  allNotificationSelection,
  emptyNotificationSelection,
  NotificationSelectionType,
} from "../utils/constants";
import { notificationStatusToTestResultStatus } from "../utils/notificationStatusToTestResultStatus";
import { NotificationsSelectMenu } from "./NotificationsSelectMenu";
import { isEqual, sortBy, isEmpty } from "lodash";
import { MonitorNotification, NotificationSelection } from "../types";
import { NotificationStatusFilter } from "../filters/constants";
import { SuggestedThresholdFilter } from "../filters/SuggestedThresholdTabs";
import { BulkUpdateStatusMenu } from "./BulkUpdateStatusMenu";
import {
  BulkUpdateThresholdMenu,
  ThresholdStatus,
} from "./BulkUpdateThresholdMenu";

const CHECKBOX_SIZE = 24;

export interface StatusUpdateProps {
  totalCount: number;
  notifications?: MonitorNotification[];
  loading?: boolean;
  severity: Severity | null;
  status: NotificationStatusFilter;
  hasFiltersApplied: boolean;
  selection: NotificationSelection;
  setSelection: (selection: NotificationSelection) => void;
  suggestedThresholdFilter: SuggestedThresholdFilter;
  showUpdateThresholdMenu?: boolean;
  handleStatusChange: (status: TestResultStatus) => void;
  handleAcceptanceChange: (status: ThresholdStatus) => void;
  handleRemoveAllStatuses: () => Promise<void>;
}

export function NotificationStatusUpdate(
  props: StatusUpdateProps,
): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(["alerts", "notifications", "errors"]);
  const classes = useStyles();
  const formatNumber = useNumberFormatter();

  const areAllNotificationsSelected =
    props.selection.state === NotificationSelectionType.SelectAll;

  const areAnyNotificationsSelected =
    Boolean(props.selection.selected.length) || areAllNotificationsSelected;

  const isAdjustmentsTabSelected =
    props.status === NotificationStatusFilter.Adjustments;

  const requiresManualApproval =
    props.suggestedThresholdFilter === "requiresApproval";

  const { count, text: selectionText } = useFilterAndSelectionText({
    isLoading: props.loading,
    hasFiltersApplied: props.hasFiltersApplied,
    notificationSelection: props.selection,
    notifications: props.notifications,
    requiresManualApproval,
    totalCount: props.totalCount,
  });

  const formattedCount = formatNumber(count);

  const areSomeNotificationsSelected = !!props.selection.selected.length;
  const isChecked = areSomeNotificationsSelected || areAllNotificationsSelected;

  return (
    <HideFromInsufficientRole>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0px 11px 0px 8px",
          background: isChecked ? theme.palette.grey[200] : "inherit",
          backdropFilter: isChecked ? "blur(4px)" : "none",
          borderRadius: 8,
          height: 48,
        }}
      >
        <Checkbox
          className={classes.checkbox}
          checked={isChecked}
          indeterminate={areSomeNotificationsSelected}
          onChange={(_, checked) => {
            if (checked === true) props.setSelection(allNotificationSelection);
            else props.setSelection(emptyNotificationSelection);
          }}
          data-testid="select-all-notifications"
          inputProps={{
            "aria-label": t("notifications:selectAll", {
              count: props.totalCount,
            }),
          }}
        />
        <NotificationsSelectMenu
          totalCount={props.totalCount}
          visibleCount={props.notifications?.length ?? 0}
          onChange={(e) => {
            if (e === NotificationSelectionType.SelectVisible)
              props.setSelection({
                state: NotificationSelectionType.SelectVisible,
                selected: props.notifications ?? [],
              });
            if (e === NotificationSelectionType.SelectAll)
              props.setSelection({
                state: NotificationSelectionType.SelectAll,
                selected: [],
              });
          }}
        />
        <Typography
          variant="button"
          style={{
            color: theme.palette.grey[500],
            marginLeft: theme.spacing(1.875),
            marginRight: theme.spacing(1.125),
            alignSelf: "center",
          }}
        >
          {selectionText}
        </Typography>
        {!props.loading ? (
          <Chip
            label={count ? formattedCount : "None"}
            className={classes.chip}
          />
        ) : null}
        {areAnyNotificationsSelected && !isAdjustmentsTabSelected ? (
          <>
            <div
              className={classes.separatorSmall}
              style={{
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
              }}
            />
            <BulkUpdateStatusMenu
              initialStatus={notificationStatusToTestResultStatus(props.status)}
              onChange={props.handleStatusChange}
            />
            {props.showUpdateThresholdMenu ? (
              <>
                <div
                  className={classes.separatorSmall}
                  style={{
                    marginLeft: theme.spacing(1),
                    marginRight: theme.spacing(1),
                  }}
                />
                <BulkUpdateThresholdMenu
                  onChange={props.handleAcceptanceChange}
                />
              </>
            ) : undefined}
            {isAdjustmentsTabSelected ? (
              <>
                <Button
                  variant="text"
                  className={classes.button}
                  data-pendo={`notifications-page-remove-all-statuses`}
                  style={{ marginLeft: theme.spacing(2.25) }}
                  onClick={props.handleRemoveAllStatuses}
                >
                  {t("notifications:removeAll")}
                </Button>
              </>
            ) : undefined}
          </>
        ) : areAnyNotificationsSelected && props.showUpdateThresholdMenu ? (
          <>
            <div
              className={classes.separatorSmall}
              style={{
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
              }}
            />
            <BulkUpdateThresholdMenu onChange={props.handleAcceptanceChange} />
          </>
        ) : undefined}
      </div>
    </HideFromInsufficientRole>
  );
}

interface FilterAndSelectionTextArgs {
  isLoading?: boolean;
  notificationSelection: NotificationSelection;
  requiresManualApproval: boolean;
  hasFiltersApplied: boolean;
  totalCount: number;
  notifications?: MonitorNotification[];
}

export const useFilterAndSelectionText = ({
  isLoading,
  notificationSelection,
  requiresManualApproval,
  hasFiltersApplied,
  notifications,
  totalCount,
}: FilterAndSelectionTextArgs): { count: number; text: string } => {
  const { t } = useTranslation(["alerts", "notifications"]);

  if (isLoading) return { count: 0, text: "" };

  if (
    notificationSelection.state === NotificationSelectionType.SelectAll &&
    isEmpty(notificationSelection.selected)
  ) {
    const requiresManualApprovalText = hasFiltersApplied
      ? "Selected that require manual approval with applied filters:"
      : "Selected that require manual approval";

    const text = requiresManualApproval
      ? requiresManualApprovalText
      : hasFiltersApplied
        ? t("notifications:selectedAllWF")
        : t("notifications:selectedAll");

    return {
      count: totalCount,
      text,
    };
  }
  if (areAllVisibleSelected(notificationSelection, notifications)) {
    const requiresManualApprovalText = hasFiltersApplied
      ? "Selected that require manual approval with applied filters:"
      : "Selected that require manual approval";

    return {
      count: notificationSelection.selected.length,
      text: requiresManualApproval
        ? requiresManualApprovalText
        : t("notifications:selectedVisible"),
    };
  }
  if (
    notificationSelection.selected.length &&
    notificationSelection.state !== NotificationSelectionType.SelectAll
  ) {
    const requiresManualApprovalText = hasFiltersApplied
      ? "Selected that require manual approval with applied filters:"
      : "Selected that require manual approval";

    return {
      count: notificationSelection.selected.length,
      text: requiresManualApproval
        ? requiresManualApprovalText
        : hasFiltersApplied
          ? t("notifications:selectedWF")
          : t("notifications:selected"),
    };
  } else if (
    notificationSelection.state === NotificationSelectionType.SelectAll
  ) {
    return {
      count: totalCount - notificationSelection.selected.length,
      text: hasFiltersApplied
        ? t("notifications:selectedWF")
        : t("notifications:selected"),
    };
  }
  const requiresManualApprovalText = hasFiltersApplied
    ? "Require manual approval with applied filters:"
    : "Requires manual approval:";

  return {
    count: totalCount,
    text: requiresManualApproval
      ? requiresManualApprovalText
      : hasFiltersApplied
        ? t("notifications:notificationsWithFilters")
        : t("notifications:total"),
  };
};

const areAllVisibleSelected = (
  notificationSelection: NotificationSelection,
  notifications?: MonitorNotification[],
): boolean => {
  if (
    notificationSelection.state === NotificationSelectionType.SelectAll ||
    !notificationSelection.selected.length
  )
    return false;
  return isEqual(sortBy(notificationSelection.selected), sortBy(notifications));
};

const useStyles = makeStyles((theme) => ({
  checkbox: {
    width: CHECKBOX_SIZE,
    height: CHECKBOX_SIZE,
    "& .MuiIconButton-label span": {
      "&:before": {
        width: CHECKBOX_SIZE,
        height: CHECKBOX_SIZE,
      },
      width: CHECKBOX_SIZE,
      height: CHECKBOX_SIZE,
    },
    "&[class*=MuiCheckbox-indeterminate] svg": {
      maxHeight: CHECKBOX_SIZE,
      maxWidth: CHECKBOX_SIZE,
    },
  },
  chip: {
    height: 18,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    alignSelf: "center",
    "& .MuiChip-label": {
      paddingLeft: 9,
      paddingRight: 9,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(13),
    },
  },
  separatorSmall: {
    height: 20,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    marginTop: 5,
    marginBottom: 5,
  },
  button: {
    background: "transparent",
    border: 0,
    boxShadow: "none",
    color: theme.palette.primary.main,
    "&:hover": {
      background: "transparent!important",
    },
  },
}));
