import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import {
  InputLabel,
  ListSubheader,
  makeStyles,
  Select,
} from "@material-ui/core";
import {
  CheckCircleSolid,
  ClockSolid,
  CheckSolid,
  useTranslation,
  useSession,
} from "@lumar/shared";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { TestResultStatus } from "../../graphql";
import { RoleCode } from "../../graphql";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    // Note: these styles that ensure that the input label is visually hidden, but not hidden from screen readers (for accessibility reasons)
    border: 0,
    clip: "rect(1px, 1px, 1px, 1px)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    width: 1,
  },
  menuTitle: {
    color: theme.palette.grey[600],
    height: "35px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
  },
  icon: { height: 20, width: 20, marginRight: 12 },
  iconSmall: { height: 16, width: 16, marginRight: 8 },
  orangeIcon: { color: theme.palette.orange[600] },
  greenIcon: { color: theme.palette.green[600] },
  checkIcon: {
    marginLeft: "12px",
    height: 16,
    width: 16,
    color: theme.palette.primary.main,
  },
}));

interface Props {
  initialStatus: TestResultStatus | null | undefined;
  onChange: (status: TestResultStatus) => void;
}

export const BulkUpdateStatusMenu = ({
  initialStatus,
  onChange,
}: Props): JSX.Element => {
  const { t } = useTranslation("notifications");
  const session = useSession();
  const classes = useStyles();
  const hasEditRights = session.hasSufficientRole(RoleCode.Editor);

  const [status, setStatus] = useState<TestResultStatus>(
    initialStatus ?? TestResultStatus.Unresolved,
  );

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>): void => {
    const newStatus = e.target.value as TestResultStatus;
    setStatus(newStatus);
    onChange(newStatus);
  };

  const isWorkingOnItSelected = status === TestResultStatus.WorkingOnIt;
  const isArchivedSelected = status === TestResultStatus.Read;

  return (
    <>
      <InputLabel className={classes.inputLabel} id="label">
        {t("bulkUpdateStatus")}
      </InputLabel>
      <Select
        labelId="label"
        id="select"
        value={TestResultStatus.Done}
        variant="outlined"
        style={{ width: 192, height: 32, overflow: "hidden" }}
        disabled={!hasEditRights}
        IconComponent={ExpandMoreRoundedIcon}
        data-testid="bulk-update-status-menu"
        data-pendo="bulk-update-status-menu"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        onChange={handleChange}
        renderValue={(value) => {
          if (value === TestResultStatus.WorkingOnIt) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <ClockSolid
                  className={clsx(classes.iconSmall, classes.orangeIcon)}
                />
                {t("working")}
              </div>
            );
          }

          if (value === TestResultStatus.Read) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <CheckCircleSolid
                  className={clsx(classes.iconSmall, classes.greenIcon)}
                />
                {t("archive")}
              </div>
            );
          }

          return <>{t("statuses")}</>;
        }}
      >
        <ListSubheader
          className={classes.menuTitle}
          // Note: this stops the menu from closing if the subheader is clicked
          onClickCapture={(e) => e.stopPropagation()}
        >
          {t("markas")}
        </ListSubheader>
        <MenuItem
          value={TestResultStatus.WorkingOnIt}
          data-testid="working-on-it-menu-item"
          data-pendo="working-on-it-menu-item"
        >
          <ClockSolid className={clsx(classes.icon, classes.orangeIcon)} />
          {t("working")}
          {isWorkingOnItSelected ? (
            <CheckSolid className={classes.checkIcon} />
          ) : null}
        </MenuItem>
        <MenuItem
          value={TestResultStatus.Read}
          data-testid="archive-menu-item"
          data-pendo="archive-menu-item"
        >
          <CheckCircleSolid className={clsx(classes.icon, classes.greenIcon)} />
          {t("archive")}
          {isArchivedSelected ? (
            <CheckSolid
              className={classes.checkIcon}
              style={{ marginLeft: "auto" }}
            />
          ) : null}
        </MenuItem>
        <MenuItem value={TestResultStatus.Done} style={{ display: "none" }} />
        {initialStatus !== TestResultStatus.Unresolved ? (
          <MenuItem
            value={TestResultStatus.Unresolved}
            data-testid="remove-all-statuses-menu-item"
            data-pendo="remove-all-statuses-menu-item"
          >
            {t("removeAll")}
          </MenuItem>
        ) : null}
      </Select>
    </>
  );
};
