import {
  useCreateEmailAlertMutation,
  useDeleteEmailAlertMutation,
} from "../../../graphql";
import {
  Action,
  mapAlertNotificationToAction,
} from "./mapAlertNotificationToAction";
import { EmailAlert } from "./types";

type MutationFunction = (
  initialValues: EmailAlert[],
  newValues: string[],
  projectId: string,
) => Promise<unknown>;

export function useSaveEmailAlertsMutation(): [
  MutationFunction,
  { loading: boolean },
] {
  const [createEmailAlert, { loading: createLoading }] =
    useCreateEmailAlertMutation();
  const [deleteEmailAlert, { loading: deleteLoading }] =
    useDeleteEmailAlertMutation();

  const isLoading = createLoading || deleteLoading;

  function saveEmailAlerts(
    initialValues: EmailAlert[],
    newValues: string[],
    projectId: string,
  ): Promise<unknown> {
    const emailAlerts = mapAlertNotificationToAction<EmailAlert>(
      initialValues,
      newValues,
      "email",
    );

    const promiseArray: Promise<unknown>[] = emailAlerts.map(
      ({ email, id, action }) => {
        if (action === Action.Create) {
          return createEmailAlert({
            variables: {
              projectId,
              email,
            },
            context: {
              includeInBatch: true,
            },
          });
        }

        return deleteEmailAlert({
          variables: {
            id,
          },
          context: {
            includeInBatch: true,
          },
        });
      },
    );

    return Promise.all(promiseArray);
  }

  return [saveEmailAlerts, { loading: isLoading }];
}
