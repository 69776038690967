import React from "react";
import { PencilSolid, Chip, useSession, useTranslation } from "@lumar/shared";
import {
  ReportTemplateUnit,
  RoleCode,
  ThresholdPredicate,
} from "../../graphql";
import { Tooltip } from "@material-ui/core";
import { NotificationUnit } from "../types";

interface Props {
  thresholdPredicate: ThresholdPredicate;
  absoluteThreshold: number;
  onClick: () => void;
  doesRuleStillExist: boolean;
  unit: NotificationUnit;
  disabled?: boolean;
}

export function EditRuleChip({
  thresholdPredicate,
  absoluteThreshold,
  onClick,
  doesRuleStillExist,
  unit,
  disabled,
}: Props): JSX.Element {
  const session = useSession();
  const { t } = useTranslation(["common", "alerts"]);

  const hasEditorRights = session.hasSufficientRole(RoleCode.Editor);

  const isClickable = hasEditorRights && doesRuleStillExist && !disabled;

  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    onClick();
  };

  return (
    <Tooltip
      title={
        isClickable
          ? (t("alerts:notificationsPage.editRule") as string)
          : (t("alerts:notificationsPage.cannotEditRuleAndThreshold") as string)
      }
      arrow={false}
    >
      <Chip
        label={t("alerts:notificationsPage.thresholdRule", {
          symbol:
            thresholdPredicate === ThresholdPredicate.GreaterThanOrEqual
              ? "≥"
              : "<",
          count: absoluteThreshold,
          unit: t(`units:${unit ?? ReportTemplateUnit.UrLs}`, {
            count: absoluteThreshold,
          }),
        })}
        data-pendo="notifications-page-edit-rule-and-threshold-chip"
        data-testid={
          doesRuleStillExist
            ? "edit-rule-and-threshold-chip"
            : "edit-rule-and-threshold-deleted"
        }
        color="primary"
        onClick={isClickable ? handleClick : undefined}
        deleteIcon={
          isClickable ? <PencilSolid data-testid="edit-rule-chip" /> : undefined
        }
        onDelete={isClickable ? handleClick : undefined}
      />
    </Tooltip>
  );
}
