import { Button, PlusSolid, useTranslation } from "@lumar/shared";
import { ListItem, makeStyles, PropTypes, useTheme } from "@material-ui/core";
import { RenderView } from "../../components/RenderView";
import { View } from "../../types";
import clsx from "clsx";
import { InView } from "react-intersection-observer";
import { GetViewListFilter } from "../utils/filterViewList";
import { useState } from "react";
import { AccountViewsTooltip } from "./AccountViewsTooltip";

const LIST_ITEM_HEIGHT = 51;

interface Props {
  view: View;
  searchTerms?: GetViewListFilter;
  onChange?: (view: View) => void;
  button?: {
    icon?: React.ReactNode | null;
    text?: React.ReactNode;
    label?: string;
    color?: PropTypes.Color;
    variant?: "text" | "outlined" | "contained";
    className?: string;
    disabled?: boolean;
  };
  index: number;
  "data-pendo"?: string;
  "data-testid"?: string;
  disabled?: boolean;
}

export function BoardSettingsListItem({
  view,
  searchTerms,
  button,
  onChange,
  disabled,
  index,
  ...props
}: Props): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation(["board"]);

  const [inView, setInView] = useState<boolean>(false);
  return (
    <InView as="div" onChange={(inView) => inView && setInView(inView)}>
      <ListItem
        alignItems="flex-start"
        data-testid={
          props["data-testid"]
            ? `${props["data-testid"]}-item-${index}`
            : undefined
        }
        disableGutters
        style={{ paddingRight: theme.spacing(1.5) }}
        className={classes.listItem}
      >
        {inView ? (
          <div className={classes.item}>
            <RenderView
              view={view}
              searchTerms={searchTerms}
              classes={{ root: classes.flex }}
            />
            <AccountViewsTooltip
              arrow={false}
              show={Boolean(disabled) ? "limit" : null}
            >
              <div>
                <Button
                  disabled={button?.disabled || disabled}
                  color={button?.color ?? "secondary"}
                  variant={button?.variant ?? "contained"}
                  className={clsx(classes.button, button?.className)}
                  startIcon={
                    button?.icon ??
                    (button?.icon === null ? undefined : <PlusSolid />)
                  }
                  aria-label={button?.label}
                  onClick={() => onChange?.(view)}
                  data-pendo={
                    props["data-pendo"]
                      ? `${props["data-pendo"]}-item`
                      : undefined
                  }
                  data-testid={
                    props["data-testid"]
                      ? `${props["data-testid"]}-item`
                      : undefined
                  }
                >
                  {button?.text ?? t("add")}
                </Button>
              </div>
            </AccountViewsTooltip>
          </div>
        ) : undefined}
      </ListItem>
    </InView>
  );
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: LIST_ITEM_HEIGHT,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  item: {
    flex: 1,
    display: "flex",
    flexFlow: "row",
    width: "100%",
  },
  flex: {
    flex: 1,
  },
  button: {
    paddingTop: 6.5,
    paddingBottom: 6.5,
    height: 32,
  },
}));
