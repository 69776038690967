import { EmptyState, useTranslation } from "@lumar/shared";

import { useGenericParams } from "../../../../_common/routing/useGenericParams";
import { assert } from "../../../../_common/assert";

export function ProjectComparisonChartNoViewsState(props: {
  className: string;
}): JSX.Element {
  const { t } = useTranslation(["errors", "projectComparisonChart"]);
  const { accountId } = useGenericParams();
  assert(accountId);

  return (
    <EmptyState
      title={t("projectComparisonChart:noSelectedViews")}
      description={t("projectComparisonChart:noSelectedViewsDescription")}
      className={props.className}
    />
  );
}
