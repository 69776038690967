import { ReactNode } from "react";
import {
  AppLayout,
  getRawAccountId,
  useAccountGuard,
  useSession,
} from "@lumar/shared";
import { SidebarContent } from "../navigation/sidenav/SidebarContent";
import { Redirect } from "react-router-dom";
import { useMonitorRoutes } from "../_common/routing/useMonitorRoutes";
import { useHasMonitorApp } from "../_common/hooks/useHasMonitorApp";
import { makeStyles } from "@material-ui/core";

export const WRAPPER_SPACING = 2.5;

const useStyles = makeStyles(() => ({
  whiteBackground: {
    background: "white",
    padding: 0,
  },
}));

export function Wrapper({
  children,
  hideSidebar,
  whiteBackground,
}: {
  children: ReactNode;
  hideSidebar?: boolean;
  whiteBackground?: boolean;
}): JSX.Element {
  useAccountGuard();
  const classes = useStyles();
  const { marketingPage } = useMonitorRoutes();

  const session = useSession();

  const hasMonitor = useHasMonitorApp();

  if (!hasMonitor) {
    return (
      <Redirect
        to={marketingPage.getUrl({
          accountId: getRawAccountId(session.account.id),
        })}
      />
    );
  }

  return (
    <AppLayout
      app="monitor"
      pendoPrefix="monitor"
      sidebarContentComponent={hideSidebar ? undefined : SidebarContent}
      classes={whiteBackground ? { main: classes.whiteBackground } : undefined}
    >
      {children}
    </AppLayout>
  );
}
