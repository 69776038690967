import React, { useState } from "react";
import { createStyles, makeStyles, Tooltip, useTheme } from "@material-ui/core";
import {
  useTranslation,
  useNumberFormatter,
  PercentageChangeChip,
} from "@lumar/shared";
import noTrend from "../../../../../_common/images/no-trend-available.svg";
import {
  DataGridReportValue,
  DataGridTrendValue,
} from "../../../account-overview/types";
import { AnalyticsHubHealthScoreLink } from "../../../account-overview/AnalyticsHubHealthScoreLink";
import { isEqual } from "lodash";
import { InView } from "react-intersection-observer";
import { SparklinesMiniChart } from "../../../../../_common/components/SparklinesMiniChart/SparklinesMiniChart";

const CHART_HEIGHT = 40;

const useStyles = makeStyles((theme) =>
  createStyles({
    cellAnchor: {
      textDecoration: "none",
      width: "100%",
      height: "100%",
    },
    percentageChipLabel: {
      color: theme.palette.grey[600],
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
    },
    tooltip: {
      marginTop: -16,
    },
  }),
);

function getChange(trend?: DataGridTrendValue[]): number {
  if (trend && trend.length > 1) {
    return trend[0].count - trend[1].count;
  }
  return 0;
}

interface HealthScoreTableCellProps {
  accountId: string;
  projectId: string;
  segmentId?: string;
  crawlId: string;
  category: {
    code: string;
    name?: string;
  };
  columnId: string;
  report?: DataGridReportValue;
  width?: number | null;
  isOverview?: boolean;
}

/**
 * NOTE: we are trying to avoid using Material-UI's `useStyle` and `useTheme` hooks as much as possible in this component,
 * as well as avoid using MUI's `Typography` and `Box` component. This is because we are experiencing slow rendering of the table,
 * and this is made worse by CSS-in-JS (which is used in Material-UI). Another way we could potentially improve performance even more is to move
 * all the styles to a separate CSS file.
 */

export const HealthScoreTableCell = React.memo(
  function HealthScoreCategoryTableCell({
    accountId,
    projectId,
    segmentId,
    crawlId,
    category,
    report,
    width,
    isOverview = false,
  }: HealthScoreTableCellProps): JSX.Element {
    const { t, i18n } = useTranslation(["healthscore", "units", "common"]);
    const formatNumber = useNumberFormatter();
    const classes = useStyles();
    const theme = useTheme();

    const absoluteChange = getChange(report?.trends);
    //  const sign = Math.sign(absoluteChange) * (report?.sign ?? 0);
    const total = report?.total ?? 0;
    const chartData = React.useMemo(
      () =>
        report?.trends?.map((trend): [number, number] => [
          trend.date.getTime(),
          trend.count,
        ]) ?? [],
      [report],
    );

    const hasUrlCountChanged = absoluteChange !== 0;
    const hasNoChangeAndNoUrls = !hasUrlCountChanged && total === 0;
    const hasTrendData = Boolean(chartData && chartData?.length > 1);

    const [inViewStatus, setInViewStatus] = useState<{
      rendered: boolean;
      visible: boolean;
    }>({ rendered: false, visible: false });
    const onViewChange = (inView: boolean): void => {
      if (inView === true) {
        setInViewStatus({ rendered: true, visible: true });
      } else {
        setInViewStatus({ rendered: inViewStatus.rendered, visible: inView });
      }
    };
    if (!Boolean(report)) return <></>;

    const title = t(`common:${isOverview ? "goToOverview" : "goToCategory"}`, {
      category: category.name,
    }) as string;

    return (
      <AnalyticsHubHealthScoreLink
        accountId={accountId}
        projectId={projectId}
        segmentId={segmentId}
        crawlId={crawlId}
        reportCategoryCode={category.code}
        className={classes.cellAnchor}
        data-testid={`health-score-grid-${category.code}-column-cell`}
        data-pendo="monitor-dashboard-report-category-link"
      >
        {/* FIXME: Should not have to cast, should not be string | undefined. Investigate i18n types */}
        <Tooltip
          title={title}
          arrow={false}
          classes={{ popper: classes.tooltip }}
          style={{ height: "100%" }}
        >
          <InView onChange={onViewChange}>
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                height: "100%",
                lineHeight: 1.43,
                marginRight: 3,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    minHeight: CHART_HEIGHT,
                    width: "100%",
                    lineHeight: 1.43,
                    padding: "8px 0px",
                    flex: 1,
                    alignItems: "center",
                    display: "flex",
                    visibility: inViewStatus.visible ? "visible" : "hidden",
                  }}
                >
                  {inViewStatus.rendered &&
                    (hasTrendData ? (
                      <SparklinesMiniChart
                        height={CHART_HEIGHT}
                        width={width}
                        allowChartUpdate={true}
                        minY={0}
                        maxY={0}
                        data={{
                          values: chartData,
                          color: theme.palette.purple[500],
                          gradient: [
                            [0, theme.palette.ultraviolet[100]],
                            [1, theme.palette.ultraviolet[100]],
                          ],
                        }}
                      />
                    ) : (
                      <img
                        src={noTrend}
                        style={{ width: width ?? 143, height: CHART_HEIGHT }}
                        alt="No trend data"
                      />
                    ))}
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexFlow: "column",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  <h6
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: theme.palette.grey[800],
                      textAlign: "right",
                      margin: 0,
                      marginTop: 8,
                    }}
                  >
                    {formatNumber(total)}
                  </h6>
                  <div
                    style={{
                      fontSize: theme.typography.pxToRem(12),
                      color: theme.palette.grey[600],
                      marginTop: 16,
                      marginLeft: 7,
                      paddingBottom: 4,
                    }}
                  >
                    {t("healthscore:totalFormat")}
                  </div>
                </div>
                {!hasNoChangeAndNoUrls && hasTrendData ? (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "start",
                      marginRight: "-7px",
                      marginLeft: "auto",
                    }}
                  >
                    {hasUrlCountChanged && inViewStatus.rendered ? (
                      <>
                        <PercentageChangeChip
                          total={total}
                          sign={1}
                          change={absoluteChange}
                          disableBackground={true}
                          lang={i18n.language}
                          compact={true}
                          variant="triangle"
                          classes={{
                            label: classes.percentageChipLabel,
                          }}
                          maximumFractionDigits={0}
                        />
                      </>
                    ) : (
                      <span
                        style={{
                          marginTop: -1,
                          color: "#4B5563",
                          paddingRight: 7,
                        }}
                      >
                        0
                      </span>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      flex: 1,
                    }}
                  />
                )}
              </div>
            </div>
          </InView>
        </Tooltip>
      </AnalyticsHubHealthScoreLink>
    );
  },
  (prev, next) =>
    prev.accountId === next.accountId &&
    prev.projectId === next.projectId &&
    prev.segmentId === next.segmentId &&
    prev.crawlId === next.crawlId &&
    prev.category === next.category &&
    prev.columnId === next.columnId &&
    prev.width === next.width &&
    isEqual(prev.report, next.report),
);
