import { useEffect, useState } from "react";

export function useComboboxTextWidth(loading?: boolean): number | undefined {
  const [comboboxTextWidth, setComboboxTextWidth] = useState<
    undefined | number
  >(undefined);

  const comboboxTextcontent = document.getElementById(
    "combobox-button-placeholder",
  )?.textContent;

  useEffect(() => {
    const comboboxSpan = document.getElementById("combobox-button-placeholder");
    const textWidth = comboboxSpan?.clientWidth
      ? Math.floor(comboboxSpan?.clientWidth)
      : undefined;
    if (comboboxTextcontent && textWidth) {
      setComboboxTextWidth(textWidth);
    }
  }, [loading, comboboxTextcontent]);

  return comboboxTextWidth;
}
