import React from "react";
import {
  ToggleIconButton,
  TrashSolid,
  DestructiveMenuActionConfirmation,
  Snackbar,
  useTranslation,
} from "@lumar/shared";
import { Tooltip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../_common/utils/getErrorMessage";

interface Props {
  onDeleteConfirmationClick: () => Promise<void>;
  disabled?: boolean;
}

export function AlertActionButtons({
  onDeleteConfirmationClick,
  disabled,
}: Props): JSX.Element {
  const [ref, setRef] = React.useState<null | HTMLElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["common", "alerts"]);

  const handleIconButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    setRef(e.currentTarget);
  };

  const handleConfirmButtonClick = async (): Promise<void> => {
    try {
      await onDeleteConfirmationClick();
      setRef(null);
      enqueueSnackbar(
        <Snackbar
          title={t("alerts:alertDeletedSuccessfully")}
          variant="success"
        />,
      );
    } catch (e) {
      setRef(null);
      enqueueSnackbar(
        <Snackbar
          title={t("alerts:alertDeletionFailed", {
            message: getErrorMessage(e),
          })}
          variant="error"
        />,
      );
    }
  };

  const handleCancelButtonClick = (): void => {
    setRef(null);
  };

  return (
    <>
      <Tooltip title={t("alerts:delete") as string} arrow={false}>
        <ToggleIconButton
          colorVariant="red"
          size="large"
          variant="outlined"
          onClick={handleIconButtonClick}
          active={Boolean(ref)}
          data-pendo="delete-alert"
          data-testid="delete-alert"
          disabled={disabled}
        >
          <TrashSolid />
        </ToggleIconButton>
      </Tooltip>
      {ref ? (
        <DestructiveMenuActionConfirmation
          title={t("alerts:deleteConfirmationTitle")}
          description={t("common:actionCannotBeUndone")}
          onConfirm={handleConfirmButtonClick}
          onCancel={handleCancelButtonClick}
          confirmText={t("dashboards:delete_menu")}
          anchorEl={ref}
          pendoPrefix="delete-alert"
        />
      ) : null}
    </>
  );
}
