import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, ChevronDownSolid, useTranslation } from "@lumar/shared";
import { makeStyles, useTheme } from "@material-ui/core";
import { NotificationSelectionType } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
  button: ({ isMenuOpen }: { isMenuOpen: boolean }) => ({
    backgroundColor: isMenuOpen
      ? theme.palette.ultraviolet[700]
      : theme.palette.primary.main,
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiButton-label": {
      height: 36,
    },
    "& .MuiButton-endIcon": {
      borderLeft: "1px solid white",
      height: "100%",
      display: "flex",
      alignItems: "center",
      marginLeft: 10,
      paddingLeft: 7,
    },
    "& .MuiSvgIcon-root": {
      marginTop: 2,
    },
  }),
  icon: {
    fontSize: theme.typography.pxToRem(20),
    marginRight: 12,
  },
  iconMenuButton: {
    width: 20,
    height: 20,
    minWidth: 20,
    alignSelf: "center",
    boxShadow: "none",
    marginLeft: theme.spacing(0.5),
  },
}));

interface Props {
  totalCount: number;
  visibleCount: number;
  onChange: (e: NotificationSelectionType) => void;
}

export function NotificationsSelectMenu({
  totalCount,
  visibleCount,
  onChange,
}: Props): JSX.Element {
  const { t } = useTranslation(["notifications"]);
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const classes = useStyles({ isMenuOpen });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="text"
        className={classes.iconMenuButton}
        onClick={handleClick}
        aria-label={t("notifications:selectionLabel")}
      >
        <ChevronDownSolid htmlColor={theme.palette.grey[500]} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <MenuItem
          data-pendo="dashboard-create-new-dashboard-button"
          data-testid="create-new-dashboard-menu-item"
          onClick={() => {
            handleClose();
            onChange(NotificationSelectionType.SelectAll);
          }}
        >
          {t("selectAll", { count: totalCount })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onChange(NotificationSelectionType.SelectVisible);
          }}
          data-pendo="dashboard-create-new-alert-button"
          data-testid="create-new-alert-menu-item"
        >
          {t("selectVisible", { count: visibleCount })}
        </MenuItem>
      </Menu>
    </>
  );
}
