import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0.2,
    backgroundColor: "white",
    zIndex: theme.zIndex.speedDial,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
  },
}));
export function LoadingOverlay(): JSX.Element {
  const classes = useStyles();

  return <Box width="100%" height="100%" className={classes.root}></Box>;
}
