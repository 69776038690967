import { useApolloClient, getApiAccountId } from "@lumar/shared";
import { useParams } from "react-router";

export function useClearReportNotificationCache(): () => void {
  const apollo = useApolloClient();
  const { accountId } = useParams<{ accountId: string }>();

  return () => {
    apollo.cache.modify({
      id: apollo.cache.identify({
        __typename: "Account",
        id: getApiAccountId(accountId),
      }),
      fields: {
        monitorNotifications: (_, details) => details.DELETE,
      },
    });
  };
}

export function useClearHealthScoreNotificationCache(): () => void {
  const apollo = useApolloClient();
  const { accountId } = useParams<{ accountId: string }>();

  return () => {
    apollo.cache.modify({
      id: apollo.cache.identify({
        __typename: "Account",
        id: getApiAccountId(accountId),
      }),
      fields: {
        monitorHealthScoreNotifications: (_, details) => details.DELETE,
      },
    });
  };
}
