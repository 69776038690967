import { useMemo } from "react";
import {
  GetAlertWithTestsQuery,
  ReportTemplateUnit,
  TestAutoThresholdAcceptance,
} from "../../../graphql";
import {
  HealthScoreRulesAndThreshold,
  RulesAndThresholdsFormState,
} from "../../_common/utils/types";
import { canReportTemplateBeUsedWithSegments } from "../../../_common/hooks/useReportTemplateAccumulator";
import { healthScoreRound } from "../../../_common/utils/healthScoreRound";

export function useInitialAlertSettings(
  data?: GetAlertWithTestsQuery,
): RulesAndThresholdsFormState {
  const alphabeticallySortedReportRules = useMemo(() => {
    const rules = data?.alert?.tests.nodes ?? [];

    // Note: it's ok to use .sort() here because we are spreading into a new array first
    // eslint-disable-next-line fp/no-mutating-methods
    return [...rules]
      .sort((a, b) =>
        a.reportTemplate.name.localeCompare(b.reportTemplate.name),
      )
      .map(
        ({
          id,
          severity,
          thresholdPredicate,
          absoluteThreshold,
          reportTemplate,
          testResultList,
          automaticThresholdAcceptanceWhenTestResultIsBetter,
          automaticThresholdAcceptanceWhenTestResultIsWorse,
          segment,
        }) => ({
          id,
          severity,
          threshold: thresholdPredicate,
          urls: absoluteThreshold,
          report: {
            ...reportTemplate,
            totalSign:
              testResultList.nodes[0]?.reportStat?.totalWeight ??
              reportTemplate.totalSign,
            unit: reportTemplate.metadata?.unit ?? ReportTemplateUnit.UrLs,
            useableWithSegments: canReportTemplateBeUsedWithSegments(
              reportTemplate.datasourceCodeEnum,
            ),
          },
          testResults: testResultList.nodes.map((tr) => ({
            absoluteThreshold: tr.absoluteThreshold,
            createdAt: tr.createdAt,
          })),
          thresholdAcceptanceWhenWorse:
            automaticThresholdAcceptanceWhenTestResultIsWorse ??
            TestAutoThresholdAcceptance.None,
          thresholdAcceptanceWhenBetter:
            automaticThresholdAcceptanceWhenTestResultIsBetter ??
            TestAutoThresholdAcceptance.None,
          segment,
        }),
      );
  }, [data?.alert?.tests.nodes]);

  const alphabeticallySortedHealthScoreRules = useMemo(() => {
    const rules = data?.alert?.healthScoreTests.nodes ?? [];

    // Note: it's ok to use .sort() here because we are spreading into a new array first
    // eslint-disable-next-line fp/no-mutating-methods
    return [...rules]
      .sort((a, b) =>
        a.reportCategory.name.localeCompare(b.reportCategory.name),
      )
      .map(
        ({
          id,
          severity,
          thresholdPredicate,
          absoluteThreshold,
          reportCategory,
          automaticThresholdAcceptanceWhenTestResultIsBetter,
          automaticThresholdAcceptanceWhenTestResultIsWorse,
          segment,
          testResultList,
        }) =>
          ({
            id,
            severity,
            threshold: thresholdPredicate,
            urls: healthScoreRound(absoluteThreshold * 100.0),
            report: {
              id: reportCategory.code,
              ...reportCategory,
              unit: "score",
              useableWithSegments: true,
            },
            testResults: testResultList.nodes.map((tr) => ({
              absoluteThreshold: healthScoreRound(
                (tr.absoluteThreshold ?? 0) * 100,
              ),

              createdAt: tr.createdAt,
            })),
            thresholdAcceptanceWhenWorse:
              automaticThresholdAcceptanceWhenTestResultIsWorse ??
              TestAutoThresholdAcceptance.None,
            thresholdAcceptanceWhenBetter:
              automaticThresholdAcceptanceWhenTestResultIsBetter ??
              TestAutoThresholdAcceptance.None,
            segment,
          }) satisfies HealthScoreRulesAndThreshold,
      );
  }, [data?.alert?.healthScoreTests.nodes]);

  return {
    reportRulesAndThresholds: alphabeticallySortedReportRules,
    categoryRulesAndThresholds: alphabeticallySortedHealthScoreRules,
    emailAlerts:
      data?.alert?.emailAlerts?.nodes.map(({ email }) => email) ?? [],
    slackWebhooks:
      data?.alert?.slackWebhooks?.nodes.map(({ url }) => url) ?? [],
    microsoftTeamsWebhooks:
      data?.alert?.webhooks?.nodes.map(({ url }) => url) ?? [],
  };
}
