import { EmptyState, useTranslation, useSession } from "@lumar/shared";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";

import { ReactNode } from "react";
import error from "../../_animations/error-animation.json";
import noresult from "../../_animations/noresult-animation.json";
import sleepingCatAnimation from "../../_animations/sleeping-cat-animation.json";
import { RoleCode } from "../../graphql";
import { NotificationStatusFilter } from "../filters/constants";
import { Paper } from "@material-ui/core";

export interface EmtyStateSettings {
  type: EmptyStateType;
  title: string;
  description?: ReactNode | string;
  icon: ReactNode;
  action?: {
    icon?: ReactNode;
    title: string;
    ["data-pendo"]?: string;
  };
}

export enum EmptyStateType {
  Error,
  NoAlerts,
  NoData,
  NoResult,
  NoSuggestedThresholds,
}

export interface EmptyStateManagerProps {
  isError?: boolean;
  isFiltered?: boolean;
  isEmpty?: boolean;
  noAlerts?: boolean;
  status?: NotificationStatusFilter;
  onClick?: (type: EmptyStateType) => void;
  isRequiresManualApprovalSelected: boolean;
  className?: string;
}

export function EmptyStateManager({
  isError,
  isFiltered,
  isEmpty,
  noAlerts,
  status,
  onClick,
  isRequiresManualApprovalSelected,
  className,
}: EmptyStateManagerProps): JSX.Element {
  const { t } = useTranslation("notificationEmptyState");
  const session = useSession();
  const hasSufficientRole = session.hasSufficientRole(RoleCode.Editor);

  const getEmptyStateType = (): EmptyStateType => {
    if (isError) return EmptyStateType.Error;
    if (noAlerts && !isFiltered && isEmpty) return EmptyStateType.NoAlerts;
    if (isFiltered && isEmpty) return EmptyStateType.NoResult;
    if (isEmpty && isRequiresManualApprovalSelected)
      return EmptyStateType.NoSuggestedThresholds;
    return isEmpty ? EmptyStateType.NoData : EmptyStateType.Error;
  };

  const options: EmtyStateSettings[] = [
    {
      type: EmptyStateType.Error,
      title: t("error"),
      description: t("errorLoadingNotifications"),
      icon: (
        <Lottie loop animationData={error} play style={{ width: "80px" }} />
      ),
      action: {
        title: t("errorButton"),
      },
    },
    {
      type: EmptyStateType.NoAlerts,
      title: t("noAlerts"),
      description: t("noAlertsDescription"),
      icon: (
        <Lottie
          loop
          animationData={sleepingCatAnimation}
          play
          style={{ width: "80px" }}
        />
      ),
      action: hasSufficientRole
        ? {
            title: t("noAlertsButton"),
            "data-pendo": "monitor-notification-noalerts-go-to-alerts-page",
          }
        : undefined,
    },
    {
      type: EmptyStateType.NoData,
      title: t("noNotifications_" + status),
      description: t("noNotificationsDescription_" + status),
      icon: (
        <Lottie
          loop
          animationData={sleepingCatAnimation}
          play
          style={{ width: "214px", margin: "auto" }}
        />
      ),
    },
    {
      type: EmptyStateType.NoResult,
      title: t("noResults"),
      description: t("noResultsDescription"),
      icon: (
        <Lottie
          loop
          animationData={noresult}
          play
          style={{ width: "80px", margin: "auto" }}
        />
      ),
      action: {
        title: t("noResultsButton"),
        "data-pendo": "monitor-notification-no-filtered-results-go-to-analyze",
      },
    },
    {
      type: EmptyStateType.NoSuggestedThresholds,
      title:
        "You don’t have notifications with threshold suggestions at the moment",
      icon: (
        <Lottie
          loop
          animationData={sleepingCatAnimation}
          play
          style={{ width: "214px", margin: "auto" }}
        />
      ),
      action: {
        title: "Go to all notifications",
      },
    },
  ];

  const current = options[getEmptyStateType()];

  return (
    <Paper className={className}>
      <EmptyState
        width="100%"
        height={500}
        title={current.title}
        description={current.description}
        icon={current.icon}
        actions={
          current.action
            ? [
                {
                  type: "button",
                  ...current.action,
                  onClick: () => onClick?.(current.type),
                },
              ]
            : undefined
        }
      />
    </Paper>
  );
}
