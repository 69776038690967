export function PendoGuideIcon(): JSX.Element {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-pendo="alerts-page-how-to-guide"
    >
      <path
        opacity="0.2"
        d="M14.0002 20.5C18.1424 20.5 21.5002 17.1421 21.5002 13C21.5002 8.85786 18.1424 5.5 14.0002 5.5C9.85811 5.5 6.50024 8.85786 6.50024 13C6.50024 17.1421 9.85811 20.5 14.0002 20.5Z"
        fill="#7C3AED"
      />
      <path
        d="M14.0002 20.5C18.1424 20.5 21.5002 17.1421 21.5002 13C21.5002 8.85786 18.1424 5.5 14.0002 5.5C9.85811 5.5 6.50024 8.85786 6.50024 13C6.50024 17.1421 9.85811 20.5 14.0002 20.5Z"
        stroke="#7C3AED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0002 14.875V14.25C14.4947 14.25 14.978 14.1034 15.3892 13.8287C15.8003 13.554 16.1207 13.1635 16.3099 12.7067C16.4992 12.2499 16.5487 11.7472 16.4522 11.2623C16.3557 10.7773 16.1176 10.3319 15.768 9.98223C15.4184 9.6326 14.9729 9.3945 14.488 9.29804C14.003 9.20157 13.5004 9.25108 13.0435 9.4403C12.5867 9.62952 12.1963 9.94995 11.9216 10.3611C11.6469 10.7722 11.5002 11.2555 11.5002 11.75"
        stroke="#7C3AED"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 18.3125C14.5178 18.3125 14.9375 17.8928 14.9375 17.375C14.9375 16.8572 14.5178 16.4375 14 16.4375C13.4822 16.4375 13.0625 16.8572 13.0625 17.375C13.0625 17.8928 13.4822 18.3125 14 18.3125Z"
        fill="#7C3AED"
      />
      <rect x="0.5" y="0.5" width="27" height="25" rx="12.5" stroke="#DDD6FE" />
    </svg>
  );
}
