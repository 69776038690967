import { Alert, EmptyState, useTranslation } from "@lumar/shared";
import { makeStyles, createStyles, Paper } from "@material-ui/core";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import {
  CustomDashboardType,
  useGetDashboardCollectionQuery,
} from "../graphql";
import { assert } from "../_common/assert";
import { useGenericParams } from "../_common/routing/useGenericParams";
import { useMonitorRoutes } from "../_common/routing/useMonitorRoutes";
import { AccountOverview } from "./dashboards/account-overview/AccountOverview";
import { HealthScoreTab } from "./dashboards/health-score/HealthScoreTab";
import errorLottie from "../_animations/error-animation.json";
import { useState } from "react";
import { DashboardTabs } from "./components/DashboardTabs";
import {
  DashboardHeader,
  MONITOR_LOCALSTORAGE_CHART_FLAG,
} from "./dashboards/common/DashboardHeader";
import { DashboardViewsProvider } from "./components/DashboardViewsProvider";
import { useHealthScoreEnabled } from "../_common/hooks/useHealthScoreEnabled";

const useStyles = makeStyles((theme) =>
  createStyles({
    emptystate: {
      height: "calc(100vh - 130px)",
    },
    options: {
      marginBottom: theme.spacing(1.6),
    },
  }),
);

export const MAX_TAB_LABEL_WIDTH = 121;
export const WRAPPER_SPACING = 2.5;
export interface SpecialTab {
  name: string;
  id: string;
  type: CustomDashboardType;
}

export function BoardPage(): JSX.Element {
  const { accountId, collectionId, dashboardId } = useGenericParams();
  assert(accountId);
  const { t } = useTranslation(["dashboards", "dashboardTabs"]);
  const classes = useStyles();

  const { dashboardPage } = useMonitorRoutes();
  const [creationStarted, setCreationStarted] = useState<{
    id?: string;
    type?: CustomDashboardType;
    name?: string;
  } | null>(null);

  const [showChart, setShowChart] = useState<boolean>(
    localStorage.getItem(MONITOR_LOCALSTORAGE_CHART_FLAG) === "true",
  );

  const hasHealthScoreEnabled = useHealthScoreEnabled();

  const [specialTab, setSpecialTab] = useState<
    { name: string; id: string; type: CustomDashboardType } | undefined
  >(undefined);

  const { data, loading, error } = useGetDashboardCollectionQuery({
    variables: {
      accountId,
      customCollectionId: collectionId,
      types: [
        CustomDashboardType.Monitor,
        ...(hasHealthScoreEnabled ? [CustomDashboardType.HealthScores] : []),
      ],
    },
    skip: !Boolean(collectionId),
    notifyOnNetworkStatusChange: true,
    onCompleted: async (data) => {
      if (data?.getCustomDashboardCollection?.customDashboards?.edges.length) {
        const current =
          data?.getCustomDashboardCollection?.customDashboards?.edges.find(
            (e) => e.node.id === dashboardId,
          )?.node;
        setSpecialTab(undefined);
        if (
          (!isOneDashboardSelected && !Boolean(dashboardId)) ||
          !Boolean(current)
        ) {
          dashboardPage.visit(
            {
              accountId,
              collectionId,
              dashboardId:
                data.getCustomDashboardCollection?.customDashboards?.edges[0]
                  ?.node.id,
            },
            { replace: true },
          );
        }
      }
    },
  });
  const currentDashboard =
    data?.getCustomDashboardCollection?.customDashboards?.edges.find(
      (e) => e.node.id === dashboardId,
    )?.node;

  const isOneDashboardSelected =
    Boolean(dashboardId) && !loading && Boolean(currentDashboard);

  const totalDashboardsNum =
    data?.getCustomDashboardCollection?.customDashboards?.edges.length ?? 0;

  const somethingWrong = Boolean(error);

  const isNoDashboardFound = !loading && !data?.getCustomDashboardCollection;

  return (
    <>
      {Boolean(collectionId) && isNoDashboardFound ? (
        <Alert severity="error">{t("noDashboardFound")}</Alert>
      ) : somethingWrong ? (
        <Paper>
          <EmptyState
            className={classes.emptystate}
            width="100%"
            height={500}
            title={t("dashboardLoadingError")}
            description={t("dashboardLoadingErrorDescription")}
            icon={
              <Lottie
                loop
                animationData={errorLottie}
                play
                style={{ width: "80px", margin: "auto" }}
              />
            }
            actions={[
              {
                type: "button",
                title: t("errorButton"),
                onClick: () => {
                  window.location.reload();
                },
              },
            ]}
          />
        </Paper>
      ) : (
        <>
          <div
            id="board-page"
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: 800,
              position: "relative",
              overflowX: "hidden",
            }}
          >
            <DashboardTabs
              specialTab={specialTab}
              data={data}
              setSpecialTab={setSpecialTab}
              creationStarted={creationStarted}
              setCreationStarted={setCreationStarted}
              totalDashboardsNum={totalDashboardsNum}
            />
          </div>
          <DashboardViewsProvider dashboardId={dashboardId} loading={loading}>
            <DashboardHeader
              dashboardsNum={totalDashboardsNum}
              name={currentDashboard?.name}
              showChart={showChart}
              onChange={setShowChart}
            />
            {currentDashboard?.type === CustomDashboardType.HealthScores ? (
              <HealthScoreTab key={dashboardId} showChart={showChart} />
            ) : (
              <AccountOverview key={dashboardId} showChart={showChart} />
            )}
          </DashboardViewsProvider>
        </>
      )}
    </>
  );
}
