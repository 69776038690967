import React from "react";
import {
  useNumberFormatter,
  useTranslation,
  Typography,
  NeutralSignIcon,
  GoodSignIcon,
  ExclamationSignIcon,
} from "@lumar/shared";
import { createStyles, makeStyles, Tooltip, useTheme } from "@material-ui/core";
import { PercentageChangeWithTriangle } from "../../_common/PercentageChangeWithTriangle";

const useStyles = makeStyles((theme) =>
  createStyles({
    percentageChipLabel: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
    },
    container: {
      display: "flex",
      alignItems: "center",
      gap: 12,
    },
    percentageChipContainer: {
      display: "flex",
      alignItems: "center",
    },
    chipLabel: { marginTop: 1 },
    monospaceNumber: {
      color: "white",
      fontWeight: "inherit",
      fontSize: "inherit",
    },
  }),
);

interface Props {
  previousUrlCount: number;
  currentUrlCount: number;
  totalSign?: number;
  variant?: "light" | "dark";
}

export function CrawlChangeStats({
  previousUrlCount,
  currentUrlCount,
  totalSign = 0,
}: Props): JSX.Element {
  const { t } = useTranslation(["units", "common", "alerts"]);
  const formatNumber = useNumberFormatter();
  const classes = useStyles();
  const theme = useTheme();

  const absoluteChange = currentUrlCount - previousUrlCount;
  const sign = totalSign * Math.sign(absoluteChange);

  return (
    <div className={classes.percentageChipContainer}>
      {absoluteChange === 0 ? (
        <>
          <Tooltip title={t("alerts:urlChangeTooltip") as string} arrow={false}>
            <Typography variant="button" className={classes.monospaceNumber}>
              {formatNumber(absoluteChange)}
            </Typography>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title={t("alerts:urlChangeTooltip") as string} arrow={false}>
            <span style={{ display: "flex" }}>
              <Typography
                variant="button"
                style={{ marginRight: 7 }}
                className={classes.monospaceNumber}
              >
                {absoluteChange > 0 && "+"}
                {formatNumber(absoluteChange)}
              </Typography>
              <PercentageChangeWithTriangle
                newValue={currentUrlCount}
                previousValue={previousUrlCount}
                sign={totalSign ?? 0}
                typographyProps={{
                  style: {
                    color: "white",
                    fontWeight: "inherit",
                    fontSize: "inherit",
                  },
                }}
              />
            </span>
          </Tooltip>

          {sign === 0 ? (
            <Tooltip title={t("alerts:neutralChange") as string} arrow={false}>
              <NeutralSignIcon htmlColor={theme.palette.grey[500]} />
            </Tooltip>
          ) : sign > 0 ? (
            <Tooltip title={t("alerts:positiveChange") as string} arrow={false}>
              <GoodSignIcon htmlColor={theme.palette.grey[500]} />
            </Tooltip>
          ) : (
            <Tooltip title={t("alerts:negativeChange") as string} arrow={false}>
              <ExclamationSignIcon htmlColor={theme.palette.grey[500]} />
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
}
