import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarKey, useSnackbar } from "notistack";
import { AlertTitle } from "@material-ui/lab";
import {
  Alert,
  CheckCircle,
  XCircleSolid,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useMonitorRoutes } from "../../../_common/routing/useMonitorRoutes";

const useStyles = makeStyles((theme) => ({
  snackbar: {
    zIndex: theme.zIndex.snackbar,
  },
  alert: {
    border: `1px solid ${theme.palette.green[300]}`,
    borderRadius: 8,
    width: 472,
    backgroundColor: theme.palette.green[50],
    padding: theme.spacing(2),
  },
  alertTitle: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(1),
  },
  alertDescription: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: theme.palette.grey[900],
  },
  alertMessage: {
    paddingTop: 2,
    paddingBottom: 0,
  },
  icon: {
    fontSize: "28px",
    marginTop: "-7px",
    color: theme.palette.green[500],
  },
  alertAction: {
    alignItems: "flex-start",
    paddingLeft: 0,
    marginRight: 0,
    "& svg": {
      fontSize: 20,
      color: theme.palette.grey[500],
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  iconButton: {
    padding: 0,
  },
}));

interface CustomSnackbarProps {
  id: SnackbarKey;
  accountId: string;
  alertId: string;
}

export const CustomSnackbar = React.forwardRef(function CustomSnackbar(
  props: CustomSnackbarProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const { t } = useTranslation("alerts");

  const { alertsPage } = useMonitorRoutes();

  function handleDismiss(): void {
    closeSnackbar(props.id);
  }

  return (
    <div ref={ref}>
      <Alert
        ref={ref}
        action={
          <IconButton className={classes.iconButton} onClick={handleDismiss}>
            <XCircleSolid />
          </IconButton>
        }
        severity="success"
        classes={{
          action: classes.alertAction,
          root: classes.alert,
          message: classes.alertMessage,
        }}
        iconMapping={{
          success: (
            <CheckCircle
              data-testid="close-alert"
              fontSize="default"
              className={classes.icon}
            />
          ),
        }}
      >
        <AlertTitle className={classes.alertTitle}>
          {t("alertCreatedTitle")}
        </AlertTitle>
        <div>
          <Typography className={classes.alertDescription}>
            {t("alertCreatedDescription")}
          </Typography>{" "}
          <Link
            to={alertsPage.getUrl({
              alertId: props.alertId,
              accountId: props.accountId,
            })}
          >
            {t("editAlert")}
          </Link>
        </div>
      </Alert>
    </div>
  );
});
