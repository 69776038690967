import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NarrowMetric, insertIf, useSession } from "@lumar/shared";

import {
  MetricType,
  ConnectionPredicate,
  ConnectionPredicateMetadata,
} from "../../../../../graphql";

export interface ViewFilter {
  code: string;
  name: string;
  connectionPredicates: Pick<ConnectionPredicateMetadata, "code" | "name">[];
  description: string;
}

export function useConnectionPredicates(): Pick<
  ConnectionPredicateMetadata,
  "code" | "name"
>[] {
  const { t } = useTranslation("filterPredicates");

  return useMemo(
    () => [
      {
        code: ConnectionPredicate.BeginsWith,
        name: t("startsWith"),
      },
      {
        code: ConnectionPredicate.Contains,
        name: t("contains"),
      },
      {
        code: ConnectionPredicate.EndsWith,
        name: t("endsWith"),
      },
      {
        code: ConnectionPredicate.Eq,
        name: t("equals"),
      },
      {
        code: ConnectionPredicate.Ge,
        name: t("greaterThanOrEqualTo"),
      },
      {
        code: ConnectionPredicate.Gt,
        name: t("greaterThan"),
      },
      {
        code: ConnectionPredicate.Le,
        name: t("lessThanOrEqualTo"),
      },
      {
        code: ConnectionPredicate.Lt,
        name: t("lessThan"),
      },
      {
        code: ConnectionPredicate.Ne,
        name: t("notEquals"),
      },
      {
        code: ConnectionPredicate.NotContains,
        name: t("notContains"),
      },
      {
        code: ConnectionPredicate.MatchesRegex,
        name: t("matchesRegex"),
      },
      {
        code: ConnectionPredicate.NotMatchesRegex,
        name: t("doesntMatchRegex"),
      },
    ],
    [t],
  );
}

export function useViewFilters({
  includeActiveAndScheduledAndTestMetrics,
}: {
  includeActiveAndScheduledAndTestMetrics: boolean;
}): NarrowMetric[] {
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { t } = useTranslation("projectFilters");
  const connectionPredicates = useConnectionPredicates();

  return useMemo(() => {
    const booleanPredicateCodes = [
      ConnectionPredicate.Eq,
      ConnectionPredicate.Ne,
    ];
    const booleanPredicates = connectionPredicates
      .filter((predicate) => booleanPredicateCodes.includes(predicate.code))
      .map((connectionPredicate) => ({
        ...connectionPredicate,
        type: MetricType["Boolean"],
      }));

    const datePredicateCodes = [
      ConnectionPredicate.Ne,
      ConnectionPredicate.Eq,
      ConnectionPredicate.Lt,
      ConnectionPredicate.Le,
      ConnectionPredicate.Gt,
      ConnectionPredicate.Ge,
    ];
    const datePredicates = connectionPredicates
      .filter((predicate) => datePredicateCodes.includes(predicate.code))
      .map((connectionPredicate) => ({
        ...connectionPredicate,
        type: MetricType["Date"],
      }));

    const stringPredicateCodes = [
      ConnectionPredicate.Eq,
      ConnectionPredicate.Ne,
      ConnectionPredicate.BeginsWith,
      ConnectionPredicate.EndsWith,
      ConnectionPredicate.Contains,
      ConnectionPredicate.NotContains,
      ConnectionPredicate.MatchesRegex,
      ConnectionPredicate.NotMatchesRegex,
    ];
    const stringPredicates = connectionPredicates
      .filter((predicate) => stringPredicateCodes.includes(predicate.code))
      .map((connectionPredicate) => ({
        ...connectionPredicate,
        type: MetricType["String"],
      }));

    const numberPredicateCodes = [
      ConnectionPredicate.Eq,
      ConnectionPredicate.Ne,
      ConnectionPredicate.Lt,
      ConnectionPredicate.Le,
      ConnectionPredicate.Gt,
      ConnectionPredicate.Ge,
    ];
    const numberPredicates = connectionPredicates
      .filter((predicate) => numberPredicateCodes.includes(predicate.code))
      .map((connectionPredicate) => ({
        ...connectionPredicate,
        type: MetricType["Integer"],
      }));

    return [
      ...[
        {
          code: "projectLastCrawlCrawlingAt",
          name: t("lastCrawlDate"),
          connectionPredicates: datePredicates,
          description: t("lastCrawlDateTooltip"),
        },
        {
          code: "projectFinishedAt",
          name: t("dateFinished"),
          connectionPredicates: datePredicates,
          description: t("dateFinishedTooltip"),
        },
        {
          code: "projectAllPagesTotal",
          name: t("totalURLs"),
          connectionPredicates: numberPredicates,
          description: t("totalURLsTooltip"),
        },
        {
          code: "projectPrimaryDomain",
          name: t("domain"),
          connectionPredicates: stringPredicates,
          description: t("domainTooltip"),
        },
        {
          code: "nameOrDomain",
          name: t("nameOrDomain"),
          connectionPredicates: stringPredicates,
          description: t("nameOrDomainTooltip"),
        },
        {
          code: "projectName",
          name: t("name"),
          connectionPredicates: stringPredicates,
          description: t("nameTooltip"),
        },
        ...insertIf(segmentationAvailable, {
          code: "segmentName",
          name: t("segmentName"),
          connectionPredicates: stringPredicates,
          description: t("segmentNameTooltip"),
        }),
        {
          code: "running",
          name: t("running"),
          connectionPredicates: booleanPredicates,
          description: t("runningTooltip"),
        },
        {
          code: "projectCreatedAt",
          name: t("dateCreated"),
          connectionPredicates: datePredicates,
          description: t("dateCreatedTooltip"),
        },
      ],
      ...(includeActiveAndScheduledAndTestMetrics
        ? [
            {
              code: "schedulesTotalCount",
              name: t("isScheduled"),
              connectionPredicates: booleanPredicates,
              description: t("isScheduledTooltip"),
            },
            {
              code: "active",
              name: t("active"),
              connectionPredicates: booleanPredicates,
              description: t("activeTooltip"),
            },
            {
              code: "siteTest",
              name: t("hasTestSite"),
              connectionPredicates: booleanPredicates,
              description: t("hasTestSiteTooltip"),
            },
          ]
        : []),
    ];
  }, [
    connectionPredicates,
    t,
    segmentationAvailable,
    includeActiveAndScheduledAndTestMetrics,
  ]);
}
