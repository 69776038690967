import { SvgIcon, useTheme } from "@material-ui/core";

export const FinishedIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <SvgIcon
      style={{ width: theme.spacing(8), height: theme.spacing(8) }}
      viewBox="0 0 64 64"
      fill="none"
    >
      <rect width="64" height="64" rx="32" fill="#ECFDF5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9627 15.9234C28.5539 14.9279 30.3691 14.347 32.2426 14.2338C34.1161 14.1206 35.9881 14.4787 37.6875 15.2754C39.387 16.072 40.8597 17.2818 41.9711 18.7943C43.0825 20.3067 43.7972 22.0736 44.0499 23.9334C45.9563 24.5048 47.5942 25.7417 48.6655 27.419C49.7367 29.0964 50.17 31.1026 49.8866 33.0725C49.6031 35.0425 48.6217 36.8451 47.121 38.1524C45.6203 39.4596 43.7001 40.1845 41.7099 40.1951H41.7022H41.661L43.5587 38.0609C44.0105 37.5565 44.3582 36.9679 44.5819 36.3289C44.8057 35.6898 44.901 35.0129 44.8625 34.3369C44.824 33.6609 44.6524 32.9992 44.3575 32.3897C44.0626 31.7802 43.6503 31.2349 43.1441 30.7852C42.638 30.3354 42.0481 29.99 41.4081 29.7688C40.7682 29.5476 40.0909 29.455 39.4151 29.4962C38.7392 29.5374 38.0782 29.7116 37.4699 30.0089C36.8615 30.3062 36.3179 30.7207 35.8702 31.2286L28.7293 39.2591L27.3716 38.2434C26.8101 37.8225 26.1685 37.5209 25.4861 37.3572C24.8037 37.1935 24.0951 37.1712 23.4038 37.2916C22.7124 37.412 22.0531 37.6727 21.4663 38.0575C20.8794 38.4423 20.3776 38.9431 19.9916 39.5291C18.028 38.741 16.384 37.3195 15.3205 35.4904C14.257 33.6612 13.8351 31.5292 14.1217 29.4328C14.4082 27.3365 15.3869 25.3959 16.9022 23.9193C18.4176 22.4426 20.3828 21.5145 22.4859 21.2823C23.3716 19.0654 24.938 17.1883 26.9627 15.9234Z"
        fill="#6EE7B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.9951 33.2034C41.1846 33.3716 41.3391 33.5754 41.4498 33.8033C41.5605 34.0311 41.6252 34.2786 41.6403 34.5314C41.6553 34.7843 41.6204 35.0377 41.5375 35.2771C41.4546 35.5165 41.3254 35.7372 41.1571 35.9266L30.8714 47.498C30.5497 47.8596 30.1038 48.087 29.6223 48.1351C29.1407 48.1833 28.6586 48.0485 28.2717 47.7577L23.1289 43.9006C22.7197 43.5937 22.4491 43.1368 22.3768 42.6305C22.3045 42.1241 22.4363 41.6098 22.7431 41.2006C23.05 40.7914 23.5069 40.5209 24.0133 40.4485C24.5196 40.3762 25.034 40.508 25.4431 40.8149L29.1666 43.6074L38.272 33.3629C38.4404 33.1732 38.6445 33.0186 38.8726 32.9079C39.1008 32.7972 39.3486 32.7326 39.6018 32.7178C39.855 32.703 40.1086 32.7382 40.3481 32.8216C40.5877 32.9049 40.8058 33.0347 40.9951 33.2034Z"
        fill="#059669"
      />
    </SvgIcon>
  );
};
