import React from "react";
import {
  Checkbox,
  Typography,
  ApolloError,
  useTranslation,
} from "@lumar/shared";
import { CircularProgress, Fade, makeStyles } from "@material-ui/core";
import { NotificationItem } from "../components/NotificationItem";
import { getNotificationCategoryList } from "../utils/getNotificationCategoryList";
import { MonitorNotification, NotificationSelection } from "../types";
import { LoadMoreOnScroll } from "../../_common/components/LoadMoreOnScroll";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";
import { EmptyStateManager, EmptyStateType } from "./EmptyStateManager";
import { NotificationStatusFilter } from "../filters/constants";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { NotificationSelectionType } from "../utils/constants";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { TestResultStatus } from "../../graphql";

interface Props {
  notifications: MonitorNotification[];
  error: ApolloError | undefined;
  isLoading: boolean;
  isReloading?: boolean;
  onLoadMore: () => void;
  hasFetchedAllData: boolean;
  isFilterApplied?: boolean;
  hasAlerts?: boolean;
  readOnly?: boolean;
  status?: NotificationStatusFilter;
  clearFilters?: () => void;
  values?: NotificationSelection;
  onChange?: (selection: NotificationSelection) => void;
  showAdjustments: boolean;
  isRequiresManualApprovalSelected: boolean;
  onGoToAllNotificationsClick?: () => void;
  pendo?: string;
  classes?: {
    list?: string;
    progress?: string;
    emptyState?: string;
  };
  onRuleEdit?: (notification: MonitorNotification) => void;
  handleAcceptSuggestedThreshold: (
    notification: MonitorNotification,
    value: number,
  ) => Promise<void>;
  handleDeclineSuggestedThreshold: (
    notification: MonitorNotification,
  ) => Promise<void>;
  handleStatusUpdate: (
    notification: MonitorNotification,
    status: TestResultStatus,
  ) => Promise<ApolloError | undefined>;
  noUnitOnChange?: boolean;
  formatUrl: (notification: MonitorNotification) => {
    url: string;
    tooltip: string;
  };
}

export function NotificationsList({
  notifications,
  error,
  isLoading,
  isReloading,
  onLoadMore,
  hasFetchedAllData,
  isFilterApplied,
  hasAlerts,
  status,
  clearFilters,
  values,
  onChange,
  onGoToAllNotificationsClick,
  showAdjustments,
  isRequiresManualApprovalSelected,
  pendo,
  classes: externalClasses,
  onRuleEdit,
  handleAcceptSuggestedThreshold,
  handleDeclineSuggestedThreshold,
  handleStatusUpdate,
  noUnitOnChange,
  readOnly = false,
  formatUrl,
}: Props): JSX.Element {
  const classes = useStyles();

  const { createAlertPage } = useMonitorRoutes();
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation("notifications");

  if (isLoading) {
    return (
      <div className={externalClasses?.progress}>
        <CircularProgress
          style={{
            height: 20,
            width: 20,
            marginLeft: "auto",
            marginRight: "auto",
          }}
          data-testid="notifications-page-loading-indicator"
        />
      </div>
    );
  }

  function isSelected(element: MonitorNotification): boolean {
    if (!values) return false;

    if (values.state === NotificationSelectionType.SelectAll) {
      return !Boolean(values.selected.find((e) => e.id === element.id));
    }
    return Boolean(values.selected.find((e) => e.id === element.id));
  }

  function handleCheckboxChange(
    notification: MonitorNotification,
    checked: boolean,
  ): void {
    if (!values) return;
    if (values.state === NotificationSelectionType.SelectAll) {
      if (!checked)
        onChange?.({
          state: NotificationSelectionType.SelectAll,
          selected: [...values.selected, notification],
        });
      else
        onChange?.({
          state: NotificationSelectionType.SelectAll,
          selected: values.selected.filter((e) => e.id !== notification.id),
        });
      return;
    }
    if (checked)
      onChange?.({
        state: NotificationSelectionType.SelectNone,
        selected: [...values.selected, notification],
      });
    else
      onChange?.({
        state: NotificationSelectionType.SelectNone,
        selected: values.selected.filter((e) => e.id !== notification.id),
      });
  }

  const handleEmptyStateActionClick = (type: EmptyStateType): void => {
    switch (type) {
      case EmptyStateType.Error:
        window.location.reload();
        break;

      case EmptyStateType.NoAlerts:
        createAlertPage.visit({ accountId: accountId });
        break;

      case EmptyStateType.NoSuggestedThresholds:
        onGoToAllNotificationsClick?.();
        break;

      case EmptyStateType.NoResult:
        clearFilters?.();
    }
  };

  if ((!notifications.length || error) && !isLoading && !isReloading) {
    return (
      <EmptyStateManager
        className={externalClasses?.emptyState}
        isRequiresManualApprovalSelected={isRequiresManualApprovalSelected}
        isEmpty={!Boolean(notifications.length)}
        isError={Boolean(error)}
        isFiltered={isFilterApplied}
        noAlerts={!(hasAlerts === true)}
        status={status}
        onClick={handleEmptyStateActionClick}
      />
    );
  }
  const notificationCategoryList = getNotificationCategoryList(notifications);

  return (
    <Fade in>
      <div className={externalClasses?.list}>
        {notificationCategoryList.map(({ category, notifications }) => (
          <React.Fragment key={category}>
            <Typography
              variant="subtitle2Medium"
              component="p"
              className={classes.categoryTitle}
            >
              {category}
            </Typography>
            {notifications.map((notification) => {
              const selected = isSelected(notification);
              return (
                <div
                  key={`${notification.id}`}
                  id={`${notification.id}`}
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <HideFromInsufficientRole>
                    {values !== undefined && !readOnly && (
                      <Checkbox
                        className={clsx(classes.checkbox, classes.selection)}
                        checked={selected}
                        style={{ marginBottom: 12 }}
                        onChange={(_, checked) => {
                          handleCheckboxChange(notification, checked);
                        }}
                        inputProps={{
                          "aria-label": t("selectNotification"),
                        }}
                      />
                    )}
                  </HideFromInsufficientRole>
                  <NotificationItem
                    formatUrl={formatUrl}
                    noUnitOnChange={noUnitOnChange}
                    handleAcceptSuggestedThreshold={
                      handleAcceptSuggestedThreshold
                    }
                    handleDeclineSuggestedThreshold={
                      handleDeclineSuggestedThreshold
                    }
                    handleStatusUpdate={handleStatusUpdate}
                    notification={notification}
                    onRuleEdit={onRuleEdit}
                    selected={selected}
                    isAdjustment={showAdjustments}
                    data-pendo={
                      pendo ? `${pendo}-notification-list-item` : undefined
                    }
                  />
                </div>
              );
            })}
          </React.Fragment>
        ))}

        {!hasFetchedAllData ? (
          <LoadMoreOnScroll onLoadMore={onLoadMore} />
        ) : null}
      </div>
    </Fade>
  );
}

const useStyles = makeStyles((theme) => ({
  emptyState: {
    paddingTop: 46,
    paddingBottom: 105,
  },
  categoryTitle: {
    color: theme.palette.grey[400],
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
    textTransform: "uppercase",
    marginBottom: 3,
    paddingLeft: 46,
  },
  checkbox: {
    width: 24,
    height: 24,
    "& .MuiIconButton-label span": {
      "&:before": {
        width: 24,
        height: 24,
      },
      width: 24,
      height: 24,
    },
  },
  selection: {
    alignSelf: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1.75),
  },
}));
