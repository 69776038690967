import { healthScoreRound } from "../../_common/utils/healthScoreRound";
import {
  GetHealthScoreNotificationsQuery,
  GetReportNotificationsQuery,
  ReportTemplateUnit,
} from "../../graphql";
import { MonitorNotification, MonitorNotificationType } from "../types";
import { TFunction } from "i18next";

export function getReportNotificationList(
  data: GetReportNotificationsQuery | undefined,
): MonitorNotification[] | undefined {
  return data?.getAccount?.notifications?.edges.map<MonitorNotification>(
    (n) => ({
      type: MonitorNotificationType.Report,
      unit: n.node.reportTemplate.metadata?.unit ?? ReportTemplateUnit.UrLs,
      base: {
        id: n.node.reportTemplate.id,
        code: n.node.reportTemplate.code,
        name: n.node.reportTemplate.name,
        unit: n.node.reportTemplate.metadata?.unit ?? ReportTemplateUnit.UrLs,
        sign: n.node.reportTemplate.totalSign,
      },
      stat: n.node.reportStat && {
        weight: n.node.reportStat.totalWeight,
        trend:
          n.node.reportStat?.reportTrend.map((t) => ({
            crawlId: t.crawlId,
            value: t.basic,
            createdAt: t.createdAt ?? null,
          })) ?? [],
        crawlId: n.node.reportStat?.crawl?.id,
        customExtractions:
          n.node.reportStat.crawl.crawlSetting?.customExtractions ?? [],
        testResults: n.node.reportStat?.testResults,
        project: n.node.reportStat.project,
      },
      severity: n.node.severity,
      id: n.node.id,
      createdAt: n.node.createdAt,
      thresholdPredicate: n.node.thresholdPredicate,
      thresholdType: n.node.thresholdType,
      absoluteThreshold: n.node.absoluteThreshold,
      suggestedAbsoluteThreshold: n.node.suggestedAbsoluteThreshold,
      suggestedAbsoluteThresholdAcceptedAt:
        n.node.suggestedAbsoluteThresholdAcceptedAt,
      suggestedAbsoluteThresholdRejectedAt:
        n.node.suggestedAbsoluteThresholdRejectedAt,
      automaticThresholdAcceptanceWhenTestResultIsWorse:
        n.node.automaticThresholdAcceptanceWhenTestResultIsWorse,
      automaticThresholdAcceptanceWhenTestResultIsBetter:
        n.node.automaticThresholdAcceptanceWhenTestResultIsBetter,
      status: n.node.status,
      segment: n.node.segment,
      test: n.node.test && {
        ...n.node.test,
        code: n.node.test.reportTemplateCode,
      },
    }),
  );
}

export function getHealthScoreNotificationList(
  data: GetHealthScoreNotificationsQuery | undefined,
  t: TFunction<"notifications">,
): MonitorNotification[] | undefined {
  return data?.getAccount?.notifications?.edges.map<MonitorNotification>(
    (n) => ({
      type: MonitorNotificationType.HealthScore,
      unit: "percent",
      base: {
        code: n.node.reportCategory.code,
        name: t("score", { name: n.node.reportCategory.name }),
        unit: "score",
        sign: 1,
      },
      stat: n.node.healthScore && {
        weight: n.node.healthScore.categoryWeight,
        trend:
          n.node.healthScore?.reportTrend.map((t) => ({
            crawlId: t.crawlId,
            value: healthScoreRound(t.healthScore * 100),
            createdAt: t.createdAt ?? null,
          })) ?? [],
        crawlId: n.node.healthScore?.crawl?.id,
        customExtractions:
          n.node.healthScore?.crawl.crawlSetting?.customExtractions ?? [],
        testResults: n.node.healthScore?.testResults,
        project: n.node.healthScore.project,
      },
      severity: n.node.severity,
      id: n.node.id,
      createdAt: n.node.createdAt,
      thresholdPredicate: n.node.thresholdPredicate,
      thresholdType: n.node.thresholdType,
      absoluteThreshold: healthScoreRound(
        (n.node.absoluteThreshold ?? 0) * 100,
      ),
      suggestedAbsoluteThreshold:
        typeof n.node.suggestedAbsoluteThreshold === "number"
          ? healthScoreRound((n.node.suggestedAbsoluteThreshold ?? 0) * 100)
          : n.node.suggestedAbsoluteThreshold,
      suggestedAbsoluteThresholdAcceptedAt:
        n.node.suggestedAbsoluteThresholdAcceptedAt,
      suggestedAbsoluteThresholdRejectedAt:
        n.node.suggestedAbsoluteThresholdRejectedAt,
      automaticThresholdAcceptanceWhenTestResultIsWorse:
        n.node.automaticThresholdAcceptanceWhenTestResultIsWorse,
      automaticThresholdAcceptanceWhenTestResultIsBetter:
        n.node.automaticThresholdAcceptanceWhenTestResultIsBetter,
      status: n.node.status,
      segment: n.node.segment,
      test: n.node.healthScoreTest && {
        ...n.node.healthScoreTest,
        code: n.node.healthScoreTest.reportCategoryCode,
        absoluteThreshold: healthScoreRound(
          (n.node.healthScoreTest.absoluteThreshold ?? 0) * 100,
        ),
      },
    }),
  );
}
