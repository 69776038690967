import {
  TagInput,
  ToggleIconButton,
  TrashSolid,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { createStyles, makeStyles, Tooltip } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { RoleCode } from "../../../../graphql";
import { HideFromInsufficientRole } from "../../../../_common/components/HideFromInsufficientRole";
import { RulesAndThresholdsFormState } from "../../utils/types";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(1),
      flex: 1,
      "& .TagInput-chip-error": {
        backgroundColor: theme.palette.red[600],
        color: theme.palette.background.paper,
        "&:focus": {
          backgroundColor: `${theme.palette.red[400]} !important`,
        },
      },
      "& .TagInput-chip-error:hover": {
        backgroundColor: theme.palette.red[400],
        color: theme.palette.background.paper,
      },
    },
    textFieldContainer: {
      display: "flex",
      maxWidth: 700,
      alignItems: "flex-start",
    },
    description: {
      maxWidth: 600,
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.grey[500],
      lineHeight: theme.typography.pxToRem(14),
    },
  }),
);

export function EmailNotificationPanel({
  onRemovePanel,
  disabled = false,
}: {
  onRemovePanel?: () => void;
  disabled?: boolean;
}): JSX.Element {
  const { t } = useTranslation(["emailNotification"]);
  const classes = useStyles();
  const session = useSession();
  const hasSufficientRole = session.hasSufficientRole(RoleCode.Editor);

  const { values, setFieldValue, setFieldTouched, errors } =
    useFormikContext<RulesAndThresholdsFormState>();

  const error = errors?.emailAlerts
    ? Array.isArray(errors?.emailAlerts)
      ? errors?.emailAlerts.find(Boolean)
      : errors?.emailAlerts
    : undefined;

  function handleRemoveChannel(): void {
    setFieldValue(`emailAlerts`, []);
    onRemovePanel?.();
  }

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
      }}
    >
      <Typography variant="subtitle2SemiBold">{t("title")}</Typography>
      <Typography variant="caption" className={classes.description}>
        {t("description")}
      </Typography>
      <div className={classes.textFieldContainer}>
        <Field
          className={classes.root}
          style={{ maxWidth: 600 }}
          name={`emailAlerts`}
          component={TagInput}
          value={values.emailAlerts}
          errors={errors?.emailAlerts}
          error={error}
          addOnSpace
          addOnComma
          disabled={!hasSufficientRole || disabled}
          variant="outlined"
          InputProps={{
            placeholder: "Enter email, comma separated...",
            autoFocus: !values.emailAlerts || values.emailAlerts.length === 0,
          }}
          onChange={(value: string[]) => {
            setFieldTouched(`emailAlerts`, true);
            setFieldValue(`emailAlerts`, value);
          }}
          data-pendo="email-notification-channel-input"
          data-testid="email-notification-channel-input"
        />
        {values?.emailAlerts?.length && !disabled ? (
          <HideFromInsufficientRole>
            <Tooltip title={t("remove") as string} arrow={false}>
              <ToggleIconButton
                colorVariant="red"
                size="large"
                variant="outlined"
                onClick={handleRemoveChannel}
                data-pendo="email-notification-channel-delete-alert"
                data-testid="email-notification-channel-delete-alert"
              >
                <TrashSolid />
              </ToggleIconButton>
            </Tooltip>
          </HideFromInsufficientRole>
        ) : undefined}
      </div>
    </div>
  );
}
