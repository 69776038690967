import { useSession, useTranslation } from "@lumar/shared";
import { useCallback } from "react";
import { TrendsComputedType } from "../../../../../graphql";
import { ReportOption } from "../../../../../_common/utils/constants";
import {
  getTableDefaultSortingList,
  getTableDefaultSortingOrders,
} from "../../../common/grid/sorting/helpers";
import { TableSortingItem } from "../../../common/grid/sorting/TableSortingPopup";

export function useTableSortingList(): (
  reportTemplates: ReportOption[] | undefined,
  tableReportTemplates: string[] | undefined,
) => TableSortingItem[] {
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { t } = useTranslation("sortingPopup");

  return useCallback(
    (reportTemplates, tableReportTemplates) => {
      const TABLE_DEFAULT_SORTING_ORDERS = getTableDefaultSortingOrders(
        segmentationAvailable,
        t,
      );
      return [
        ...getTableDefaultSortingList(segmentationAvailable, t),
        ...(tableReportTemplates?.map((code) => {
          const element = reportTemplates?.find((e) => e.code === code);
          return {
            code: code,
            title: element?.name ?? "",
            sortBy: [
              {
                code: TrendsComputedType.Last,
                title: t("sortingPopup:totalURLs"),
                order: TABLE_DEFAULT_SORTING_ORDERS,
              },
              {
                code: TrendsComputedType.AbsDiff,
                title: t("sortingPopup:absolute"),
                order: TABLE_DEFAULT_SORTING_ORDERS,
              },
            ],
          };
        }) ?? []),
      ];
    },
    [segmentationAvailable, t],
  );
}
