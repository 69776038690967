import clsx from "clsx";
import { makeStyles, Popper, PopperProps } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  size: {
    minWidth: 360,
  },
}));
export const LeftBottomPopper = function ({
  className,
  ...props
}: Omit<PopperProps, "placement">): React.ReactElement {
  const classes = useStyles();
  return (
    <Popper
      {...props}
      placement="bottom-start"
      className={clsx(classes.size, className)}
    />
  );
};
