import React from "react";
import { makeStyles } from "@material-ui/core";
import { SelectableButtonGroup, useTranslation } from "@lumar/shared";
import { ThresholdPredicate } from "../../graphql";
import { ReportOption } from "../../_common/utils/constants";

const useStyles = makeStyles({
  readOnly: {
    pointerEvents: "none",
  },
});

interface Props {
  isDisabled: boolean;
  threshold: ThresholdPredicate;
  onThresholdChange: (threshold: ThresholdPredicate, urls?: number) => void;
  report?: ReportOption;
  segmentId?: string;
  urls: string | number | undefined;
}

function arePropsEqual(oldProps: Props, newProps: Props): boolean {
  return (
    oldProps.isDisabled === newProps.isDisabled &&
    oldProps.threshold === newProps.threshold &&
    oldProps.report === newProps.report &&
    oldProps.segmentId === newProps.segmentId &&
    oldProps.urls === newProps.urls
  );
}

export const ThresholdPredicateButtons = React.memo(function Buttons({
  isDisabled,
  threshold,
  onThresholdChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["thresholds"]);
  const THRESHOLD_OPTIONS = [
    {
      value: ThresholdPredicate.GreaterThanOrEqual,
      label: "≥",
      tooltipTitle: t("greater"),
      "data-testid": "greater-than-button",
      "data-pendo": "rule-greater-than-button",
    },
    {
      value: ThresholdPredicate.LessThan,
      label: "<",
      tooltipTitle: t("less"),
      "data-testid": "less-than-button",
      "data-pendo": "rule-less-than-button",
    },
  ];

  return (
    <SelectableButtonGroup
      dataTestId="threshold-button-group"
      className={isDisabled ? classes.readOnly : undefined}
      colorVariant="muted"
      value={threshold}
      onValueChange={(e) => onThresholdChange(e)}
      options={THRESHOLD_OPTIONS}
      size="small"
    />
  );
}, arePropsEqual);
