import React from "react";
import {
  Alert,
  useTranslation,
  ApolloError,
  getRawProjectId,
  Warning,
} from "@lumar/shared";
import { Skeleton } from "@material-ui/lab";
import { CrawlStatus, GetAlertsFilteredQuery } from "../../../graphql";
import { AlertListItem } from "./AlertListItem";
import { Fade, useTheme } from "@material-ui/core";
import { useParams } from "react-router";
import { useMonitorRoutes } from "../../../_common/routing/useMonitorRoutes";

interface Props {
  loading: boolean;
  error: ApolloError | undefined;
  data: GetAlertsFilteredQuery | undefined;
  searchTerm?: string;
  onSelectionChanged?: () => void;
}

export function AlertsList({
  loading,
  error,
  data,
  searchTerm,
  onSelectionChanged,
}: Props): JSX.Element {
  const { t } = useTranslation(["common", "alerts"]);
  const theme = useTheme();

  const { alertsPage } = useMonitorRoutes();

  const { alertId } = useParams<{
    alertId?: string;
  }>();

  if (loading) {
    return (
      <>
        {new Array(3).fill(null).map((_, i) => (
          <Skeleton
            key={i}
            variant="rect"
            height={54}
            width="100%"
            style={{ borderRadius: 8, marginBottom: 8 }}
          />
        ))}
      </>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        <strong>{t("alerts:alertsLoadingFailure")}</strong> {error.message}
      </Alert>
    );
  }

  return (
    <Fade in>
      <div style={{ overflowY: "auto" }}>
        {data?.getAccount?.alerts.edges.map(({ node }) => {
          return (
            <AlertListItem
              key={node.id}
              name={node.name}
              primaryDomain={node.primaryDomain}
              onClick={() => {
                onSelectionChanged?.();
                alertsPage.visit({ alertId: node.id });
              }}
              selected={alertId === node.id}
              id={getRawProjectId(node.id)}
              searchTerm={searchTerm}
              sign={
                (node.lastCrawlStatus === CrawlStatus.Archived ||
                  node.lastCrawlStatus === CrawlStatus.Archiving) && (
                  <Warning
                    style={{
                      width: 18,
                      height: 18,
                      strokeWidth: 1.5,
                      fill: theme.palette.grey[200],
                      color: theme.palette.grey[500],
                    }}
                  />
                )
              }
            />
          );
        })}
      </div>
    </Fade>
  );
}
