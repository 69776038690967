import React from "react";
import {
  ReportTemplateUnit,
  useGetHealthScoreTrendsQuery,
} from "../../../graphql";
import {
  SparklinesMiniChart,
  SparklinesMiniChartLineData,
} from "../../../_common/components/SparklinesMiniChart/SparklinesMiniChart";
import { ReportTrendElement } from "../../../_common/types";
import {
  useNumberFormatter,
  useTranslation,
  Typography,
  Alert,
  getRawCrawlId,
} from "@lumar/shared";
import {
  createStyles,
  Fade,
  makeStyles,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { isReportTrendElementArray } from "../../../_common/utils/isReportTrendElement";
import { CrawlChangeStats } from "./CrawlChangeStatistics";
import { minBy, maxBy } from "lodash";
import { DashStyleValue } from "highcharts";
import { isUndefined, isEqual } from "lodash";
import { healthScoreRound } from "../../../_common/utils/healthScoreRound";

const SPARKLINE_HEIGHT = 29;
const SPARKLINE_WIDTH = 90;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    urlCountText: {
      width: 80,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(17),
      color: theme.palette.grey[800],
      fontWeight: 500,
      marginLeft: 10,
      textDecoration: "none",
      textDecorationColor: "white",
    },
  }),
);

interface Props {
  categoryCode: string;
  projectId: string;
  segmentId?: string;
  thresholdTrendData?: [number, number][];
  yAxisLineValue?: number;
  unit: ReportTemplateUnit | "score";
}

function TrendVisualisation({
  categoryCode,
  projectId,
  thresholdTrendData,
  yAxisLineValue,
  unit,
  segmentId,
}: Props): JSX.Element {
  const { data, loading, error } = useGetHealthScoreTrendsQuery({
    variables: {
      projectId,
      segmentId,
      categoryCode,
    },
    context: {
      includeInBatch: true,
    },
  });

  const { t } = useTranslation(["units", "common", "alerts"]);
  const formatNumber = useNumberFormatter();
  const classes = useStyles();
  const theme = useTheme();
  if (!loading && error)
    return (
      <Alert severity="error" size="small">
        {t("alerts:trendDataError")}
      </Alert>
    );

  const healthScore = data?.getProject?.healthScore;

  const trends: ReportTrendElement[] | undefined =
    healthScore?.map((tr) => ({
      createdAt: tr.createdAt ?? "",
      value: healthScoreRound((tr.healthScore ?? 0) * 100.0),
      crawlId: getRawCrawlId(tr.crawlId?.toString() ?? ""),
    })) ?? [];

  if (!isReportTrendElementArray(trends) && !loading) {
    return (
      <Alert severity="warning" size="small">
        {t("common:noData")}
      </Alert>
    );
  }

  const mostRecentUrlCount = trends.length ? trends[0].value : 0;
  const previousUrlCount = trends.length > 1 ? trends[1]?.value : 0;

  const seriesData: [number, number][] =
    trends.map((tr) => [new Date(tr.createdAt).getTime(), tr.value]) ?? [];

  return (
    <div className={classes.container}>
      {loading ? (
        <Skeleton
          variant="rect"
          style={{ borderRadius: 6 }}
          height={SPARKLINE_HEIGHT}
          width={SPARKLINE_WIDTH + 80}
        />
      ) : (
        <Fade in>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              title={
                <div>
                  <Typography variant="subtitle4">
                    {t("alerts:rulesAndThresholds.changeComparison")}
                  </Typography>
                  <CrawlChangeStats
                    currentUrlCount={mostRecentUrlCount}
                    previousUrlCount={previousUrlCount ?? 0}
                    totalSign={1}
                  />
                  <div
                    style={{ marginTop: theme.spacing(1.5), display: "flex" }}
                  >
                    <div
                      style={{
                        width: 22,
                        height: 0,
                        marginRight: theme.spacing(0.625),
                        alignSelf: "center",
                        border: `1px dashed ${
                          isUndefined(yAxisLineValue)
                            ? theme.palette.red[400]
                            : theme.palette.orange[500]
                        }`,
                        borderTopWidth: 0,
                      }}
                    />
                    <Typography variant="subtitle4">
                      {t(
                        isUndefined(yAxisLineValue)
                          ? "alerts:rulesAndThresholds.thresholdHistory"
                          : "alerts:rulesAndThresholds.currentThreshold",
                      )}
                    </Typography>
                  </div>
                </div>
              }
              arrow={false}
            >
              <span>
                <SparklinesMiniChart
                  height={SPARKLINE_HEIGHT}
                  width={SPARKLINE_WIDTH}
                  minX={minBy(seriesData, (e) => e[0])?.[0] ?? 0}
                  maxX={maxBy(seriesData, (e) => e[0])?.[0] ?? 1}
                  data={[
                    seriesData,
                    ...(thresholdTrendData
                      ? [
                          {
                            values: thresholdTrendData,
                            color: theme.palette.red[500],
                            gradient: null,
                            disableMarker: true,
                            dashStyle: "ShortDash" as DashStyleValue,
                            lineWidth: 1,
                            zIndex: 2,
                          },
                        ]
                      : []),
                    ...(!isUndefined(yAxisLineValue)
                      ? [
                          {
                            direction: "Y",
                            color: theme.palette.orange[500],
                            lineWidth: 1,
                            value: yAxisLineValue,
                            zIndex: 3,
                          } as SparklinesMiniChartLineData,
                        ]
                      : []),
                  ]}
                  allowChartUpdate={true}
                />
              </span>
            </Tooltip>
            <Tooltip
              title={
                t("alerts:totalUrlCountTooltip", {
                  unit: t(`units:${unit}_plural`),
                }) as string
              }
              arrow={false}
            >
              <Typography
                variant="subtitle2SemiBold"
                className={classes.urlCountText}
                noWrap
              >
                {formatNumber(mostRecentUrlCount)}
              </Typography>
            </Tooltip>
          </div>
        </Fade>
      )}
    </div>
  );
}

export const HealthScoreTrendVisualisation = React.memo(
  TrendVisualisation,
  (prev, next) =>
    prev.projectId === next.projectId &&
    prev.segmentId === next.segmentId &&
    prev.categoryCode === next.categoryCode &&
    isEqual(prev.thresholdTrendData, next.thresholdTrendData) &&
    prev.yAxisLineValue === next.yAxisLineValue,
);
