import { differenceBy } from "lodash";
import { SimpleView } from "../../types";

export function mapViewsToAction(
  initialValues: SimpleView[],
  newValues: SimpleView[],
): { toDelete: string[]; toCreate: SimpleView[] } {
  const toDelete = differenceBy(
    initialValues,
    newValues,
    (e) => `P:${e.projectId}-S:${e.segmentId ?? ""}`,
  ).map((value) => value.id);

  const toCreate = differenceBy(
    newValues,
    initialValues,
    (e) => `P:${e.projectId}-S:${e.segmentId ?? ""}`,
  );

  return { toDelete, toCreate };
}
