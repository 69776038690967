import { makeStyles, useTheme } from "@material-ui/core";
import { useTranslation, Typography } from "@lumar/shared";
import step1 from "../images/slack-instructions-step-1.png";
import step2 from "../images/slack-instructions-step-2.png";
import step3 from "../images/slack-instructions-step-3.png";
import step4 from "../images/slack-instructions-step-4.png";
import step5 from "../images/slack-instructions-step-5.png";
import step6 from "../images/slack-instructions-step-6.png";
import { HidableBlock } from "../../../../_common/components/Hideable/HideableBlock";
import { InstructionsList } from "../../../../_common/components/InstructionsList/InstructionsList";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
    letterSpacing: "-0.2px",
  },
  image: {
    maxWidth: "100%",
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(5.75),
  },
  link: {
    color: theme.palette.blue[600],
    "&:hover": {
      color: theme.palette.blue[700],
    },
  },
}));

export function SlackInstructions(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("slack-info");
  const theme = useTheme();

  const instructions: JSX.Element[] = [
    <div style={{ display: "grid" }} key="instruction-step-1">
      <Typography variant="body2">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://api.slack.com/apps/new"
          data-testid="incoming-webhooks-link"
          className={classes.link}
        >
          {t("1-part1")} <span className={classes.bold}>{t("1-part2")}</span>
        </a>{" "}
        {t("1-part3")}
      </Typography>
      <img width={335} className={classes.image} src={step1} alt="step 1" />
    </div>,
    <div style={{ display: "grid" }} key="instruction-step-2">
      <Typography variant="body2">
        <span className={classes.bold}>{t("2-part1")}</span>
        {t("2-part2")}
        <span className={classes.bold}>{t("2-part3")}</span>
      </Typography>
      <img width={335} className={classes.image} src={step2} alt="step 2" />
    </div>,
    <div style={{ display: "grid" }} key="instruction-step-3">
      <Typography variant="body2">
        {t("3-part1")}
        <span className={classes.bold}>{t("3-part2")}</span>
        {t("3-part3")}
        <span className={classes.bold}>{t("3-part4")}</span>
      </Typography>
      <img width={486} className={classes.image} src={step3} alt="step 3" />
    </div>,
    <div style={{ display: "grid" }} key="instruction-step-4">
      <Typography variant="body2">
        {t("4-part1")}
        <span className={classes.bold}>{t("4-part2")}</span>
      </Typography>
      <img width={335} className={classes.image} src={step4} alt="step 4" />
    </div>,
    <div style={{ display: "grid" }} key="instruction-step-5">
      <Typography variant="body2">
        <span className={classes.bold}>{t("5-part1")}</span>
        {t("5-part2")}
        <span className={classes.bold}>{t("5-part3")}</span>
      </Typography>
      <img width={243} className={classes.image} src={step5} alt="step 5" />
    </div>,
    <div style={{ display: "grid" }} key="instruction-step-6">
      <Typography variant="body2">
        <span className={classes.bold}>{t("6-part1")}</span>
        {t("6-part2")}
      </Typography>
      <img width={415} className={classes.image} src={step6} alt="step 6" />
    </div>,
  ];

  return (
    <HidableBlock
      title={t("title")}
      descriptor="instructions"
      dataIdDescriptor="monitor-slack-webhooks"
    >
      <InstructionsList
        description={
          <Typography
            style={{ marginBottom: theme.spacing(6.5) }}
            variant="caption"
          >
            {t("description")}
          </Typography>
        }
        instructions={instructions}
        dataId="monitor-slack-webhooks"
      />
    </HidableBlock>
  );
}
