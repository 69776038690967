import {
  Button,
  ChevronDownSolid,
  ChevronUpSolid,
  OrderBySolid,
  useTranslation,
} from "@lumar/shared";
import { createStyles, makeStyles } from "@material-ui/core";
import { TFunction } from "i18next";
import React from "react";
import {
  MonitorSortItem,
  TableColumnSortOrder,
} from "../../../helpers/MonitorSortItem";
import { TableSortingItem, TableSortingPopup } from "./TableSortingPopup";

interface TableSortingProps {
  current: MonitorSortItem;
  columns: TableSortingItem[];
  onApply: (sort: MonitorSortItem) => void;
  disabled?: boolean;
  testId?: string;
  pendoId?: string;
}
const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      "&.Mui-disabled": {
        border: `1px solid ${theme.palette.grey[300]}`,
        color: theme.palette.grey[700],
        boxShadow: "none",
        backgroundColor: theme.palette.grey[100],
      },
      marginRight: theme.spacing(1),
    },
  }),
);

export function TableSorting({
  current,
  columns,
  onApply,
  disabled,
  testId,
  pendoId,
}: TableSortingProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("sortingPopup");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const opened = Boolean(anchorEl);

  const currentTitle = getCurrentSortingTitle(current, columns, t);

  return (
    <>
      <Button
        data-testid={testId ? `${testId}-sorting-button` : undefined}
        data-pendo={pendoId ? `${pendoId}-sorting-button` : undefined}
        disabled={disabled}
        className={classes.button}
        variant="outlined"
        startIcon={<OrderBySolid />}
        endIcon={opened ? <ChevronUpSolid /> : <ChevronDownSolid />}
        size="large"
        onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
      >
        {currentTitle}
      </Button>
      {Boolean(anchorEl) ? (
        <TableSortingPopup
          testId={testId ? `${testId}-popup` : undefined}
          pendoId={pendoId ? `${pendoId}-popup` : undefined}
          onApply={(sort) => {
            setAnchorEl(null);
            onApply(sort);
          }}
          onCancel={() => setAnchorEl(null)}
          columns={columns}
          current={current}
          anchorEl={anchorEl}
        />
      ) : undefined}
    </>
  );
}

function getCurrentSortingTitle(
  current: MonitorSortItem,
  columns: TableSortingItem[],
  t: TFunction<"sortingPopup">,
): string {
  switch (current.type) {
    case TableColumnSortOrder.byMostRecentCrawl:
    case TableColumnSortOrder.byOldestCrawl:
      return t("sortingPopup:crawl_finished_date");
    case TableColumnSortOrder.byProjectNameAToZ:
    case TableColumnSortOrder.byProjectNameZToA:
      return t("sortingPopup:project_name");
    case TableColumnSortOrder.bySegmentNameAToZ:
    case TableColumnSortOrder.bySegmentNameZToA:
      return t("sortingPopup:segment_name");
    case TableColumnSortOrder.unused:
    case TableColumnSortOrder.byTrend:
      if (!current.trend) return t("error");
      return (
        columns
          .find((e) => e.code === current.trend?.code)
          ?.sortBy.find((e) => e.code === current.trend?.type)?.title ??
        t("error")
      );
  }
}
