import { ApolloError, NetworkStatus, getRawCrawlId } from "@lumar/shared";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useMostRecentCrawlIdsQuery } from "../../graphql";

interface ReturnType {
  loading: boolean;
  data: number[];
  error: ApolloError | undefined;
}

export const useMostRecentCrawlIds = (skip?: boolean): ReturnType => {
  const { accountId } = useParams<{ accountId: string }>();

  const { data, loading, error, fetchMore, networkStatus } =
    useMostRecentCrawlIdsQuery({
      variables: {
        accountId,
        cursor: null,
      },
      fetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
      skip,
    });

  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const pageInfo = data?.getAccount?.monitorProjects.pageInfo;
  const stillLoading =
    Boolean(pageInfo?.hasNextPage) && Boolean(pageInfo?.endCursor);

  useEffect(() => {
    if (pageInfo?.hasNextPage && pageInfo.endCursor && !isFetchingMore) {
      fetchMore({
        variables: {
          cursor: pageInfo.endCursor,
        },
      });
    }
  }, [fetchMore, isFetchingMore, pageInfo?.endCursor, pageInfo?.hasNextPage]);

  const crawlIds: number[] = useMemo(
    () =>
      data?.getAccount?.monitorProjects.edges
        .map((e) => {
          if (e.node.lastFinishedCrawl?.id) {
            return Number(getRawCrawlId(e.node.lastFinishedCrawl?.id));
          }
          return -1;
        })
        .filter((id) => id > 0) ?? [],
    [data?.getAccount?.monitorProjects.edges],
  );

  return {
    error,
    data: crawlIds,
    loading: loading || isFetchingMore || stillLoading,
  };
};
